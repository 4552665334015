import { UserLocationProvider } from 'app/utils/userLocationService';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GlobalEventsService } from './core/services/globalEvents.service';
import { UtilsComponent } from './utils/utils.component';
import { SweetAlertService } from './utils/sweetAlert.service';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { OrderServices } from './core/interfaces/orders.interfaces';
import { UserServices } from './core/interfaces/user.interfaces';
import { ExternalMenuServices } from './core/interfaces/external-menu.interfaces';
import { DialogCartComponent } from './shared/dynamic-modules/cart/dialog-cart.component';
import { TranslateService } from '@ngx-translate/core';
import { LanguageRouteService } from './core/services/language-route.service';
import { Subscription } from 'rxjs';

declare var jQuery: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html', 
    styleUrls: ['./app.component.css'] 
})
export class AppComponent implements OnInit {
 
    /**
     * Modal de direcciones
     */
    // @ViewChild(AddressModalComponent) addressModal: AddressModalComponent;

    /**
     * Modal de la configuracion de las notificaciones de los productos
     */
    // @ViewChild(NotificationProductsComponent) notificationProductModal: NotificationProductsComponent;

    /**
     * Modal que muestra el detalle de una notificacion
     */
    // @ViewChild(NotificationDetailComponent) notificationDetailModal: NotificationDetailComponent;

    private subsQueryParamsEvent: any;

    /**
     * Indica si el mapa del home esta abierto
     */
    public showMap: boolean;

    /**
     * Suscripción a eventos del router para capturar la ruta al iniciar la navegación (NavigationStart).
     */
    private _routerSubscription: Subscription;

    /**
     * Constructo de la clase
     *
     * @author Jhoan Sebatian Chilito S. - Jul. 31-2018
     * @version 1.0.0
     * @param globalEvents clase para el manejo de eventos globales
     */
    constructor(
        private readonly globalEvents: GlobalEventsService,
        private readonly utils: UtilsComponent,
        private readonly route: ActivatedRoute,
        @Inject('ExternalMenuServices') private readonly externalMenuService: ExternalMenuServices,
        private readonly sweetAlertService: SweetAlertService,
        private userLocation: UserLocationProvider,
        private readonly location: Location,
        @Inject('UserServices') private readonly userServices: UserServices,
        @Inject('OrderServices') public readonly orderServices: OrderServices,
        private dialog: MatDialog,
        private readonly translate: TranslateService,
        private languageRouteService: LanguageRouteService
    ) {
        //this.userLocation.getCountryIp();
        if (this.location.path() === '' ||
            this.location.path() === undefined ||
            this.location.path() === null) {
            this.userServices.deleteCookies();
        }
        this.showMap = false;
        this.utils.hideMainLoader();
        // Se utiliza languageRouteService para obtener el idioma desde la URL.
        this._routerSubscription = this.languageRouteService.routerSubscription;
    }

    /**
     * @see {@link https://angular.io/guide/lifecycle-hooks#lifecycle-hooks Lifecycle Hooks Docs}
     */
    

    /**
     * NOTA: Se coloca que el ngOnInit sea async ya que el metodo getDefaultLang(); 
     * es un método tipo promesa asincrono
     */
    async ngOnInit() {
        this._routerSubscription;
        // Llamamos el método getDefaultLang() para saber que idioma obtener y colocarlo en el sistema
        const language = await this.utils.getDefaultLang();
        this.translate.setDefaultLang(language);
        this.translate.use(language);

        // Si tenemos ya un lenguaje
        if (language) {
            // Esta key LOCALIZE_DEFAULT_LANGUAGE la agrega la libreria de translate, entonces la igualamos para que sea igual
            // a la que el usuario selecciono
            let languageLocalStorage = localStorage.getItem('LOCALIZE_DEFAULT_LANGUAGE');
            if (languageLocalStorage) {
                localStorage.setItem('LOCALIZE_DEFAULT_LANGUAGE', language); // Actualiza el valor en localStorage
            }
        }

        /** NOTA: Se inicializa la variable que contiene la localizacion en el sessionStorage, ya que al borrar cache, y volver a recargar, marcaba un error */

        this.userLocation.handlerLocation()


        /**
         * NOTA: SE CAMBIA EVENTO A CAUSA DE QUE NO SE ESTABA DISPARANDO EN CIERTAS OCASIONES
         * PERO SE DEBE DE ELIMINAR PORQUE PUEDE QUE AL ESTAR ESCUCHANDO SIEMPRE SE VUELVA MAS LENTA LA PAGINA
         * 
         * SE DEVUELVE EL COMMIT
         */
        this.subsQueryParamsEvent = this.route.queryParams.subscribe((queryParams: any) => {
            this.verifiedParams(queryParams);
        });
        // Listener para el manejo de los modales
        this.globalEvents.closeAllModals.subscribe(() => {

            // Cierra el modal de reviews
            jQuery('#ratingModal').modal('hide');

        });
        this.globalEvents.showMap.subscribe(result => this.showMap = result);
    }

    /**
     * Permite identificar si esta intentando ingresar a un menu online
     * si por el contrario esta hacediendo a otra pagina desde un celular
     * debera mostrar una modal indicando que descargue la app
     * @author William Alarcon Marz 27 -2020
     */
    private verifiedParams(queryParams: any) {
        const currentUrl = window.location.href;
        if (queryParams.otherpage === 'true') {
            this.externalMenuService.setInitialPropertiesExternal();
        } else {
            if (currentUrl.indexOf('recover_password') > 0) {
                return;
            }
            if (currentUrl.indexOf('otherpage=true') === -1 && currentUrl.indexOf('terms') === -1 && currentUrl.indexOf('/lng/') === -1) {
                const kindDevice = this.utils.isSmallDevice();
                if (kindDevice.isSmall) {
                    this.sweetAlertService.showcaseStores('DOWNLOAD_FOR_COUPONS', true, kindDevice.so, false)
                }
            }
        }
    }

    /**
     * Abre el carrito como un modal
     */
    public openCart() {
        this.dialog.open(DialogCartComponent, {
            data: {
                restaurantInfo: this.orderServices.getRestaurant(),
                openAs: 'modal',
                selectedTable: this.orderServices.selectedTableOrTurn,
                selectedFloor: this.orderServices.selectedFloor,
                collapseAngleButton: true
            },
            panelClass: ['cartDetail', 'modal-cart']
        }
        );
    }

}
