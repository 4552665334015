import { AddFavoriteRestaurantStructureRequest, ChangePasswordStructureRequest, ModifyProfileStructureRequest, ProfileAddressStructureRequest, ProfileServices } from 'app/core/interfaces/profile.interfaces';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ConfigComponent } from 'app/core/config/config';
import { Profile } from 'app/core/entities/profile';
import { ResponsesStructure } from 'app/core/interfaces/transversal.interfaces';
import { UtilsComponent } from 'app/utils/utils.component';

@Injectable()
export class ProfileService implements ProfileServices{

    /**
     *  Variable para guardar la url del ws para el loyalty cash
     */
    private _urlLoyalty: string;
    
    constructor(
        public httpClient: HttpClient,
        private readonly config: ConfigComponent,
        private readonly injector: Injector
        
        )
    { 
        this._urlLoyalty = '';
    }

    // Variable que almacena la url de los webservices
    private urlOmt = this.config.getUrlOmt();
    private urlLicensor = this.config.getUrlLicensor();

    /**
     * Método encargado de cargar las puntos del usuario
    */
   	
   loadPointLoyalty(): Observable<ResponsesStructure> {
    // Injecto el servicio utilsComponet para saber si el usuario está en un menu online y obtener el id del restaurante
        const utilsComponent = this.injector.get(UtilsComponent);
        // Se hace validación para saber si el usuario está ingresando desde un menu online o desde omt para que se liste
        // el o los restaurantes que son
        if(!utilsComponent.externalMenu){
            this._urlLoyalty = `${this.urlOmt}cli/loyaltyPoints`;
        }else{
            this._urlLoyalty = `${this.urlOmt}cli/loyaltyPoints?restaurantId=${utilsComponent.idRestaurantExt}`
        }
        return this.httpClient.get<ResponsesStructure>(this._urlLoyalty).pipe(
            map((response: ResponsesStructure) => {
                console.log(response);
                return response;
            }),
            catchError(e => {
            return throwError(e);
            })
        );
    }

     /**
     * Método encargado de cargar las puntos del usuario por restaurant
    */
   	
    loadPointLoyaltyRestaurant(nickname:any, phone:any): Observable<any> { 
        let params = new HttpParams();
            params = params.append('nickname', nickname);
            params = params.append('phone', phone);        

        const url = this.urlLicensor + 'getAllClientsPointAccountByRestaurant';
        console.log('url loyalty', url);
        return this.httpClient.get<any>(url, { params: params }).pipe(
            map((response: any) => {
                console.log(response);
                return response;
            }),
            catchError(e => {
            return throwError(e);
            })
        );
    }

    

    /**
     * Permite guardar el idioma establecido por el usuario en la configuracion de perfil
     */
    /**
     * Se realiza el tipado general del servicio consumido, ya que tanto el request como el data del response
     * del mismo, son variables de tipo string 
     */
    saveLang(lang: string): Observable<ResponsesStructure> {
        const url = `${this.urlOmt}cli/set-lang`;
        return this.httpClient.post<ResponsesStructure>(url, {'lang': lang}).pipe(
            map((response: ResponsesStructure) =>{
                return response;
            }),
            catchError(e => {
              return throwError(e);
            })
        );
    }

    /**
     * Metodo encargado de devolver la posición del ssuario
     * @param data
     */
    /**
     * Se realiza la creación de las interfaces con las estructuras para el tipado del request y el 
     * response de la petición del servicio trabajado
     */
    public getUserLatLng(data: ProfileAddressStructureRequest): Observable<ResponsesStructure> {
        const url = `${this.urlOmt}address`;
        return this.httpClient.post<ResponsesStructure>(url, data).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),
            catchError(e => {
              return throwError(e);
            })
        );
    }

    /**
     * Método encargado de cargar las direcciones del usuario
    */
   /**
    * Se realiza la creación de las interfaces para el tipado del request y el response del servicio
    * consumido y se realizar la actualización en sus implementaciones mediante la inyección de 
    * servicios
    */
    load(): Observable<ResponsesStructure> {
        const url = `${this.urlOmt}cli/address`;
        return this.httpClient.get<ResponsesStructure>(url).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),
            catchError(e => {
              return throwError(e);
            })
        );
    }

    /**
     * Método encargado de eliminar una dirección de la base de datos de acuerdo a un id que recibe como parámetro
     * @param id Es el identificador de la dirección a eliminar
     */
    /**
     * Se realiza el ajuste del tipado general de la petición del servicio, ya que para el request y para
     * el response del mismo, no fue necesario
     */
    delete(id: string): Observable<ResponsesStructure> {
        const url = `${this.urlOmt}cli/address/${id}`;
        return this.httpClient.delete<ResponsesStructure>(url).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),
            catchError(e => {
              return throwError(e);
            })
        );
    }

    /**
     * Método encargado de guardar la direccion del usuario
     * @param data es la información de la dirección a guardar
    */
   /**
    * Se realizó la creación de las interfaces referentes a las estructuras de los request y responses
    * de las peticiones de los servicios trabajados
    */
    save(data: ProfileAddressStructureRequest, method: string): Observable<ResponsesStructure> {
        // En caso de ser una nueva dirección
        if (method === 'POST') {
            const url = `${this.urlOmt}cli/address`;
            return this.httpClient.post<ResponsesStructure>(url, data).pipe(
                map((response: ResponsesStructure) => {
                    return response;
                }),
                catchError(e => {
                  return throwError(e);
                })
            );
            // En caso de estar editando una dirección
        } else if (method === 'PUT') {
            const url = `${this.urlOmt}cli/address/${data.id}`;
            return this.httpClient.put<ResponsesStructure>(url, data).pipe(
                map((response: ResponsesStructure) => {
                    return response;
                }),
                catchError(e => {
                  return throwError(e);
                })
            );
        }
    }

    /**
     * Permite establecer una direccion como principal
     * @param address_id
     */
    /**
     * Se realiza el tipado de la petición en general del servicio, ya que del request y del response
     * no fue necesario, en el request se envía una variable de tipo boolean y el data response es
     * una variable de tipo string
     */
    setMainAddress(address_id: string, modify: boolean): Observable<ResponsesStructure> {
        const url = `${this.urlOmt}cli/address/${address_id}/as-main`;
        return this.httpClient.post<ResponsesStructure>(url, {'modify': modify}).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),
            catchError(e => {
              return throwError(e);
            })
        );
    }

    /**
     * Permite actualizar el perfil del usuario
     * @param profile
     */
    /**
     * Se realiza el tipado del request de la petición del servicio trabajado, el data del response es 
     * un string
     */
    update(profile: Profile): Observable<ResponsesStructure> {
        const dataProfile: ModifyProfileStructureRequest = {
            name: profile.name,
            lastname: profile.lastname,
            phone: profile.phone,
            birthdate: profile.birthdate
        };
        const url = `${this.urlOmt}cli/profile`;
        return this.httpClient.put<ResponsesStructure>(url, dataProfile).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),
            catchError(e => {
              return throwError(e);
            })
        );
    }

     /**
     * Permite validar el nuevo telefono atraves del codigo de 6 digitos
     * @param data
     */
    validatePhone(data: any): Observable<ResponsesStructure> {
        const dataValidate: any = {
            phone: data.phone,
            code: data.code,
        };
        const url = `${this.urlOmt}cli/validateCodeToChangePhone`;
        return this.httpClient.post<ResponsesStructure>(url, dataValidate).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),
            catchError(e => {
              return throwError(e);
            })
        );
    }


    /**
     * Metodo para obtener datos de perfil
     */
    /**
     * Se crea la interface para la respuesta de la petición del servicio trabajado
     */
    getProfile(phone: boolean = false): Observable<ResponsesStructure> {
        if (phone) {
            const url = `${this.urlOmt}cli/profile?phone=${phone}`;
            return this.httpClient.get<ResponsesStructure>(url).pipe(
                map((response: ResponsesStructure) => {
                    return response;
                }),
                catchError(e => {
                  return throwError(e);
                })
            );
        }
        const url = `${this.urlOmt}cli/profile`;
        return this.httpClient.get<ResponsesStructure>(url).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),
            catchError(e => {
              return throwError(e);
            })
        );
    }

    /**
     * Servicio que permite ejecutar el cambio de contraseña
     */
    /**
     * Se realiza la creación de las interfaces referentes al tipado de las estructuras del request y
     * response de la petición del servicio trabajado
     */
    updatePassword(data: ChangePasswordStructureRequest): Observable<ResponsesStructure> {
        const url = `${this.urlOmt}cli/changePassword`;
        return this.httpClient.put<ResponsesStructure>(url, data).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),
            catchError(e => {
              return throwError(e);
            })
        );
    }

    /**
     * Servicio para marcar un restaurante como favorito
     *
     * @param favRestaurantId Es el id del restaurante a marcar como favorito
     */
    /**
     * Se realiza la creación de la interface del request de la petición para el tema del tipado del servicio
     * trabajado 
     */
    setFavorite(favRestaurantId: string): Observable<ResponsesStructure> {
        const data: AddFavoriteRestaurantStructureRequest = { 
            restaurant_id: favRestaurantId 
        };
        const url = `${this.urlOmt}cli/favoriteRestaurant`;
        return this.httpClient.post<ResponsesStructure>(url, data).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),
            catchError(e => {
              return throwError(e);
            })
        );
    }

    /**
     * Servicio para desmarcar un restaurante como favorito
     */
    /**
     * No es necesario crear interfaces para el tipado del request y data response de la petición del 
     * servicio trabajado, ya que ambas son variables string
     */
    deleteFavorite(favRestaurantId: string): Observable<ResponsesStructure> {
        const url = `${this.urlOmt}cli/favoriteRestaurant/${favRestaurantId}`;
        return this.httpClient.delete<ResponsesStructure>(url).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),
            catchError(e => {
              return throwError(e);
            })
        );
    }

    /**
     * Servicio para cargar lista de favoritos
     */
    /**
     * Se realiza la creación de las interfaces para el tipado del request y response del servicio
     * consumido y se actualizan sus implementaciones mediante la inyección de servicios en la 
     * interface correspondiente
     */
    getFavoritesList(freeText: string = null): Observable<ResponsesStructure> {
        let params = new HttpParams();
        if (freeText !== null) {
            params = params.append('free_text', freeText);
        }
        const url = `${this.urlOmt}cli/favoriteRestaurants`;
        return this.httpClient.get<ResponsesStructure>(url, {'params': params}).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),
            catchError(e => {
              return throwError(e);
            })
        );
    }

    /**
     * Servicio para actualizar la imagen de perfil
     * @param image
     */
    /**
     * Se realiza la creación de las interfaces referentes a las estructuras del request y el response
     * de la petición del servicio trabajado
     */
    saveImageProfile(image: any): Observable<ResponsesStructure> {
        const headers = new HttpHeaders({
            'Content-Type': 'multipart/form-data'
        });
        const formData = new FormData();
        formData.append('file', image);
        const url = `${this.urlOmt}cli/profileImage`;
        return this.httpClient.post<ResponsesStructure>(url, formData, { headers: headers }).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),
            catchError(e => {
              return throwError(e);
            })
        );
    }

}

