import { Injectable } from '@angular/core';
/**
 * Clase dedicada a contener todas las constantes de la aplicacion
 *
 * @author Jhonier Gaviria M. - Ago. 01-2018
 * @version 1.0.0
 */
@Injectable()
export class AppConstants {

    static readonly RANGE_MIN_VALUE = 100;
    static readonly RANGE_MAX_VALUE = 10000;
    static readonly CHARGE_VALUE = 0;
    static readonly CONST_GOOGLE = 'GOOGLE';
    static readonly CONST_FACEBOOK = 'FACEBOOK';
    static readonly DECIMALS_NUMBER = {
        TWO: 2,
        THREE: 3
    };
    /**
     * Constantes que definen el tipo de proyecto
     */
    public static readonly PROJECT_TYPE = {
        PROJECT_OMT_LITE: 1, /** LEVEL LITE */
        PROJECT_OMT_WEB : 2, 
        PROJECT_OMT_APP : 3, 
        PROJECT_SUPER_DA: 4,         
        PROJECT_OMT_GO  : 5
    };
    /**
     * Constantes que definen el tipo de error generado
     */
    public static readonly ERROR_TYPE = {
        CLIENT_ERROR: 1,
        SERVER_ERROR : 2,
    };
    
    /**
     * Nombre de las variables del sesionStorage
     */
    public static readonly SESSION_STORAGE = {
        USER_COUNTRY: 'userCountry'
    };

    public static readonly PHONE_NUMBER_MASK = {
        MASKED: '(000) 000-0000',
        UNMASKED: '0000000000'
    };

    /**
     * Distancia maxima entre el usuario y el restaurante
     */
    static MAXIMUM_DISTANCE = 30;

    /**
     * Almacena nombres de las variables del local storage
     */
    public static readonly LOCAL_STORAGE_DATA = {
        LANGUAGE_USER: 'omt_web__LANGUAGE__USER__',
        DATA_USER: 'omt_web_dataUser',
        AUTH_TOKEN: 'omt_web_auth_token',
        DATA_SESSION: 'omt_web_dataSession',
        USE_UPON_DELIVERY: 'omt_web_useUponDelivery',
        PHOTO_URL: 'omt_web_photoUrl',
        REMEMBER_LOGIN: 'omt_web_REMEMBER_LOGIN',
        SOCIAL_LOGIN: 'omt_web_loguinSocial',
        CART_DELIVERY_DETAIL: 'omt_web__cart__deliveryDetail',
        MENU_DATA: 'omt_web_menu',
        LANGUAGE_ROUTE: 'omt_web_language_route',
    };

    /**
     * Almacena nombres de las variables del sessions storage
     */
    public static readonly SESSIONS_STORAGE_DATA = {
        NEAR_SEARCH: 'omt_web_near_search',
        NEAR_SEARCH_COOKIE: 'omt_web_near_search_cookie',
        RESTAURANT_NAME: 'omt_web_restaurantName',
        USER_COUNTRY: 'omt_web_userCountry',
        USER_POSITION: 'omt_web_userPosition',
        PARAMS_SEARCH_RESTAURANT: 'omt_web_paramsSearchRestaurant'
    };

    /**
     * Constates de estado de pedido
     */
    static readonly CONSTANTS_STATE = {
        CANCELED: 0,
        PENDING: 3,
        ALSO_PENDING: 1,
        RECEIVED: 4,
        BEING_PREPARED: 5,
        PREPARED: 6,
        DELIVERY_ON_SITE: 7,
        SEND_TO_DELIVERY: 8,
        SEND_TO_CUSTOMER: 9,
        ARRIVES_AT_DESTINATION: 10,
        DELIVERED: 11
    };
    /**
     * Numero de items or pagina en los listados
     */
    static readonly ITEMS_PER_PAGE_LIST = 21;

    /**
     * Defibe el tiempo que se muestran las notificaciones
     */
    static readonly TIME_NOTIFICATIONS = 8000;

    /**
     * Cantidad de items a traer por pagina para las notificaciones
     */
    static readonly ITEMS_PER_PAGE_NOTIFICATION_LIST: number = 50;

    
    /**
     * Llave secreta del servicio de recaptcha
     */
    static readonly SECRET_KEY_RECAPTCHA: string = '6LctwgAVAAAAAEqc_8Ec5emQ6tLYWFBVRx4mffLB';
    /**
     * Rango en kilometros para la busqueda en los filtros del listado del restaurante
     */
    static readonly RANGE_SEARCH_RESTAURANTS = {
        FIVE_MILLES: 8000,
        TEN_MILLES: 16094,
        FIFTEEN_MILLES: 24140,
        TWENTY_MILLES: 32187,
    };

    /**
     * Nota: El originNumber es el número establecido para obtener de que plataforma se realizó la compra.
     * 1 = level
     * 2 = Open My Tab Web
     * 3 = Open My Tab App
     * 4 = Menu Online
     * 5 = White Label
     */
    /**
     * Variable identificadora de la página
     */
    public static readonly BRAND_ALIAS = {
        name: 'OPEN_MY_TAB',
        originNumber: 2
    };

    /**
    * Variable identificadora de la página (MENU_ONLINE);
    */
    public static readonly BRAND_ALIAS_ONLINE = {
        name: 'MENU_ONLINE',
        originNumber: 4
    };

    /**
     * Servicios que puede tener un restaurante.
     */
    public static readonly SERVICES_RESTAURANT = {
        SERVICE_PICKUP: 1,
        SERVICE_DELIVERY: 2,
        SERVICE_DINE_IN: 3,
        SERVICE_COUPON: 4
    };

    /**
    * Constates de metodos de pago por contra entrega
    */
    public readonly UPON_DELIVERY_PAY_METHOD = {
        CASH: 'cash',
        CREDIT_CARD: 'credit_card',
        CREDIT_DEBIT: 'credit_debit',
        BONUS: 'bonus'
    };
    // NOTA: Estás variables deben de ser las últimas, ya que si agregan más después de ellas, las toma como inexistentes.
    /**
     * variables constantes de la appweb.
     */
    public readonly maxCharactersNotes = 200;
    public readonly maxChatactersReviews: any = 5000;

    // tslint:disable-next-line:member-ordering
    public static readonly TAPS_TRACK = {
        TYPE_RESTAURANT_CLICKED: 1,
        TYPE_REVIEWS_CLICKED: 2,
        TYPE_PHONE_CLICKED: 3,
        TYPE_PHONECALLED_CLICKED: 4,
        TYPE_ADDRESS_CLICKED: 5,
        TYPE_COUPONS_CLICKED: 6,
        TYPE_RESTAURANTHOME_CLICKED: 7,
        TYPE_COUPONSPECIFIC_CLICKED: 8,
        // Si redime el cupon
        TYPE_COUPONREEDEM_CLICKED: 9,
        TYPE_OFFERSINPLACE_CLICKED: 10,
        TYPE_OFFERSOUTPLACE_CLICKED: 11,
        TYPE_ASK_FOR_COUPONS: 12
    };

    static readonly ORDER_STATUS  = {
        pending: 1,
        dispatch: 2,
        billed: 3,
        deferredOrder: 3
    };

}

