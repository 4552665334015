import { ProductModifier } from './ProductModifier';

export class ProductSale {
    public product_id: string;
    public name: string;
    public product_size_id: string;
    public qty: number;
    public sold_value: number;
    public setTotalModifiers: number; // total sumado de los modificadores sin impuestos
    public total: number; // total de venta con impuestos
    public salePrice: number; // valor original del producto
    public ode_sub_total: number; // valor original del producto
    public productDescription: string;
    public itt_id: number;
    public itParent: number;
    public tax_total: number;
    public ode_status: number;
    public tax_include: number;
    public ode_discount: number;
    public ode_discount_data: string;
    public taxes: Array<any>;
    public note: string;
    public modifiers: Array<ProductModifier>;
    public ode_deferred_date?: any;


    constructor() {
        this.product_id = null;
        this.product_size_id = null;
        this.qty = null;
        this.sold_value = null;
        this.setTotalModifiers = null; // total sumado de los modificadores sin impuestos
        this.total = null; // total de venta con impuestos
        this.salePrice = null; // valor original del producto
        this.ode_sub_total = null; // valor original del producto
        this.productDescription = null;
        this.itt_id = null;
        this.itParent = null;
        this.tax_total = null;
        this.ode_status = null;
        this.tax_include = null;
        this.ode_discount = null;
        this.ode_discount_data = null;
        this.taxes = [];
        this.note = null;
        this.modifiers = [];
        this.ode_deferred_date = '';
    }



}
