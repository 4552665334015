import { LocalizeRouterModule } from 'localize-router';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

export const routes: Routes = [];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    LocalizeRouterModule.forChild(routes)],
  exports: [
    RouterModule,
    LocalizeRouterModule]})
export class SharedRoutingModule { }
