import { OrderTotals } from 'app/core/entities/OrderTotals';
import { Inject, Injectable } from '@angular/core';
import { AppConstants } from 'app/app-constants';
import { UtilsComponent } from 'app/utils/utils.component';
import { TaxesServices } from 'app/core/interfaces/taxes.interfaces';
import { OrderServices } from 'app/core/interfaces/orders.interfaces';

@Injectable()
export class TaxesService implements TaxesServices{
    responseTaxes = {
        total: 0,
        subTotal: 0,
        shipping: 0,
        taxes: 0
    };

    public COUNTRY = 'US';
    public detailSale: OrderTotals;
    public enableSmartTaxes = true;
    public smartTax = false;
    public arrayProductDetail: any = [];
    public currentTaxPercentTotal = 0;
    public numberDecimals = 2;

    constructor(@Inject('OrderServices') private readonly orderServices: OrderServices,
        private readonly utils: UtilsComponent,) {
        this.detailSale = new OrderTotals();
    }

    /**
     * Permite calcular los valores totales
     */
    public calculateTotalPrice(products: any, charge: any, tip = 0): Promise<any> {
        this.arrayProductDetail = products;
        return new Promise((resolve) => {
            // primero reseteamos los valores
            this.resetTotals();
            // Asignamos el valor del domicilio
            this.detailSale.charges = charge;
            this.detailSale.tip = tip;
            resolve(this.checkSmartTaxes());
        });
    }

    /**
      * Finalidad del metodo es recorrer los productos para verificar si hay
      * productos con impuestos incluidos y productos con impuestos no incluidos
      * en el carrito de compras, con esto se determina si se debe aplicar
      * "smartTax" que por cierto este debe ser consultado desde level configuracion
      * del restaurante
      *
      */
    checkSmartTaxes(): Promise<any> {
        this.smartTax = false;
        return new Promise((resolve) => {
            // en caso de que el restaurante tenga la opción de smarttax habilitada
            if (this.enableSmartTaxes) {
                let include = false, noInclude = false;
                for (const product of this.arrayProductDetail) {
                    if (product.taxInclude) {
                        include = true;
                    } else {
                        noInclude = true;
                    }
                    if (include && noInclude) {
                        break;
                    }
                }
                if (include && noInclude) {
                    this.smartTax = true;
                }
            }
            resolve(this.setSmartTax());
        });
    }

    /**
     * pone el valor del odet_smart_taxes en los impuestos de los productos
     * @returns {Promise<T>}
     */
    setSmartTax(): Promise<any> {
        return new Promise(resolve => {
            // se recorren los productos recibidos que fueron asignados a esa variable
            for (const product of this.arrayProductDetail) {
                const currentTaxesProduct = [];
                // se recorren los taxes por cada producto
                for (const currentTax of product.taxInformation.taxes) {
                    if (!currentTax.odet_smart_tax || currentTax.odet_smart_tax === 0) {
                        currentTax.odet_smart_tax = 0;
                        currentTaxesProduct.push(currentTax);
                        if (this.smartTax && product.taxInclude) {
                            const copyTax = JSON.parse(JSON.stringify(currentTax));
                            copyTax.odet_smart_tax = 1;
                            currentTaxesProduct.push(copyTax);
                        }
                    }
                }
                product.tax = currentTaxesProduct;
            }
            resolve(this.calculateTaxProduct());
        });
    }

    /**
     * suma el total de taxes de una orden
     * @returns {number}
     */
    totalTax(): number {
        let totalTax = 0;
        for (const tax of this.detailSale.taxs) {
            totalTax += Number(tax.total);
        }
        return totalTax;
    }

    /**
     * calcula todos los impuestos de un producto
     * @param productDetail
     * @param taxValue
     * @returns {number}
     */
    calculateMultiTax(productDetail: any, taxValue: any, subTotalProduct: any): number {
        let totalTaxe = 0;
        let existTax;
        let currentTaxPercent;
        let tax;
        currentTaxPercent = Number(taxValue.percent);
        if (this.COUNTRY === 'US') {
            // el sub total se calcula siempre con el valor del producto de venta sin importar si tiene tax incluido o
            // no incluido.
            if (taxValue.odet_smart_tax === 0) {
                // this.detailSale.subTotal += subTotalProduct;
            }
            // verificamos que no este exenta de impuestos
            if (this.detailSale.exemptTaxComment === null) {
                // bandera para saber si el impuesto existe sobre el producto
                existTax = false;
                // se asigna el valor del subTotal para ser guardado en bd
                productDetail.subTotal = subTotalProduct;
                // validamos si esta incluido
                if (productDetail.taxInclude && (taxValue.odet_smart_tax === 1 || !this.smartTax)) {
                    subTotalProduct = subTotalProduct / (1 + this.currentTaxPercentTotal);
                }
                for (let i = 0; i < this.detailSale.taxs.length; i++) {
                    tax = this.detailSale.taxs[i];
                    // se verifica si el impuesto ya existe
                    if (tax.id === taxValue.id) {
                        // se suma el impuesto del producto nuevo al valor
                        // que ya se tenia previamente
                        totalTaxe = currentTaxPercent * subTotalProduct;
                        if ((!productDetail.taxInclude && taxValue.odet_smart_tax === 0) ||
                            (productDetail.taxInclude && taxValue.odet_smart_tax === 0 && this.smartTax)) {
                            tax.total = Number(tax.total) + totalTaxe;
                            productDetail.total = Number(productDetail.total) + totalTaxe;
                        }
                        if (productDetail.taxInclude && taxValue.odet_smart_tax === 0) {
                            this.detailSale.taxsIncluide += totalTaxe;
                        }
                        taxValue.value = this.utils.roundDecimals(totalTaxe, AppConstants.DECIMALS_NUMBER.THREE);
                        existTax = true;
                        break;
                    }
                }
                // se verifica el cambio de la bandera
                if (!existTax) {
                    // se agrega el impuesto nuevo al arreglo de impuestos
                    totalTaxe = Number(currentTaxPercent) * subTotalProduct;
                    if (taxValue.odet_smart_tax === 0 &&
                        (!productDetail.taxInclude || this.smartTax)) {
                        this.detailSale.taxs.push({
                            percentage: currentTaxPercent,
                            total: totalTaxe,
                            id: taxValue.id
                        });
                    }
                    if (productDetail.taxInclude && taxValue.odet_smart_tax === 0) {
                        this.detailSale.taxsIncluide += totalTaxe;
                    }
                    taxValue.value = this.utils.roundDecimals(totalTaxe, AppConstants.DECIMALS_NUMBER.THREE);
                }

            }
        }
        /*if (this.COUNTRY === 'CO') {
            //  bandera para saber si el impuesto existe
            // sobre el producto
            existTax = false;
            subTotalProduct = Number(productDetail.total) / (1 + this.currentTaxPercentTotal);
            for (let j = 0; j < detailSale.taxs.length; j++) {
                tax = detailSale.taxs[j];
                // se verifica si el impuesto ya existe
                if (angular.equals(tax.tax_id, taxValue.tax_id)) {
                    let valueTax = tax.total;
                    currentTaxvalue = UtilitiesFactory.roundDecimals((currentTaxPercent *
                     Number(subTotalProduct)), CONSTANTS.APP.DECIMALS.NUMBER);
                    //  se suma el impuesto del producto nuevo al valor
                    //   que ya se tenia previamente
                    taxValue.value = currentTaxValue;
                    totalTaxe = UtilitiesFactory.roundDecimals((valueTax + currentTaxValue),
                        CONSTANTS.APP.DECIMALS.NUMBER);
                    tax.total = totalTaxe;
                    tax.total = UtilitiesFactory.roundDecimals(tax.total, CONSTANTS.APP.DECIMALS.NUMBER);
                    // se retorna la bandera de manera afirmativa
                    existTax = true;
                    break;
                }
            }
            // se verifica el cambio de la bandera

            if (!existTax) {
                // se agrega el impuesto nuevo al arreglo de impuestos
                // totalTaxe = UtilitiesFactory.roundDecimals(currentTaxPercent * subTotalProduct, CONSTANTS.APP.DECIMALS.NUMBER);
                totalTaxe = currentTaxPercent * subTotalProduct;
                //console.log('cuando no exyste para el detalle y se procesa', totalTaxe)
                taxValue.value = totalTaxe;
                detailSale.taxs.push({
                    percentage: currentTaxPercent,
                    total: totalTaxe,
                    tax_id: taxValue.tax_id
                });
            }
            return totalTaxe;
        }*/
        if (taxValue.odet_smart_tax === 1) {
            totalTaxe = 0;
        }
        return totalTaxe;
    }

    /**
   * ajusta el total del producto dependiendo de los descuentos realizados
   * @param item
   * @param totalModifier
   */
    applyDiscountByItem(item: any, totalModifier: any): void {
        const totalOfItem = (Number(item.size.price) * Number(item.size.quantity)) + Number(totalModifier);
        // COMENTADO YA QUE NO SE ESTA USANDO
        // Se verifica que el producto tenga descuentos
        /*if (Number(item.product.ode_discount) > 0) {
          //Se suma todos los descuentos de los items
          this.detailSale.discountTotalItems += Number(item.size.ode_discount);
          item.total = totalOfItem - Number(item.size.ode_discount);
        } else {*/
        item.total = totalOfItem;
        // }
        // Si tiene cupones asociados
        item.discount = 0;
        if (item.coupons) {
            // se recorren los cupones agregados al producto para restar
            for (const coupon of item.coupons) {
                item.total -= coupon.discount;
                item.discount += coupon.discount;
                // this.detailSale.discountItemsCoupon += coupon.discount;
            }
        }
    }

    /**
     * Método encargado de calcular los descuentos que existen en la orden
     * NOTA: De momento solo de cupones generales
     * @author Jhonier Gaviria M.
     */
    calculateDisccounts(): void {
        for (const coupon of this.orderServices.coupons) {
            // Si el cupón se aplica de forma general
            if (coupon.type === 'general') {
                this.detailSale.discountOrderCouponData.discount += Number(coupon.discount);
            }
        }
    }

    /**
     * metodo que permite iterar los productos para calcular los taxes de cada uno y a su vez actualizar el valor
     * total de cada producto
     *
     * @returns {boolean}
     */
    calculateTaxProduct(): OrderTotals {
        // se verifica si el arreglo de impuesto esta inicializado
        if (this.detailSale.taxs.length === 1 && (!this.detailSale.taxs[0].id || this.detailSale.taxs[0].id === '')) {
            this.detailSale.taxs = [];
        }
        let totalOfItem, totalCopy;
        for (const productDetail of this.arrayProductDetail) {
            // productDetail.setTotalModifiers = productDetail.setTotalModifiers ? productDetail.setTotalModifiers : 0;
            totalOfItem = Number(productDetail.size.price) * Number(productDetail.size.quantity);
            this.applyDiscountByItem(productDetail, productDetail.setTotalModifiers);
            totalCopy = totalOfItem + productDetail.setTotalModifiers;
            // se asigna el valor del subTotal para ser guardado en bd
            productDetail.subTotal = totalCopy;
            this.currentTaxPercentTotal = 0;
            // determina el total de taxe de un producto para poder carcular su subtotal real
            for (let i = 0; i < productDetail.tax.length; i++) {
                if (productDetail.tax[i].odet_smart_tax === 0) {
                    this.currentTaxPercentTotal += Number(productDetail.tax[i].percent);
                }
            }
            // hacemos el descuento de impuestos para los que son productos con impuesto incluidos
            if (this.COUNTRY === 'US' && productDetail.taxInclude && this.detailSale.exemptTaxComment) {
                // calculamos el descuento de los impuestos incluido por producto
                // calculamos el valor total por la cantidad seleccionada
                // productDetail.total = totalCopy - (tmpDiscountTax * Number(productDetail.product.cant));
            }
            // calculamos el total de la orden sumando uno a uno el total de los productos
            /*if (CONSTANTS.APP.COUNTRY === "CO") {
             this.detailSale.subTotal += UtilitiesFactory.roundDecimals(Number(totalCopy /
             (1 + currentTaxPercentTotal)), CONSTANTS.APP.DECIMALS.NUMBER);
             } else {

             }*/
            productDetail.totalTax = 0;
            // calculamos precios en caso de que el producto tenga asociados algun impuesto
            // si no se toma el valor total de cada producto
            if (productDetail.tax.length > 0) {
                // obtenemos el valor
                const subTotalProduct = this.getSubTotalByProduct(productDetail);
                this.detailSale.preSubTotal += subTotalProduct + productDetail.discount;
                this.detailSale.subTotal += subTotalProduct;
                // se recorren los taxes de cada producto y calculamos el valor del tax
                // partiendo del precio con el que se venderia el producto
                for (let j = 0; j < productDetail.tax.length; j++) {
                    const total = this.calculateMultiTax(productDetail, productDetail.tax[j], subTotalProduct);
                    productDetail.totalTax += total;
                }
            } else {
                this.detailSale.subTotal += productDetail.total;
            }
            // productDetail.total = totalCopy;
        }
        this.calculateDisccounts();
        this.detailSale.totalTax = this.totalTax();
        this.detailSale.subTotal = Number(Number(this.detailSale.subTotal).toFixed(this.numberDecimals));
        this.detailSale.discounts.total = Number(Number(this.detailSale.discounts.total).toFixed(this.numberDecimals));
        let totalDetailOrder =
            this.detailSale.subTotal -
            this.detailSale.discounts.total -
            this.detailSale.discountOrderCouponData.discount +
            this.detailSale.charges + this.detailSale.tip;
        // se redondean valores
        totalDetailOrder = Number(totalDetailOrder.toFixed(this.numberDecimals));
    
        // Se redondea los taxes
        this.detailSale.taxs = this.detailSale.taxs.map(tax => ({...tax, total: parseFloat(tax.total.toFixed(2))}));
    
        // Se recalcula el total usando los valores de los taxes redondeados
        const totalOrder = Number(totalDetailOrder) + this.detailSale.taxs.reduce((sum, tax) => sum + tax.total, 0);
    
        // se redondean valores
        this.detailSale.total = Number(totalOrder.toFixed(this.numberDecimals));
        // calculo de level Zero y total con Level Zero
        /*this.detailSale.levelZero = UtilitiesFactory.roundDecimals((this.detailSale.total *
         CONSTANTS.APP.LEVEL_ZERO.LEVEL_PERCENTAGE), numberDecimals);
         this.detailSale.totalWhitLevelZero = UtilitiesFactory.roundDecimals((Number(this.detailSale.total) +
         Number(this.detailSale.levelZero)),
         numberDecimals);*/
        // mientras se define el manejo de valores para impuesto, se asigna el mismo valor a total
        this.detailSale.taxes = Number(this.detailSale.total) - Number(this.detailSale.charges) - Number(this.detailSale.subTotal);
        return this.detailSale;
    }

    /**
     * Calcula el subtotal de un producto 
     * CREAR INTERFAZ DE PRODUCT
     * @param product {
     *                      size:{price,quantity},
     *                      setTotalModifiers,
     *                      discount
     *                  }
     */
    public getSubTotalByProduct(product: any): number {
        return (product.size.price * product.size.quantity) + product.setTotalModifiers
            - product.discount;
    }

    /**
     * Permite reiniciar las variables de los totales
     */
    private resetTotals() {
        this.responseTaxes.subTotal = 0;
        this.responseTaxes.shipping = 0;
        this.responseTaxes.taxes = 0;
        this.responseTaxes.total = 0;
        this.detailSale.taxs = [];
        this.detailSale.taxsIncluide = 0;
        this.detailSale.totalTax = 0;
        this.detailSale.preSubTotal = 0;
        this.detailSale.subTotal = 0;
        this.detailSale.charges = 0;
        this.detailSale.discounts.total = 0;
        this.detailSale.discountOrderCouponData.discount = 0;
    }
}
