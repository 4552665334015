export class Payment {
    public ipr_history_key_id: string;
    public ipr_authcode: string;
    public ipr_pay_type: number;
    public ipr_card_name: string;
    public ipr_order_key_id: string;
    public ipr_cliaccount: string;
    public ipr_ref_code: string;
    public ipr_maccount: string;
    public ipr_total: number;
    public ipr_initial_total: number;
    public ipr_merchant_id: string;
    public ipr_terminal_id: string;
    public ipr_sequence_number: string;
    public ipr_date_time: string;
    public ipr_ip_device: string;
    public ipr_port_device: string;
    public ipr_credit_card_id: string;
    public ipr_credit_card_data: string;
    public paymentTokenId?: string;
    public save: boolean;
    constructor() {
        this.paymentTokenId = '';
        this.save = false;
    }
}
