import { ErrorHandler, Inject, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { AppConstants } from 'app/app-constants';
import { ResponsesStructure } from 'app/core/interfaces/transversal.interfaces';
import { ErrorServices } from '../interfaces/error.interfaces';
import { LoggingServices } from '../interfaces/logging.interfaces';
import { UtilsComponent } from 'app/utils/utils.component';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    constructor(private readonly injector: Injector,
        @Inject('ErrorServices') private readonly errorService: ErrorServices,
        @Inject('LoggingServices') private readonly loggingServices: LoggingServices,
        private utils: UtilsComponent,) { }

    handleError(error: Error | HttpErrorResponse) {
        // const notifier = this.injector.get(SweetAlertService);
        let typeError: number;
        let message;
        let stackTrace;
        if (error instanceof HttpErrorResponse) {
            // Server Error
            message = this.errorService.getServerMessage(error);
            stackTrace = this.errorService.getServerStack(error);
            typeError = AppConstants.ERROR_TYPE.SERVER_ERROR;
        } else {
            // Client Error
            message = this.errorService.getClientMessage(error);
            stackTrace = this.errorService.getClientStack(error);
            typeError = AppConstants.ERROR_TYPE.CLIENT_ERROR;
        }
        // Always log errors
        this.loggingServices.logError(message, stackTrace, typeError).subscribe((response: ResponsesStructure) => {
            console.log('Logging Exitoso ', response);
        }, error => {
            console.log('Error en Logging', error);
        });
        // console.error(error);
    }
}
