import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { DynamicComponentLoader } from 'app/core/dynamic-loader/dynamic-component-loader.service';

@Component({
    selector: 'app-dialog-directions-component',
    template: '<div #directionsModal></div>'})
export class DialogDirectionsComponent {
    @ViewChild('directionsModal', { read: ViewContainerRef, static: true }) _directionsModal:
        | ViewContainerRef
        | undefined;

    constructor(private readonly loader: DynamicComponentLoader) {
        this.loader.getComponentFactory('directions-modal').subscribe({
            next: factory => {
                if (!this._directionsModal) {
                    return;
                }
                this._directionsModal.createComponent(factory).changeDetectorRef.detectChanges();
            }
        });
    }
}
