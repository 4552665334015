import { LocalizeParser, LocalizeRouterModule, LocalizeRouterSettings } from 'localize-router';
import { RouterModule, Routes } from '@angular/router';

import { HttpClient } from '@angular/common/http';
import { LocalizeRouterHttpLoader } from 'localize-router-http-loader';
import { Location } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export const routes: Routes = [
    { path: '', redirectTo: 'app', pathMatch: 'full' },
    { path: 'app', loadChildren: () => import('./feature/views/pages.module').then(m => m.PagesModule) },
    { path: 'pages', loadChildren: () => import('./feature/views/pages.module').then(m => m.PagesModule) },
    { path: 'lng', loadChildren: () => import('./feature/landing-page/landing-page.module').then(m => m.LandingPageModule) },
    { path: 'payment', loadChildren: () => import('./feature/views/payment-gateway/payment-gateway.module').then(m => m.PaymentGatewayModule) },
    { path: '**', loadChildren: () => import('./feature/views/not-found/not-found.module').then(m => m.NotFoundModule) },
];

/**
 * Permite realizar la carga las extensiones de idiomas para ser interpretadas al navegarr
 *
 * @author Jhonier Gaviria M. Ago. 27-2018
 * @version 1.0.0
 *
 * @param translate Servicio de traducion
 * @param location Servicio para interactuar con las url
 * @param settings Interfaz de configuracion para rutas dependiendo el idioma
 * @param http Servicio para realizar peticiones
 */
export function LocalizeLoaderFactory(translate: TranslateService, location: Location, settings: LocalizeRouterSettings, http: HttpClient) {
    return new LocalizeRouterHttpLoader(translate, location, settings, http, './assets/locales/locales.json');
}

@NgModule({
    imports: [
        RouterModule.forRoot(routes, { enableTracing: false, useHash: true }),
        LocalizeRouterModule.forRoot(routes, {
            parser: {
                provide: LocalizeParser,
                useFactory: LocalizeLoaderFactory,
                deps: [TranslateService, Location, LocalizeRouterSettings, HttpClient]
            }
        }),
    ],
    exports: [
        RouterModule,
        LocalizeRouterModule
    ]
})

export class AppRoutingModule { }
