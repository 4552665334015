import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';

import { AddHttpPipe } from './pipes/add-http.pipe';
import { AgmCoreModule } from '@agm/core';
import { AutoCompleteDirective } from 'app/shared/directives/autoComplete.directive';
import { AutoResizeTextareaDirective } from './directives/autosize.directive';
import { CheckoutLoyaltySkeletonComponent } from './components/skeletons/checkout-loyalty-skeleton/checkout-loyalty-skeleton.component';
import { CommonModule } from '@angular/common';
import { CouponsService } from 'app/core/services/coupons.service';
import {
    CouponsSkeletonComponent
}
    from './components/skeletons/coupons-skeleton/coupons-skeleton.component';
import { CreditCardPipe } from './pipes/card-credit.pipe';
import { Currency } from './pipes/currency.pipe';
import { DateTimeLocalizePipe } from './pipes/date-time-localize.pipe';
import { DaysAbbreviatorPipe } from './pipes/days-abbreviator.pipe';
import { DecimalsPipe } from './pipes/decimals.pipe';
import { DeliveryAddressComponent } from './components/delivery-address/delivery-address.component';
import { DetailHeaderComponent } from './components/detail-header/detail-header.component';
import {
    DetailRestaurantSkeletonComponent
}
    from './components/skeletons/detail-restaurant-skeleton/detail-restaurant-skeleton.component';
import { DistancePipe } from './pipes/distance.pipe';
import { EmptyValueListDirective } from './directives/EmptyValueSelect.directive';
import { FilterFormDirective } from './directives/filter-form.directive';
import {
    FiltersSectionComponent
}
    from './components/without-results/filters-section/filters-section.component';
import {
    FormCreditCardsComponent
}
    from './components/form-credit-cards/form-credit-cards.component';
import {
    FormDeliveryColombiaComponent
}
    from './components/form-delivery-colombia/form-delivery-colombia.component';
import { HttpClient } from '@angular/common/http';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { LabelsModifiersComponent } from './components/labels-modifiers/labels-modifiers.component';
import { ListCouponsComponent } from './components/list-coupons/list-coupons.component';
import { LoadOptimizationImageDirective } from './directives/load-optimization-image.directive';
import { LoadingImageComponent } from './components/loading-image/loading-image';
import {
    MenuRestaurantSkeletonComponent
}
    from './components/skeletons/menu-restaurant-skeleton/menu-restaurant-skeleton.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import {
    MyFavoriteSkeletonComponent
}
    from './components/skeletons/my-favorite-skeleton/my-favorite-skeleton.component';
import {
    MyOrderSkeletonComponent
}
    from './components/skeletons/my-order-skeleton/my-order-skeleton.component';
import {
    MyReviewsSkeletonComponent
}
    from './components/skeletons/my-reviews-skeleton/my-reviews-skeleton.component';
import { NgModule } from '@angular/core';
import { NgxBarcodeModule } from 'ngx-barcode';
import { NgxMaskModule } from 'ngx-mask';
import { NoRightClickDirective } from './directives/no-right-click.directive';
import { OnlyLettersDirective } from './directives/only-letters.directive';
import { PhonePipe } from './pipes/phone.pipe';
import { RemoveHttpPipe } from './pipes/remove-http.pipe';
import { RemoveTagbrPipe } from './pipes/remove-tagbr.pipe';
import {
    RestaurantListSkeletonComponent
}
    from './components/skeletons/restaurant-list-skeleton/restaurant-list-skeleton.component';
import {
    SettingsNotificationsProductsSkeletonComponent
}
    from './components/skeletons/settings-notifications-products-skeleton/settings-notifications-products-skeleton.component';
import {
    SettingsNotificationsSkeletonComponent
}
    from './components/skeletons/settings-notifications-skeleton/settings-notifications-skeleton.component';
import { SharedCouponsOffertComponent } from './components/shared-coupons-offert/shared-coupons-offert.component';
import { SharedRoutingModule } from './shared-routing.module';
import { SharedService } from '../core/services/shared.service';
import { SpecialOfferSkeletonComponent } from './components/skeletons/special-offer-skeleton/special-offer-skeleton.component';
import { SpecialOffertComponent } from './components/special-offert/special-offert.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { SplitPipe } from './pipes/split.pipe';
import { SubModifiersComponent } from './components/sub-modifiers/sub-modifiers.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslatorModule } from 'app/translator/translator.module';
import { UtilsComponent } from 'app/utils/utils.component';
import { WithoutResultsComponent } from './components/without-results/without-results.component';
import { autoCompleteMenuComponent } from 'app/shared/components/autoComplete/autoCompleteMenu.component';
import { SuggestedTipSkeletonComponent } from './components/skeletons/suggested-tip-skeleton/suggested-tip-skeleton.component';

export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient, './assets/locales/', '.json');
}

// export const options: Partial<IConfig> | (() => Partial<IConfig>);
// import { AddressDeliveryModalComponent } from './components/address-delivery-modal/address-delivery-modal.component';
@NgModule({
    imports: [
        CommonModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
        }),
        FormsModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyDy4Rq7RHrtAoSq8jpN2e_e7Cwjn8LqV1k',
            libraries: ['places']
        }),
        ModalModule.forRoot(),
        InfiniteScrollModule,
        SharedRoutingModule,
        TranslatorModule,
        ReactiveFormsModule,
        NgxBarcodeModule,
        NgxMaskModule.forRoot(),
    ],
    exports: [
        AgmCoreModule,
        InfiniteScrollModule,
        AutoResizeTextareaDirective,
        NoRightClickDirective,
        AutoCompleteDirective,
        EmptyValueListDirective,
        // AddressModalComponent,
        DeliveryAddressComponent,
        autoCompleteMenuComponent,
        // DetailCouponModalComponent,
        // NotificationDetailComponent,
        ListCouponsComponent,
        LoadingImageComponent,
        SpinnerComponent,
        MyFavoriteSkeletonComponent,
        CouponsSkeletonComponent,
        MyOrderSkeletonComponent,
        MyReviewsSkeletonComponent,
        SettingsNotificationsSkeletonComponent,
        SettingsNotificationsProductsSkeletonComponent,
        // ServiceTimeRestComponent,
        WithoutResultsComponent,
        FiltersSectionComponent,
        RestaurantListSkeletonComponent,
        DetailRestaurantSkeletonComponent,
        MenuRestaurantSkeletonComponent,
        SpecialOfferSkeletonComponent,
        DecimalsPipe,
        Currency,
        DistancePipe,
        RemoveHttpPipe,
        AddHttpPipe,
        PhonePipe,
        RemoveTagbrPipe,
        CreditCardPipe,
        DateTimeLocalizePipe,
        DaysAbbreviatorPipe,
        FormCreditCardsComponent,
        FormDeliveryColombiaComponent,
        SubModifiersComponent,
        LabelsModifiersComponent,
        // SafeHTMLPipe,
        SharedCouponsOffertComponent,
        DetailHeaderComponent,
        SpecialOffertComponent,
        SplitPipe,
        LoadOptimizationImageDirective,
        FilterFormDirective,
        CheckoutLoyaltySkeletonComponent,
        SuggestedTipSkeletonComponent,
        OnlyLettersDirective,
        TranslateModule
    ],
    declarations: [
        AutoCompleteDirective,
        EmptyValueListDirective,
        // AddressModalComponent,
        DeliveryAddressComponent,
        autoCompleteMenuComponent,
        // DetailCouponModalComponent,
        // NotificationDetailComponent,
        ListCouponsComponent,
        SpinnerComponent,
        LoadingImageComponent,
        // ServiceTimeRestComponent,
        MyFavoriteSkeletonComponent,
        CouponsSkeletonComponent,
        MyOrderSkeletonComponent,
        MyReviewsSkeletonComponent,
        SettingsNotificationsSkeletonComponent,
        SettingsNotificationsProductsSkeletonComponent,
        WithoutResultsComponent,
        FiltersSectionComponent,
        RestaurantListSkeletonComponent,
        DetailRestaurantSkeletonComponent,
        MenuRestaurantSkeletonComponent,
        SpecialOfferSkeletonComponent,
        DecimalsPipe,
        Currency,
        DistancePipe,
        RemoveHttpPipe,
        AddHttpPipe,
        PhonePipe,
        RemoveTagbrPipe,
        CreditCardPipe,
        AutoResizeTextareaDirective,
        NoRightClickDirective,
        DateTimeLocalizePipe,
        DaysAbbreviatorPipe,
        FormCreditCardsComponent,
        FormDeliveryColombiaComponent,
        SubModifiersComponent,
        LabelsModifiersComponent,
        DetailHeaderComponent,
        SharedCouponsOffertComponent,
        SpecialOffertComponent,
        SplitPipe,
        LoadOptimizationImageDirective,
        FilterFormDirective,
        CheckoutLoyaltySkeletonComponent,
        OnlyLettersDirective,
        SuggestedTipSkeletonComponent,
        // AddressDeliveryModalComponent
    ],
   /*  entryComponents: [
        autoCompleteMenuComponent
    ], */
    providers: [
        CouponsService,
        SharedService,
        DecimalsPipe
    ]
})
export class SharedModule {

    constructor(private _translate: TranslateService,
        private _utils: UtilsComponent){
        this._setDefaultLang();
    }

     // Llamamos el método getDefaultLang() para saber que idioma obtener y colocarlo en el sistema
    private async _setDefaultLang(){
        const language = await this._utils.getDefaultLang();
        this._translate.setDefaultLang(language);
        this._translate.use(language);
         // Si tenemos ya un lenguaje
         if (language) {
            // Esta key LOCALIZE_DEFAULT_LANGUAGE la agrega la libreria de translate, entonces la igualamos para que sea igual
            // a la que el usuario selecciono
            let languageLocalStorage = localStorage.getItem('LOCALIZE_DEFAULT_LANGUAGE');
            if (languageLocalStorage) {
                localStorage.setItem('LOCALIZE_DEFAULT_LANGUAGE', language); // Actualiza el valor en localStorage
            }
        }
    }
 }
