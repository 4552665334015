import { NgModule } from '@angular/core';
import { DynamicComponentLoaderModule } from 'app/core/dynamic-loader/dynamic-component-loader.module';
import { DialogServiceTimeRestComponent } from './dialog-service-time-rest.component';

@NgModule({
    declarations: [DialogServiceTimeRestComponent],
    exports: [DialogServiceTimeRestComponent],
    imports: [
        DynamicComponentLoaderModule.forModule({
            componentId: 'time-rest',
            path: 'time-rest',
            loadChildren: () => import('./content/service-time-rest.module').then(m => m.ServiceTimeRestModule)
        }),
    ]
})
export class DialogServiceTimeRestModule { }
