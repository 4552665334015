import { UtilsComponent } from 'app/utils/utils.component';
import { Injectable, EventEmitter, Inject } from '@angular/core';
import { ExternalMenuServices, PropertiesExternalMenu } from '../interfaces/external-menu.interfaces';
import { FaviconServices } from '../interfaces/favicon.interfaces';

@Injectable({
    providedIn: 'root'}
)
export class ExternalMenuService implements ExternalMenuServices{

    public whiteLabelActive: EventEmitter<boolean> = new EventEmitter();

    constructor(
        @Inject('FaviconServices') private readonly faviconService: FaviconServices,
        private utils: UtilsComponent
    ) { }

    /**
     * Establece las propiedades para que el aspecto del html
     * sea el del menu externo
     * @author Alvaro Felipe Garcia Mendez - Sept. 3 -2019
     * @version 1.0.0
     */
    public setInitialPropertiesExternal(): void {
        this.faviconService.removeFavicon();
        const header = document.querySelector('body');
        header.className = 'menuExterno';
        const head = document.head || document.getElementsByTagName('head')[0];
        const style = document.createElement('style');
        style.type = 'text/css';
        const css = '.logo-none{display:none} .searchHeader{display:none}';
        style.appendChild(document.createTextNode(css));
        head.appendChild(style);
    }

    /**
     * Establece en el sistema otros datos necesarios y cambia los colores
     * segun se definieron en el superdashboard para el menu externo
     * @param idRestaurant id del restaurante con menu externo activado
     * @param tmpColors colores del menu externo
     * @author Alvaro Felipe Garcia Mendez - Sept. 3 -2019
     * @version 1.0.0
     */
    public setConfigPropertiesExternal(idRestaurant: string, configView: PropertiesExternalMenu): void {
        this.utils.externalMenu = configView.useExternalMenu;
        this.utils.useWhiteLabel = configView.useWhiteLabel;
        this.utils.urlPlayStore = configView.urlPlayStore;
        this.utils.urlAppStore = configView.urlAppStore;
        this.utils.idRestaurantExt = idRestaurant;
        sessionStorage.setItem('idRestaurantExt', this.utils.idRestaurantExt);
        this.utils.externalMenuColor = configView.bgColor;
        this.changeColors(configView.bgColor, configView.fontColor);
        if (configView.useWhiteLabel) {
            this.whiteLabelActive.emit(true);
        }
    }


    /**
     * Cambia el color general traido del superdashboard
     * @author Alvaro Felipe Garcia Mendez - Sept. 3 -2019
     * @version 1.0.0
     */
    private changeColors(theColor: string, theLetra: string) {
        const head = document.head || document.getElementsByTagName('head')[0];
        const style = document.createElement('style');
        style.type = 'text/css';
        const css = 'body{ --color: ' + theColor + '; --letra:' + theLetra + ' }';
        style.appendChild(document.createTextNode(css));
        head.appendChild(style);
    }
}
