import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'decimals' })
export class DecimalsPipe implements PipeTransform {

    constructor(private datePipe: DecimalPipe) { }

    /**
     * @see {PipeTransform#transform}
     */
    transform(value: number, format: string): number {
        return + this.datePipe.transform(value, format);
    }
}
