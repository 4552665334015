import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { DynamicComponentLoader } from 'app/core/dynamic-loader/dynamic-component-loader.service';

@Component({
  selector: 'app-dialog-adress-component',
  template: '<div #addressModal></div>'})
export class DialogAddressComponent {
    @ViewChild('addressModal', { read: ViewContainerRef, static: true }) _addressModal:
    | ViewContainerRef
    | undefined;

  constructor(private readonly loader: DynamicComponentLoader) {
    this.loader.getComponentFactory('address-modal').subscribe({
      next: factory => {
        if (!this._addressModal) {
          return;
        }
        this._addressModal.createComponent(factory).changeDetectorRef.detectChanges();
      }
    });
  }
}
