import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { GlobalEventsService } from 'app/core/services/globalEvents.service';
import { MatDialog } from '@angular/material/dialog';
import { DeliveryServices } from 'app/core/interfaces/delivery.interfaces';
import { OrderServices } from 'app/core/interfaces/orders.interfaces';

@Component({
  selector: 'app-address-delivery-modal',
  templateUrl: './address-delivery-modal.component.html',
  styleUrls: ['./address-delivery-modal.component.sass']}
)
export class AddressDeliveryModalComponent implements OnInit {

  /**
   * Es el modal de lenguaje
   */

  @ViewChild('addressesModal', { static: true }) public addressesModal: ModalDirective;

  /**
   * Variable que almacena
   */
  public addresses: any;
  data: any;
  addressPicked: any;

  constructor(
    private globalEvents: GlobalEventsService,
    @Inject('OrderServices') private readonly orderServices: OrderServices,
    @Inject('DeliveryServices') private readonly deliveryService: DeliveryServices,
    public dialog: MatDialog
  ) {

    // Escucha para abrir el modal
    this.globalEvents.openAddressModal.subscribe((data) => {
      this.data = data;
      this.addresses = this.data.addresses;
      this.addressesModal.show();
    });

  }

  ngOnInit() {
    this.addressesModal.onHide.subscribe(() => {
      this.addresses = [];
    });
  }

  /**
   * Permite cerrar el modal
   *
   * @author Jhonier Gaviria M. - Jul. 26-2018
   * @version 1.0.0
   */
  public close(): void {
    this.addressesModal.hide();
  }

  /**
   * Funcion que guarda la direccion seleccionada por el usuario para realizar el delivery
   */
  public choseAddressToDeliveryOrder() {
    this.data.naturalAddress = this.addressPicked.naturalAddress;
    this.data.userPosition = { lat: Number(this.addressPicked.latitude), lng: Number(this.addressPicked.longitude) };
    this.data.closeModal = true;
    // this.globalEvents.deliveryMap.emit(this.data);
    if (!this.orderServices.getDeliveryDetail()) {
      this.deliveryService
      .validateUserUbication(
          this.data.restaurant,
          this.data.userPosition,
          this.data.product,
          this.orderServices.getServiceTypeSelected(), 
          this.data.naturalAddress,
          this.addressPicked.note,
          false);
      this.orderServices.addItem(this.data.product);
      this.orderServices.saveData();
      this.globalEvents.actionCart.emit();
      this.dialog.closeAll();
    } else {
      this.dialog.closeAll();

      // this.deliveryProvider.calculateDistanceWithUser(this.data.userPosition, this.data.restaurant.address, this.data.restaurant, this.data.naturalAddress);
      let userAddress = this.orderServices.getDeliveryDetail().deliveryLocation;
      if(this.data.selectedDifferentAddress){
        userAddress = this.data.userPosition;
      } 
      this.deliveryService
      .validateUserUbication(
          this.data.restaurant,
          userAddress,
          this.data.product,
          this.orderServices.getServiceTypeSelected(), 
          this.data.naturalAddress,
          this.addressPicked.note,
          true);

    }
    this.close();
  }

  public setAddress(address) {
    this.addressPicked = address;
  }
}
