import { NgModule } from '@angular/core';
import { DynamicComponentLoaderModule } from 'app/core/dynamic-loader/dynamic-component-loader.module';
import { DialogItemDetailsComponent } from './dialog-item-details.component';

@NgModule({
    declarations: [DialogItemDetailsComponent],
    exports: [DialogItemDetailsComponent],
    imports: [
        DynamicComponentLoaderModule.forModule({
            componentId: 'items-cart',
            path: 'items-cart',
            loadChildren: () => import('./content/item-details.module').then(m => m.ItemDetailsModule)
        }),
    ]
})
export class DialogItemDetailsModule { }
