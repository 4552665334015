import { AppConstants } from 'app/app-constants';
import { ConfigComponent } from 'app/core/config/config';
import { Coupon } from 'app/core/entities/Coupon';
import { StorageCoupons } from 'app/core/entities/StorageCoupons';
import { GlobalEventsService } from 'app/core/services/globalEvents.service';
import { SweetAlertService } from 'app/utils/sweetAlert.service';
import { LocalizeRouterService } from 'localize-router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UserLocationProvider } from 'app/utils/userLocationService';
import { UtilsComponent } from 'app/utils/utils.component';
import { Observable, throwError } from 'rxjs';
import { ResponsesStructure } from 'app/core/interfaces/transversal.interfaces';
import { catchError, map } from 'rxjs/operators';
import { CouponsServices } from 'app/core/interfaces/coupons.interfaces';
import { OrderServices } from 'app/core/interfaces/orders.interfaces';

/**
 *  Variable para manejo de funciones jquery
 */
declare var jQuery: any;

/**
 * Es un servicio para las transacciones operacionales de los cupones
 *
 * @author Jhonier Gaviria M.
 */
@Injectable()
export class CouponsService implements CouponsServices{

  /**
   * Variable que almacena la url de los webservices
   */
  private urlOmt: string;

  /**
   * Variable para persistir los cupones a redimir
   */
  storageCoupons: StorageCoupons;

  /**
   * Variable para almacenar el arreglo de cupones de la aplicación
   */
  coupons: Coupon[];

  /**
   * Variable para almacenar el arreglo de ofertas de la aplicación
   */
  offers: Coupon[];

  /**
   * Variable para guardar el total de cupones de la aplicación
   */
  quantityCoupons: number;

  /**
  * Variable para guardar el total de cupones disponibles
  */
  quantityAvailable: number;

  /**
   * Indica que se debe abrir la página del detalle del restaurante,
   * esto es variable según la página en el que se encuentra el usuario
   */
  openDetailRestaurant: boolean;

  /**
   * Constructor de la clase
   *
   * @param httpClient Servicio para peticiones
   * @param config Componente de configuración de la aplicación
   * @param globalEventsServiceProvider Servicio de eventos de la aplicación
   */
  constructor(
    public httpClient: HttpClient,
    private readonly translate: TranslateService,
    private readonly sweet: SweetAlertService,
    private readonly globalEventsService: GlobalEventsService,
    private readonly router: Router,
    private readonly localize: LocalizeRouterService,
    private readonly config: ConfigComponent,
    @Inject('OrderServices') private readonly orderServices: OrderServices,
    private readonly userLocation: UserLocationProvider,
    public utils: UtilsComponent)
  {
    this.urlOmt = this.config.getUrlOmt();
    this.storageCoupons = null;
    // Escuchamos el evento de que se debe borrar la memoria cuando se finaliza la compra
    this.globalEventsService.resetStorageCoupons.subscribe(() => {
      this.storageCoupons = null;
      this.resetPropertySelectedCoupon();
    });
    // COMENTADO PARA QUE NO TOME LOS CUPONES DEL LOCALSTORAGE,
    // BASTA CON DESCOMENTAR ESTA LINEA PARA QUE VUELVA A FUNCIONAR
    // this.storageCoupons = this.getCouponsFromLocalStorage();
  }

  /**
   * Permite obtener todos los cupones activos de la aplicacion
   *
   * @author Jhonier Gaviria M. - Ago. 30-2018
   * @version 1.1.0
   *
   * @param freeText Nombre, descripcion o parte del nombre de la ciudad del restaurante
   * @param cuisines Listado de cocinas seleccionadas
   * @param coordinates Coordenadas geografica seleccionadas para ordenar restaurantes por mas cercanos
   * @param page Numero de pagina a consultar del paginador
   * @param range Rango en el que se encuentran los restaurantes
   */
   /**
   * Tipado del response del servicio implementado correctamente
   */
  getActiveCoupons(freeText: string, cuisines: any[] = [], coordinates: any = null, page: number,
    range: number, filterCoupons = null, loadOffert): Observable<ResponsesStructure> {
    let params = new HttpParams();
    params = params.append('page', String(page));
    params = params.append('range', String(range));
    params = params.append('order_by', 'distance');
    params = params.append('sort', '0');
    // Si el usuario ha elegido cocinas
    if (cuisines.length > 0) {
      params = params.append('cuisines', cuisines.toString());
    }
    // Si el campo de busqueda de busqueda de restaurante no esta vacio
    if (freeText && freeText !== '') {
      params = params.append('free_text', freeText);
    }
    // Si el usuario ha filtrado algun lugar
    if (coordinates !== null) {
      params = params.append('latitude', coordinates.lat);
      params = params.append('longitude', coordinates.lng);
    }
    if (filterCoupons) {
      params = params.append('isByClient', '1');
    }
    if (loadOffert) {
      params = params.append('filterBy', 'offer');
    } else {
      params = params.append('filterBy', 'coupon');
    }
    const url = `${this.urlOmt}coupons`;
    return this.httpClient.get<ResponsesStructure>(url, { params: params }).pipe(
      map((response: ResponsesStructure) => {
        return response;
      }),catchError(error => {
        return throwError(error);
      })
    );
  }

  /**
   * Permite obtener todos los cupones activos de la aplicación
   *
   * @param restaurantId Es el identificador del restaurante
   * @author Jhonier Gaviria M.
   */
  /**
   * Tipado del response del servicio implementado correctamente
   */
  getActiveCouponsByRestaurant(restaurantId, page: number): Observable<ResponsesStructure> {
    let params = new HttpParams();
    params = params.append('page', String(page));
    params = params.append('restaurantId', restaurantId);
    params = params.append('items_per_page', String(AppConstants.ITEMS_PER_PAGE_LIST));
    const url = `${this.urlOmt}coupons`;
    return this.httpClient.get<ResponsesStructure>(url, { params: params }).pipe(
      map((response: ResponsesStructure) => {
        return response;
      }),catchError(error => {
        return throwError(error);
      })
    );
  }

  /**
   * Envia al servidor la información de la orden y cupón para su validación
   * @param couponCode Es el código del cupón a validar
   * @param sale Es el pedido del usuario
   * @author Jhonier Gaviria M.
   */
  /**
   * Se crean las interfaces para el tipado del request y response del servicio consumido
   */
  validateCoupon(couponCode: string, sale: any): Observable<ResponsesStructure> {
    const url = `${this.urlOmt}coupons/check/${couponCode}`;
    return this.httpClient.post<ResponsesStructure>(url, sale).pipe(
      map((response: ResponsesStructure) =>{
        return response;
      }),catchError(error => {
        return throwError(error);
      })
    );
  }

  /**
   * Permite actualizar la información de los cupones en localstorage
   *
   * @param coupon Es el nuevo cupón agregar para redimir
   * @param openDetailRestaurant Indica si se ha solicitado que se abra el detalle del restaurante
   * @author Jhonier Gaviria M.
   */
  saveCouponsData(coupon: Coupon, openDetailRestaurant: boolean): void {
    // Indicamos que se debe o no abrir la ventana del detalle del restaurante
    this.openDetailRestaurant = openDetailRestaurant;
    // Validamos que no haya productos de otro restaurante en el carrito
    if (this.orderServices.getRestaurant().id && this.orderServices.getRestaurant().id !== ''
      && this.orderServices.getRestaurant().id !== coupon.restaurantId && coupon.restaurantId !== null && !coupon.isSelected) {
      // Validamos si quiere reemplazar los cupones
      this.askToDeleteCoupons(coupon);
    } else if (this.storageCoupons === null) { // En caso de que no existan aun cupones persistiendo
      // Iniciamos valores
      this.resetStorageCoupons(coupon);
    } else {
      // Validamos que sea el mismo restaurante
      if (this.storageCoupons.restaurantId !== coupon.restaurantId && this.storageCoupons.restaurantId !== null
        && coupon.restaurantId !== null) {
        // Validamos si quiere reemplazar los cupones
        this.askToDeleteCoupons(coupon);
      } else {
        // Revisamos si existe para eliminarlo
        if (coupon.isSelected) {
          // Eliminamos el cupón
          this.removeCoupon(coupon);
        } else {
          // Agregamos el cupón
          this.addCoupon(coupon);
        }
      }
    }
  }

  /**
   * Permite inciar los valores del storage coupons
   *
   * @param coupon El nuevo cupon a agregar
   */
  resetStorageCoupons(coupon: Coupon = null): void {
    if (coupon !== null) {
      this.storageCoupons = {
        restaurantId: coupon.restaurantId,
        coupons: []
      };
      this.addCoupon(coupon);
    } else {
      this.storageCoupons = {
        restaurantId: null,
        coupons: []
      };
    }
  }

  /**
   * Permite obtener el listado de cupones almacenados en localstorage
   *
   * @returns StorageCoupons El listado de cupones guardados en localstorage o null en caso de que no hayan
   */

  /**
   * Permite agregar un cupón al localstorage para ser persistido
   *
   * @param coupon Es el nuevo cupón a agregar para redimir
   */
  addCoupon(coupon: Coupon): void {
    if (!this.storageCoupons || !this.storageCoupons.coupons) {
      this.resetStorageCoupons();
    }
    // Marcamos el cupon como seleccionado
    coupon.isSelected = true;
    // Guardamos el cupon en el arreglo de storage
    this.storageCoupons.coupons.push(coupon);
    // Lanza un alert que confirma que el cupon se agrego al carrito (este alert solo se lanza para colombia)
    if (this.userLocation.isColombia(this.userLocation.getUserLocationData().country)) {
      this.translate.get(['COUPON_ADDED_TO_CART_CONFIRM', 'OK']).subscribe(translation => {
        this.sweet.alert(translation.COUPON_ADDED_TO_CART_CONFIRM, null, null, translation.OK);
      });
    }
    // Validamos si se debe abrir la página del detalle del restaurante
    if (this.openDetailRestaurant) {
      // Mando la order para que se abra la pagina de destalle del restaurante
      const restaurantId: any = coupon.restaurantId;
      let urlrest = '/app/detail-restaurant/'.concat(restaurantId);
      if (this.utils.externalMenu) {
        urlrest = '/app/detail-restaurant/'.concat(restaurantId).concat('?otherpage=true');
      }
      // Si esta en el menú apuntamos a la primera categoría
      if (this.localize.translateRoute(urlrest) === this.router.url) {
        jQuery('#s1').trigger('click');
      } else {
        this.router.navigate([this.localize.translateRoute(urlrest)]);
      }
      this.globalEventsService.closeModelaDetailCoupon.emit();
    } else {
      this.globalEventsService.closeModelaDetailCoupon.emit();
    }
  }

  /**
   * Permite realizar la consulta si el usuario desea limpiar los cupones del storage
   *
   * @param coupon El cupón nuevo de otro restaurante
   */
  askToDeleteCoupons(coupon: Coupon = null, functionCallback: Function = null): void {
    this.translate.get('COUPON_OTHER_RESTAURANT').subscribe(res => {
      this.sweet.confirm(res).then((isConfirmed: boolean) => {
        if (isConfirmed) {
          // Reseteamos valores
          this.resetPropertySelectedCoupon();
          this.resetStorageCoupons(coupon);
          if (functionCallback) {
            functionCallback(true);
          }
        } else if (functionCallback) {
          functionCallback(false);
        }
      });
    });
  }

  /**
   * Permite reiniciar a todos los cupones la propiedad isSelected a false
   */
  resetPropertySelectedCoupon(): void {
    if (this.coupons) {
      for (const coupon of this.coupons) {
        coupon.isSelected = false;
      }
    }
  }

  /**
   * Permite actualizar un cupón del storageCoupon con la información del servidor
   *
   * @param coupon Es la nueva información del cupón a actualizar
   * @author Jhonier Gaviria Mu.
   */
  updateCoupon(coupon: Coupon): void {
    // Recorremos los cupones del storage
    for (const i in this.storageCoupons.coupons) {
      // Valido que sea el cupón a actualizar
      if (this.storageCoupons.coupons[i].code === coupon.code) {
        this.storageCoupons.coupons[i] = coupon;
      }
    }
  }

  /**
   * Permite eliminar un cupón de la persistencia
   *
   * @param coupon Es el cupón a eliminar
   */
  removeCoupon(coupon: Coupon): void {
    const position = this.searchCouponInStorageCoupons(coupon);
    if (position !== -1) {
      this.storageCoupons.coupons.splice(position, 1);
      coupon.isSelected = false;
      // Validamos para borrar el localstorage por completo
      if (this.storageCoupons.coupons.length === 0) {
        this.storageCoupons = null;
      }
    }
  }

  /**
   * Permite validar que todos los cupones agregados al carrito esten aplicados
   *
   * @param coupon Un cupón que existe solo en order page
   */
  verifyAllCounponsValidated(coupon: Coupon): boolean {
    // Si este cupón tiene texto quiere decir que no ha sido validado
    if (!coupon.isValidated && coupon.code !== '') {
      return false;
    }
    if (this.storageCoupons && this.storageCoupons !== null) {
      // Recorremos todos los cupones agregados al arreglo de storage
      for (const tmpCoupon of this.storageCoupons.coupons) {
        // Validamos que si hay alguno no validado
        if (!tmpCoupon.isValidated && tmpCoupon.code !== '') {
          return false;
        }
      }
    }
    return true;
  }

  /**
   * Permite validar si un cupón ya fue cargado al carrito y validado
   *
   * @param coupon El cupón a buscar
   * @returns boolean true si ya esta en el carrito, false en caso contrario
   * @author Jhonier Gaviria M.
   */
  validateCouponWasUsed(coupon: Coupon): boolean {
    if (this.storageCoupons === null) {
      return false;
    }
    // Validamos si se encuentra en el listado
    for (const tmpCoupon of this.storageCoupons.coupons) {
      if (coupon.code === tmpCoupon.code && tmpCoupon.isValidated) {
        return true;
      }
    }
    return false;
  }

  /**
  * Permite validar si un cupón se encuentra almacenado en localstorage
  *
  * @param coupon Cupón a validar si ya esta en localstorage
  * @returns number Un valor mayor a cero si existe y -1 en caso contrario
  */
  searchCouponInStorageCoupons(coupon: Coupon): number {
    if (this.storageCoupons === null) {
      return -1;
    }
    // Validamos si se encuentra en el listado
    for (const i in this.storageCoupons.coupons) {
      if (coupon.id === this.storageCoupons.coupons[i].id) {
        return parseInt(i);
      }
    }
    return -1;
  }

  /**
  * Permite validar si los cupones obtenidos del servidor ya tengo marcados para redimir
  * y asignarle la propiedad de seleccionados
  *
  * @param coupons El listado de cupones a validar si estan localmente
  */
  getPropertySelectedCoupon(coupons: Coupon[]): Coupon[] {
    // Recorremos los cupones recibidos
    for (const coupon of coupons) {
      // Validamos si esta almacenado localmente para marcarlo como seleccionado
      if (this.searchCouponInStorageCoupons(coupon) !== -1) {
        coupon.isSelected = true;
      } else {
        coupon.isSelected = false;
      }
    }
    return coupons;
  }

  /**
   * Permite construir un objeto con la información del cupón aplicado
   *
   * @param dataCoupon Respuesta del servidor con la data del cupón
   */
  buildCouponApplied(dataCoupon: any): any {
    const couponApply = {
      'discount': dataCoupon.discount,
      'type': dataCoupon.type,
      'coupon': dataCoupon.coupon.id
    };
    // Si tiene la propiedad cantidad
    if (dataCoupon.qty) {
      couponApply['qty'] = dataCoupon.qty;
    }
    // Si es de un producto
    if (dataCoupon.type === 'product') {
      couponApply['productOrder'] = dataCoupon.productOrder.id;
    }
    return couponApply;
  }

   /**
   * Tipado del response del servicio implementado correctamente
   */
  getCouponCodeToReclaim(idCoupon): Observable<ResponsesStructure> {
    const data = {
      idCoupon: idCoupon
    };
    const url = `${this.urlOmt}cli/coupon/reclaim`;
    return this.httpClient.post<ResponsesStructure>(url, data).pipe(
      map((response: ResponsesStructure) => {
        return response;
      }),catchError(error => {
        return throwError(error);
      })
    );
  }

}
