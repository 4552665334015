<!-- Modal Addresses -->
<article bsModal #addressesModal="bs-modal" class="modal fade modalAddresses" tabindex="-1" role="dialog"
  aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="moda-header">
        <h4 class="modal-title">
          {{ 'SELECT_ADDRESS' | translate }}
        </h4>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" *ngIf="addresses && addresses.length">
        <div class="radio line" *ngFor="let address of addresses; let address" (click)="setAddress(address)">
          <label class="flx cien nopad">
            <input class="noabs" type="radio" name="optradio"> ({{ address.name  }}):
            {{ address.naturalAddress}}</label>
        </div>
        <div class="modal-footer flx">
          <button type="button" class="btn btn-danger btnLoad" (click)="close()" aria-label="cancel">
            <span> {{ 'CANCEL' | translate }}</span>
          </button>
          <button type="button" class="btn btn-primary btnLoad" (click)="choseAddressToDeliveryOrder()"
            aria-label="confirm">
            <span> {{ 'CONFIRM' | translate }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</article>