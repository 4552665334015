import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserLocationProvider } from 'app/utils/userLocationService';
import { Observable, of, throwError } from 'rxjs';
import { ClientsAccompanimentServices, UserHasClickedStructureRequest } from 'app/core/interfaces/clients-accompaniment.interfaces';
import { ResponsesStructure } from 'app/core/interfaces/transversal.interfaces';
import { catchError, map } from 'rxjs/operators';
import { AuthServices } from 'app/core/interfaces/auth.interfaces';
import { ConfigComponent } from 'app/core/config/config';
import { AppConstants } from 'app/app-constants';

@Injectable({
  providedIn: 'root'
})
export class ClientsAccompanimentService implements ClientsAccompanimentServices{

  constructor(private readonly http: HttpClient,
    private readonly userLocationProvider: UserLocationProvider,
    @Inject('AuthServices') private readonly authService: AuthServices,
    private readonly config: ConfigComponent) { }

    /**
     * Tipado del request y el response del servicio implementado correctamente
     */
  public userHasClicked(typeTrack: number, restaurantId?: string): Observable<ResponsesStructure> {
    const data: UserHasClickedStructureRequest = this.paramsBuilder(typeTrack, restaurantId);
    if (data) {
      const url = `${this.config.getUrlOmt()}accompanimentOfClient`;
      return this.http.post<ResponsesStructure>(url, data).pipe(
        map((response: ResponsesStructure) => {
          return response;
        }),catchError(error => {
          return throwError(error);
        })
      );
    } else {
      console.error("Error obteniedo los datos");
      return of();
    }
  }

  /**
   * @author Anderson Barreto B. - Feb. 24-2020
   * @description metodo encargado de construir los parametros
   * @param typeTrack tipo de click
   * @param restaurantId id del restaurante
   * @version 1.0.0
   */
  public paramsBuilder(typeTrack: number, restaurantId?: string): UserHasClickedStructureRequest {
    const deviceId = 'web_browser';
    const currentLocation = this.userLocationProvider.getCurrentLocation();
    const userLocationData = this.userLocationProvider.getUserLocationData();
    if (userLocationData) {
      let clientId = '';
      if (this.authService.isAuthenticated()) {
        const userId = JSON.parse(localStorage.getItem(AppConstants.LOCAL_STORAGE_DATA.DATA_USER));
        clientId = userId.id;
      }
      const data: UserHasClickedStructureRequest = {
        latitude: currentLocation.lat,
        longitude: currentLocation.lng,
        track_country: userLocationData.country,
        track_state: userLocationData.state,
        track_city: userLocationData.city,
        zipcode: userLocationData.zipCode,
        type: typeTrack,
        device: deviceId,
        restaurant_id: restaurantId,
        client: clientId,
        brand: AppConstants.BRAND_ALIAS.originNumber
      };
      return data;
    }

  }
}
