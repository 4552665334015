import { CustomerRecordStructureRequest, NewPasswordStructureRequest, PasswordRecoveryStructureRequest, SendEmailStructureRequest, UserServices } from 'app/core/interfaces/user.interfaces';
import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AppConstants } from 'app/app-constants';
import { ConfigComponent } from 'app/core/config/config';
import { ResponsesStructure } from 'app/core/interfaces/transversal.interfaces';

/*
  Generated class for the UtilsServiceProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class UserServiceProvider implements UserServices {

    private url: string;

    /**
     * Emite el estado de la cookie para saber
     * si en algun momento se eligió una ubicacion aunque sea de ciudad
     * en el formulario de ubicacion para Colombia
     */
    public emitterCookie: EventEmitter<any>;

    constructor(
        public http: HttpClient,
        private readonly config: ConfigComponent)
    {
        this.emitterCookie = new EventEmitter<any>();
    }

     /**
     * Se realiza la creación de la debida interface para el tipado del response del servicio
     * consumido adicionalmente se agrega el método a la inyección de servicios
     */
    public myOrders(idRestaurantExt: string, params): Observable<ResponsesStructure> {
        if (idRestaurantExt) {
            this.url = this.config.getUrlOmt() + 'cli/orders?' + params + '&idRestaurant=' + idRestaurantExt;
        } else {
            this.url = this.config.getUrlOmt() + 'cli/orders?' + params;
        }
        return this.http.get<ResponsesStructure>(this.url).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),
            catchError(e => {
              return throwError(e);
            })
        );
    }

    /**
     * Se realiza la creación de la debida interface para el tipado del response del servicio
     * consumido adicionalmente se agrega el método a la inyección de servicios
     */
    public myOrdersTotal(apiKey: any): Observable<ResponsesStructure> {
        const url = this.config.getUrlOmt() + 'cli/orders/total';
        return this.http.get<ResponsesStructure>(url).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),
            catchError(e => {
              return throwError(e);
            })
        );
    }

    public OrderDetail(isGoToLastOrder: boolean, idOrder: string, idRestaurant: string): Observable<ResponsesStructure> {
        let params = new HttpParams();
        params = params.append('order_id', idOrder);
        params = params.append('is_last_order', String(isGoToLastOrder));
        params = params.append('id_restaurant', idRestaurant);
        const url = this.config.getUrlOmt() + 'cli/order/';
        return this.http.get<ResponsesStructure>(url, { params: params }).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),
            catchError(e => {
              return throwError(e);
            })
        );
    }

    /**
     * Permite volver a hacer el pedido de una orden que ya se habia hecho
     * @param idOrder
     */
    /**
     * Se realiza la creación de la debida interface para el tipado del response del servicio
     * consumido adicionalmente se agrega el método a la inyección de servicios
     */
    public reOrder(idOrder: string, isExternalMenu: boolean = false): Observable<ResponsesStructure> {
        let brand: string;
        if (isExternalMenu) {
            brand = '?brand=' + AppConstants.BRAND_ALIAS_ONLINE.name;
        } else {
            brand = '?brand=' + AppConstants.BRAND_ALIAS.name;
        }
        const url = this.config.getUrlOmt() + 'cli/reorder/' + idOrder + brand;
        return this.http.get<ResponsesStructure>(url).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),
            catchError(e => {
              return throwError(e);
            })
        );
    }

    public validateToken(token: any): Observable<ResponsesStructure> {
        const url = `${this.config.getUrlOmt()}activateAccount`;
        return this.http.post<ResponsesStructure>(url, { 'token': token, 'resetPassword': true }).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),
            catchError(e => {
              return throwError(e);
            })
        );
    }

    /**
     * funcion para ubicar el usuario a traves de wifi
     */
     /**
     * Se realiza la creación de la debida interface para el tipado del response del servicio
     * consumido adicionalmente se agrega el método a la inyección de servicios
     */
    public getCountryFromIp(): Observable<ResponsesStructure> {
        const url = `${this.config.getUrlOmt()}search-ip`;
        return this.http.get<ResponsesStructure>(url).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),
            catchError(e => {
              return throwError(e);
            })
        );
    }

    /**
     * Envia la peticion al WS para cargar los resturantes
     * @param idRestaurantExt identificador del restaurante
     */
     /**
     * Se realiza la creación de la debida interface para el tipado del response del servicio
     * consumido adicionalmente se agrega el método a la inyección de servicios
     */
    public myOrdersRestaurants(idRestaurantExt: string): Observable<ResponsesStructure> {
        if (idRestaurantExt) {
            this.url = this.config.getUrlOmt() + 'cli/orders-restaurants?id=' + idRestaurantExt;
        } else {
            this.url = this.config.getUrlOmt() + 'cli/orders-restaurants';
        }
        return this.http.get<ResponsesStructure>(this.url).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),
            catchError(e => {
              return throwError(e);
            })
        );
    }

    /**
     * se encarga de enviar la peticion al WS con el criterio de busqueda
     * @param params
     */
    public filterOrder(params): Observable<ResponsesStructure> {
        this.url = this.config.getUrlOmt() + 'cli/orders' + params;
        return this.http.get<ResponsesStructure>(this.url).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),
            catchError(e => {
              return throwError(e);
            })
        );
    }

    /**
     * Servicio para desmarcar un restaurante como favorito
     */
    /**
     * Se realiza la creación de la debida interface para el tipado del response del servicio
     * consumido adicionalmente se agrega el método a la inyección de servicios
     */
    public deleteFavorite(favRestaurantId: string): Observable<ResponsesStructure> {
        const url = `${this.config.getUrlOmt()}cli/favoriteRestaurant/${favRestaurantId}`;
        return this.http.delete<ResponsesStructure>(url).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),
            catchError(e => {
              return throwError(e);
            })
        );
    }

    /**
     * Permite enviar la solicitud de recuperacion de contraseña
     *
     * @author Angel Andres Diaz Calle - Jul-25-2018
     * @version 1.0.0
     *
     * @param dataRecovery
     */
    /**
     * Se realiza la creación de la debida interface para el tipado del response del servicio
     * consumido adicionalmente se agrega el método a la inyección de servicios
     */
    public sendEmailPasswordRecovery(dataRecovery): Observable<ResponsesStructure> {
        const data: PasswordRecoveryStructureRequest = {
            email_username: dataRecovery.email,
            externalMenu: dataRecovery.externalMenu,
            externalMenuColor: dataRecovery.externalMenuColor,
            dominio: dataRecovery.dominio,
            device_platform: dataRecovery.device_platform,
            idRestaurant: dataRecovery.idRestaurant
        };
        const url = `${this.config.getUrlOmt()}requestPasswordRecovery`;
        return this.http.post<ResponsesStructure>(url, data).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),
            catchError(e => {
              return throwError(e);
            })
        );
    }

    /**
     * Permite enviar la nueva contraseña para que sea guardada en el WS
     *
     * @author Angel Andres Diaz Calle - Jul-25-2018
     * @version 1.0.0
     *
     * @param dataUser usuario y nueva contraseña
     */
    /**
     * Se realiza la creación de la debida interface para el tipado del response del servicio
     * consumido adicionalmente se agrega el método a la inyección de servicios
     */
    public passwordRecovery(dataUser): Observable<ResponsesStructure> {
        const data: NewPasswordStructureRequest = {
            new_password: dataUser.newPassword,
            email_user: dataUser.email,
            token: dataUser.token,
            device_platform: dataUser.device_platform
        };
        const url = `${this.config.getUrlOmt()}newPassword`;
        return this.http.post<ResponsesStructure>(url, data).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),
            catchError(e => {
              return throwError(e);
            })
        );
    }

    /**
     * Envia la informacion de contacto al ws
     *
     * @author Anderson Barreto - En-20-2019
     * @version 1.0.0
     *
     * @param dataContact Nombre, email y zipcode
     */
    /**
     * Se realiza la creación de la debida interface para el tipado del response del servicio
     * consumido adicionalmente se agrega el método a la inyección de servicios
     */
    public sendContactInformation(dataContact, country: string, city: string): Observable<ResponsesStructure> {
        const data: SendEmailStructureRequest = {
            email: dataContact.email,
            name: dataContact.name,
            country: country,
            city: city,
            zipcode: dataContact.zipCode,
            typeInfo: 'requestService'
        };
        const url = `${this.config.getUrlOmt()}sendEmail`;
        return this.http.post<ResponsesStructure>(url, data).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),
            catchError(e => {
              return throwError(e);
            })
        );
    }
    /**
     * Borra las cookies que se crea al presionar submit en el formulario inicial
     * @author William Alarcon 30 Marzo - 2020
     */
    public deleteCookies(): void {
        document.cookie = 'searchUserPosition= ; expires = Thu, 01 Jan 1970 00:00:00 GMT';
        document.cookie = 'wasVisited= ; expires = Thu, 01 Jan 1970 00:00:00 GMT';
    }

    /**
     * Registra la informacion relaciando al covid de un cliente
     * @author William Alarcón 27 Nov 2020
     * @param data
     * {
        name: '',
        lastname: '',
        cc: '',
        age: '',
        phone: '',
        address: '',
        email: '',
        corporalTemperature: ''
      }
     */
    /**
     * Se realiza la creación de la debida interface para el tipado del response del servicio
     * consumido adicionalmente se agrega el método a la inyección de servicios
     */
    public registerCustomerCovid(data: CustomerRecordStructureRequest): Observable<ResponsesStructure> {
        const url = `${this.config.getUrlOmt()}customerRecord`;
        return this.http.post<ResponsesStructure>(url, data).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),
            catchError(e => {
              return throwError(e);
            })
        );
    }
}
