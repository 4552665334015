<div class="background-white">
    <div class="container">
        <div class="row">
            <article class=" thanks" *ngIf="dataUser && (showViewPanel == 'order-completed' || paymentGatewayData) ">
                <div class="modal-header">
                    <h4>{{ 'ORDER_COMPLETE' | translate}}
                        <span class="orange">{{ 'THANK_YOU' | translate }}</span>
                    </h4>
                </div>
                <h5 class="text-center" *ngIf="dataUser?.phone">{{ 'PLEASE_CHECK_PHONE' | translate: {'phone': dataUser?.phone} }}</h5>
            
                <div class="estimated-container" *ngIf="estimatedTime">
                    <h5 class="estimated-title" *ngIf="orderTypeSelected === typeServices.SERVICE_PICKUP">{{ 'ESTIMATED_TIME_AT_PICK_UP' | translate}}</h5>
                    <h5 class="estimated-title" *ngIf="orderTypeSelected === typeServices.SERVICE_DELIVERY">{{ 'ESTIMATED_DELIVERY_TIME' | translate}}</h5>
                    <h5 class="estimated-title" *ngIf="orderTypeSelected === typeServices.SERVICE_DINE_IN">{{ 'ESTIMATED_TIME_TO_DINE_IN' | translate}}</h5>
                    <p class="estimated-description">
                      <i class="fa fa-clock-o"></i>
                      {{estimatedTime}}</p>
                  </div>
            
                <blockquote class="blockquote">
                    <h4>{{ 'PAYMENT_INFORMATION' | translate }}</h4>
                    <ul class="modal-frm">
                        <li>
                            <strong>{{ 'FULLNAME' | translate }}:</strong>&nbsp;{{ dataUser.name}}&nbsp;{{dataUser.lastname}}</li>
                        <li>
                            <strong>{{ 'EMAIL' | translate }}:</strong>&nbsp;{{ dataUser.email }}</li>
                        <!-- <li>
                            <strong>{{ 'Telephone' | translate }}:</strong>&nbsp;{{dataUser.phone | mask: '(000) 000-0000'}}</li> -->
                        <li *ngIf="(orderServices?.payment && isCrediCarPanel) || paymentGatewayData">
                            <strong class="row p-0 m-0" *ngIf="orderServices?.payment && isCrediCarPanel && !paymentGatewayData">
                                {{ 'PAYMENT' | translate }}:&nbsp;{{'CREDIT_CARD' | translate}}<span class="text-end">{{'ENDING_IN' | translate}}</span>
                                {{orderServices?.payment?.ipr_cliaccount}}
                            </strong>
                            <ul *ngIf="paymentGatewayData" class="requestTransaction">
                                <li>
                                    <strong class="row p-0 m-0" >
                                        {{ 'PAYMENT' | translate }}:&nbsp;{{'CREDIT_CARD' | translate}}<span class="text-end">{{'ENDING_IN' | translate}}</span>
                                        {{paymentGatewayData?.cardLast4Digit}}
                                    </strong>
                                </li>
                                <li>
                                    <span>{{'TYPE_CREDIT_CARD' | translate}}:</span>&nbsp;{{paymentGatewayData?.cardType}}
                                </li>
                                <li>
                                    <span>{{'TRANSACTION_ID' | translate}}:</span>&nbsp;{{paymentGatewayData?.transactionId}}
                                </li>
                                <li>
                                    <span>{{'PAYMENT_CODE' | translate}}:</span>&nbsp;{{paymentGatewayData?.transactionReferenceId}}
                                </li>
                                <li>
                                    <span>{{'PAYMENT_REFERENCE' | translate}}:</span>&nbsp;{{paymentGatewayData?.RRN}}
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <h4>{{ 'ORDER_DETAIL' | translate}}</h4>
                    <ul class="modal-frm">
                        <li>
                            <strong>{{ 'RESTAURANT' | translate}}:</strong>&nbsp;{{ restaurantsServices?.detailRestaurant?.name }}
                        </li>
                        <li *ngIf="orderTypeSelected === typeServices.SERVICE_PICKUP">
                            <strong>{{ 'TYPE_SERVICE' | translate}}:</strong>&nbsp;{{ 'PickUp' | translate }}
                        </li>
                        <li *ngIf="orderTypeSelected === typeServices.SERVICE_DELIVERY">
                            <strong>{{ 'TYPE_SERVICE' | translate}}:</strong>&nbsp;{{ 'Delivery' | translate }}
                        </li>
                        <li *ngIf="orderTypeSelected === typeServices.SERVICE_DINE_IN">
                            <strong>{{ 'TYPE_SERVICE' | translate}}:</strong>&nbsp;{{ 'DINE_IN' | translate }}
                        </li>
                        <li *ngIf="orderTypeSelected === 3 || orderTypeSelected === 4 || orderTypeSelected === 5">
                            <strong>{{ 'TABLE_NUMBER' | translate}}:</strong>&nbsp;{{turn}}
                          </li>
                          <li *ngIf="orderTypeSelected === 3 || orderTypeSelected === 4 || orderTypeSelected === 5">
                            <strong>{{ 'FLOOR' | translate}}:</strong>&nbsp;{{selectedFloor}}
                          </li>
                        <li *ngIf="orderServices.payment && isCrediCarPanel">
                            <strong>{{ 'PAYMENT_CODE' | translate }}:</strong>&nbsp;{{orderServices.payment.ipr_ref_code}}
                        </li>
                        <li *ngIf="orderServices?.productSale?.ode_status === 2">
                            <strong>{{ 'TITLE_MODAL_DEFERRED' | translate}}: {{orderServices.dataDeferred.dataDeferredInfo}} - {{orderServices.dataDeferred.timeDeferredInfo}}</strong>
                        </li>
                        <li *ngIf="!paymentGatewayData">
                            <strong>{{ 'TOTAL' | translate }}:</strong>&nbsp;{{ order.total | currencyOMT }}
                        </li>
                        <ul *ngIf="paymentGatewayData" class="requestTransaction">
                            <li>
                                <span>{{ 'SUBTOTAL' | translate }}:</span>&nbsp;{{ paymentGatewayData?.amount | currencyOMT }}
                            </li>
                            <li>
                                <span>{{ 'TOTAL' | translate }}:</span>&nbsp;{{ paymentGatewayData?.totalAmount | currencyOMT }}
                            </li>
                        </ul>
                       
                    </ul>
            
                </blockquote>
            
                <!-- <button type="button" class="btn btn-primary" (click)="close()" aria-label="close">{{ 'CLOSE' | translate }}</button> -->
            
            </article>
        </div>
    </div>
    
    
</div>
