import { EventEmitter, Injectable } from '@angular/core';

import { GeoPosition } from 'app/core/entities/GeoPosition';
import { ReplaySubject } from 'rxjs';

@Injectable()
export class GlobalEventsService {

    public comment: any = '';

    public scheduleRestaurantsEvent: EventEmitter<Array<any>>;
    public searchRestaurantsEvent: EventEmitter<Function>;
    public nearLocationEvent: EventEmitter<GeoPosition>;
    public resetSearchRestaurant: EventEmitter<void>;

    /**
     * Avisa si el mapa se encuentra o no abierto
     */
    public showMap: EventEmitter<boolean>;
    /**
     * Avisa al momento de quitar un filtro de busqueda avanzada mediante el tag
     */
    public removeAdvancedFilters: EventEmitter<any>;

    /**
     * Avisa del cambio en el rango desde el listado de restaurantes
     * y se refresquen los filtros de cuisines, meals y features en el
     * buscador avanzado
     */
    public refreshFiltersByRange: EventEmitter<number>;

    public verifiedTypeOrder: EventEmitter<void>;
    public loginModal: EventEmitter<string>;
    public sinupModal: EventEmitter<void>;
    public login: EventEmitter<void>;
    public logout: EventEmitter<void>;
    public updatedProfile: EventEmitter<void>;
    public changeAddress: EventEmitter<void>;
    public profileImage: EventEmitter<any>;
    // public addAddressModal: EventEmitter<{ addresses: any, address: string, modal: string }>;
    // public addNotificationModal: EventEmitter<void>;
    // public detailCouponModal: EventEmitter<{ coupon: Coupon, isFromCart: boolean }>;
    // public editAddressModal: EventEmitter<{ addresses: any, address: ProfileAddress }>;
    // public showDetailNotificacionModal: EventEmitter<{ notification: any }>;
    public newServiceType: EventEmitter<any>;
    /**
     * Notifica que se debe abrir el modal de los platos de un restaurante que se desea recibir notificaciones
     */
    public showDetailProductsNotificacionModal: EventEmitter<{ id: string, name: string, images: any, allProductsSelected: boolean }>;

    public showListNotifications: EventEmitter<void>;
    public refreshOrders: EventEmitter<void>;
    public addReviewsModal: EventEmitter<any>;
    public langChange: EventEmitter<any>;
    public closeModelaDetailCoupon: EventEmitter<void>;

    /**
     * Notifica que se debe recargar los restaurantes en la pagina de configuracion de notificaciones
     */
    public reloadRestaurantsNotifications: EventEmitter<void>;

    /**
     * Cierra todos los modales activos
     */
    public closeAllModals: EventEmitter<void>;

    /**
     * Emitter para notificar que se deben reiniciar los cupones almacenados en storage
     */
    public resetStorageCoupons: EventEmitter<any>;

    /**
     * Notifica que se debe mostrar el modal del lenguage
     */
    public openLanguageModal: EventEmitter<void>;

    /**
     * Notifica que se debe mostrar el lenguage de direcciones
     */
    public openAddressModal: EventEmitter<any>;

    /**
     * Transmite el string del buscador del apartado near
     */
    public locationString: EventEmitter<string>;

    /**
     * Notifica que se hizo un cambio en el carrito, agregar un producto, eliminarlo
     */
    public actionCart: EventEmitter<void>;

    /**
     * Notifica que se hizo un cambio en el carrito, agregar un producto, eliminarlo
     */
    public newAddShopingCart: EventEmitter<void>;

    /**
     * Notifica que se hizo un cambio en el carrito, agregar un producto, eliminarlo
     */
    public reloadDetailRestaurant: EventEmitter<string>;
    /**
     * Transmite datos al modal de direcciones/como llegar
     * restaurant Infomración del restaurante
     * newPosition : Una posición que define el usuario
     */
    public directionsModal: EventEmitter<{ restaurant: any, newPosition: any }>;

    /**
     * Transmite los datos del componente restaurantList hacia el componente hijo home-map
     */
    public toHomeMap: ReplaySubject<any>;

     /**
     * Transmite los datos del componente restaurantList hacia el componente hijo home-map
     */
    public deliveryMap: EventEmitter<any>;
    /*
     * Avisa al momento de limpiar el carrito
     */
    public clearCart: EventEmitter<any>;
    /*
     * Se pasa por parametro el servicio que se debe actualizar
     * DELIVERY O PICKUP
     */
    public setTypeServiceRestaurant: EventEmitter<string|number>;

    /**
     * Dispara el evento para seleccionar la ubicacion actual
     */
    public selectCurrentLocation: EventEmitter<any>;
    public closeAddTipModal: EventEmitter<any>;

    public closeDialogCheckout: EventEmitter<void>;

    /**
     * Constructor de la clase
     *
     * @author Jhonier Gaviria M. - Jul. 26-2018
     * @version 1.0.0
     */
    constructor() {
        this.searchRestaurantsEvent = new EventEmitter<Function>();
        this.nearLocationEvent = new EventEmitter<GeoPosition>();
        this.resetSearchRestaurant = new EventEmitter<void>();
        this.removeAdvancedFilters = new EventEmitter<any>();
        this.refreshFiltersByRange = new EventEmitter<number>();
        this.verifiedTypeOrder = new EventEmitter<void>();
        this.loginModal = new EventEmitter<string>();
        this.sinupModal = new EventEmitter<void>();
        this.login = new EventEmitter<void>();
        this.logout = new EventEmitter<void>();
        this.updatedProfile = new EventEmitter<void>();
        this.changeAddress = new EventEmitter<void>();
        this.profileImage = new EventEmitter<void>();
        // this.addAddressModal = new EventEmitter<{ addresses: any, address: string, modal: string }>();
        // this.addNotificationModal = new EventEmitter<void>();
        // this.detailCouponModal = new EventEmitter<{ coupon: Coupon, isFromCart: boolean }>();
        // this.editAddressModal = new EventEmitter<{ addresses: any, address: ProfileAddress }>();
        // this.showDetailNotificacionModal = new EventEmitter<{ notification: any }>();
        this.showDetailProductsNotificacionModal = new EventEmitter<{
            id: string,
            name: string,
            images: any,
            allProductsSelected: boolean
        }>();
        // this.editAddressModal = new EventEmitter<{ addresses: any, address: ProfileAddress }>();
        this.showListNotifications = new EventEmitter<void>();
        this.refreshOrders = new EventEmitter<void>();
        this.addReviewsModal = new EventEmitter<void>();
        this.langChange = new EventEmitter<any>();
        this.closeModelaDetailCoupon = new EventEmitter<void>();
        this.resetStorageCoupons = new EventEmitter<any>();
        this.openLanguageModal = new EventEmitter<void>();
        this.openAddressModal = new EventEmitter<any>();
        this.locationString = new EventEmitter<string>();
        this.directionsModal = new EventEmitter<{ restaurant: any, newPosition: any }>();
        this.closeAllModals = new EventEmitter<void>();
        this.reloadRestaurantsNotifications = new EventEmitter<void>();
        this.scheduleRestaurantsEvent = new EventEmitter<Array<any>>();
        this.actionCart = new EventEmitter<void>();
        this.newAddShopingCart = new EventEmitter<void>();
        this.reloadDetailRestaurant = new EventEmitter<string>();
        this.toHomeMap = new ReplaySubject<any>(1);
        this.deliveryMap = new EventEmitter<any>();
        this.clearCart = new EventEmitter<any>();
        this.setTypeServiceRestaurant = new EventEmitter<string|number>();
        this.newServiceType = new EventEmitter<any>();
        this.showMap = new EventEmitter<boolean>();
        this.selectCurrentLocation = new EventEmitter<any>();
        this.closeAddTipModal = new EventEmitter<any>();
        this.closeDialogCheckout = new EventEmitter<void>();
    }
}
