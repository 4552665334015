import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { DynamicComponentLoader } from 'app/core/dynamic-loader/dynamic-component-loader.service';

@Component({
    selector: 'app-dialog-notification-product-component',
    template: '<div #notificationProduct></div>'})
export class DialogNotificationProductsComponent {
    @ViewChild('notificationProduct', { read: ViewContainerRef, static: true }) notificationProduct:
        | ViewContainerRef
        | undefined;

    constructor(private readonly loader: DynamicComponentLoader) {
        this.loader.getComponentFactory('notification-product').subscribe({
            next: factory => {
                if (!this.notificationProduct) {
                    return;
                }
                this.notificationProduct.createComponent(factory).changeDetectorRef.detectChanges();
            }
        });
    }
}
