import { AuthService } from "angularx-social-login";
import { FilterSearchComponent } from "app/shared/components/filterSearch/filterSearch.component";
import { SharedService } from "app/core/services/shared.service";
import { GlobalEventsService } from "app/core/services/globalEvents.service";
import { SweetAlertService } from "app/utils/sweetAlert.service";
import { UtilsComponent } from "app/utils/utils.component";
import { LocalizeRouterService } from "localize-router";
import {
    Component,
    HostListener,
    Inject,
    OnInit,
    ViewChild,
} from "@angular/core";
// import { MatDialog } from '@angular/material';
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AppConstants } from "app/app-constants";
import { AuthServices } from "app/core/interfaces/auth.interfaces";
import { DynamicModalServices } from "app/core/interfaces/dynamic-modals.interfaces";
import {
    TotalOrdersStructureResponse,
    UserServices,
} from "app/core/interfaces/user.interfaces";
import { ResponsesStructure } from "app/core/interfaces/transversal.interfaces";
import { OrderServices } from "app/core/interfaces/orders.interfaces";
import { DLoginComponent } from "app/shared/dynamic-modules/login/DLogin.component";
import { DialogRegisterComponent } from "app/shared/dynamic-modules/register/dialog-register.component";

// Variable para manejo de funciones jquery
declare var jQuery: any;

@Component({
    selector: "mainHeader",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
    refDialog: any = false;
    /**
     * Esta pendiente de accion del boton retroceso
     * del navegador, valida si el formulario esta activado
     * para evitar la usabilidad del boton en las otras funcionalidades
     */
    @HostListener("window:popstate", ["$event"])
    onPopState(_) {
        if (this.refDialog) {
            window.history.forward();
            this.refDialog = false;
        }
    }
    /**
     * guarda los datos del usuario que estan en el local Storage
     */
    public dataUser: any;

    /**
     * guarda la imagen del usuario que estan en el local Storage
     */
    public photoUrl: any;

    /**
     * variable para guardar el estado, para saber si el usuario esta logeado
     * */
    public authenticated: boolean;

    /**
     * Guarda el total de pedidos pendientes
     */
    public totalPending: number;

    public idrestaurant: string;

    // accedemos al elemento hijo, en este caso el buscador
    @ViewChild(FilterSearchComponent)
    private readonly formSearchElement: FilterSearchComponent;

    /**
     * Listado de lenguajes de la aplicacion
     */
    public languages: any[];

    /**
     * Es el lenguaje seleccionado
     */
    public languageSelected: string;

    // openModal: boolean;
    public isCookie: any;
    /**
     * Variable que determina si la pagina donde estamos es la del detalle del restaurante
     */
    public isDetailPage: boolean;

    public idRestaurant: string;

    constructor(
        public utils: UtilsComponent,
        private readonly translate: TranslateService,
        private readonly localize: LocalizeRouterService,
        @Inject("OrderServices") private readonly orderServices: OrderServices,
        private readonly globalEvents: GlobalEventsService,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        @Inject("AuthServices") private readonly authServices: AuthServices,
        private readonly authService: AuthService,
        @Inject("UserServices") private readonly userServices: UserServices,
        private readonly sweet: SweetAlertService,
        @Inject("DynamicModalServices")
        private readonly modalService: DynamicModalServices,
        private readonly sharedService: SharedService
    ) {
        this.authenticated = false;
        this.languages = [
            {
                value: "es",
                label: "SPANISH",
            },
            {
                value: "en",
                label: "ENGLISH",
            },
        ];

       
        
    }

    /**
     * @see {@link https://angular.io/guide/lifecycle-hooks#lifecycle-hooks Lifecycle Hooks Docs}
     */
    ngOnInit() {
        // Se llama cuando se setea la variable de una imagen de perfil
        this.globalEvents.profileImage.subscribe((imagePath) => {
            if (
                imagePath &&
                localStorage.getItem(AppConstants.LOCAL_STORAGE_DATA.AUTH_TOKEN)
            ) {
                this.photoUrl = imagePath;
            }
        });
        if (localStorage.getItem(AppConstants.LOCAL_STORAGE_DATA.AUTH_TOKEN)) {
            this.authenticated = true;
            this.dataUser = JSON.parse(
                localStorage.getItem(AppConstants.LOCAL_STORAGE_DATA.DATA_USER)
            );
            if (
                localStorage.getItem(
                    AppConstants.LOCAL_STORAGE_DATA.PHOTO_URL
                ) === "null" ||
                localStorage.getItem(
                    AppConstants.LOCAL_STORAGE_DATA.PHOTO_URL
                ) === null ||
                localStorage.getItem(
                    AppConstants.LOCAL_STORAGE_DATA.PHOTO_URL
                ) === undefined
            ) {
                this.photoUrl = null;
            } else {
                this.photoUrl = localStorage.getItem(
                    AppConstants.LOCAL_STORAGE_DATA.PHOTO_URL
                );
            }
        }
        this.globalEvents.login.subscribe(() => {
            this.dataUser = JSON.parse(
                localStorage.getItem(AppConstants.LOCAL_STORAGE_DATA.DATA_USER)
            );
            this.authenticated = true;
            if (this.utils.externalMenu) {
                const urlrest = '/app/detail-restaurant/' + this.utils.idRestaurantExt;
                const paramGet = {
                    otherpage: true,
                };
                this.router.navigate([this.localize.translateRoute(urlrest)], {
                    queryParams: paramGet, queryParamsHandling: 'merge',
                });
            }
        });
        this.userServices.emitterCookie.subscribe((data: object) => {
            this.isCookie = data;
        });
        this.globalEvents.logout.subscribe(() => {
            this.authenticated = false;
        });
        this.globalEvents.updatedProfile.subscribe(() => {
            // cuando se modifiquen los datos del usuario actualizamos los del componente
            this.dataUser = JSON.parse(
                localStorage.getItem(AppConstants.LOCAL_STORAGE_DATA.DATA_USER)
            );
        });
        this.route.queryParams.subscribe((params) => {
            if (params.idrestaurant) {
                this.idrestaurant = params.idrestaurant;
                this.utils.idRestaurantExt = params.idrestaurant;
            }
        });
        this.setAttrToBanner();
        // Escuchador de ruta
        this.sharedService.listenerForRoute().subscribe((data: any) => {
            // Determina si la pagina en la que estamos es la del detalle del restaurante
            this.isDetailPage =
                this.sharedService.setIsLandingPage(data, "detailRestaurant") ||
                this.sharedService.setIsLandingPage(data, "search");
        });
        if (
            localStorage.getItem(AppConstants.LOCAL_STORAGE_DATA.LANGUAGE_USER)
        ) {
            this.languageSelected = localStorage.getItem(
                AppConstants.LOCAL_STORAGE_DATA.LANGUAGE_USER
            );
        }

        this.route.params.subscribe((params) => {
            console.log("params", params);
        });

        this._notShowHeader();
    }

    public resetSearch() {
        /**
         * Enrutador para que se devuelva al lsitado de restaurantes al presionar el logo de omt
         */
        this.router.navigateByUrl('app/profile/about-me').then(()=> this.router.navigate(["es/app/restaurant-list"]));
        //this.router.navigate([this.localize.translateRoute('/app/restaurant-list')], { queryParams: {} });
        this.globalEvents.resetSearchRestaurant.emit();
         
    }

    /**
     * Metodo que escucha cuando el boton de mas opciones es presionado,
     * ejecuta otra funcion que permite mostrar las opciones avanzadas del buscador
     */
    public toogleMoreOptions() {
        this.formSearchElement.toggleShowAdvancedOptions();
    }

    /**
     * abre el modal de login
     */
    public openLogiMmodal() {
        this.refDialog = true;
        this.modalService.openDialog(DLoginComponent, {
            panelClass: "dialog-right",
        });
    }

    /**
     * abre el modal de sinUp
     */
    public openSinupModal() {
        this.refDialog = true;
        this.modalService.openDialog(DialogRegisterComponent, {
            panelClass: "dialog-right",
        });
    }

    /**
     * abre el modal del cart
     */
    // openCartModal() {
    // this.modalService.openDialog(DialogCartComponent, {
    //     panelClass: 'dialog-right'
    // });

    // }

    /**
     * Método para remover los datos de sesión del localstorage al hacer logout
     */
    private logoutRemoveData() {
        this.authenticated = false;
        localStorage.removeItem(AppConstants.LOCAL_STORAGE_DATA.AUTH_TOKEN);
        localStorage.removeItem(AppConstants.LOCAL_STORAGE_DATA.DATA_SESSION);
        localStorage.removeItem(AppConstants.LOCAL_STORAGE_DATA.DATA_USER);
        localStorage.removeItem(AppConstants.LOCAL_STORAGE_DATA.PHOTO_URL);
        localStorage.removeItem(AppConstants.LOCAL_STORAGE_DATA.LANGUAGE_USER);
        // logout social net
        if (
            localStorage.getItem(AppConstants.LOCAL_STORAGE_DATA.SOCIAL_LOGIN)
        ) {
            localStorage.removeItem(AppConstants.LOCAL_STORAGE_DATA.PHOTO_URL);
            localStorage.removeItem(
                AppConstants.LOCAL_STORAGE_DATA.SOCIAL_LOGIN
            );
            this.authService.signOut();
        }
        this.globalEvents.logout.emit();
    }

    /**
     * alert para cuando el usuario se va a desloguear confirme si desea salir o no
     */
    public confirmLogout() {
        this.translate.get("LOGOUT_MESSAGE").subscribe((res) => {
            this.sweet.confirm(res).then((isConfirmed: boolean) => {
                if (isConfirmed) {
                    this.logout();
                }
            });
        });
    }

    /**
     * Metodo para realizar el proceso de logout
     */
    public logout() {
        this.authServices
            .logout(
                localStorage.getItem(AppConstants.LOCAL_STORAGE_DATA.AUTH_TOKEN)
            )
            .subscribe(
                () => {
                    this.logoutRemoveData();
                    // Si estamos en el detalle hacemos que permanezca allí
                    if (this.router.url.indexOf("detailRestaurant") !== -1) {
                        console.log("header 1 omt sin white label");
                        // Si es menu externo
                        if (this.utils.externalMenu) {
                            const urlrest =
                                "/app/detail-restaurant/" +
                                this.utils.idRestaurantExt;
                            const paramGet = {
                                otherpage: true,
                            };
                            this.router.navigate(
                                [this.localize.translateRoute(urlrest)],
                                { queryParams: paramGet }
                            );
                        }
                    } else {
                        let language = localStorage.getItem(AppConstants.LOCAL_STORAGE_DATA.LANGUAGE_USER);
                        this.utils.navigateRestaurant(language, this.utils.idRestaurantExt);
                    }
                    this.authenticated = false;
                },
                (error) => {
                    this.logoutRemoveData();
                }
            );
    }

    public myOrdersTotal() {
        if (!jQuery(".collapsed").hasClass("in")) {
            const apiKey = localStorage.getItem(
                AppConstants.LOCAL_STORAGE_DATA.AUTH_TOKEN
            );
            this.userServices
                .myOrdersTotal(apiKey)
                .subscribe((response: ResponsesStructure) => {
                    const dataResponse: TotalOrdersStructureResponse =
                        response.data;
                    if (dataResponse) {
                        this.totalPending = dataResponse.totalProces;
                    }
                });
        }
    }

    /**
     * Permite obtener el total de productos en el carrito de compras
     */
    public getTotalItems() {
        return this.orderServices.getTotalItems();
    }

    /**
     * Permite establecer un idioma por defecto
     * @param language El nuevo lenguage
     */
    public chooseLanguage(language: string) {
        this.languageSelected = language;
        localStorage.setItem(
            AppConstants.LOCAL_STORAGE_DATA.LANGUAGE_USER,
            this.languageSelected
        );
        this.globalEvents.langChange.emit(this.languageSelected);
    }

    /**
     * Metodo encargado de setear atributo jquery al banner para que funcione correctamente
     */
    private setAttrToBanner() {
        jQuery(".wm-banner").slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2000,
            infinite: true,
            fade: true,
            dots: true,
            arrows: false,
            responsive: [
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                    },
                },
                {
                    breakpoint: 800,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: 400,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
            ],
        });
    }

    /**
     * Metodo encargado de mostrar u ocultar el banner dependiendo de la seccion donde se
     * encuentre el usuario
     * @param showBanner : boolean -> Varible que determina si se pone el id al Header
     */
    // canShowBanner(showBanner: boolean) {
    //     if (showBanner) {
    //         jQuery('.showBanner').show();
    //         jQuery('#filterSearchForm').show();
    //     } else {
    //         jQuery('.showBanner').hide();
    //         // jQuery('#filterSearchForm').hide();
    //     }
    // }

    /**
     * Animación en jquery para flecha del idioma
     */
    public animationLanguage() {
        jQuery("a")
            .find('[class*="fa-angle"]')
            .toggleClass("fa-angle-up fa-angle-down");
    }

    /**
     * Lleva al carrito que se encuentra incrustado en el detalle del restaurante
     * Si se encuentra en el detalle hace un reload y si está fuera navega a la url
     * @author Alvaro Felipe Garcia Mendez - June 6 - 2019
     * @version 1.0.1
     */
    public goToDetail() {
        const id = this.orderServices.getRestaurant().id;
        if (this.router.url.indexOf("detailRestaurant") !== -1) {
            if (this.router.url.indexOf(id) !== -1) {
                return;
            }
            this.globalEvents.reloadDetailRestaurant.emit(id);
            return;
        }
        this.router.navigate([
            this.localize.translateRoute("/app/detail-restaurant"),
            id,
        ]);
    }

    public openLanguageModal() {
        this.globalEvents.openLanguageModal.emit();
    }

    /**
     *  Método para no mostrar el header cuando se está en un menu online
     *  @author Oscar Alarcon - Abr. 26 2024
     *  @version 1.0.0
     */
    private _notShowHeader(){
        // Se obtiene la url actual
        var currentUrl = window.location.href;
        // Se verifica si la URL contiene "?otherpage=true"
        if (currentUrl.includes('?otherpage=true')) {
            var headerElement = document.getElementById("wm-header");
            // Se verifica si el elemento existe en el DOM
            if (headerElement) {
                // Se modifica el estilo solo si el elemento existe
                headerElement.style.display = "none";
            }
        }
    }
}
