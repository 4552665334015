import { NgModule } from '@angular/core';
import { DynamicComponentLoaderModule } from 'app/core/dynamic-loader/dynamic-component-loader.module';
import { DialogDetailCouponModalComponent } from './dialog-detail-coupon-modal.component';

@NgModule({
  declarations: [DialogDetailCouponModalComponent],
  exports: [DialogDetailCouponModalComponent],
  imports: [
    DynamicComponentLoaderModule.forModule({
      componentId: 'detail-coupon-modal',
      path: 'detail-coupon-modal',
      loadChildren: () => import('./content/detail-coupon-modal.module').then(m => m.DetailCouponModalModule)
    })
  ]
})
export class DialogDetailCouponModalModule { }
