import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { DynamicComponentLoader } from 'app/core/dynamic-loader/dynamic-component-loader.service';

@Component({
    selector: 'app-dialog-reviews-modal',
    template: '<div #reviewsModal></div>'})
export class DialogReviewsModalComponent {

    @ViewChild('reviewsModal', { read: ViewContainerRef, static: true }) _reviewsModal:
        | ViewContainerRef
        | undefined;

    constructor(private readonly loader: DynamicComponentLoader) {
        this.loader.getComponentFactory('reviews-modal').subscribe({
            next: factory => {
                if (!this._reviewsModal) {
                    return;
                }
                this._reviewsModal.createComponent(factory).changeDetectorRef.detectChanges();
            }
        });
    }
}
