import { NgModule } from '@angular/core';
import { DynamicComponentLoaderModule } from 'app/core/dynamic-loader/dynamic-component-loader.module';
import { DLoginComponent } from './DLogin.component';

@NgModule({
  declarations: [DLoginComponent],
  exports: [DLoginComponent],
  imports: [
    DynamicComponentLoaderModule.forModule({
      componentId: 'login',
      path: 'login',
      loadChildren: () => import('./content/login.module').then(m => m.LoginModule)
    })
  ]
})
export class DLoginModule {}
