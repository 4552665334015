import { ActivatedRoute } from '@angular/router';
import { IPaymentGateway } from '../interfaces/payment-gateway-information.interface';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PaymentGatewayServiceService {


  /**
   * Variable para guardar el divisor para hacer la operación del subtotal y total del pago con dejavoo
   */
  private _totalDivisor: number;

  constructor(private _route: ActivatedRoute) {

    this._totalDivisor = 100;
   }

  /**
   * @description Metodo para saber si llegan parametros en la ruta sobre el pago con la pasarela dejavoo
   * @author Oscar Alarcon - marz. 21-2024
   * @version 1.0.0
   * @returns se retorna null cuando no llegan los parametros 
   */
  public extractPaymentGatewayData(): IPaymentGateway | null{
    const params = this._route.snapshot.queryParams;
    if (
      params['responseCode'] &&
      params['responseMessage'] &&
      params['transactionType'] &&
      params['transactionReferenceId'] &&
      params['amount'] &&
      params['customFee'] &&
      params['transactionNumber'] &&
      params['batchNumber'] &&
      params['cardType'] &&
      params['cardLast4Digit'] &&
      params['tips'] &&
      params['responseApprovalCode'] &&
      params['RRN'] &&
      params['totalAmount'] &&
      params['transactionId']
    ) {
      return {
        responseCode: +params['responseCode'],
        responseMessage: params['responseMessage'],
        transactionType: +params['transactionType'],
        transactionReferenceId: params['transactionReferenceId'],
        amount: +params['amount'] / this._totalDivisor,
        customFee: +params['customFee'],
        transactionNumber: +params['transactionNumber'],
        batchNumber: +params['batchNumber'],
        cardType: params['cardType'],
        cardLast4Digit: params['cardLast4Digit'],
        tips: +params['tips'],
        localTax: 0,
        stateTax: 0,
        responseApprovalCode: params['responseApprovalCode'],
        RRN: params['RRN'],
        totalAmount: +params['totalAmount'] / this._totalDivisor,
        transactionId: params['transactionId']
        };
    } else {
      return null;
    }
  }
}
