import 'polyfills.ts';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .then(success => {
        if (!environment.production) {
            console.log('Bootstrap success');
        }
        // if ('serviceWorker' in navigator) {
        //     navigator.serviceWorker.register('/ngsw-worker.js');
        // }
    })
    .catch(err => console.error(err));
