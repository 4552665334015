<!-- Modal Language -->
<article bsModal #languageModal="bs-modal" class="modal fade modalLanguage" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="moda-header">
          <h4 class="modal-title black">
              {{ 'SELECT_LANGUAGE' | translate }}
            </h4>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="close()">
              <span aria-hidden="true">&times;</span>
          </button>
      </div>
      <div class="modal-body">
        <div class="radio line" *ngFor="let language of languages">
          <label class="flx cien nopad">
            <input class="noabs" type="radio" name="optradio" [checked]="language.value === languageSelected" (click)="changeLanguageSelected(language.value)"> {{ language.label | translate }}</label>
        </div>
        <div class="modal-footer flx">
          <button type="button" class="btn ml-0 mr-0 btn-danger btnLoad" (click)="close()" aria-label="cancel">
            <span> {{ 'CANCEL' | translate }}</span>
          </button>
          <button type="button" class="btn ml-0 mr-0 btn-primary btnLoad" (click)="chooseLanguage()" aria-label="confirm">
            <span> {{ 'CONFIRM' | translate }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</article>