// Variable para manejo de funciones jquery
declare var jQuery: any;
/**
 * Esta clase es la que se utiliza para hace la peticion a un web services
 */
export class Paginator {
    orderBy: string;
    page: number;
    itemsPerPage: number;
    sort: number;

    // inicializamos variables
    constructor() {
        this.orderBy = null;
        this.page = null;
        this.itemsPerPage = null;
        this.sort = null;
    }

    /**
     * permite asignar valores por defecto
     */
    toDefaultValues() {
        this.orderBy = 'distance';
        this.page = 1;
        this.itemsPerPage = jQuery(window).width() < 1400 ? 8 : 9;
        this.sort = 0;
    }
}
