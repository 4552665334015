import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { DynamicComponentLoader } from 'app/core/dynamic-loader/dynamic-component-loader.service';

@Component({
    selector: 'app-coupon-code',
    template: '<div #couponModal></div>'})
export class DialogCouponCodeComponent {

    @ViewChild('couponModal') couponCode:
        | ViewContainerRef
        | undefined;

    constructor(private readonly loader: DynamicComponentLoader) {
        this.loader.getComponentFactory('coupon-code').subscribe({
            next: factory => {
                if (!this.couponCode) {
                    return;
                }
                this.couponCode.createComponent(factory).changeDetectorRef.detectChanges();
            }
        });
    }
}
