export class formSearchRestaurants {
    textFilter: string;
    nearLocation: {
        lat: number,
        lng: number
    };
    distance: number;
    // onlyOpen: boolean;
    cuisines: Array<any>;
    features: Array<any>;
    meals: Array<any>;
    isSearchAvanced: boolean;
    order: string;
    range: string;

    constructor() {
        this.initialize();
    }

    /**
     * Permite inicializar las variables en valores por defecto
     */
    public initialize() {
        this.textFilter = null;
        this.nearLocation = null;
        this.distance = 0;
        // this.onlyOpen = true;
        this.cuisines = [];
        this.features = [];
        this.meals = [];
        this.isSearchAvanced = false;
        this.order = '';
        this.range = '';
    }



}
