import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EmailServices, SendEmailStructureRequest } from 'app/core/interfaces/emails.interfaces';
import { ResponsesStructure } from 'app/core/interfaces/transversal.interfaces';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class EmailService implements EmailServices{

  constructor( 
    public readonly http: HttpClient)
  { }

  /**
   * consume el servicio de registro de usuario
   * @param data
   */
  /**
   * Se realiza el tipado del objeto que se envía como request, sin embargo, no se pudo tipar el .data
   * de la respuesta ya que no se pudo probar la funcionalidad
   */
  landing(data: SendEmailStructureRequest): Observable<ResponsesStructure> {
    const url = 'https://omtws.openmytab.com/sendEmail';
    return this.http.post<ResponsesStructure>(url, data).pipe(
      map((response: ResponsesStructure) => {
        return response;
      }),catchError(error => {
        return throwError(error);
      })
    );
  }

}
 