import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { NavigationStart, Router } from '@angular/router';
import { AppConstants } from 'app/app-constants';

@Injectable({
  providedIn: 'root'
})

/**
 * Este servicio se encarga de determinar el idioma basado en la URL ingresada por el usuario.
 */

export class LanguageRouteService {

    /**
     * Suscripción a eventos del router para capturar la ruta al iniciar la navegación (NavigationStart).
     */
    public routerSubscription: Subscription;

    /**
     * Variable para guardar la ruta completa
     */
    private _currentUrl: string;

    /**
     * Variable para indicar si el idioma es el lenguage establecido
     */
    public englishLanguage: boolean;

  constructor(private router: Router,) {
    this.englishLanguage = false;
    // Suscripción al evento NavigationStart para capturar la URL inicial al iniciar la navegación.
    // Esto permite actualizar la ruta actual y gestionar el idioma correspondiente.
    this.routerSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationStart)).subscribe((event: NavigationStart) => {
      this._currentUrl = event.url;
      // Se llama a languageRoute después de capturar la URL
      this._languageRoute();
    });
   }

   /**
    * Método para determinar el idioma según la URL actual del usuario y lo guarda en el almacenamiento local.
    * @author Oscar Alarcon - Jun. 04-2024
    * @version 1.0.0
    */
   private _languageRoute(): void {
    // Variable para guardar el lenguaje de la URL
    let language: string;
    // Se verifica los fragmentos del idioma ('/es/') o ('/en/') en la URL.
    const hasEsFragment = this._currentUrl.includes('/es/');
    const hasEnFragment = this._currentUrl.includes('/en/');
    // Según el fragmento que se obtiene en la URL, se lo asignamos a la variabe language
    if (hasEsFragment) {
      language = 'es';
      this.englishLanguage = false;
    } else if (hasEnFragment) {
      language = 'en';
      this.englishLanguage = true;
    }
    // Se guarda el idioma en el localStorage
    localStorage.setItem(AppConstants.LOCAL_STORAGE_DATA.LANGUAGE_ROUTE, language);
  }
}
