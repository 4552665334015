import { AuthService } from 'angularx-social-login';
import { AppConstants } from 'app/app-constants';
import { LocalizeRouterService } from 'localize-router';
import { Inject, Injectable } from '@angular/core';
import { GlobalEventsService } from '../core/services/globalEvents.service';
import { ImagesUtilsComponent } from './imagesUtils.component';
import { UtilsComponent } from './utils.component';
import { ResponsesStructure } from 'app/core/interfaces/transversal.interfaces';
import { AuthServices, SingUpStructureResponse } from 'app/core/interfaces/auth.interfaces';
import { ProfileImageStructureResponse, ProfileServices } from 'app/core/interfaces/profile.interfaces';

@Injectable()
export class UtilsSocialNetwork {

    dataSession: any;
    dataUser: any;

    constructor(
        private globalEvents: GlobalEventsService,
        private authService: AuthService,
        @Inject('AuthServices') private readonly authServices: AuthServices,
        private utils: UtilsComponent,
        @Inject('ProfileServices') private readonly profileServices: ProfileServices,
        private imagesUtils: ImagesUtilsComponent,
        private globalEventsService: GlobalEventsService,
        private localize: LocalizeRouterService
    ) {
    }

    signInWithNetwork(typeSocial): void {
        let currentSocial = AppConstants.CONST_GOOGLE;
        if (typeSocial === AppConstants.CONST_FACEBOOK) {
            currentSocial = AppConstants.CONST_FACEBOOK;
        }
        this.authService.signIn(currentSocial)
            .then((data) => {
                this.singUpSocial(this.utils.clone(data));
            })
            .catch((error) => {
                console.log('error ==>', error);
            });
    }

    /**
     * Define imagen de perfil y hace la transmisión de imagen a los subscribe de profileImage
     * @param pathImageProfile Path de imagen de perfil
     */
    setImageProfile(profileImage: any) {
        this.imagesUtils.setContentFolder('/images/client/profile/');
        this.imagesUtils.setImageDefault('assets/images/avatar_omt.jpg');
        this.imagesUtils.setSizeFormat('');
        const pathImageProfile = this.imagesUtils.getPath(profileImage);
        localStorage.setItem(AppConstants.LOCAL_STORAGE_DATA.PHOTO_URL, pathImageProfile);
        this.globalEventsService.profileImage.emit(pathImageProfile);
    }


    /**
     * se encarga de enviar datos al ws login SocialNet
     */
    singUpSocial(responseSocialNet) {

        if (responseSocialNet) {
            if (responseSocialNet.provider === AppConstants.CONST_GOOGLE) {
                responseSocialNet.provider = 1;
            }
            if (responseSocialNet.provider === AppConstants.CONST_FACEBOOK) {
                responseSocialNet.provider = 2;
            }
            const responseDataSplit = responseSocialNet.name.split(' ');

            if (responseDataSplit.length === 1 || responseDataSplit.length === 2) {
                responseSocialNet.name = responseDataSplit[0];
            }
            if (responseDataSplit.length === 2) {
                responseSocialNet.lastname = responseDataSplit[1];
            }
            if (responseDataSplit.length === 3 || responseDataSplit.length >= 4) {
                responseSocialNet.name = responseDataSplit[0] + ' ' + responseDataSplit[1];
                if (responseDataSplit.length === 3) {
                    responseSocialNet.lastname = responseDataSplit[2];
                }
            }
            if (responseDataSplit.length >= 4) {
                responseSocialNet.lastname = responseDataSplit[2].concat(' ').concat(responseDataSplit[3]);
            }
            this.authServices
                .singUpSocialNet(responseSocialNet)
                .subscribe((response: ResponsesStructure) => {
                    if (response.errors) {
                    } else {
                        const dataResponse: SingUpStructureResponse = response.data;
                        this.dataSession = dataResponse.mobile_sessions[0];
                        delete dataResponse.mobile_sessions;
                        this.dataUser = dataResponse;
                        // validamos que haya informacion del iidioma por parte del usuario
                        if (this.dataUser.langCode && this.dataUser.langCode !== null) {
                            // establecemos el idioma del usuario que se esta logueando
                            this.localize.changeLanguage(this.dataUser.langCode);
                            localStorage.setItem(AppConstants.LOCAL_STORAGE_DATA.LANGUAGE_USER, this.dataUser.langCode);
                        }
                        localStorage.setItem(AppConstants.LOCAL_STORAGE_DATA.AUTH_TOKEN, this.dataSession.apiKey);
                        localStorage.setItem(AppConstants.LOCAL_STORAGE_DATA.DATA_SESSION, JSON.stringify(this.dataSession));
                        localStorage.setItem(AppConstants.LOCAL_STORAGE_DATA.DATA_USER, JSON.stringify(this.dataUser));
                        localStorage.setItem(AppConstants.LOCAL_STORAGE_DATA.SOCIAL_LOGIN, 'true');
                        if (this.dataUser.isNewClient) {
                            let photoUrl = responseSocialNet.photoUrl;
                            photoUrl = photoUrl.split('?')[0];
                            this.imagesUtils.getBlobFromUrl(String(photoUrl + '?width=588&height=583'), blob => {
                                this.profileServices.saveImageProfile(blob).subscribe((response: ResponsesStructure)  => {
                                    if (!response.errors) {
                                        const dataResponse: ProfileImageStructureResponse = response.data;
                                        this.setImageProfile(dataResponse);
                                    }
                                });
                            });
                        } else {
                            this.setImageProfile(this.dataUser.image_profile);
                        }
                        this.globalEvents.login.emit();
                    }
                    // tslint:disable-next-line:no-unused-expression
                }), error => {
                    console.log('error ==>', error);
                };
        }
    }

}
