export enum PhoneOrderType {
    /**
     * Esto es para cuando el order type no es 3(phone order)
     */
    noPhone = 0,
    /**
     * Cuando el tipo de phone order es pickup
     */
    pickup = 1,
    /**
     * Cuando el tipo de phone order es delivery
     */
    delivery = 2,
    /**
     * Se utiliza para indicar que el order type es 1(dine in) y que es una mesa
     */
    table = 3,
    /**
     * Se utiliza para indicar que el order type es 1(dine in) y que es un palito u otro objeto donde se tiene el número de 
     */
    turn = 4,
    /**
     * Se utiliza para indicar que el order type es 1(dine in) y que el sistema le genera el turno automaticamente
     */
    turnAutomatic = 5
}
