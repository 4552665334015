import { NgModule } from '@angular/core';
import { DynamicComponentLoaderModule } from 'app/core/dynamic-loader/dynamic-component-loader.module';
import { DialogDirectionsComponent } from './dialog-directions-modal.component';

@NgModule({
    declarations: [DialogDirectionsComponent],
    exports: [DialogDirectionsComponent],
    imports: [
        DynamicComponentLoaderModule.forModule({
            componentId: 'directions-modal',
            path: 'directions-modal',
            loadChildren: () => import('./content/directions-modal.module').then(m => m.DirectionsModalModule)
        })
    ]
})
export class DialogDirectionsModalModule { }
