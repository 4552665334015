import { NgModule } from '@angular/core';
import { DynamicComponentLoaderModule } from 'app/core/dynamic-loader/dynamic-component-loader.module';
import { DialogPrivacyPolicyComponent } from './dialog-privacy-policy.component';

@NgModule({
    declarations: [DialogPrivacyPolicyComponent],
    exports: [DialogPrivacyPolicyComponent],
    imports: [
        DynamicComponentLoaderModule.forModule({
            componentId: 'privacy-policy',
            path: 'privacy-policy',
            loadChildren: () => import('./content/privacy-policy.module').then(m => m.PrivacyPolicyModule)
        })
    ]
})
export class DialogPrivacyPolicyModule { }
