import { GeoPosition } from './GeoPosition';

export class ResultAutoComplete {
    coords: GeoPosition;
    address: any;
    place: any;
    isSameCountry: boolean;
    constructor() {
        this.coords = null;
        this.address = null;
        this.place = null;
        this.isSameCountry = false;
    }

}
