import { ResultAutoComplete } from 'app/core/entities/ResultAutoComplete';
import {
    ComponentFactoryResolver, Directive, ElementRef, EventEmitter, HostListener, Input, OnChanges,
    OnInit, Output, Renderer2, SimpleChanges, ViewContainerRef}
    from '@angular/core';
import { autoCompleteMenuComponent } from '../components/autoComplete/autoCompleteMenu.component';

@Directive({
    selector: '[appAutoCompleteDirective]',
    exportAs: 'kijho-autocomplete'}
)
export class AutoCompleteDirective implements OnInit, OnChanges {

    /**
     * Por medio de este atributo nos vamos a comunicar con el componente padre
     */
    @Input() appAutoCompleteDirective: any;

    /**
     * Nos permite identificar si se habailita o no la seleccion de direcciones
     */
    @Input() enableAddress: boolean;

    /**
     * Nos permite identificar si se habailita o no la seleccion de direcciones
     */
    @Input() initAddressFormat: string;

    /**
     * Permite emitir eventos de cambios sobre el componente
     */
    @Output() onChange: EventEmitter<any>;

    /**
     * Indica si el componente(input) que implementa la directiva requiere saber el momento en que se elimina el valor de busqueda
     */
    @Input()
    public isListeningValueInput: boolean;

    /**
     * Indica que el ultimo emit de 'onChange' fue con valor null, al ser true no debe emitir de nuevo para no estar realizando peticiones
     * cada que presionan teclas como borrar y las flechas con el campo de busqueda vacio
     */
    private lastEmitWasNull: boolean;

    // permite identificar si esta habilitado el focus sobre el campo de texto
    isFocus: boolean;

    // permite comunicar el componente con la directiva
    compAutoComp: autoCompleteMenuComponent;

    /**
     * Variable que hara la representacion del boton de limpiar campo
     */
    private buttonReset: HTMLSpanElement;

    constructor(
        public element: ElementRef,
        public viewContainerRef: ViewContainerRef,
        public componentFactoryResolver: ComponentFactoryResolver,
        public renderer: Renderer2)
    {
        // deshabilitamos algunas autoayudas del campo de entrada;
        this.element.nativeElement.setAttribute('autocomplete', 'off');
        this.element.nativeElement.setAttribute('autocapitalize', 'off');
        this.element.nativeElement.setAttribute('autocorrect', 'off');

        this.onChange = new EventEmitter<any>();
        this.isFocus = false;
        this.enableAddress = false;
        this.initAddressFormat = null;
        this.isListeningValueInput = false;
        this.lastEmitWasNull = false;

        this.loadComponent();

        // Construimos el boton de eliminar el texto del input
        this.createButtonElement();
    }

    /**
     * @see {@link https://angular.io/guide/lifecycle-hooks#lifecycle-hooks Lifecycle Hooks Docs}
     */
    ngOnChanges(changes: SimpleChanges) {
        if (typeof changes['initAddressFormat'] !== 'undefined') {
            if (changes['initAddressFormat']['firstChange'] && 
                changes['initAddressFormat']['currentValue'] !== 'undefined') {

                if (this.initAddressFormat !== null && typeof this.initAddressFormat !== 'undefined') {
                    this.element.nativeElement.value = this.compAutoComp.removeCountry(this.initAddressFormat, true);
                }

                // mostramos el boton de limpiar campo
                this.showButtonReset();
            }
        }
    }

    /**
     * @see {@link https://angular.io/guide/lifecycle-hooks#lifecycle-hooks Lifecycle Hooks Docs}
     */
    ngOnInit(): void {
        this.compAutoComp.onSelect.subscribe((result: ResultAutoComplete) => {

            if (result.isSameCountry) {
                this.element.nativeElement.value = this.compAutoComp.removeCountry(result.address.formatted);
            } else {
                this.element.nativeElement.value = result.address.formatted;
            }

            // mostramos el boton de limpiar campo
            this.showButtonReset();

            this.onChange.emit(result);
            this.lastEmitWasNull = false;
        });
        this.compAutoComp.enableShowAddress(this.enableAddress);
    }


    @HostListener('focus')
    onfocus(): void {
        this.isFocus = true;
        this.compAutoComp.setFocus(this.isFocus);
        this.element.nativeElement.select();
        // mostramos el boton de limpiar campo;
        this.checkIfShowButtonReset();
    }


    @HostListener('blur')
    onBlur(): void {
        this.isFocus = false;
        // mostramos el boton de limpiar campo;
        this.checkIfShowButtonReset();
        setTimeout(() => {
            this.compAutoComp.setFocus(this.isFocus);
        }, 500);
    }

    @HostListener('keyup', ['$event'])
    onKeyUp(e: any): void {
        // esc
        if (e.keyCode === 27) {
            return;
        }

        // up
        if (e.keyCode === 38) {
            return;
        }

        // down
        if (e.keyCode === 40) {
            return;
        }

        // enter, tab
        if (e.keyCode === 13) {
            return;
        }

        // mostramos el boton de limpiar campo;
        this.checkIfShowButtonReset();

        // pasamos a buscar informacion con el texto ingresado
        this.compAutoComp.loadPredictions(this.element.nativeElement.value);
    }

    /**
     * Reset forzado del input para usar en el .ts
     * @author William Alarcon - Nov. 7-2018
     * @version 1.0.0
     */
    public resetValueInput() {
        this.element.nativeElement.value = '';
        this.hideButtonReset();
    }

    /**
     * Permite cargar el componente que despliega y trabaja el menu
     */
    private loadComponent() {
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(autoCompleteMenuComponent);
        const componentRef = this.viewContainerRef.createComponent(componentFactory);
        this.compAutoComp = componentRef.instance;
    }

    /**
     * Permite construir el elemento de la x para eliminar el texto de los inputs
     *
     * @author Jhonier Gaviria M. - Ago. 23-2018
     * @version 1.1.0
     */
    private createButtonElement(): void {
        /** Creamos un nuevo componente html */
        this.buttonReset = document.createElement('span');

        /** Se agrega la clase css */
        this.buttonReset.setAttribute('class', 'fa fa-times searchCloseIcon');

        // ocultamos el boton
        // this.buttonReset.setAttribute('hidden', 'hidden');
        this.buttonReset.setAttribute('style', 'display: none !important;');

        /** Se crea el elemento DOM */
        this.renderer.appendChild(this.element.nativeElement.parentElement, this.buttonReset);

        // Se agrega evento sobre el elemento
        this.buttonReset.addEventListener('click', () => {

            // limpiamos el campo
            this.element.nativeElement.value = '';
            
            // ocultamos nuevamente el boton
            this.hideButtonReset();

            // Validamos si el campo requeria escuchar cuando se ha limpiado el texto para realizar el emit
            if (this.isListeningValueInput && !this.lastEmitWasNull) {
                this.onfocus();
                this.lastEmitWasNull = true;
            }
        });

    }

    /**
     * Permite mostrar el boton de limpiar texto
     */
    private showButtonReset() {
        this.buttonReset.removeAttribute('style');
    }

    /**
     * Permite mostrar el boton de limpiar texto
     */
    private hideButtonReset() {
        this.buttonReset.setAttribute('style', 'display: none !important;');
    }

    /**
     * Verifica si el campo contiene algun valor ingresado para determinar si se muestra o no el button de limpiar campo
     */
    private checkIfShowButtonReset() {
        // si el campo esta vacio ocultamos el campo de lo contrario lo mostramos
        if (this.element.nativeElement.value === '') {
            this.hideButtonReset();
        } else {
            this.showButtonReset();
        }

    }

}
