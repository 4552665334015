<!--// Header \\-->
<div class="container">

    <div class="row wm-mainheader">

        <!--// Main Header \\-->
        <a class="customerBrand" id="restaurantName">

        </a>
        <h1 class="wm-logo logo-none col-md-2 logo-hide-one">
            <a (click)="resetSearch()">
                <img class="original" src="./assets/images/openmytab.svg" width="154px" alt="Open My Tab">
                <img class="stick" src="./assets/images/openmytabWhite.svg" width="154px" alt="Open My Tab">
            </a>
        </h1>
        <div class="col-md-8">
            <div class="col-sm-12"
                *ngIf="isDetailPage || (isCookie && (isCookie.isCookie.length && 
                (isCookie.isColombia && isCookie.isCookie.indexOf('wasVisited=true') !== -1) || 
                !isCookie.isColombia)) ">
                <!--// Search \\-->
                <app-filter-search>
                </app-filter-search>
                <!--// End Search \\-->
            </div>
        </div>

        <div class="navbar navbar-default navHead col-md-2">
            <div class="navbar-header">
                <h1 class="wm-logo logo-none col-md-2 logo-hide">
                    <a (click)="resetSearch()">
                        <img class="original" src="./assets/images/openmytab.svg" width="154px" alt="Open My Tab">
                        <img class="stick" src="./assets/images/openmytabWhite.svg" width="154px" alt="Open My Tab">
                    </a>
                </h1>
                <button class="btn autoW btn-search spaceNav"
                    *ngIf="isDetailPage  || (isCookie && (isCookie.isCookie.length && 
                    (isCookie.isColombia && isCookie.isCookie.indexOf('wasVisited=true') !== -1) || 
                    !isCookie.isColombia))"
                    (click)="toogleMoreOptions()">
                    <em class="fa fa-search-plus searchHeader"></em>
                </button>
                <!--<a class="searchPop spaceNav" (click)="resetSearch()" style="cursor: pointer">
                            <span class="byephone">All</span>
                            <i class="fa fa-cutlery" title="All Restaurants"></i>
                        </a>  -->
                <!-- <a href="#" class="spaceNav" data-toggle="modal" data-target="#shoopingCartModal" *ngIf="getTotalItems() > 0"> -->
                <a class="spaceNav" *ngIf="getTotalItems() > 0" (click)="goToDetail()">
                    <em class="fa fa-shopping-cart orange"></em>
                    <span class="badge badge-pill badge-danger">{{getTotalItems()}}</span>
                </a>
                <span class="spaceNav disabled only-coupons" *ngIf="getTotalItems() == 0">
                    <em class="fa fa-shopping-cart"></em>
                    <span class="badge badge-pill badge-danger">{{getTotalItems()}}</span>
                </span>
                <!-- <div class="searchPop spaceNav byeTab">
                            <a class="sf-with-ul" style="cursor: pointer">
                                <i class="fa fa-language"></i>
                            </a>
                            <ul class="wm-dropdown-menu">
                                <li>
                                    <a style="cursor: pointer" (click)="switchLanguage('en')">{{ 'ENGLISH' | translate }}</a>
                                </li>
                                <li>
                                    <a style="cursor: pointer" (click)="switchLanguage('es')">{{ 'SPANISH' | translate }}</a>
                                </li>
                            </ul>
                        </div>  -->

                <div class="searchPop spaceNav" *ngIf="!authenticated">

                    <span class="sf-with-ul">
                        <em class="fa fa-user"></em>
                    </span>
                    <ul class="wm-dropdown-menu">
                        <!-- <li>
                                    <a href="#" (click)="openLogiMmodal()">{{ 'LOGIN' | translate }}</a>
                                </li> -->
                        <li *ngIf="!authenticated">
                            <!-- <div #testOutlet *ngIf="openModal" class="modal moLog"></div> -->
                            <a (click)="openLogiMmodal()">
                                <em class="fa fa-user"></em>
                                {{ 'LOGIN' | translate }}
                            </a>
                        </li>


                        <li *ngIf="!authenticated">
                            <a (click)="openSinupModal()">
                                <em class="fa fa-user-plus"></em>
                                {{ 'REGISTER' | translate }}
                            </a>
                        </li>
                        <li>
                            <a class="sf-with-ul selectLang" data-toggle="collapse" href="#selectLang2" role="button"
                                aria-expanded="false" aria-controls="selectLang2" (click)="openLanguageModal()">
                                <em class="fa fa-language"></em>
                                {{ 'LANGUAGE' | translate }}
                                <!-- <em class="fa fa-angle-down"></em> -->
                            </a>
                            <!-- <div class="collapse" id="selectLang2">
                                <ul>
                                    <li *ngFor="let language of languages">
                                        <a (click)="chooseLanguage(language.value)">
                                            {{ language.label | translate }}
                                            <em
                                                [ngClass]="{'fa fa-check-circle': language.value === languageSelected}"></em>
                                        </a>
                                    </li>
                                </ul>
                            </div> -->
                        </li>
                        <!-- <li>
                                    <a href="#" (click)="openSinupModal()">{{ 'REGISTER' | translate }}</a>
                                </li> -->
                    </ul>
                </div>

                <button *ngIf="authenticated" type="button" class="navbar-toggle collapsed" data-toggle="collapse"
                    (click)="myOrdersTotal()" data-target="#navbar-collapse-1" aria-expanded="false"
                    aria-label="navigation">
                    <span class="sr-only">Toggle navigation</span>
                    <span class="fa fa-bars"></span>
                </button>
            </div>
            <nav class="navbar-collapse collapsed collapse" id="navbar-collapse-1">
                <br />
                <div class="text-center" *ngIf="authenticated">
                    <a class="avatarRound" href="#" [routerLink]="['/app/profile/about-me'] | localize"
                        *ngIf="!photoUrl">
                        <img class="img-circle" src="./assets/images/avatar_omt.jpg" alt="profile">
                    </a>
                    <a class="avatarRound" href="#" [routerLink]="['/app/profile/about-me'] | localize"
                        *ngIf="photoUrl">
                        <img class="img-circle" [src]="photoUrl" alt="profile">
                    </a>
                    <div *ngIf="dataUser">
                        <h4>
                            <span *ngIf="dataUser.name">{{ dataUser.name }}</span>
                            <span *ngIf="dataUser.lastname">&nbsp;{{ dataUser.lastname }}</span>
                        </h4>
                        <p *ngIf="dataUser.email" class="email">{{ dataUser.email }}</p>
                    </div>
                </div>
                <ul class="nav navbar-nav orange">
                    <!-- <li><a [routerLink]="['/index'] | localize" [routerLinkActive]="['router-link-active']">{{ 'Home' | translate }}</a></li> -->
                    <!-- <li><a [routerLink]="['/restaurant-list'] | localize" [routerLinkActive]="['router-link-active']">{{ 'Restaurants' | translate }}</a></li> -->
                    <li>
                        <a *ngIf="!utils.externalMenu" [routerLink]="['/app/restaurant-list'] | localize"
                            [routerLinkActive]="['router-link-active']">
                            <em class="fa fa-home"></em> {{ 'Home' | translate }}</a>
                        <a *ngIf="utils.externalMenu && !idrestaurant">
                            <em class="fa fa-home"></em> {{ 'Home' | translate }}</a>
                        <a *ngIf="utils.externalMenu && idrestaurant"
                            [routerLink]="['/app/detail-restaurant', idrestaurant] | localize"
                            [routerLinkActive]="['router-link-active']" [queryParams]="{otherpage: 'true'}">
                            <em class="fa fa-home"></em> {{ 'Home' | translate }}</a>
                    </li>
                    <!-- <li><a [routerLink]="['/contact-us'] | localize" [routerLinkActive]="['router-link-active']">{{ 'ContactUs' | translate }}</a></li>
									<li><a [routerLink]="['/dashboard']">Show My Restaurant</a></li>
                                    <li><a [routerLink]="['/faq'] | localize" [routerLinkActive]="['router-link-active']">{{ 'Faq' | translate }}</a></li>
                                    -->
                    <!-- <li *ngIf="!authenticated"><a href="#" data-toggle="modal" data-target="#loginModal">{{ 'LOGIN' | translate }}</a></li> -->

                    <!-- <li *ngIf="!authenticated">
                                <a href="#" (click)="openLogiMmodal()">{{ 'LOGIN' | translate }}</a>
                            </li> -->

                    <li *ngIf="authenticated" id="tabNav-aboutMe">
                        <!-- MENU EXTERNO -->
                        <a *ngIf="utils.externalMenu" [routerLink]="['/app/profile/about-me'] | localize"
                        [queryParams]="{otherpage: 'true', idrestaurant: utils.idRestaurantExt}">
                            <em class="fa fa-user"></em> {{ 'ABOUT_ME' | translate }}</a>
                        <!-- OPEN MY TAB -->
                        <a *ngIf="!utils.externalMenu" [routerLink]="['/app/profile/about-me'] | localize">
                            <em class="fa fa-user"></em> {{ 'ABOUT_ME' | translate }}</a>
                    </li>
                    <li *ngIf="authenticated" id="tabNav-myOrders">
                        <!-- MENU EXTERNO -->
                        <a *ngIf="utils.externalMenu" [routerLink]="['/app/profile/my-orders'] | localize"
                        [queryParams]="{otherpage: 'true', idrestaurant: utils.idRestaurantExt}">
                            <em class="fa fa-cutlery"></em> {{ 'MY_ORDERS' | translate}}
                            <!-- <span class="badge">{{totalPending}}</span>-->
                        </a>
                        <!-- OPEN MY TAB -->
                        <a *ngIf="!utils.externalMenu" [routerLink]="['/app/profile/my-orders'] | localize">
                            <em class="fa fa-cutlery"></em> {{ 'MY_ORDERS' | translate}}
                            <!-- <span class="badge">{{totalPending}}</span>-->
                        </a>
                    </li>
                    <li *ngIf="authenticated" id="tabNav-myFavorites">
                        <!-- MENU EXTERNO -->
                        <!-- <a *ngIf="utils.externalMenu" [routerLink]="['/app/profile/my-favorites'] | localize" [queryParams]="{otherpage: 'true'}">
                                    <i class="fa fa-heart"></i> {{ 'MY_FAVOURITES' | translate }}</a> -->
                        <!-- OPEN MY TAB -->
                        <a *ngIf="!utils.externalMenu" [routerLink]="['/app/profile/my-favorites'] | localize">
                            <em class="fa fa-heart"></em> {{ 'MY_FAVOURITES' | translate }}</a>
                    </li>
                    <li *ngIf="authenticated" id="tabNav-myReviews">
                        <!-- MENU EXTERNO -->
                        <a *ngIf="utils.externalMenu" [routerLink]="['/app/profile/my-reviews'] | localize"
                        [queryParams]="{otherpage: 'true', idrestaurant: utils.idRestaurantExt}">
                            <em class="fa fa-comments"></em> {{ "MY_REVIEWS" | translate }}</a>
                        <!-- OPEN MY TAB -->
                        <a *ngIf="!utils.externalMenu" [routerLink]="['/app/profile/my-reviews'] | localize"
                            [routerLinkActive]="['router-link-active']">
                            <em class="fa fa-comments"></em> {{ "MY_REVIEWS" | translate }}</a>
                    </li>

                    <li *ngIf="authenticated">
                        <!-- MENU EXTERNO -->
                        <a *ngIf="utils.externalMenu" [routerLink]="['/app/profile/my-coupons'] | localize"
                        [queryParams]="{otherpage: 'true', idrestaurant: utils.idRestaurantExt}">
                            <em class="fa fa-tag double-tags"></em> {{ "COUPONS" | translate }}</a>
                        <!-- OPEN MY TAB -->
                        <a *ngIf="!utils.externalMenu" [routerLink]="['/app/profile/my-coupons'] | localize"
                            [routerLinkActive]="['router-link-active']">
                            <em class="fa fa-tag double-tags"></em> {{ "COUPONS" | translate }}</a>
                    </li>
                    <li *ngIf="authenticated">
                        <!-- MENU EXTERNO -->
                        <a *ngIf="utils.externalMenu" [routerLink]="['/app/profile/my-offerts'] | localize"
                        [queryParams]="{otherpage: 'true', idrestaurant: utils.idRestaurantExt}">
                            <em class="fa fa-tag"></em> {{ "OFFERT" | translate }}</a>
                        <!-- OPEN MY TAB -->
                        <a *ngIf="!utils.externalMenu" [routerLink]="['/app/profile/my-offerts'] | localize"
                            [routerLinkActive]="['router-link-active']">
                            <em class="fa fa-tag"></em> {{ "OFFERT" | translate }}</a>
                    </li>
                    <li *ngIf="authenticated" id="tabNav-myNotifications">
                        <!-- MENU EXTERNO -->
                        <a *ngIf="utils.externalMenu" [routerLink]="['/app/profile/my-notifications'] | localize"
                        [queryParams]="{otherpage: 'true', idrestaurant: utils.idRestaurantExt}">
                            <em class="fa fa-bell"></em> {{ "NOTIFICATIONS" | translate }}</a>
                        <!-- OPEN MY TAB -->
                        <a *ngIf="!utils.externalMenu" [routerLink]="['/app/profile/my-notifications'] | localize"
                            [routerLinkActive]="['router-link-active']">
                            <em class="fa fa-bell"></em> {{ "NOTIFICATIONS" | translate }}</a>
                    </li>
                    <li *ngIf="authenticated">
                        <!-- MENU EXTERNO -->
                        <!-- <a *ngIf="utils.externalMenu" (click)="openLanguageModal()" [routerLink]="['/app/restaurant-list'] | localize" [queryParams]="{otherpage: 'true'}">
                                    <i class="fa fa-bell"></i> {{ "LANGUAGE" | translate }}</a> -->
                        <!-- OPEN MY TAB -->
                        <!-- <a *ngIf="!utils.externalMenu" (click)="openLanguageModal()" [routerLinkActive]="['router-link-active']">
                                    <i class="fa fa-language"></i> {{ "LANGUAGE" | translate }}</a> -->
                        <a (click)="openLanguageModal()" [routerLinkActive]="['router-link-active']">
                            <em class="fa fa-language"></em> {{ "LANGUAGE" | translate }}</a>
                    </li>
                    <!-- <li *ngIf="authenticated">
                                        <a href="#" [routerLink]="['/profile/my-cards'] | localize">
                                            <i class="fa fa-credit-card"></i> {{ 'MY_CARDS' | translate }}</a>
                                    </li> -->

                    <li *ngIf="authenticated">
                        <a (click)="confirmLogout()">
                            <em class="fa fa-sign-out"></em> {{ 'LOGOUT' | translate }}</a>
                    </li>

                    <!-- <li *ngIf="!authenticated">
                                        <a href="#" (click)="openSinupModal()">{{ 'REGISTER' | translate }}</a>
                                    </li>                             -->
                    <!-- 
                            <!-- 
            <!-- 
                            <li class="hiTab">
                                <a href="#" class="spaceNav" data-toggle="modal" data-target="#shoopingCartModal" *ngIf="getTotalItems() > 0">
                                    <i class="fa fa-shopping-cart orange"></i> <span class="badge">{{getTotalItems()}}</span>
                                </a>
                                <a class="spaceNav" *ngIf="getTotalItems() == 0">
                                    <i class="fa fa-shopping-cart orange"></i> <span class="badge">{{getTotalItems()}}</span>
                                </a>
                            </li>
                                        -->
                    <!-- 
                                <li class="hiTab">
                                    <a class="sf-with-ul spaceNav" style="cursor: pointer"><i class="fa fa-flag"></i></a>
                                    <ul class="wm-dropdown-menu">
                                        <li><a style="cursor: pointer" (click)="switchLanguage('en')" >English</a></li>
                                        <li><a style="cursor: pointer" (click)="switchLanguage('es')">Español</a></li>
                                    </ul>
                                </li>
                        -->
                </ul>
            </nav>
        </div>
    </div>

</div>
