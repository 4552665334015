import { resolve } from 'path';
import { Injectable } from '@angular/core';
import { RestaurantOrder } from 'app/core/entities/RestaurantOrder';
import { UtilsComponent } from 'app/utils/utils.component';
import { OrderServices } from 'app/core/interfaces/orders.interfaces';
import { RestaurantsServices, RestaurantsInformationStructureResponse } from "app/core/interfaces/restaurants.interfaces";
import { Inject } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VisibleButtonDeferredService {

  /**
   * Variable para guardar cierta información del restaurante con tal interfaz RestaurantOrder
   */
  public restaurant: RestaurantOrder;

  /**
   * Variable para al macenar el status del pago (tarjeta o contra entrega)
   */
  public creditCardPaymentStatus: boolean;
  public isCrediCarPanel: boolean;

/**
 * Variable para guardar la data del detalle del restaurnate
 */
  public reponseData: any;

  constructor(
    public utils: UtilsComponent,
    @Inject('OrderServices') public readonly orderServices: OrderServices,
    @Inject("RestaurantsServices") public  restaurantsServices: RestaurantsServices,
  ) 
  {
    this.creditCardPaymentStatus = null;
    this.restaurant = null;
    this.isCrediCarPanel = null;
  }

  /**
   *  Metodo para obtener la data del restaurante
   *  @author Oscar Alarcon - jun. 19-2023
   *  @version 1.0.0
   */
  public getDataRestaurant(idRestaurant){
    return new Promise((resolve) => {
      this.restaurantsServices
    .getDetailRestaurant(idRestaurant, '')
    .subscribe(response => {
      const dataResponse: RestaurantsInformationStructureResponse = response.data;
      this.reponseData = dataResponse;
      resolve(this.reponseData);
    });
    });
  }

  /**
   *  Metodo para saber si el restaurante tiene como metodo de pago credito
   *  @author Oscar Alarcon - jun. 19-2023
   *  @version 1.0.0
   */
  public checkCardRestaurant(detailRestaurant: RestaurantsInformationStructureResponse){
    this.creditCardPaymentStatus = detailRestaurant?.configuration[0]['allowRealCreditCardsPayments'];
    if (!this.creditCardPaymentStatus) {
      this.isCrediCarPanel = false;
    }else{
      this.isCrediCarPanel = true;
    }
  }
}
