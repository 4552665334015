 import { NgModule } from '@angular/core';
import { DynamicComponentLoaderModule } from 'app/core/dynamic-loader/dynamic-component-loader.module';
 import { DialogCartComponent } from './dialog-cart.component';
 
 @NgModule({
     declarations: [DialogCartComponent],
     exports: [DialogCartComponent],
     imports: [
         DynamicComponentLoaderModule.forModule({
             componentId: 'cart',
             path: 'cart',
             loadChildren: () => import('./content/cart.module').then(m => m.CartModule)
         })
     ]
 })
 export class DialogCartModule { }
