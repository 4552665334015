      <div class="main-modal">
        <div class="modal-header">
          <h3 class="modal-title">{{"TITLE_MODAL_DEFERRED" | translate}}</h3>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        
        <div class="modal-body">
            <div class="row content-columns">
                <div class="col-5 col-lg-6 colum-list">
                    <ul class="form-select">
                            <li *ngFor="let date of arrayDate"  class="li-select" [ngClass]="{'option-select': date.dateValue == dateMain}" (click)="getDate(date.dateValue)">
                                {{date.dateDeferred}}
                            </li>
                    </ul>
                </div>
                <div class="col-7 col-lg-6 colum-list">
                    <ul class="form-select">
                        <li *ngFor="let time of arrayHour" class="li-select" [ngClass]="{'option-select': time.timeValue == timeMain}" (click)="getTime(time.timeValue)">
                            {{time.timeDeferred}}
                        </li>
                    </ul>
                </div>
        </div>
        <div class="row content-footer" *ngIf="!verifyRestaurantClose">
            <div class="contenet-defered-buttons col-12">
                <button class="btn-defered-cancel" (click)="closeModalDeferred()">{{"CLOSE_DEFERRED" | translate}}</button>
            </div>
        </div>
        <div class="row content-footer" *ngIf="verifyRestaurantClose">
            <div class="contenet-defered-buttons col-6">
                <button class="btn-defered-cancel" (click)="closeModalDeferred()">{{"CLOSE_DEFERRED" | translate}}</button>
            </div>
            <div class="contenet-defered-buttons col-6">
                <button class="btn-defered-schedule" (click)="ObterDataDeferred()">{{"DELIVER_NOW_DEFERRED" | translate}}</button>
            </div>
        </div>
        </div>
      </div>
