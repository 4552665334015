import { AppConstants } from 'app/app-constants';
import { formSearchRestaurants } from 'app/core/entities/formSearchRestaurants';
import { GeoPosition } from 'app/core/entities/GeoPosition';
import { GlobalEventsService } from 'app/core/services/globalEvents.service';
import { UserLocationProvider } from 'app/utils/userLocationService';
import { UtilsComponent } from 'app/utils/utils.component';
import { LocalizeRouterService } from 'localize-router';
import { GoogleMapsAPIWrapper, MapsAPILoader } from '@agm/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import {
    AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild }
    from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { ResponsesStructure } from 'app/core/interfaces/transversal.interfaces';
import { FiltersStructureResponse, RestaurantsServices } from 'app/core/interfaces/restaurants.interfaces';
import { SweetAlertService } from 'app/utils/sweetAlert.service';

// Variable para manejo de funciones jquery
declare var jQuery: any;

// Variable para manejo autocompletar location
declare var google: any;

@Component({
    selector: 'app-filter-search',
    templateUrl: './filterSearch.component.html',
    styleUrls: ['./filterSearch.component.css'],
    providers: [GoogleMapsAPIWrapper],
    animations: [
        trigger('easeInOut', [
            state('in', style({ transform: 'translateX(100)' })),
            transition('void => *', [
                style({ transform: 'translateY(-76px)' }),
                animate(500)
            ]),
            transition('* => void', [
                animate(500, style({ transform: 'translateY(-76px)' }))
            ])
        ])
    ]})
export class FilterSearchComponent implements OnInit, AfterViewInit, OnDestroy {

    // Variable para almacenar los tipos de comida
    meals: Array<any>;

    // Variable para almacenar los tipos de cocinas
    cuisines: Array<any>;

    // Variable para almacenar los tipos de Caracteristicas
    features: Array<any>;

    isError: boolean;

    // variable que determina las opciones avanzadas del buscador se estan mostrando
    isOpenAvancedOptions: boolean;

    /**
     * Variable que manejara el formulario del buscador
     */
    formSearch: formSearchRestaurants;

    @ViewChild('nearLocation', { static: true })
    nearLocationElement: ElementRef;

    // variable para manejar el escuchador de queries params
    subsQueryParams: any;

    /**
     * Direccion del usuario que se va a mostrar en el buscador
     */
    public inputNearAddress: any;

    /**
     * para guardar la direccion temporalmente
     */
    private tmpInputNearAddress: any;

    /**
     * Rango en kilometros para la busqueda en los filtros del listado del restaurante
     */
    RANGE_SEARCH_RESTAURANTS: any;

    /**
     * Almacena el sistema de medida de acuerdo a la ubicacion del usuario
     */
    public metricUnit: string;

    private rangeSearchRestaurants: number;

    /**
     * Tamaños de los filtros elegidos
     */
    public cuisinesLength: number;
    public featuresLength: number;
    public mealsLength: number;

    /**
     * Respuestas de los ws
     */
    public responseMeals: boolean;
    public responseCuisines: boolean;
    public responseFeatures: boolean;

    constructor(
        @Inject('RestaurantsServices') private readonly restaurantsServices: RestaurantsServices,
        private readonly router: Router,
        private readonly sweetAlertService: SweetAlertService,
        private readonly utils: UtilsComponent,
        private readonly localize: LocalizeRouterService,
        private readonly translate: TranslateService,
        private readonly globalEvents: GlobalEventsService,
        private readonly userLocation: UserLocationProvider,
        private readonly route: ActivatedRoute,
        private readonly mapsAPILoader: MapsAPILoader,
        private readonly chRef: ChangeDetectorRef)
    {
        // inicializamos variables
        this.formSearch = new formSearchRestaurants();
        this.isOpenAvancedOptions = false;
        this.isError = false;
        this.tmpInputNearAddress = '';
        this.responseMeals = true;
        this.responseFeatures = true;
        this.responseCuisines = true;
        this.RANGE_SEARCH_RESTAURANTS = AppConstants.RANGE_SEARCH_RESTAURANTS;
        this.rangeSearchRestaurants = this.RANGE_SEARCH_RESTAURANTS.FIVE_MILLES;
        // Obtenemos la unidad de medida del usuario
        this.metricUnit = this.userLocation.getMetricUnit();
        this.closeSearchAdvanced();
        this.cuisines = [];
        this.features = [];
        this.meals = [];
    }

    /**
     * See {@linkDocs guide/lifecycle-hooks "Lifecycle Hooks Guide"}.
     */
    ngAfterViewInit() {
        const inputAddress = this.nearLocationElement.nativeElement;
        if (sessionStorage.getItem(AppConstants.SESSIONS_STORAGE_DATA.NEAR_SEARCH) !== null) {
            inputAddress.value = sessionStorage.getItem(AppConstants.SESSIONS_STORAGE_DATA.NEAR_SEARCH);
        } 
        if(sessionStorage.getItem(AppConstants.SESSIONS_STORAGE_DATA.NEAR_SEARCH_COOKIE) !== null &&
            this.userLocation.isColombia(sessionStorage.getItem(AppConstants.SESSIONS_STORAGE_DATA.USER_COUNTRY))){
            inputAddress.value = sessionStorage.getItem(AppConstants.SESSIONS_STORAGE_DATA.NEAR_SEARCH_COOKIE);
            this.inputNearAddress = sessionStorage.getItem(AppConstants.SESSIONS_STORAGE_DATA.NEAR_SEARCH_COOKIE);
        }
    }

    ngOnInit() {
        const userPosition = this.userLocation.getCurrentLocation();
        if(!this.onlineMenuValidation()){
            this.reloadFilters(userPosition);
        };
        // Metodo encargado de detectar cuando se cambia el idioma de la aplicación y realizar alguna acción
        this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            // Se cargan nuevamente los select con su idioma correspondiente
            if (this.formSearch.nearLocation !== null) {
                this.reloadFilters(this.formSearch.nearLocation);
            } else {
                this.reloadFilters(userPosition);
            }
        });
        this.globalEvents.resetSearchRestaurant.subscribe(() => {
            this.cuisines = this.resetCheckOptions(this.cuisines);
            this.meals = this.resetCheckOptions(this.meals);
            this.features = this.resetCheckOptions(this.features);
            this.reloadFilters(userPosition);
            if (this.tmpInputNearAddress === '') {
                this.myAdress();
            } else {
                this.nearLocationElement.nativeElement.value = this.tmpInputNearAddress[0].concat(', ').concat(this.tmpInputNearAddress[1]);
            }
            sessionStorage.removeItem(AppConstants.SESSIONS_STORAGE_DATA.NEAR_SEARCH);
            // reseteamos los valores del formulario
            this.formSearch = new formSearchRestaurants();
            // Actualizamos los selects
            this.resetSelects();
        });
        // creamos un suscriptor
        this.subsQueryParams = this.route.queryParams.subscribe(params => {
            // en caso de recibir un nombre de restaurante para filtrar
            if (typeof params.name !== 'undefined') {
                this.formSearch = new formSearchRestaurants();
                this.formSearch.textFilter = params.name;
            }
            if (params.search && params.search === '1') {
                if (sessionStorage.getItem(AppConstants.SESSIONS_STORAGE_DATA.NEAR_SEARCH) !== null) {
                    this.inputNearAddress = sessionStorage.getItem(AppConstants.SESSIONS_STORAGE_DATA.NEAR_SEARCH);
                }
                if (sessionStorage.getItem(AppConstants.SESSIONS_STORAGE_DATA.PARAMS_SEARCH_RESTAURANT)) {
                    this.formSearch = JSON.parse(sessionStorage.getItem(AppConstants.SESSIONS_STORAGE_DATA.PARAMS_SEARCH_RESTAURANT));
                }
            }
        });
        // Actualizamos los inputs del buscador avanzado en caso de que se retire un tag de busqueda
        this.globalEvents.removeAdvancedFilters.subscribe(result => {
            this.cuisines = this.updateArrays(this.cuisines, result.cuisines);
            this.features = this.updateArrays(this.features, result.features);
            this.meals = this.updateArrays(this.meals, result.meals);
            this.changeFilters('cuisines');
            this.changeFilters('features');
            this.changeFilters('meals');
            this.toSearch(true);
        });
        // Actualizamos las cocinas si hay cambio en el rango del listado de restaurantes
        this.globalEvents.refreshFiltersByRange.subscribe(range => {
            this.rangeSearchRestaurants = range;
            if (this.formSearch.nearLocation !== null) {
                this.reloadFilters(this.formSearch.nearLocation);
            } else {
                this.reloadFilters(userPosition);
            }
        });
        // nos suscribimos a los cambios de ubicacion para actualizar el valor del metricUnit
        this.userLocation.onChange.subscribe((data) => {
            if (data) {
                this.inputNearAddress = data;
            } else {
                this.myAdress();
            }
            // Obtenemos la unidad de medida del usuario
            this.metricUnit = this.userLocation.getMetricUnit();
        });
    }

    /**
     * función que retorna true si es un menú online o falso si no lo es
     *
     * @author Luis Eduardo Novoa Enciso - 28-09-2021
     * @version 1.1.0
     */
    onlineMenuValidation(): boolean {
        const currentUrl = window.location.href;
        let float: boolean;
        if (currentUrl.indexOf('otherpage=true') === -1 ? float = false: float = true) {}
        return float;
    }

    /**
     * @see {@link https://angular.io/guide/lifecycle-hooks#lifecycle-hooks Lifecycle Hooks Docs}
     */
    ngOnDestroy() {
        // en caso de que se elimine el elemento
        this.subsQueryParams.unsubscribe();
    }

    /**
     * Cierra el buscador avanzado cuando se le da click sobre cualquier parte
     */
    private closeSearchAdvanced() {
        const that = this;
        jQuery(document).click(function (event) {
            const clickover = jQuery(event.target);
            if (clickover.parents('.w64').length === 0) {
                const _opened = jQuery('.filtersearch').hasClass('filterShow');
                if (_opened === true && !clickover.hasClass('fa-search-plus')) {
                    that.isOpenAvancedOptions = false;
                    if (!that.chRef['destroyed']) {
                        that.chRef.detectChanges();
                    }
                }
            }
        });
    }

    /**
     * Permite recibir la respuesta de la direccion que se busca
     * @param event
     */
    public resultSearchLocation(event: any) {
        if (event === null) {
            this.setNearLocation();
        } else if (event.coords === null) {
            this.setNearLocation();
        } else {
            this.setNearLocation(event.coords);
        }
    }

    /**
     *  Cambia la direccion de busqueda
     */
    private setNearLocation(coords: any = null) {
        this.formSearch.nearLocation = coords;
        this.globalEvents.nearLocationEvent.emit(coords);
        if (coords === null) {
            this.serachPositionUser();
            sessionStorage.removeItem(AppConstants.SESSIONS_STORAGE_DATA.NEAR_SEARCH);
        } else {
            sessionStorage.setItem(AppConstants.SESSIONS_STORAGE_DATA.NEAR_SEARCH, this.nearLocationElement.nativeElement.value);
            this.toSearch();
            this.reloadFilters(coords);
        }
    }

    /**
     * Permite resetar la posicion del usuario cuando se borra la caja de texto de near location
     */
    public changeFieldNearLocation($event) {
        if ($event.key === 'Backspace') {
            const oldSearch = sessionStorage.getItem(AppConstants.SESSIONS_STORAGE_DATA.NEAR_SEARCH);
            if (this.nearLocationElement.nativeElement.value.trim().length <= 1 && oldSearch !== null) {
                sessionStorage.removeItem(AppConstants.SESSIONS_STORAGE_DATA.NEAR_SEARCH);
                this.formSearch.nearLocation = null;
                this.globalEvents.nearLocationEvent.emit(null);
            }
        }
    }

    /**
     * Permite abrir y cerrar el panel de opciones avanzadas
     */
    public toggleShowAdvancedOptions() {
        this.isOpenAvancedOptions = !this.isOpenAvancedOptions;
    }

    /**
     * Metodo encargado de realizar peticion a los web services de enviar
     * los tipos de comidas registrados
     */
    private getAllMeals(position: GeoPosition = null) {
        const mealsIdentifier = 'meals';
        this.restaurantsServices
            .getAllMeals(position, this.rangeSearchRestaurants)
            .subscribe((response: ResponsesStructure) => {
                const dataResponse: FiltersStructureResponse[] = response.data;
                if(dataResponse !== undefined && dataResponse !== null){
                    this.meals = this.resetCheckOptions(dataResponse);
                    this.utils.setCachedTime(mealsIdentifier);
                    localStorage.setItem(mealsIdentifier, JSON.stringify(this.meals));
                    this.responseMeals = true;
                }
            });
    }

    /**
     * Metodo encargado de realizar peticion a los web services de enviar
     * los tipos de comidas registrados
     */
    private getAllCuisines(position: GeoPosition = null) {
        this.utils.getAllCuisines(result => {
            if (result !== null) {
                this.cuisines = this.resetCheckOptions(result);
            }
            this.responseCuisines = true;
        }, position, this.rangeSearchRestaurants);
    }

    /**
     * Metodo encargado de realizar peticion a los web services de enviar
     * los tipos de Caracteristicas registrados
     */
    private getAllFeatures(position: GeoPosition = null) {

        const featuresIdentifier = 'features';
        this.restaurantsServices
            .getAllFeatures(position, this.rangeSearchRestaurants)
            .subscribe((response: ResponsesStructure) => {
                const dataResponse: FiltersStructureResponse[] = response.data;
                if(dataResponse !== undefined && dataResponse !== null){
                    this.features = this.resetCheckOptions(dataResponse);
                    this.utils.setCachedTime(featuresIdentifier);
                    localStorage.setItem(featuresIdentifier, JSON.stringify(this.features));
                    this.responseFeatures = true;
                }
            });
    }

    /**
     * Metodo encargado de obtener los arreglos enviados desde el buscador, para validar cuales estan checked
     *
     * @param items
     * @version 1.0.1
     */
    private getOptionsSelectedCheckbox(items: any[]) {
        const optionsChecked: string[] = [];
        for (const i in items) {
            if (items[i].checked) {
                optionsChecked.push(items[i]);
            }
        }
        return optionsChecked;
    }

    /**
     * Permite establecer el atributo checked en false,
     * este atributo el que se usa para saber cual fue el que selecciono el usuario en el buscador
     * @param items
     * @version 1.0.1
     */
    private resetCheckOptions(items: any[]) {
        if (items && items.length >= 0) {
            items.forEach((element, index) => {
                items[index].checked = false;
            });
            return items;

        }
    }

    /**
     * Metodo encargado de cerrar el div que muestra el error al setear mal el campo location
     */
    public closeDivError() {
        this.isError = false;
    }

    /**
     * Se dispara cuando se asigna un valor en el selector de orden
     */
    public changeOrderList(option) {
        this.formSearch.order = option;
    }

    /**
     * Se dispara cuando se asigna un valor en el selector de rango
     */
    public changeRange(option) {
        this.formSearch.range = option;
        this.setRange(option);
        if (this.formSearch.nearLocation !== null) {
            this.reloadFilters(this.formSearch.nearLocation);
        } else {
            const userPosition = this.userLocation.getCurrentLocation();
            this.reloadFilters(userPosition);
        }
    }

    /**
     * Permite establecer el rango de busqueda
     */
    private setRange(option) {
        switch (option) {
            case '1':
                this.rangeSearchRestaurants = this.RANGE_SEARCH_RESTAURANTS.FIVE_MILLES;
                break;
            case '2':
                this.rangeSearchRestaurants = this.RANGE_SEARCH_RESTAURANTS.TEN_MILLES;
                break;
            case '3':
                this.rangeSearchRestaurants = this.RANGE_SEARCH_RESTAURANTS.FIFTEEN_MILLES;
                break;
            case '4':
                this.rangeSearchRestaurants = this.RANGE_SEARCH_RESTAURANTS.TWENTY_MILLES;
                break;
            default:
                this.rangeSearchRestaurants = this.RANGE_SEARCH_RESTAURANTS.FIVE_MILLES;
                break;
        }
    }

    /**
     * Permite realizar busquedas de restaurantes
     */
    public toSearch(isSearchAvanced = false) {
        const that = this;
        // avisamos que se esta haciendo la busqueda
        this.showLabelSearching(true);
        // asignamos el tipo de busqueda
        this.formSearch.isSearchAvanced = isSearchAvanced;
        // clonamos el formulario de busqueda
        const tmpDataSearch: formSearchRestaurants = this.utils.clone(this.formSearch);
        // Validamos si no se tiene una dirreccion seleccionada para marcar como direccion la posicion del usuario
        if (tmpDataSearch.nearLocation === null) {
            tmpDataSearch.nearLocation = this.userLocation.getCurrentLocation();
        }
        let filters: string;
        // verificamos si es busqueda avanzada
        if (isSearchAvanced) {
            // recogemos las opciones seleccionadas
            tmpDataSearch.cuisines = this.getOptionsSelectedCheckbox(this.cuisines);
            tmpDataSearch.features = this.getOptionsSelectedCheckbox(this.features);
            tmpDataSearch.meals = this.getOptionsSelectedCheckbox(this.meals);
        }
        if (this.nearLocationElement.nativeElement.value !== '' &&
            this.nearLocationElement.nativeElement.value !== null &&
            this.nearLocationElement.nativeElement) {
            filters = this.nearLocationElement.nativeElement.value;
            this.globalEvents.locationString.emit(filters);
        }
        // guardamos los parametros de busqueda en cache
        this.restaurantsServices.setParamsSearch(tmpDataSearch);
        // redireccionamos a la página de resultados
        this.router.navigate([this.localize.translateRoute('/app/restaurant-list')], { queryParams: { search: '1' } });
        // emitimos un evento indicando de que se debe hacer busqueda,
        // cuando la busqueda se ejecute el devolvera un callback por el mismo canal
        this.globalEvents.searchRestaurantsEvent.emit();
        // avisamos que ya se hizo la busqueda
        that.showLabelSearching(false);
        // escondemos las opciones avanzadas
        that.isOpenAvancedOptions = false;
    }

    /**
     * Permite ubicar la posicion del usuario y asignarlo en la caja del buscador
     */
    private serachPositionUser(toSearch = true) {
        const userPosition = this.userLocation.getCurrentLocation();
        if (userPosition !== null) {
            this.formSearch.nearLocation = userPosition;
            this.globalEvents.nearLocationEvent.emit(this.formSearch.nearLocation);
            this.reloadFilters(userPosition);
        }
        if (toSearch) {
            this.toSearch();
        }
    }

    /**
     * Metodo encargado de cambiar el valor del boton de filtro para identificar cuando se esta
     * haciendo una busqueda
     */
    private showLabelSearching(isFound: boolean) {
        let valueBtn = '';
        if (isFound) {
            if (this.translate.currentLang === 'es') {
                valueBtn = 'Buscando...';
            } else {
                valueBtn = 'Searching...';
            }
        } else {
            if (this.translate.currentLang === 'es') {
                valueBtn = 'Buscar';
            } else {
                valueBtn = 'Search';
            }
        }
        jQuery('#searchButton').val(valueBtn);
    }

    /**
     * permite obtener la direccion para ponerla en el Near location
     *
     * @author Angel Andres diaz Calle - 23-08-09
     * @version 1.1.0
     */
    private myAdress() {
        const userPosition = this.userLocation.getCurrentLocation();
        const that = this;
        this.mapsAPILoader.load().then(() => {
            const geocoder = new google.maps.Geocoder;
            if (userPosition !== null) {
                geocoder.geocode({ 'location': userPosition }, function (results, status) {
                    if (status === 'OK') {
                        if (sessionStorage.getItem(AppConstants.SESSIONS_STORAGE_DATA.NEAR_SEARCH) !== null) {
                            this.inputNearAddress = sessionStorage.getItem(AppConstants.SESSIONS_STORAGE_DATA.NEAR_SEARCH);
                        } else {
                            if(sessionStorage.getItem(AppConstants.SESSIONS_STORAGE_DATA.NEAR_SEARCH_COOKIE) !== null ){
                                that.nearLocationElement.nativeElement.value = sessionStorage.getItem(AppConstants.SESSIONS_STORAGE_DATA.NEAR_SEARCH_COOKIE);
                            } else {
                                const inputNearAddress = results[0].formatted_address;
                                that.tmpInputNearAddress = inputNearAddress.split(',');
                                that.nearLocationElement.nativeElement.value = `${that.tmpInputNearAddress[1]},${that.tmpInputNearAddress[2]}`;
                            }
                        }
                    }
                });
            }
        });
    }

    /**
     * compara los arreglos de meals, features y cuisines,
     * los actualiza segun los tags de busqueda avanzada
     * que se retiren y que el cambio se vea reflejado en
     * los input de busqueda avanzada
     * @param localArray arreglo existente en el filtro
     * @param objectFromArray objeto proveniente del globalevents
     * @version 1.0.0
     * @author Alvaro Felipe Garcia Mendez - Oct. 24-2018
     */
    private updateArrays(localArray: Array<any>, objectFromArray: Array<any>) {
        if (localArray && objectFromArray.length > 0) {
            for (let i = 0; i < localArray.length; i++) {
                objectFromArray.forEach(element => {
                    if (localArray[i].checked) {
                        if (localArray[i].id === element.id) {
                            localArray[i] = element;
                        }
                    }
                });
            }
            return localArray;
        } else {
            return localArray;
        }
    }

    /**
     * Carga filtros de Meals, Features y Cuisines
     * @param position coordenadas de la ubicación seleccionada en el buscador avanzado
     * @author Alvaro Felipe Garcia Mendez - Nov. 9-2018
     * @version 1.0.0
     */
    private reloadFilters(position: GeoPosition = null) {
        this.responseCuisines = false;
        this.responseFeatures = false;
        this.responseMeals = false;
        this.getAllMeals(position);
        this.getAllFeatures(position);
        this.getAllCuisines(position);
        this.changeFilters('cuisines');
        this.changeFilters('features');
        this.changeFilters('meals');
    }

    /**
     * Carga el numero de filtros de Meals, Features y Cuisines
     * @param filter tipo de filtro a actualizar
     * @author Alvaro Felipe Garcia Mendez - Nov. 22-2018
     * @version 1.0.0
     */
    public changeFilters(filter: string) {
        if (filter === 'cuisines') {
            this.cuisinesLength = this.getOptionsSelectedCheckbox(this.cuisines).length;
        } else if (filter === 'features') {
            this.featuresLength = this.getOptionsSelectedCheckbox(this.features).length;
        } else if (filter === 'meals') {
            this.mealsLength = this.getOptionsSelectedCheckbox(this.meals).length;
        }
    }

    /**
     * Reinicia a los valores por defecto, los selects de orden, rango y cerrado/abierto
     * del buscador avanzado
     * @author Alvaro Felipe Garcia Mendez - Nov. 26-2018
     * @version 1.0.0
     */
    private resetSelects() {
        jQuery('[name=range]').removeAttr('checked');
        jQuery('[name=order]').removeAttr('checked');
        jQuery('[name=range]').filter('[value=\''.concat(1 + '\']')).prop('checked', true);
        jQuery('[name=order]').filter('[value=\''.concat(1 + '\']')).prop('checked', true);
    }


    /**
     * Metodo para verificar si no hay tipo de cocinas que mostrar para que se dispare un alert
     *  @author Oscar Alarcon - ener. 25-2023
     *  @version 1.0.0
     */
    public noTypeCuisines(){
        if(!this.cuisines.length){
            this.sweetAlertService.alertNoCusineType();
        }
    }

    /**
     * Metodo para verificar si no hay caracteristicas que mostrar para que se dispare un alert
     *  @author Oscar Alarcon - ener. 25-2023
     *  @version 1.0.0
     */
    public noFeatures(){
        if(!this.features.length){
            this.sweetAlertService.alertNoFeatures();
        }
    }

    /**
     * Metodo para verificar si no hay comidas servidas que mostrar para que se dispare un alert
     *  @author Oscar Alarcon - ener. 25-2023
     *  @version 1.0.0
     */
    public noMeals(){
        if(!this.meals.length){
            this.sweetAlertService.alertNoMeals();
        }
    }
}
