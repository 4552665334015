import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogBranchRestaurantsModalComponent } from './dialog-branch-restaurants-modal.component';
import { DynamicComponentLoaderModule } from 'app/core/dynamic-loader/dynamic-component-loader.module';

@NgModule({
  declarations: [DialogBranchRestaurantsModalComponent],
  exports: [DialogBranchRestaurantsModalComponent],
  imports: [
    CommonModule,
    DynamicComponentLoaderModule.forModule({
      componentId: 'branch-restaurants-modal',
      path: 'branch-restaurants-modal',
      loadChildren: () => import('./content/branch-restaurants.module').then(m => m.BranchRestaurantsModule)
    }),
  ]
})
export class DialogBranchRestaurantsModalModule { }
