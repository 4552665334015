import { ConfigComponent } from 'app/core/config/config';
import { GeoPosition } from 'app/core/entities/GeoPosition';
import * as moment from 'moment-timezone';
import { Observable, Subscriber } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Injector, Input } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { ImagesUtilsComponent } from './imagesUtils.component';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SweetAlertService } from './sweetAlert.service';
import { LocalizeRouterService } from 'localize-router';
import { AppConstants } from 'app/app-constants';
import { RestaurantsInformationStructureResponse, RestaurantsServices } from 'app/core/interfaces/restaurants.interfaces';
import { ResponsesStructure } from 'app/core/interfaces/transversal.interfaces';
import { GetFavoritesStructureResponse } from 'app/core/interfaces/profile.interfaces';
import { FaviconServices } from 'app/core/interfaces/favicon.interfaces';
import { DynamicComponentLoader } from 'app/core/dynamic-loader/dynamic-component-loader.service';
import { Subscription } from 'rxjs';
import { LanguageRouteService } from 'app/core/services/language-route.service';

// Variable para manejo de funciones jquery
declare var jQuery: any;

@Injectable()
export class UtilsComponent {

    // variable para inidcar si se esta en un restaurante externo
    externalMenu: boolean;

    // guarda el color del restaurante externo
    externalMenuColor: any;

    //TODO: Hay que revisar esta variable por que en ocaciones llega en null
    // variable que guarda el id del restaurante externo
    idRestaurantExt: string;

    public useExternalMenu: boolean;

    public useWhiteLabel: boolean;

    public urlAppStore: string;

    public urlPlayStore: string;

    /**
     * sufijo para identificar el tiempo de cache
     */
    cachedTimeStrConstant = '_cached_time';

    /**
     * tiempo de cache en minutos
     */
    timeMaxCache = 20;

    public restaurantStatus: {restaurantIsOpen: boolean, message: string};

    //Variable para validar si el horarios de atencion debe de aparecer o no
    public validateHours: any;

    /**
     * Indica si se debe o no mostrar inicialmente los cupones
     */
    @Input() showCouponsSection: boolean;

    /**
     *  Variable que guarda si el restaurante está abierto o cerrado, que se obtiene desde el local storage
     */
    public restarurantIsOpenlocal: any;

    /**
     * Suscripción a eventos del router para capturar la ruta al iniciar la navegación (NavigationStart).
     */
    private _routerSubscription: Subscription;

    constructor(
        private configApp: ConfigComponent,
        @Inject('RestaurantsServices') private readonly restaurantServices: RestaurantsServices,
        private imagesUtils: ImagesUtilsComponent,
        private http: HttpClient,
        @Inject('FaviconServices') private readonly faviconService: FaviconServices,
        private titleService: Title,
        private router: Router,
        private localize: LocalizeRouterService,
        private translate: TranslateService,
        private sweet: SweetAlertService,
        private meta: Meta,
        private loader: DynamicComponentLoader,
        private readonly injector: Injector,
    ) {
        this.externalMenu = false;
        this.useWhiteLabel = false;
        this.urlAppStore = '';
        this.urlPlayStore = '';
        this.externalMenuColor = '#b71f37';
        this.showCouponsSection = true;
        // Se obtiene del local storage si el restaurante está cerrado o abierto
        this.restarurantIsOpenlocal = localStorage.getItem('restaurantIsOpen');
        // Como desde el local storage se obtiene un string, lo convertimos en boolean
        if(this.restarurantIsOpenlocal == 'true'){
            this.restarurantIsOpenlocal = true
        }else if(this.restarurantIsOpenlocal == 'false'){
            this.restarurantIsOpenlocal = false
        }
        // Inicializamos la variable restaurantIsOpen de lo que obtenemos del localstorage 
        // (la guardada se hace en el componente checkout.componente.ts) ya que cuando
        // recargamos la pagina y se tienen intems en carrito, está variable quedaba undifined
        this.restaurantStatus = {
            restaurantIsOpen: this.restarurantIsOpenlocal,
            message: ''
        };
        this.idRestaurantExt = '' || localStorage.getItem('restaurantId');

        // Se elimina del localStorage el LANGUAGE_USER para evitar conflictos con getDefaultLang.
        // Anteriormente, había que recargar la página dos veces al cambiar el idioma desde la URL
        // para que el sistema actualizara completamente el nuevo idioma.
        localStorage.removeItem(AppConstants.LOCAL_STORAGE_DATA.LANGUAGE_USER);
        // Se utiliza languageRouteService para obtener el idioma desde la URL.
        let languageRouteServices = this.injector.get(LanguageRouteService);
        this._routerSubscription = languageRouteServices.routerSubscription;
        this.getDefaultLang();
    }

    /**
     * Pone el icono y Titulo de OMT
     * @author Alvaro Felipe Garcia Mendez - Sept. 4 - 2019
     * @version 1.0.0
     */
    public tabOMT() {
        this.faviconService.setFavicon('/assets/favicon/favicon-16x16.png');
        this.titleService.setTitle('Open My Tab');
    }

    /**
     * permite obtener el pais a partir de una ubicación, internamente se utilizan 2 servicios para alternar en caso de error
     *
     * @param {string} lat
     * @param {string} long
     * @param callback
     */
    public getCountryFromLatLon(lat: string | number, long: string | number, callback) {

        const that = this;

        const tmpCountry = {
            name: '',
            code: ''
        };

        const requestByGoogle = function () {
            const urlGeoCodeGMap = 'https://maps.googleapis.com/maps/api/geocode/json?latlng=' + lat +
                ',' + long + '&key=' + that.configApp.apiKeyGMap;
            that.http.get(urlGeoCodeGMap)
                .subscribe(response => {
                    //console.log('response', response);
                    let aplica = false;
                    loopResults: for (const element of response['results']) {
                        for (const address of element.address_components) {
                            if (address.types[0] === 'country') {
                                tmpCountry.name = address.long_name.trim();
                                tmpCountry.code = address.short_name.trim();
                                callback(tmpCountry);
                                aplica = true;
                                break loopResults;
                            }
                        }
                    }

                    if (!aplica) {
                        callback(null);
                    }
                }, errorResponse => {
                    // requestByGeonames();
                });
        };

        const requestByGeonames = function () {
            /**
             * INFORMACIÓN API:
             *
             * username: kijho
             * password: 2G9ADYsc
             * email: arodriguez@kijho.com
             *
             * url: http://www.geonames.org/login
             */
            this.http.get('//api.geonames.org/countryCodeJSON?lat=' + lat + '&lng=' + long + '&username=kijho')
                .subscribe(response => {
                    const tmpResponse = JSON.parse(response['_body']);
                    if (typeof tmpResponse.countryName === 'undefined' || typeof tmpResponse.countryCode === 'undefined') {
                        requestByGoogle();
                    } else {
                        tmpCountry.name = tmpResponse.countryName.trim();
                        tmpCountry.code = tmpResponse.countryCode.trim();
                        callback(tmpCountry);
                    }
                }, errorResponse => {
                    requestByGoogle();
                });
        };

        requestByGoogle();
    }

    /**
     * Permite subir el scroll de la página
     */
    public backTop(tag: string = null) {
        if (tag == null) {
            // Etique principal del top
            tag = '.wm-main-wrapper';
        }
        jQuery('html, body').animate({ scrollTop: jQuery(tag).offset().top }, 'slow');
    }

    
    /**
     *  Metodo para que el scroll suba la página rapidamente
     *  @author Oscar Alarcon - marz. 1-2023
     *  @version 1.0.1
     */
    public backTopFast(tag: string = null) {
        if (tag == null) {
            // Etique principal del top
            tag = '.wm-main-wrapper';
        }
        jQuery('html, body').animate({ scrollTop: jQuery(tag).offset().top }, 1);
    }

    /**
    * valida que solo se escriban numeros
    * @param event
    * @returns {boolean}
    */
    public onlyNumber(event: KeyboardEvent) {

        const allowButtons = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'ArrowRight', 'ArrowLeft', 'Delete', 'Backspace', 'Tab'];

        // en caso de que la tecla no este incluida en los botones presionados
        // tratamos de anular la escritura de ese boton
        if (!allowButtons.includes(event.key)) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
        }

        // if ([46, 8, 9, 27, 13, 110, 190].indexOf(event.keyCode) !== -1 ||
        //     // Allow: Ctrl+A
        //     (event.keyCode === 65 && (event.ctrlKey || event.metaKey)) ||
        //     // Allow: Ctrl+C
        //     (event.keyCode === 67 && (event.ctrlKey || event.metaKey)) ||
        //     // Allow: Ctrl+V
        //     (event.keyCode === 86 && (event.ctrlKey || event.metaKey)) ||
        //     // Allow: Ctrl+X
        //     (event.keyCode === 88 && (event.ctrlKey || event.metaKey)) ||
        //     // Allow: home, end, left, right
        //     (event.keyCode >= 35 && event.keyCode <= 39)) {
        //     // let it happen, don't do anything
        //     // deja que ocurra, no hagas nada
        //     return;
        // }

        // // Ensure that it is a number and stop the keypress
        // // Asegúrate de que sea un número y detén la pulsación de tecla
        // if ((event.shiftKey || (event.keyCode < 48 || event.keyCode > 57)) && (event.keyCode < 96 || event.keyCode > 105)) {
        //     event.preventDefault();
        // }
    }

    /**
     * Metodo encargado de capturar la fecha actual (current date) y enviar formateado el dato
     */
    public getCurrentDate() {
        return moment();
    }

    /**
     * Metodo encargado de capturar la fecha actual (current date) y enviar formateado el dato
     */
    public getCurrentDateFormat(format) {
        const now = moment();
        return now.format(format);
    }

    /**
     *
     * Metodo encargado de determinar si una fecha se encuentra entre dos fechas dadas,
     * Actualmente este metodo esta haciendo uso de la libreria: MomentJs
     *
     * https://momentjs.com/
     *
     */
    // public checkDateBetween(dateFrom: string, dateTo: string, dateCheck: string, dateFormat: string = 'YYYY-MM-DD') {
    //     const tmpDateCheck = moment(dateCheck);
    //     return (tmpDateCheck >= moment(dateFrom, dateFormat) && tmpDateCheck <= moment(dateTo, dateFormat));
    // }

    /**
     * Metodo encargado de setear las horas para mostrar en los horarios
     * @param hour: any -> Hora que se va a setear
     * @param formatHour: boolean -> Variable que indica el formato de las horas a manejar (12/24 Horas)
     */
    public captureHour(hour: string, formatHour12: boolean = false) {

        // variable para indicar si es mañana o tarde
        let amPm: string;

        // variable
        let tmpHour: string;

        // separamos las horas, minutos y segundos en un arreglo
        const splitHour = hour.split(':');

        // verificamos que la cantidad de elementos sea de 2 =>   09 : 20 : 00
        if (splitHour.length !== 3) {
            return hour;
        }

        // verificamos si esta pidiendo la hora en formato de 12 horas
        if (!formatHour12) {
            return splitHour[0] + ':' + splitHour[1];
        }

        // verficamos si la hora es de media noche
        if (splitHour[0] === '00') {
            tmpHour = '12';
            amPm = 'AM';
        }

        // verificamos si hora de la mañana
        if (Number(splitHour[0]) < 12) {
            tmpHour = splitHour[0];
            amPm = 'AM';
        }

        // verificamos si hora de la tarde
        if (Number(splitHour[0]) === 12) {
            tmpHour = splitHour[0];
            amPm = 'PM';
        }

        // verificamos si hora de la tarde
        if (Number(splitHour[0]) > 12) {
            tmpHour = String(Number(splitHour[0]) - 12);
            amPm = 'PM';
        }

        // verificamos si la hora final es menor a 10
        if (tmpHour.length < 2) {
            tmpHour = '0' + tmpHour;
        }

        return tmpHour + ':' + splitHour[1] + ' ' + amPm;
    }

    /**
     * Metodo encargado de limpiar cadenas, quitando tildes
     * @param value: string -> Cadena que se va a limpiar
     */
    public cleanString(value: string) {
        value = value.replace(/á/gi, 'a');
        value = value.replace(/é/gi, 'e');
        value = value.replace(/í/gi, 'i');
        value = value.replace(/ó/gi, 'o');
        value = value.replace(/ú/gi, 'u');
        return value;
    }

    /**
     * permite identificar si un restaurante esta abierto en este momento
     * @param openDates
     */
    public isOpenRestaurant(openDates: any) {

        // const today = this.getCurrentDate();
        let isOpen = false;
        const tmpWeekDay = Number(moment().day());

        loopOpens: for (const tmpOpenDate of openDates) {

            // // Se verifica que exista una temporada que contenga la fecha actual
            // const itIsCurrentSeason = this.checkDateBetween(
            //     tmpOpenDate.startDate,
            //     tmpOpenDate.endDate,
            //     today.toString()
            // );

            // // se verifica si es vigente
            // if (!itIsCurrentSeason) {
            //     continue;
            // }
            let hours = tmpOpenDate.hours;
            if (tmpOpenDate.hours_data) {
                hours = tmpOpenDate.hours_data;
            }
            for (const tmpHours of hours) {

                // buscamos un hour open que sea del dia de "hoy" y que el horario sea de atencion y no de domicilios
                if (Number(tmpHours.attentionType) === 1 && tmpWeekDay === Number(tmpHours.weekDay)) {

                    const now = moment();
                    const openHour = moment(now.format('YYYY-MM-DD') + ' ' + tmpHours.hourFrom);
                    const closeHour = moment(now.format('YYYY-MM-DD') + ' ' + tmpHours.hourTo);

                    isOpen = (now >= openHour && now <= closeHour);

                    break loopOpens;
                }
            }
        }

        return isOpen;
    }

    /**
     * Permite clonar variables
     * @param value
     */
    public clone(value: GetFavoritesStructureResponse[] | any): any {
        if (value instanceof Array) {
            return this.cloneArray(value);
        } else if (value instanceof Object) {
            return this.cloneObject(value);
        } else {
            return value;
        }
    }

    /**
     * Permite clonar objetos
     * @param anyObject
     */
    public cloneObject(anyObject: Object): any {
        const cloneObj = {};
        // tslint:disable-next-line:forin
        for (const attribut in anyObject) {
            cloneObj[attribut] = this.clone(anyObject[attribut]);
        }
        return cloneObj;
    }

    /**
     * Permite clonar arreglos
     * @param anyArray
     */
    public cloneArray(anyArray: Array<any>) {
        const tmpArray = [];
        for (const element of anyArray) {
            tmpArray.push(this.clone(element));
        }
        return tmpArray;
    }

    /**
     * Permite verificar si una variable esta vacia
     * @param value
     */
    public isEmpty(value: any) {
        if (value instanceof Array) {
            return value.length === 0;
        } else if (value instanceof Object) {
            return this.objectIsEmpty(value);
        } else {
            return String(value).trim() === '';
        }
    }

    /**
     * Permite verificar si un objecto esta vacío
     * @param objValue
     */
    public objectIsEmpty(objValue: Object) {
        for (const key in objValue) {
            if (objValue.hasOwnProperty(key)) {
                return false;
            }
        }
        return true;
    }


    /**
     * Verifica si ha expirado el tiempo de cache de algun identificador
     * de cache valido para el navegador
     * verdadero si se ha terminado o no existe
     * falso si aun no se ha terminado
     */
    checkCacheExpiratedTime(identifier: string) {

        if (localStorage.getItem(identifier + this.cachedTimeStrConstant)) {

            /**
             * tiempo inicial almacenado
             */
            const startedTime = localStorage.getItem(identifier + this.cachedTimeStrConstant);

            const difference = new Date().getTime() - parseInt(startedTime, 10);

            const resultInMinutes = Math.round(difference / 60000);

            /**
             * si el tiempo de cache aun no se ha terminado
             */
            if (resultInMinutes <= this.timeMaxCache) {

                return false;

            }

        }

        return true;

    }

    /**
     * guardar un tiempo de cache
     * @param identifier identificador para los datos que se quieren alamcenar en cache
     */
    setCachedTime(identifier: string) {

        const startTime = new Date().getTime();

        localStorage.setItem(identifier + this.cachedTimeStrConstant, startTime + '');

    }

    /**
      * Metodo encargado de realizar peticion a los web services de enviar
      * los tipos de comidas registrados
      */
    getAllCuisines(callback: Function, position: GeoPosition, range: number) {

        /**
         * identificador para las cocinas
         */
        const cuisinesIdentifier = 'cuisines';

        /**
         * si ha expirado la cache
         */
        const checkedCacheExpiratedTime = this.checkCacheExpiratedTime(cuisinesIdentifier);

        // if (checkedCacheExpiratedTime) {
        return this.restaurantServices
            .getAllCuisines(position, range)
            .subscribe(response => {
                if (response['data']) {
                    localStorage.setItem(cuisinesIdentifier, JSON.stringify(response['data']));
                    this.setCachedTime(cuisinesIdentifier);
                    callback(response['data']);
                } else {
                    callback(null);
                }
            }, () => {
                callback(null);
            });
        // } else {
        //     callback(JSON.parse(localStorage.getItem(cuisinesIdentifier)));
        // }
    }

    /**
     * Permite establecer el atributo checked en false,
     * este atributo el que se usa para saber cual fue el que selecciono el usuario en el buscador
     * @param items
     */
    resetCheckOptions(items: any[]) {
        // tslint:disable-next-line:forin
        for (const i in items) {
            items[i].checked = false;
        }
        return items;
    }

    /**
     * Permite convertir un elemento a una cadena con las propiedades requeridas
     *
     * @param element Es el elemento a transformar
     */
    transformElementToString(element: any) {
        return JSON.stringify({ id: element.id, name: element.name });
    }

    /**
     * se encarga de traer la ruta imagen segun los parametros
     * @param images
     * @param imagePath
     * @param folder
     * @param sizeFormat
     */
    getImagePathLogo(images: any, imagePath: string, folder: string, sizeFormat: string) {
        const defaultImagePath = imagePath;

        if (typeof images === 'undefined') {
            return defaultImagePath;
        }

        const image: any = this.imagesUtils.getOneImage(images);

        if (image == null) {
            return defaultImagePath;
        }

        this.imagesUtils.setContentFolder(folder);
        this.imagesUtils.setImageDefault(defaultImagePath);
        this.imagesUtils.setSizeFormat(sizeFormat);

        return this.imagesUtils.getPath(images);
    }

    /**
     * Permite clonar un objeto apartir de otro con las propiedades indicadas
     *
     * @param element El elemento a clonar
     * @param properties Las propiedades a clonar
     */
    cloneObjectByProperties(element: any, properties: any) {
        const newElement = {};
        // Recorremos el arreglo de propiedades que requerimos
        for (const property in properties) {
            // Si tiene la propiedad que se busca
            if (element.hasOwnProperty(properties[property])) {
                const key = Object.keys(element)[property];
                newElement[key] = element[key];
            }
        }
        return newElement;

    }

    /**
     * Desaparece la animacion de carga principal
     */
    hideMainLoader() {
        jQuery('.spinner').animate({
            opacity: 0.5,
        }, 800, function () {
            jQuery('.container-loading').hide();
            setTimeout(function () {
                jQuery('.child').parents('.gm-style-iw').parent().addClass('infoPop');
            }, 2000);

        });
    }

    /**
     * Obtiene los meses
     */
    getMonths(): Array<any> {
        moment.locale(localStorage.getItem(AppConstants.LOCAL_STORAGE_DATA.LANGUAGE_USER));
        const months = [];
        const monthMomnet = moment.monthsShort();
        let objectMonth;
        for (let _i = 0; _i < monthMomnet.length; _i++) {

            if (_i > 8) {
                objectMonth = { 'value': (_i + 1).toString(), label: monthMomnet[_i] };

            } else {
                objectMonth = { 'value': '0' + (_i + 1), label: monthMomnet[_i] };

            }
            months.push(objectMonth);
        }
        return months;
    }

    /**
     * Obtiene los dias por mes
     * @param date
     */
    getDaysByMonth(date: string): Array<any> {
        // 2018-09
        const numDays = moment(date, 'YYYY-MM').daysInMonth();
        const days = [];
        let day;
        for (let _i = 1; _i <= moment(date, 'YYYY-MM').daysInMonth(); _i++) {
            if (_i > 9) {
                day = { 'value': _i.toString(), label: _i };
            } else {
                day = { 'value': '0' + _i, label: '0' + _i };
            }
            days.push(day);
        }
        return days;
    }

    /**
     * devuelve
     * @param startYear cantidad de años a restar 2018 - (25)
     */
    getYearDefault(startYear: number = 25) {
        return new Date().getFullYear() - startYear;
    }

    /**
     * Obtiene un rango de años
     * @param startYear determina el año inicial. cantidad de años que se quiere restar al año actual ejm 2018 - (25)
     * @param finalYear determina el año final en el que quedará el rango de años. ejm 2019 - 10 = 2009.
     */
    getYears(startYear: number = null, finalYear: number = null): Array<any> {
        let currentYear = new Date().getFullYear();
        const years = [];
        if (startYear) {
            startYear = currentYear - startYear;
        } else {
            startYear = 1980;
        }
        if (finalYear) {
            finalYear = currentYear - finalYear;
            currentYear = finalYear;
        }
        while (startYear <= currentYear) {
            const year = currentYear--;
            years.push({ value: year, label: year });
        }
        return years;
    }

    /**
     * Permite eliminar las tildes de las cadenas de texto
     */
    public removeAccents(chain: string) {
        try {
            return chain.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        } catch (error) {
            return chain;
        }
    }

    /**
     * Permite ordenar la lista de las tarjetas de credito o de direcciones
     *
     * @param list listado a ordenar
     * @param isCardList tarjetas true, direcciones false
     * @author Alejandro Rodriguez Sept. 7 - 2018
     * @author Alvaro Felipe Garcia Mendez - May 10-2019
     * @version 1.0.3
     */
    public sortList(list: Array<any>, isCardList: boolean): void {
        list.sort((elementA: any, elementB: any) => {
            let objectKeysA = [];
            let objectKeysB = [];
            if (isCardList) {
                objectKeysA = [elementA.mainCard, elementA.createdAt];
                objectKeysB = [elementB.mainCard, elementB.createdAt];
            } else {
                objectKeysA = [elementA.mainAddress, elementA.createdat.date];
                objectKeysB = [elementB.mainAddress, elementB.createdat.date];
            }

            // en caso de que las tarjetas a comparar sean iguales en su estado de favorito,
            // es decir si ambas no son favoritas o si ambas son favoritas
            // ordenamos por la fecha de la mas nueva a la mas vieja
            if (objectKeysA[0] === objectKeysB[0]) {
                const unixA = moment(objectKeysA[1], 'YYYY-MM-DD HH:mm:ss').unix();
                const unixB = moment(objectKeysB[1], 'YYYY-MM-DD HH:mm:ss').unix();
                // ordenamos de la mas vieja a la mas nueva
                return unixA - unixB;
            }

            // si una es favorita y la otra no,
            // ordenamos por la favorita
            return objectKeysB[0] - objectKeysA[0];
        });
    }

    /**
     * Permite calcular si el restaurante esta abierto/cerrado y si prontamente va a cambiar de estado,
     * tener en cuenta que devuelve un estado sin traducir, este es el key para la traduccion equivalente,
     * por lo que se debe aplicar el filtro de translate a esa respuesta
     * @param openDates
     * @author Alvaro Felipe Garcia Mendez - July 19 - 2019
     * @version 1.0.2
     */
    public getRestaurantScheduleStatus(openDates: any, status: number, closeGeneral: number | boolean = null) {

        return new Observable<string>((observer: Subscriber<string>) => {
            let textStatus = '';
            if (Boolean(closeGeneral)) {
                textStatus = 'CLOSED';
                observer.next(textStatus);
                return;
            }
            // Constante que almacena el día de hoy en número
            const tmpWeekDay = Number(moment().day());

            // Almacena la zona horaria local
            const localTimeZone = moment.tz.guess();

            observer.next(textStatus);
            if (openDates.length === 0) {
                textStatus = 'NOT_AVAILABLE';
            }
            for (const tmpOpenDate of openDates) {
                // si el not available ya está seteado se rompe el ciclo for y se pasa al código siguiente al for
                if (textStatus === 'NOT_AVAILABLE') {
                    break;
                }
                let hours = tmpOpenDate.hours;
                if (tmpOpenDate.hours_data) {
                    hours = tmpOpenDate.hours_data;
                }
                if (hours && hours.length > 0) {
                    for (let i = 0; i < hours.length; i++) {

                        // buscamos un hour open que sea del dia de "hoy" y que el horario sea de atencion y no de domicilios
                        // para el día de hoy
                        if (tmpWeekDay === Number(hours[i].weekDay)) {

                            const now = moment();
                            let hourFrom = hours[i].hourFrom;
                            let hourTo = hours[i].hourTo;

                            // valida si la zona horaria donde se encuentra el restaurante
                            // es diferente a la del usuario
                            if (tmpOpenDate.timeZone) {
                                if (tmpOpenDate.timeZone !== localTimeZone) {

                                    hourFrom = moment.tz(now.format('YYYY-MM-DD') + ' ' + hourFrom, tmpOpenDate.timeZone).tz(localTimeZone).format('HH:mm:ss');
                                    hourTo = moment.tz(now.format('YYYY-MM-DD') + ' ' + hourTo, tmpOpenDate.timeZone).tz(localTimeZone).format('HH:mm:ss');
                                }
                            }

                            const openHour = moment(now.format('YYYY-MM-DD') + ' ' + hourFrom);
                            const closeHour = moment(now.format('YYYY-MM-DD') + ' ' + hourTo);

                            // si la apertura es mayor al cierre del restaurante quiere decir
                            // que el cierre se hace al dia siguiente despues de las 0 horas del dia en curso
                            if (openHour >= closeHour) {
                                // se le suma 1 dia a el cierre
                                closeHour.add(1, 'day').format('YYYY-MM-DD');
                            }
                            // const isOpen = (now >= openHour && now <= closeHour);

                            // esta es la manera de calcular con momentjs si una fecha u hora esta dentro de un rango
                            const isOpen = (now.isBetween(openHour, closeHour, null, '[]'));

                            if (isOpen) {
                                const remainingTimeToClose = closeHour.diff(now, 'minutes');
                                if (remainingTimeToClose <= 30 && remainingTimeToClose >= 0 && status !== 2) {
                                    textStatus = 'CLOSED_SOON';
                                    break;
                                } else if (status === 2) {
                                    textStatus = 'NOT_AVAILABLE';
                                    break;
                                } else {
                                    textStatus = 'OPEN';
                                    break;
                                }
                            } else {
                                const remainingTimeToOpen = openHour.diff(now, 'minutes');
                                if (remainingTimeToOpen <= 30 && remainingTimeToOpen >= 0) {
                                    textStatus = 'OPEN_SOON';
                                    break;
                                } else {
                                    textStatus = 'CLOSED';
                                }
                            }
                            // para otros días
                        } else if ((hours.length === i + 1) && textStatus === '') {
                            textStatus = 'CLOSED';
                            break;
                        }
                    }
                }
            }

            if (textStatus.trim() !== '') {
                observer.next(textStatus);
            } else {
                observer.next('NOT_AVAILABLE');
            }

        });
    }

    /**
     * Método que obtiene el idioma para setear la traducción
     * @author Oscar Alarcon - Abr. 10-2024
     * @version 1.0.1
     * @returns Se retorna el idioma
     */
    public async getDefaultLang(): Promise<string> {
        // Se obtiene el dato del idioma del usuario que se guardo en el localStorage
        let languageSelected = localStorage.getItem(AppConstants.LOCAL_STORAGE_DATA.LANGUAGE_USER);
        // Se obtiene el dato del idioma de la ruta
        let languageRoute = localStorage.getItem(AppConstants.LOCAL_STORAGE_DATA.LANGUAGE_ROUTE);
        // Si no hay ningún idioma en el localStorage
        if (!languageSelected) {
            // Se espera hasta que el idioma este el el localStorage
            await new Promise<void>(resolve => {
                const checkLanguage = () => {
                    languageSelected = localStorage.getItem(AppConstants.LOCAL_STORAGE_DATA.LANGUAGE_USER);
                    languageRoute = localStorage.getItem(AppConstants.LOCAL_STORAGE_DATA.LANGUAGE_ROUTE);
                    if (languageSelected && languageRoute) {
                        resolve();
                    } else {
                        // Se verifica cada 100 milisegundos si aun no se ha obtenido el dato del localStorage
                        setTimeout(checkLanguage, 100);
                    }
                };
                
                // Se llama al checkLanguage para volver a inciar la comprobación
                checkLanguage();
            });
        }else if (languageSelected && languageSelected !== languageRoute){
            // Usa el idioma de la URL si es diferente al idioma seleccionado previamente.
            languageSelected = languageRoute;
            // Se guarda el idioma de la URL para que este sea utulizado en el sistema
            localStorage.setItem(AppConstants.LOCAL_STORAGE_DATA.LANGUAGE_USER, languageSelected);
        }
        return languageSelected;
    }
    /**
     * Indica si el navegador por el cual esta accediendo es de un dispositivo movil
     * @author William Alarcón
     * @version 1.0.0
     */
    public isMobile(): boolean {
        const userAgent = navigator.userAgent;
        if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(userAgent)) {
            return true;
        }
        return false;
    }

    /**
     * 
     *  Realiza las validaciones propias de la navegacion del proyecto
     * verificar si esta accdiendo por menu online para agregar queryParam a la url
     * @author William Alarcon
     * @version 1.0.0
     * @param {String} language lenguaje que tiene configurado el usuario es | en
     * @param {String} url pagina a la que quiere acceder '/app/detail-restaurant'
     * @param {String} paramUrl parametro adicional de la url /idRestaurant
     * @param {Object} queryParams (opcional) parametros de url ?param=value
     */
    public navigationOMT(language: string, url: string, paramUrl: string | number = '', queryParams: Object = {}): void {
        if (this.externalMenu) {
            queryParams['otherpage'] = true;
        }
        if (paramUrl !== '') {
            // TODO: Revisar como funciona this.router.navigate([this.localize.translateRoute(language + url), paramUrl]); ya que esto está eliminado los parametros del las url
            this.router.navigate([this.localize.translateRoute(language + url),
                paramUrl]);
            // Se llama al metodo navigate para que no se elimine el parametro ?otherpage=true cuando es menu online
                this.navigateRestaurant(language, paramUrl);
        } else {
            this.router.navigate([this.localize.translateRoute(language + url)]);
        }
    }

    /** 
    * funcion para retornar un numero con los decimales que el usuario selecciona
    * @param {number} valueData
    * @param {number} decimalData cantidad de decimales
    */
    public roundDecimals(valueData, decimalData: number = null, returnWithDecimal: boolean = false): number {
        /*if (CONSTANTS.APP.COUNTRY === "C0") {
            return this._roundCincuenta(valueData);
        }*/
        // Se hace la validación por si no se pasan parametros tome el valor pore defecto
        if (typeof decimalData === 'undefined' || decimalData === null) {
            decimalData = AppConstants.DECIMALS_NUMBER.TWO;
        }
        // se genera el numero dependiendo de los decimales que el usuario este usando
        const numberString = Number(valueData).toString();
        // si el número es un decimal con muchos ceros se retorna directamente 0
        if (numberString.toString().indexOf('e-') >= 0) {
            return 0;
        }
        const arrayNumber = numberString.split('.');
        // obtiene los decimales
        let decimals = arrayNumber[1] ? arrayNumber[1] : '00';
        // si hay mas decimales de los que se necesitan se valida si se debe redondear por encima y se garantiza que suceda
        // javascript no sabe redondear 1.165.toFixed(2) = 1.17 pero 10.165.toFixed(2) = 10.16
        if (decimals.length > decimalData) {
            const lastDecimal = decimals[decimalData];
            // garantiza el redondeo por encima
            if (Number(lastDecimal) === 5) {
                decimals = decimals.substr(0, decimalData) + '6';
            }
        }
        // re arma el valor con los ajustes realizados
        valueData = Number(arrayNumber[0] + '.' + decimals);
        // realiza el redondeo final
        // const resultToFixed = ;
        if (returnWithDecimal) {
            return valueData.toFixed(decimalData, decimalData);
        } else {
            return Number(valueData.toFixed(decimalData));
        }
    }

    /**
    * Funcion que valida si el restaurante esta no disponible o esta cerrado.
    *
    * @author Valeria Medina Ramirez. April 2-2020
    * @version 1.0.151
    */
    public letOpenProductPage(restaurant): Promise<object> {
        return new Promise(resolve => {
            this.translate.get(['RESTAURANT_INACTIVE', 'TEMPORALLY_CLOSED', 'OK']).subscribe((translated) => {
                 this.restaurantStatus;
                if (restaurant.statusSchedule) {
                    if (restaurant['statusSchedule'] === 'NOT_AVAILABLE') {
                        // showAlert(translated.RESTAURANT_INACTIVE, translated.OK);
                        resolve(
                            this.restaurantStatus = {
                                restaurantIsOpen: false,
                                message: 'RESTAURANT_INACTIVE'
                            });
                    } else if (restaurant['statusSchedule'] === 'CLOSED' || restaurant.scheduleStatus === 'OPEN_SOON') {
                        // this.showAlert(translated.TEMPORALLY_CLOSED, translated.OK);
                        resolve(
                            this.restaurantStatus = {
                                restaurantIsOpen: false,
                                message: 'TEMPORALLY_CLOSED'
                            }
                        );
                    } else {
                        resolve(
                            this.restaurantStatus = {
                                restaurantIsOpen: true,
                                message: 'OPEN'
                            }
                        );
                    }
                } else {
                    // this.showAlert(translated.RESTAURANT_INACTIVE, translated.OK);
                    resolve(
                        this.restaurantStatus = {
                            restaurantIsOpen: false,
                            message: 'RESTAURANT_INACTIVE'
                        }
                    );
                }
            });
        });
    }

    /**
     * @author Anderson Barreto B. - Jun 04 2020
     * @version 1.0.0
     * @description Sirve para saber si el restaurante esta abierto o cerrado
     * @param restaurantId id del restaurante
     */
    public isOperOrClosed(restaurantId: any): Promise<any> {
        return new Promise(resolve => {
            this.restaurantServices.getDetailRestaurant(restaurantId, 'es')
                .subscribe(async (response: ResponsesStructure) => {
                    let restaurant: RestaurantsInformationStructureResponse = response.data;
                    restaurant.statusSchedule = await this.restaurantScheduleStatus(restaurant);
                    //console.log('restaurant.scheduleStatus', restaurant.statusSchedule);
                    this.letOpenProductPage(restaurant).then(restauratStatus => {
                        //console.log('respuestaseje', restauratStatus);
                        resolve(restauratStatus);
                    });

                }, error => {
                    console.log(error);
                });
        });
    }

    /**
     * @author Anderson Barreto B. - Jun 04 2020
     * @version 1.0.0
     * @description sirve para saber el estado del restaurante.
     * @param restaurant datos del restaurante
     */
    public restaurantScheduleStatus(restaurant: any): Promise<any> {
        return new Promise(resolve => {
            return this.getRestaurantScheduleStatus(
                restaurant.openingDates,
                restaurant.status,
                restaurant.restClosed
            ).subscribe(scheduleStatus => {
                if (scheduleStatus) {
                    return resolve(scheduleStatus);
                }
            });
        });
    }

    /**
     * Funcion que muestra la alerta q le avisa a el restaurante que no puede hacer un
     * pedido, ya sea porque esta inactivo o esta cerrado.
     *
     * @author Valeria Medina Ramirez. April 2-2020
     * @version 1.0.151
     *
     * @param alertMessage mensaje de la alerta
     * @param buttonMessage mensaje del boton de la alerta
     */
    public showAlert(alertMessage: string, buttonMessage: string) {
        this.sweet.alert(alertMessage, null, null, buttonMessage, false);
    }

    /**
     * Funcion que desarrolla la logica para las landings (esconde el logo y el buscador)
     */
    public initializeLandingPages(removeFooter: boolean = false) {
        const header = document.querySelector('body');
        header.className = 'menuExterno';
        const head = document.head || document.getElementsByTagName('head')[0];
        const style = document.createElement('style');
        style.type = 'text/css';
        const css = '.logo-none{display:none} .searchHeader{display:none}';
        style.appendChild(document.createTextNode(css));
        head.appendChild(style);
        this.meta.removeTag('name= "viewport"');
        this.meta.addTag({ name: 'viewport', content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0' });
        this.tabOMT();
        
        if (removeFooter) {
            this.hidePrincipalFooter();
        }
    }


    private hidePrincipalFooter() {
        const footer = document.querySelector('#wm-footer');
        footer.remove();
    }

    /**
     * Funcion que elimna los items duplicados en un array
     * @param array array que se envia para devolverlo sin items duplicados
     *
     * NOTA: SE DEBE DE CREAR UN METODO PARA QUE ENVIE UN CORREO QUE INDIQUE CUALES SON LOS ID QUE ESTAN LLEGANDO REPETIDOS
     */
    public deleteDuplicateItemsInArray(array) {
        if (array) {
            array = array.filter((value, index, newArray) => {
                return newArray.findIndex(valueArray => JSON.stringify(valueArray) === JSON.stringify(value)) === index;
            });
        }
        return array;
    }

        /**
     * @author Anderson Barreto B. - Sept 13-2021
     * @version 1.0.0
     * @description Determina si el dispositivo donde esta el usuario es pequeño (tablet, celular, etc..)
     * @returns {object<boolean, string, string<'android' | 'ios'>}
     */
         public isSmallDevice(): {isSmall: boolean, type: string, so: 'android' | 'ios' } {

            const kindDevice = ['webOS', 'iPhone', 'Android', 'iPad', 'iPod'];
    
            let isMatchDevice = {
                isSmall: false,
                type: '',
                so: null
            };
    
            kindDevice.forEach(type => {
                const regType = new RegExp(type, 'i');
                const so = type === 'Android' ? 'android' : 'ios'
                if (navigator.userAgent.match(regType)) {
                    console.log('intro', type);
                    isMatchDevice = {
                        isSmall: true,
                        type,
                        so
                    }
                }
            });
    
            return isMatchDevice;
        }

    /**
     *  Metodo que funciona para navegar al restaurante
     *  @author Oscar Alarcon - Sep. 14-2023
     *  @version 1.0.1
     *  @param idRestaurant id del restaurante, se obtiene desde el html
     */
    public navigateRestaurant(language: string, idRestaurant: string | number){
        if(idRestaurant && idRestaurant !== ''){
            const urlDetailRestaurant = '/app/detail-restaurant/';
            if(this.externalMenu){
                this.router.navigate([this.localize.translateRoute(`${language}${urlDetailRestaurant}${idRestaurant}`)],
                {queryParams: {otherpage:true}});
            }else {
                this.router.navigate([this.localize.translateRoute(`${language}${urlDetailRestaurant}${idRestaurant}`)]);
            } 
        }else{
            const urlOmt = '/app/restaurant-list';
            this.router.navigate([this.localize.translateRoute(urlOmt)]);
        }
    }

    /**
     *  Metodo que funciona para navegar entre las vistas teniendo en cuenta si es menú online u omt
     *  @author Oscar Alarcon - Feb. 15-2024
     *  @version 1.0.0
     *  @param url: ruta de la vista ejemoplo /app/profile/my-notifications
     */
    public navigateBetweenViews(url: string) {
        const queryParams = { otherpage: 'true', idrestaurant: this.idRestaurantExt };
        if(this.externalMenu){
            this.router.navigate([this.localize.translateRoute(url)], { queryParams });
        }else{
            this.router.navigate([this.localize.translateRoute(url)]);
        }
        this.getDefaultLang();
    }
}
