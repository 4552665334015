import * as numeral from 'numeral';
import { Pipe, PipeTransform } from '@angular/core';
import { UserLocationProvider } from 'app/utils/userLocationService';

/**
 * Clase utilizada como pipe para convertir un numero a formato moneda
 *
 * @author Jhonier Gaviria M. - Ago. 22-2018
 * @version 1.1.0
 */
@Pipe({ name: 'currencyOMT', pure: false })
export class Currency implements PipeTransform {

    /**
     * Constructor de la clse
     * @param userLocation  Gestion de la ubicacion del usuario
     */
    constructor(private userLocation: UserLocationProvider) {
    }

    /**
     * @see {PipeTransform}
     */
    transform(amount: string): any {
        /* if (this.userLocation.useColombianCurrency()) {
            return numeral(amount).format('$0,0');
        } else { */
            return numeral(amount).format('$0,0.00');
        //}
    }
}
