import { Injectable } from '@angular/core';
import { ModifiersServices } from 'app/core/interfaces/modifiers.interfaces';

@Injectable({
    providedIn: 'root'})
export class ModifiersProductsService implements ModifiersServices{

    /**
     * Aloja los ultimos modificadores consultados
     * y los mantiene como un caché para evitar consumir datos
     */
    private lastModifiersRequested: any;

    /**
     * Aloja los ultimos modificadores con configuracion consultados
     * y los mantiene como un caché para evitar consumir datos
     */
    private lastConfigModifiersRequested: any;

    /**
     * Aloja el id del producto sobre el que se consultaron
     * los modificadores
     */
    private productId: string;
    lastModifiersChecked: any;
    lastGroupModifiersChecked: any;
    lastGroupModifiersCheckedIndex: any;
    constructor() {
        this.resetData();
    }

    /**
     * Obtiene los ultimos modificadores consultados por el ws
     */
    public getLastModifiers(): any {
        return this.lastModifiersRequested;
    }

    /**
     * Obtiene los ultimos modificadores consultados por el ws
     */
    public getLastConfigModifiers(): any {
        return this.lastConfigModifiersRequested;
    }

    /**
     * Obtiene el id del producto consultado por el ws
     * de modificadores
     */
    public getProductId(): string {
        return this.productId;
    }

    /**
     * Guarda los ultimos modificadores consultados por el ws
     * @param modifiersToSet modificadores a guardar
     */
    public setLastModifiers(modifiersToSet: any): void {
        this.lastModifiersRequested = modifiersToSet;
        for (const keyGroupModifier of this.lastModifiersRequested) {
            keyGroupModifier['products'].forEach((element: object) => {
                element['checked'] = false;
            });
        }
    }

    /**
     * Guarda los ultimos modificadores consultados por el ws
     * @param modifiersToSet modificadores a guardar
     */
    public setLastModifiersChecked(modifiersToSet: any): void {
        this.lastModifiersChecked = modifiersToSet;
    }

    /**
     * Guarda los ultimos modificadores consultados por el ws
     * @param modifiersToSet modificadores a guardar
     */
    public getLastModifiersChecked(): any {
        return this.lastModifiersChecked;
    }
    /**
     * Guarda los ultimos modificadores consultados por el ws
     * @param modifiersToSet modificadores a guardar
     */
    public setLastGroupModifiersChecked(modifiersToSet: any, index): void {
        this.lastGroupModifiersChecked = modifiersToSet;
        this.lastGroupModifiersCheckedIndex = index;
    }

    /**
     * Guarda los ultimos modificadores consultados por el ws
     * @param modifiersToSet modificadores a guardar
     */
    public getLastGroupModifiersChecked(): any {
        return {
            lastGroupModifiersChecked: this.lastGroupModifiersChecked,
            lastGroupModifiersCheckedIndex: this.lastGroupModifiersCheckedIndex
        };
    }

    /**
     * Guarda los ultimos modificadores consultados por el ws
     * @param modifiersToSet modificadores a guardar
     */
    public setLastConfigModifiers(modifiersToSet: any): void {
        this.lastConfigModifiersRequested = modifiersToSet;
    }

    /**
     * Guarda el id del producto consultado por el ws
     * de modificadores
     * @param id modificadores a guardar
     */
    public setProductId(id: any): void {
        this.productId = id;
    }

    /**
     * Deja los valores por defecto de las variables del servicio
     */
    public resetData(): void {
        this.lastModifiersRequested = [];
        this.lastConfigModifiersRequested = [];
        this.productId = '';
    }

    /**
     * Prepara los modificadores para que se envien como en Level Android
     * @param modifiers
     * @author Alvaro Felipe Garcia Mendez - Aug. 8 - 2019
     * @version 1.0.0
     */
    public prepareModifiers(modifiers: Array<object>): Array<object> {
        const products = [];
        for (const modifier of modifiers) {
            const product = {
                id: modifier['id'],
                sorting: modifier['sorting'],
                modifGroupName: modifier['modifGroupName'],
                size: modifier['size'],
                name: modifier['name'],
                qty: modifier['qty'],
                value: modifier['value'],
                idModifGrop: modifier['idModifGrop'],
                onTheSide: modifier['onTheSide'],
                itemIndex: 0,
                sizeId: modifier['sizeId'],
                allowance: 0, // quemado
                countFree: 0, // quemado
                ordering: null, // quemado
                odmId: null,
                odmPaternId: null,
                subModifiers: [],
                modFree: modifier['modFree']
            };
            for (const catSubModifier of modifier['subModifiers']) {
                for (const subModifier of catSubModifier['subModifiers']) {
                    if (subModifier['checked'] === true) {
                        const subMod = {
                            id: subModifier['id'],
                            sorting: catSubModifier['sorting'],
                            modifGroupName: catSubModifier['name'],
                            size: subModifier.sizeSelected['name'],
                            sizeId: subModifier.sizeSelected['id'],
                            name: subModifier['name'],
                            qty: subModifier.sizeSelected['quantity'],
                            value: subModifier.sizeSelected['price'],
                            idModifGrop: catSubModifier['id'],
                            // allowance: catSubModifier['allowance'],
                            // countFree: catSubModifier['countFree'],
                            allowance:0,
                            countFree:0,
                            ordering: null,
                            onTheSide: false,
                            odmId: null,
                            odmPaternId: null,
                            subModifiers: [],
                            itemIndex: 0,
                            modFree: subModifier.sizeSelected['isFree'],
                        };
                        product.subModifiers.push(subMod);
                    }
                }
            }
            products.push(product);
        }
        return products;
    }
}
