import { GeoPosition } from './GeoPosition';

export class Delivery {
  public del_address: string;
  public del_config: number;
  public del_type: number;
  public del_charge: number;
  public del_comment: string;
  public del_created: string;
  public del_delivery_time: string;
  public deliveryLocation: GeoPosition;
  public del_city_name: string;
  public del_state_name: string;
  public del_country_name: string;

  /**
   * Distancia del domicilio en metros
   */
  public del_distance: number;
  public z_zipcode: string;
  constructor() {}
}
