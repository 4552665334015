import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { DynamicComponentLoader } from 'app/core/dynamic-loader/dynamic-component-loader.service';

@Component({
    selector: 'app-dialog-detail-coupon-modal',
    template: '<div #detailCouponModal></div>'})
export class DialogDetailCouponModalComponent {

    @ViewChild('detailCouponModal', { read: ViewContainerRef, static: true }) detailCouponModal:
        | ViewContainerRef
        | undefined;

    constructor(private readonly loader: DynamicComponentLoader) {
        this.loader.getComponentFactory('detail-coupon-modal').subscribe({
            next: factory => {
                if (!this.detailCouponModal) {
                    return;
                }
                this.detailCouponModal.createComponent(factory).changeDetectorRef.detectChanges();
            }
        });
    }
}
