import { UtilsComponent } from 'app/utils/utils.component';

import { AuthService } from 'angularx-social-login';
import { GlobalEventsService } from 'app/core/services/globalEvents.service';
import { tap, timeout } from 'rxjs/operators';
import {
    HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest,
    HttpResponse }
    from '@angular/common/http';
import { Injectable, Injector, InjectionToken, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppConstants } from 'app/app-constants';
import { Observable, throwError } from 'rxjs';
import { ConfigComponent } from 'app/core/config/config';
import { GlobalErrorHandler } from 'app/core/services/global-error-handler';
import { SweetAlertService } from 'app/utils/sweetAlert.service';
import { Auth } from './auth-service';
import {ActivatedRoute, Router} from '@angular/router';
import { LocalizeRouterService } from "localize-router";
import { Location } from '@angular/common';

export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');

@Injectable()
export class AuthInterceptor implements HttpInterceptor {


    constructor(
        private readonly injector: Injector,
        private readonly route: ActivatedRoute,
        private readonly router: Router,
        private readonly localize: LocalizeRouterService,
        private readonly authService: AuthService,
        private readonly globalEventsService: GlobalEventsService,
        @Inject(DEFAULT_TIMEOUT) protected defaultTimeout: number,
        ){}


    /**
     * Permite verificar si es una peticion para el servidor api
     * @param urlRequest
     */
    isRequestForApi(urlRequest: string): boolean {
        const config = this.injector.get(ConfigComponent);
        // verificamos si es una url con protocolo, en este caso eso nos dice que esta haciendo una peticion a un servidor
        if (urlRequest.includes('http')) {
            const urlObjRequest = new URL(urlRequest);
            const urlObjApi = new URL(config.getUrlOmt());
            return (urlObjRequest.host === urlObjApi.host);
        }
        return false;
    }

    /**
     * Permite obtener el idioma seleccionado
     */
    private getLang() {
        // cargamos el servicio que manipula el idioma
        // con el objetivo de saber que idioma tiene seleccionado el usuario
        const translator = this.injector.get(TranslateService);
        return translator.currentLang.length === 2 ? translator.currentLang : 'en';
    }


    /**
     * See
     * @param request
     * @param next
     */
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const auth = this.injector.get(Auth);
        const timeoutValue = request.headers.get('timeout') || this.defaultTimeout;
        const timeoutValueNumeric = Number(timeoutValue);
        const notifier = this.injector.get(GlobalErrorHandler);
        // verificamos si estamos haciendo una peticion a la api
        if (!this.isRequestForApi(request.url)) {
            // en caso de no estar haciendo la peticion a la api, no filtramos la cabecera
            return next.handle(request).pipe(timeout(timeoutValueNumeric));
        }
        // obtenemos el content type de la petición
        const ContentTypeRequest = request.headers.get('Content-Type');
        // Creamos un objeto con las cabeceras
        const objDataHeaders = {
            'Api-Key': auth.getToken(),
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Accept-Language': this.getLang(),
            'Time-Zone': Intl.DateTimeFormat().resolvedOptions().timeZone
        };
        // en caso de querer estar subiendo una imagen eliminamos el content type, ya que el propio angular
        // le agrega el content type correspondiente, seguido de un codigo de desencriptacion:
        // ejemplo:  Content-Type:multipart/form-data; boundary=----WebKitFormBoundaryZoqUTnYP1CCa1tYs
        if (ContentTypeRequest === 'multipart/form-data') {
            delete objDataHeaders['Content-Type'];
        }
        // Si no existe el token lo eliminamos
        if (auth.getToken() === null) {
            delete objDataHeaders['Api-Key'];
        }
        // Creamos la petición
        const tmpRequest = request.clone({
            responseType: 'json',
            headers: new HttpHeaders(objDataHeaders)
        });
        // Realizamos siguiente petición
        return next.handle(tmpRequest).pipe(timeout(timeoutValueNumeric)).pipe(
            tap((event: HttpEvent<any>) => {
                // Validamos que sea un evento de tipo response
                if (event instanceof HttpResponse) {
                    // Valido si el servidor devuelve un nuevo token para el usuario y lo seteo
                    if (event.headers.get('Api-Key')) {
                        auth.setToken(event.headers.get('Api-Key'));
                    }
                    // verificamos si hay un error
                    if (event.body && typeof event.body['errors'] !== 'undefined') {
                        // obtenemos el estado de respuesta
                        const responseHttpStatus = Number(event.body['errors']['status']);
                        // verificamos si hay error de sesion
                        if (responseHttpStatus === 401) {
                            // Validamos para mostrar solo una vez el mensaje
                            if (auth.getToken() !== null) {
                                this.removeSession(event.body['errors']['message']);
                            }
                        }
                    }
                }
            }, ((error: HttpErrorResponse) => {
                if (error instanceof HttpErrorResponse) {
                    // verificamos si hay errores desde el servidor
                    if (error.status === 401) {
                        // Validamos para mostrar solo una vez el mensaje
                        if (auth.getToken() !== null) {
                            if (error['error']['errors'] && error['error']['errors']['message']) {
                                this.removeSession(error['error']['errors']['message']);
                            } else {
                                this.removeSession(error.message);
                            }
                        }
                    } else {
                        return throwError;
                    }
                    notifier.handleError(error);
                }
            })
            ));
    }

    /**
     * Permite eliminar la información almacenada localmente de la sesión del usuario
     *
     * @author Jhonier Gaviria M. - Jul. 31-2018
     * @version 1.2.0
     *
     * @param message Es el mensaje para mostrar en pantalla
     */
    private removeSession(message: string): void {   
        localStorage.removeItem(AppConstants.LOCAL_STORAGE_DATA.AUTH_TOKEN);
        localStorage.removeItem(AppConstants.LOCAL_STORAGE_DATA.DATA_SESSION);
        localStorage.removeItem(AppConstants.LOCAL_STORAGE_DATA.DATA_USER);
        localStorage.removeItem(AppConstants.LOCAL_STORAGE_DATA.PHOTO_URL);
        let language = localStorage.getItem(AppConstants.LOCAL_STORAGE_DATA.LANGUAGE_USER);
        // logout social net
        if (localStorage.getItem(AppConstants.LOCAL_STORAGE_DATA.SOCIAL_LOGIN)) {
            localStorage.removeItem(AppConstants.LOCAL_STORAGE_DATA.SOCIAL_LOGIN);
            this.authService.signOut();
        }
        this.globalEventsService.logout.emit();
        this.globalEventsService.closeAllModals.emit();
        // Se injecata utils component
        const utilsComponent = this.injector.get(UtilsComponent);
        // Se llma al metodo navigateRestaurant para que navegue cuando se hace el logout si es un menu
        // online o es desde omt
        utilsComponent.navigateRestaurant(language, utilsComponent.idRestaurantExt);
        const sweetAlertService = this.injector.get(SweetAlertService);
        sweetAlertService.alert(message);
    }
}
