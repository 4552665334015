import { ConfigComponent } from 'app/core/config/config';
import { DomSanitizer } from '@angular/platform-browser';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

/**
 * Variable para manejo de funciones jquery
 */
declare var jQuery: any;

@Injectable()
export class ImagesUtilsComponent {

    constructor(
        private configApp: ConfigComponent,
        public sanitizacion: DomSanitizer,
    ) { }

    // Variable que almacena direccion de s3
    urlS3 = 'https://s3-us-west-1.amazonaws.com/kijho-open-my-tab/';

    sizeFormat: String = null;
    contentFolder: String = null;
    imageDefault: String = null;


    /**
     * Permite asignar una url de imagen por defecto
     *
     * @param tmpImageDefault
     */
    setImageDefault(tmpImageDefault: String) {
        this.imageDefault = tmpImageDefault;
    }

    /**
     * Permite asignar la ruta de la carpeta contenedora de las imagenes
     *
     * @param tmpContentFolder
     */
    setContentFolder(tmpContentFolder: String) {
        this.contentFolder = environment.envName + tmpContentFolder;
    }

    /**
     * Permite asignar un tamaño y formato de la imagen
     *
     * @param tmpSizeFormat
     */
    setSizeFormat(tmpSizeFormat: String) {
        this.sizeFormat = tmpSizeFormat;
    }

    /**
     * Permite obtener la ruta de una imagen establecida en el ws
     *
     * @param image
     */
    getBuidPath(image): String {

        let extension = null;
        if (this.getIsJpg(image)) {
            extension = '.jpg';
        }
        if (this.getIsJpeg(image)) {
            extension = '.jpeg';
        }
        if (this.getIsPng(image)) {
            extension = '.png';
        }
        if (extension == null) {
            return this.imageDefault;
        }

        if (!this.sizeFormat) {
            this.sizeFormat = '';
        }
        
        if (image.cropedPath) {          
            return image.cropedPath.substring(0, image.cropedPath.lastIndexOf('.')) + this.sizeFormat 
            + image.cropedPath.substring(image.cropedPath.lastIndexOf('.'));
        }

        /**
         * TODO: Se creo esta otra condicion para poder construir la url de la imagen de
         * cupones, ya que la variable envName del environet siempre trae de valor prod, 
         * y no trae el valor stage, probablemente sea en la construccion del proyecto
         */

        if (image.path) {          
            return image.path.substring(0, image.path.lastIndexOf('.')) + this.sizeFormat 
            + image.path.substring(image.path.lastIndexOf('.'));
        }

        return this.urlS3 + this.contentFolder + image.fileName + this.sizeFormat + extension;
    }

    /**
     * Los siguinetes metodos verifican la extencion de la imagen (png, jpeg, jpg)
     *
     * @param image
     */
    getIsPng(image) {
        const extension: any = image.path.substr(image.path.lastIndexOf('.') + 1);
        return extension === 'png';
    }

    getIsJpeg(image) {
        const extension: any = image.path.substr(image.path.lastIndexOf('.') + 1);
        return extension === 'jpeg';
    }

    getIsJpg(image) {
        const extension: any = image.path.substr(image.path.lastIndexOf('.') + 1);
        return extension === 'jpg';
    }

    /**
     * Verifica si la imagen se encuentra en el ws y devuelve la ruta que la contiene,
     * si no está, devuelve una ruta de img por defecto
     *
     * @param image
     */
    getPath(image: any) {
        if (image !== null) {
            if (!jQuery.isEmptyObject(image)) {
                if (image.isUploadedOnAWS || image.isUploadedInAws) {
                    return this.getBuidPath(image);
                } else if (image.path.includes('omtws-stage.openmytab.net') || image.path.includes('omtws.openmytab.com') || image.path.includes('omt.localhost')){
                    return image.path;
                } else {
                    const urlApi = this.configApp.getUrlHostApi();
                    let path;
                    if (image.cropedPath) {
                        path = urlApi + image.cropedPath;
                    } else {
                        path = urlApi + image.path;
                    }
                    return path;
                }
            }
        }
        return this.imageDefault;
    }

    /**
     * Verifica si el parametro es un arreglo, si lo és, devuelve la posicion [0],
     * Si no verifica el id de la imagen y devuelve el objeto images
     *
     * @param images
     */
    getOneImage(images: any) {
        if (images instanceof Array) {
            if (images.length > 0) {
                return images[0];
            }
        } else {
            if (images !== null) {
                if (images.id !== null) {
                    return images;
                }
            }
        }
        return null;
    }

    /**
     * permite seleecionar de una lista de imagenes una que pertenezca a una categoria,
     * adicional si el tercer parametro esta en false,
     * solo devuelve la primera imagen que encuentra
     *
     * @param images
     * @param categoryId
     * @param multriples
     */
    getImageByCategory(images: any, categoryId: number, multriples?: boolean) {

        if (!multriples) {
            for (const image of images) {
                if (image.category === categoryId) {
                    return image;
                }
            }
            return null;
        } else{
            const tmpImages = [];
            for (const image of images) {
                if (image.category === categoryId) {
                    tmpImages.push(image);
                }
            }
            return tmpImages;
        }
    }

    /**
     * permite discriminar una lista de imagenes por categoria
     *
     * @param images
     */
    discriminateImagesByCategory(images: any, categories: any) {

        const tmpImages = {};
        // recorremos las opciones de categoria
        for (const category of categories) {

            // filtramos por categoria
            tmpImages[category.id] = this.getImageByCategory(images, category.id, category.multiple);
        }

        return tmpImages;
    }

    /**
     * Convierte una imagen que llega por url a blob a traves de una petición ajax
     *
     * @param urlImg Es la url de la imagen que se desea convertir
     * @param callback Función que devuelve el resultado
     */
    getBlobFromUrl(urlImg: string, callback: Function) {
        // creamos un objeto XMLHttpRequest para hacer la peticion via ajax
        const xhr = new XMLHttpRequest();
        xhr.open('GET', urlImg, true);
        xhr.responseType = 'blob';

        // listen para cuando escuche que la consulta cambió de estado
        xhr.onreadystatechange = function () {
            // verificamos si la peticion se ha completado
            if (Number(xhr.readyState) === 4) {
                // verificamos si la peticion fue correcta
                if (Number(xhr.status) === 200) {
                    callback(xhr.response);
                } else {
                    // en caso de que el estado no haya sido 200
                    callback(null);
                }
            }
        };
        // ejecutamos la petición
        xhr.send();
    }

    /**
     * @author William Alarcón
     * @version 1.0.0
     * @param images arreglo de imagen a buscar
     * @param defaultImagePath Es la imagen que se va a mostrar si no se logra obtener una ruta real
     *                          ./assets/extra-images/default.jpg
     * @param folderAmazon es la carpeta en donde se encuentra la imagen en amazon
     * @param sizeCroped es el tamaño que se requiere de amazon
     * Obtiene una imagen del servidor de omt o de amazon si es el caso, si es de amazon obtiene la imagen cortada que se le indique
     */
    getImagePathMainThumb(images: any, defaultImagePath: string, folderAmazon: string, sizeCroped: string): string {
        if (typeof images === 'undefined') {
            return defaultImagePath;
        }
        if (typeof images === 'string') {
            return images;
        }

        const image = this.getOneImage(images);

        if (image == null) {
            return defaultImagePath;
        }

        this.setContentFolder(folderAmazon);
        this.setImageDefault(defaultImagePath);
        this.setSizeFormat(sizeCroped);

        return this.getPath(image);
    }

    public urlIsApi(url: string) {
        if (url.includes('http')) {
            const urlObjRequest = new URL(url);
            const urlObjApi = new URL(this.configApp.urlOmt);
            // console.info("comparando: ", urlObjRequest.host , ' == ' , urlObjApi.host );
            return (urlObjRequest.host.trim() === urlObjApi.host.trim());
        }
        return false;
    }
    /**
     * permite verificar si una URL es para hacer peticion a un servidor
     * el objetivo es identificar cuando se esta solicitando archivos locales
     * @param url
     */
    public isUrlExternal(url: string) {
        // verificamos si la url es presenta protocolo http o https
        // con eso nos damos cuenta de que es una url externa
        return url.includes('http://') || url.includes('https://');
    }

    /**
     * obtiene una img con menos peso para renderizar mejor
     * @param img
     * @param callback
     */
    generateBlob(imgSrc: string, callback: Function) {

        const that = this;

        // revisamos si la imagen recibida es una imagen local o del api local,
        if (this.isUrlExternal(imgSrc) && !this.urlIsApi(imgSrc)) {

            // en caso de ser un servidor utilizamos un servicio
            // para que nos consiga la imagen por nosotros
            imgSrc = this.configApp.urlOmt + 'file-s3?url=' + imgSrc;
        }

        // Obtenemos el blob y se convierte a una url blob
        this.getBlobFromUrl(imgSrc, blob => {
            if (blob !== null) {
                const url = that.sanitizacion.bypassSecurityTrustResourceUrl(URL.createObjectURL(blob));
                callback(url);
            } else {
                callback(null);
            }
        });
    }

} 
