import { RestaurantOrder } from 'app/core/entities/RestaurantOrder';
import { EventEmitter, Injectable } from '@angular/core';

@Injectable()
export class ItemDetailsEventsService {

    public restaurantEvent: EventEmitter<RestaurantOrder>;
    public geoPositionEvent: EventEmitter<any>;
    public updateEvent: EventEmitter<any>;

    constructor() {
        this.restaurantEvent = new EventEmitter<RestaurantOrder>();
        this.geoPositionEvent = new EventEmitter<any>();
        this.updateEvent = new EventEmitter<any>();
    }

}
