import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { PaymentGatewayComponent } from './payment-gateway/payment-gateway.component';
import { PaymentGatewayRoutingModule } from './payment-gateway-routing.module';
import { SharedModule } from 'app/shared/shared.module';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, './assets/locales/', '.json');
}

@NgModule({
  imports: [
    PaymentGatewayRoutingModule,
    CommonModule,
    SharedModule,
    TranslateModule.forChild({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      },
      isolate: true
  }),
  ],
  exports: [
    PaymentGatewayComponent
  ],
  declarations: [
    PaymentGatewayComponent
  ],
})
export class PaymentGatewayModule { }
