/**
 * Clase que representa un restaurante seleccionado para realizar una orden
 *
 * @author Jhonier Gaviria M. - Jul. 31-2018
 * @version 1.0.0
 */
export class RestaurantOrder {

    /**
     * Identificador del restaurante
     */
    id: string;

    /**
     * Nombre del restaurante
     */
    name: string;

    /**
     * Indica si acepta contraentrega
     */
    configuration: Object;

    /**
     * Indica si tiene activado smart taxes
     */
    smartTaxes: boolean;

    /**
     * Direccion del logo del restaurante
     */
    logo: string;

    deliveryRanges: any;

    multiplePrice: any;
     /**
     * Indica el nickname de la licencia restaurant
     */
    levelDatabaseNickname?:any;

    /**
     * TODO: Se debe definir un tipo de dato en donde se indique las horas abiertas
     *  {hours_data } almacena la hora inicio y final de cada dia 
     */
    openingDates?: any;

    /**
     * Constructor de la clase
     *
     * @author Jhonier Gaviria M. - Jul. 31-2018
     * @version 1.0.0
     */
    constructor() {
        this.id = null;
        this.name = null;
        this.logo = null;
        this.configuration = null;
        this.smartTaxes = true;
    }
}
