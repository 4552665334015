import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigComponent } from 'app/core/config/config';
import { ContactUsServices, ContactUsStructureRequest } from 'app/core/interfaces/contact-us.interfaces';
import { Observable, throwError } from 'rxjs';
import { ResponsesStructure } from 'app/core/interfaces/transversal.interfaces';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContactUsService implements ContactUsServices{

  constructor(private readonly http: HttpClient, private readonly config: ConfigComponent) { }

  /**
  * Tipado del request y el response del servicio implementado correctamente
  */
  public contactUs(data: ContactUsStructureRequest): Observable<ResponsesStructure> {
    const url = `${this.config.getUrlOmt()}contactMessage`;
    return this.http.post<ResponsesStructure>(url, data).pipe(
      map((response: ResponsesStructure) => {
        return response;
      }),catchError(error => {
        return throwError(error);
    })
    );
  }
}
