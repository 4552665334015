import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogCouponCodeComponent } from './dialog-coupon-code.component';
import { DynamicComponentLoaderModule } from 'app/core/dynamic-loader/dynamic-component-loader.module';

@NgModule({
  imports: [
    CommonModule,
    DynamicComponentLoaderModule.forModule({
      componentId: 'coupon-code',
      path: 'coupon-code',
      loadChildren: () => import('./content/coupon-code.module').then(m => m.CouponCodeModule)
    })
  ],
  declarations: [DialogCouponCodeComponent],
  exports: [DialogCouponCodeComponent]
})
export class DialogCouponCodeModule { }
