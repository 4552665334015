import { Payment } from 'app/core/entities/Payment';
import { UtilsComponent } from 'app/utils/utils.component';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { CreditCardsServices } from 'app/core/interfaces/credit-cards.interfaces';
import { SplashService } from './splash.service';

/*
 Generated class for the GatewayProvider provider.

 See https://angular.io/guide/dependency-injection for more info on providers
 and Angular DI.
 */
@Injectable()
export class GatewayService {

    gateways = {
        splash: 1,
        payu: 2
    };

    constructor(
        public http: HttpClient,
        private splash: SplashService,
        private utils: UtilsComponent,
        @Inject('CreditCardsServices') private readonly creditCardService: CreditCardsServices) {
    }

    sale(gateway, creditCardData, merchantId) {
        return new Promise((resolve) => {
            if (gateway === this.gateways.splash) {
                this.splash.startPayment(creditCardData, merchantId).subscribe((data: any) => {
                    if (data.response.errors.length === 0) {
                        // obtenemos el nombre del operador segun su digito identificador
                        // const cardType = this.utils.getCardType(creditCardData.number);
                        const cardType = this.creditCardService.getCodeFranchise(creditCardData['franchise']);
                        const dataPayment = data.response.data[0];
                        const totalPayment = Number((dataPayment.total / 100).toFixed(2));
                        const payment = new Payment();
                        payment.ipr_history_key_id = dataPayment.id;
                        payment.ipr_authcode = dataPayment.id;
                        payment.ipr_pay_type = 2;
                        payment.ipr_card_name = cardType;
                        payment.ipr_order_key_id = dataPayment.id;
                        payment.ipr_cliaccount = dataPayment.payment.number;
                        // payment.ipr_ref_code = dataPayment.id;
                        payment.ipr_maccount = '0';
                        payment.ipr_total = totalPayment;
                        payment.ipr_initial_total = totalPayment;
                        payment.ipr_merchant_id = dataPayment.merchant;
                        payment.ipr_terminal_id = dataPayment.origin;
                        payment.ipr_sequence_number = dataPayment.id;
                        payment.ipr_date_time = this.utils.getCurrentDateFormat('YYYY-MM-DD HH:mm:ss');
                        payment.ipr_ip_device = '';
                        payment.ipr_port_device = '';
                        resolve(payment);
                    } else {
                        resolve(this.proccessError(data.response.errors));
                    }
                });
            }
            if (gateway === this.gateways.payu) {
                // proceso correspondiente a payu
            }
        });
    }

    /**
     * procesa la respuesta de error para eviar un mensaje al solicitante del servicio.
     * @param errors
     * @returns {{error: string}}
     */
    proccessError(errors) {
        let errorMsg = '';
        for (const error of errors) {
            errorMsg += error.msg + '\n';
        }
        return ({ error: errorMsg });
    }

    reversePayment(gateway, transactionId) {
        return new Promise((resolve) => {
            if (gateway === this.gateways.splash) {
                this.splash.startReverse(transactionId).subscribe((responseReverse: any) => {
                    resolve(responseReverse);
                });
            }
            if (gateway === this.gateways.payu) {
                // proceso correspondiente a payu
            }
        });
    }

    refundPayment(gateway, transactionId, amount) {
        return new Promise((resolve) => {
            if (gateway === this.gateways.splash) {
                this.splash.startRefund(transactionId, amount).subscribe((responseRefund: any) => {
                    resolve(responseRefund);
                });
            }
            if (gateway === this.gateways.payu) {
                // proceso correspondiente a payu
            }
        });
    }

}
