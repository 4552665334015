import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppConstants } from 'app/app-constants';
import { Delivery } from 'app/core/entities/Delivery';
import { DefaultRange, DeliveryServices } from 'app/core/interfaces/delivery.interfaces';
import { DynamicModalServices } from 'app/core/interfaces/dynamic-modals.interfaces';
import { OrderServices } from 'app/core/interfaces/orders.interfaces';
import { DialogItemDetailsComponent } from 'app/shared/dynamic-modules/item-details/dialog-item-details.component';
import { SweetAlertService } from 'app/utils/sweetAlert.service';
import { UserLocationProvider } from 'app/utils/userLocationService';
import { OrderProvider } from './order.service';

@Injectable({
  providedIn: 'root'
})
export class DeliveryService implements DeliveryServices{

  /**
    * Almacena la distancia que se debe recorrer para la entrega
    */
  private distanceToDelivery: any;

  /**
   * almacena la configuracion del tipo de delivery
   */
  private deliveryTypes: any;

  /**
   * Almacena el valor total del delivery
   */
  private totalDelivery;

  /**
   * configuracon con el tipo de delivery
   */
  private readonly CONF_TYPE_DELIVERY = {
    // si no tiene recargo
    CONF_TYPE_NOT_RECHARGE: 0,

    // si tiene recargo por distancia
    CONF_TYPE_DELIVERY_RECHARGE_BY_DISTANCE: 0,

    // si la tarifa es plena
    // tiene un valor unico sin importar la distancia
    CONF_TYPE_DELIVERY_PLAIN_RECHARGE: 1,

  };

  /**
   * indica como se va a llevar a cabo el cobro para calcularlo
   */
  private readonly CONF_ITEM_TYPE = {
    // en caso de no tener recargo
    CONF_ITEM_TYPE_NOT_RECHARGE: 0,

    // si el rango esta establecido en kilometros
    CONF_ITEM_TYPE_KILOMETER: 1,

    // si el rango esta establecido en millas
    CONF_ITEM_TYPE_MILE: 2,

    // si tiene un costo fijo
    CONF_ITEM_TYPE_TOTAL_COST: 3
  };

  /**
   * Constante con el eqivalente en metros de 1 milla
   */
  private readonly ONE_MILE_IN_METERS = 0.00062137;

  /**
   * Constante con el equivalente en metros de 1 Km
   */
  private readonly ONE_KILOMETER_IN_METERS = 0.001;
  constructor(
    @Inject('OrderServices') private readonly orderServices: OrderServices,
    private userLocation: UserLocationProvider,
    private translateService: TranslateService,
    private sweet: SweetAlertService,
    private _orderService: OrderProvider,
    @Inject('DynamicModalServices') private readonly modalService: DynamicModalServices) {
    this.totalDelivery = 0;
  }

  /**
   * @author Anderson Barreto B. - Mar. 09-2020
   * @version 1.0.0
   * @param distance la distancia en metros que debe recorrer el domiciliario
   * @param deliveryRanges los rangos con los precios y configuracion del domicilio
   */
  public calculateDeliveryPrice(distance: number, deliveryRanges: any): void {
    this.distanceToDelivery = distance;
    this.deliveryTypes = deliveryRanges;
    // ordena el objeto de menor a mayor por km o millas
    this.deliveryTypes.info_recharge = this.deliveryTypes.info_recharge.sort((a, b) => {
      return (a.rangeDelivery - b.rangeDelivery);
    });
    // si esta configurado por distancia pero no tiene recargo
    if (this.deliveryTypes.typeDelivery === this.CONF_TYPE_DELIVERY
      .CONF_TYPE_DELIVERY_RECHARGE_BY_DISTANCE && this.deliveryTypes.typeRange === this.CONF_ITEM_TYPE.CONF_ITEM_TYPE_NOT_RECHARGE) {
      this.orderServices.deliveryDetail.del_charge = this.totalDelivery;
      this.orderServices.deliveryDetail.del_config = this.CONF_ITEM_TYPE.CONF_ITEM_TYPE_NOT_RECHARGE;
      this.orderServices.deliveryDetail.del_type = this.CONF_ITEM_TYPE.CONF_ITEM_TYPE_NOT_RECHARGE;
      return;
    }
    // si esta configurado como tarifa plena
    if (this.deliveryTypes.typeDelivery === this.CONF_TYPE_DELIVERY.CONF_TYPE_DELIVERY_PLAIN_RECHARGE) {
      this.orderServices.deliveryDetail.del_charge = this.deliveryTypes.info_recharge[0].fixedCostValue;
      this.orderServices.deliveryDetail.del_config = this.CONF_TYPE_DELIVERY.CONF_TYPE_DELIVERY_PLAIN_RECHARGE;
      this.orderServices.deliveryDetail.del_type = this.CONF_ITEM_TYPE.CONF_ITEM_TYPE_TOTAL_COST;
      return;
    }
    this.distanceToDelivery = this.convertMetersToMilesOrKm(this.distanceToDelivery);
    this.orderServices.deliveryDetail.del_config = this.CONF_TYPE_DELIVERY.CONF_TYPE_DELIVERY_RECHARGE_BY_DISTANCE;
    this.orderServices.deliveryDetail.del_type = this.distanceToDelivery.unit;
    // se calcula el valor del domicilio teniendo en cuenta los rangos y la distancia a regorrer
    for (const deliveryType of this.deliveryTypes.info_recharge) {
      // toma el ultimo valor en caso de que la distancia sea superior al ultimo rango, 
      // se hace de esta manera ya que el objeto se encuentra ordenado
      this.totalDelivery = deliveryType.deliveryPrice;
      if (deliveryType.rangeDelivery >= this.distanceToDelivery
        .distance && this.distanceToDelivery.distance <= deliveryType.rangeDelivery) {
        this.totalDelivery = deliveryType.deliveryPrice;
        break;
      }
    }
    this.orderServices.deliveryDetail.del_charge = this.totalDelivery;
  }



  /**
   * @author Anderson Barreto B. - Mar. 09-2020
   * @version 1.0.0
   * @description Metodo encargado de convertir los metros a millas o kilometros 
   * @param distance Recibe la distancia que debe recorrer el domiciliario para la entrega
   * @returns {object =  {unit: number, distance: number}}  retorna la unidad de medida de acuerdo a los constantes y su valor equivalente
   */
  private convertMetersToMilesOrKm(distance: number): object {
    const inKm = distance * this.ONE_KILOMETER_IN_METERS;
    const inMiles = distance * this.ONE_MILE_IN_METERS;
    let distanceToUser = {
      unit: this.CONF_ITEM_TYPE.CONF_ITEM_TYPE_MILE,
      distance: inMiles
    }
    if (this.deliveryTypes.typeRange === 1) {
      distanceToUser = {
        unit: this.CONF_ITEM_TYPE.CONF_ITEM_TYPE_KILOMETER,
        distance: inKm
      }
      return distanceToUser;
    }
    return distanceToUser;
  }

   /**
   * Permite establecer el rango por defecto para el restaurante,
   * tomando el valor proveniente en el restaurante
   */
  public setDefaultRange(restaurant): DefaultRange {
    // Solo si tiene el rango configurado
    let ratioRestaurant;
    if (restaurant.deliveryRanges) {
      if (restaurant.deliveryRanges.length > 0) {
        ratioRestaurant = Number(
          restaurant.deliveryRanges[0].rangeMax
        );
      } else {
        // En caso de no estar configurado toma el valor mínimo del rango configurado
        ratioRestaurant = AppConstants.RANGE_MIN_VALUE;
        // Creo un delivery range para trabajar los recargos de domicilios(temporal)
        restaurant.deliveryRanges.push({
          id: '',
          rangeCharge: AppConstants.CHARGE_VALUE,
          rangeMax: AppConstants.RANGE_MIN_VALUE
        });
      }
      const dataDefaultRange = {
        deliveryRanges: restaurant.deliveryRanges,
        ratioRestaurant: ratioRestaurant
      }
      return dataDefaultRange;
    }
  }

  /**
   * Funcion que valida que cuando se escoga una direccion previamente guardada este dentro
   * del rango delimitado por el restaurante
   * //mainImage
   */
  public validateUserUbication(restaurant, deliveryLocation, productSelected = null, deliveryData = null, deliveryAddress, noteAddress: string, openItemsPage = true): void {
    const restaurantAddress = {
      lat: parseFloat(restaurant.address.latitude),
      lng: parseFloat(restaurant.address.longitude)
    };
    // Establecemos el rango apartir de los datos del restaurante
    const dataRanges = this.setDefaultRange(restaurant);
    const ratioRestaurant = dataRanges.ratioRestaurant;
    // Validamos que contenga la direccion del usuario
    this.userLocation.restaurantContainsUserUbication(ratioRestaurant, restaurantAddress, deliveryLocation).then(resp => {
      if (resp) {
        // Calculamos la distancia que existe entre el restaurante y el usuario
        this.userLocation.getDistanceMatrixService(restaurantAddress, deliveryLocation).then(service => {
          const infoDelivery = new Delivery();
          console.log('deliveryAddress', deliveryAddress);
          // Dirección del usuario
          infoDelivery.del_address = deliveryAddress;
          // Distancia del usuario al restaurante
          infoDelivery.del_distance = service['response'].rows[0].elements[0].distance.value;
          // Le pasamos el comentario del usuario
          infoDelivery.del_comment = noteAddress;
          // colocamos el zip code del usuario
          infoDelivery.z_zipcode = deliveryData.address.zip_code;
          // Se le asigna el re cargo que trae el restaurante(por el momento, se debe cambiar, pasarlo al carrito de compras)
          infoDelivery.del_charge = Number(restaurant.deliveryRanges[0].rangeCharge);
          infoDelivery.deliveryLocation = deliveryLocation;
          // Se le asigna el nombre de la ciudad del usuario
          infoDelivery.del_city_name = deliveryData.address.city_name;
          // Se le asigna el pais del usuario 
          infoDelivery.del_country_name = deliveryData.address.country_name;
          // Se le asigna el estado del usuario
          infoDelivery.del_state_name = deliveryData.address.state_name;
          // Guardo en la sesión la información del domicilio
          this.orderServices.setDeliveryDetail(infoDelivery);
          this.calculateDeliveryPrice(service['response'].rows[0].elements[0].distance.value, restaurant.deliveryRanges[0]);
          if(openItemsPage) {
            const initialState = {
              product: productSelected,
              typeOrder: this.orderServices.getServiceTypeSelected().orderType,
              restaurant: restaurant
            };
            this.modalService.openDialog(DialogItemDetailsComponent, {
              panelClass: 'dialog-right',
              data: initialState
            });
          }
        });
      } else {
        if(this.userLocation.isColombia()){
          this.translateService.get('DIFFERENT_ADDRESS').subscribe(resp => {
            this.sweet.alert(resp);
            //Se borra la data del pedido para que cuando haya una direccion incorrecta, no se pueda confirmar y se dañe la app
            this._orderService.clearCart();
          });
        }
      }
    });
  }

}
