import { AppConstants } from '../../app-constants';
import { Delivery } from './Delivery';
import { Payment } from './Payment';
import { ProductSale } from './ProductSale';

export class Sale {
    // valores fijos
    public use_id?: string;
    public use_id_owner?: string;
    public cus_id?: string;
    public tab_id?: string;
    public ord_sub_table?: number;
    public order_type?: number;
    public name?: string;
    public phone?: string;
    public email: string;
    public externalMenu?: boolean;
    public externalMenuColor?: any;
    // valores variables
    public ord_auto_tip_percent?: number;
    public ord_auto_tip_value?: number;
    public ord_comment?: string;
    public ord_discount?: string;
    public ord_status?: number;
    public ord_update?: string;
    // variables anteriores a taxes
    public products?: Array<ProductSale>;
    public payment?: Payment;
    // Variable para contraentrega
    public useLoyaltyPoints?: number;
    public uponDelivery?: boolean;
    public uponDeliveryData?: Object;
    public restaurant_id: string;
    public phone_type?: string;
    public is_anonimous?: boolean;
    public total_order?: string;
    public subtotal_order?: string;
    public arrayTaxes?: Array<any>;
    public delivery?: Delivery;
    public taxsIncluide?: number;
    public totalTax?: number;
    public coupons?: any[];
    public brandFrom?: number;
    public brand?: string;
    public tip?: number;
    public allInformation?: boolean;
    public orderDineInTurn?: string;
    public ode_deferred_date?: any;
    public externalPay?: boolean;
    public requestQuote?: {};

    constructor(public ord_take_time: string, tmpDelivery: Delivery, isExternalMenu: boolean) {
        this.delivery = tmpDelivery;

        // datos fijos
        /**
         * corresponde al usuario de open my tab en android
         * este usuario puede ser cambiado por otro
         */
        this.use_id = '2';
        /**
        * corresponde al usuario de open my tab en android
        * que inicia la orden
        */
        this.use_id_owner = '2';
        /**
         * es el cliente por defecto de la aplicación de android
         */
        this.cus_id = '1';
        /**
         * es ma mesa por defecto que se usa en android
         */
        this.tab_id = '1';
        this.ord_sub_table = 1;
        this.order_type = 3;
        // this.ord_id_target = 0;
        // this.ord_print_pre_invoice = 0;
        // this.ord_is_kitchen = 1;

        // datos variables
        this.products = [];
        this.ord_auto_tip_percent = 0;
        this.ord_auto_tip_value = 0;
        this.ord_comment = '';
        this.ord_discount = '0,,,$';
        this.ord_status = AppConstants.ORDER_STATUS.billed;
        this.ord_update = ord_take_time;
        this.coupons = [];

        // Se modifican las fechas de creación y el tiempo en que se entrega(domicilio)
        this.delivery.del_created = ord_take_time;
        this.delivery.del_delivery_time = ord_take_time;
        // Se envia el número con el que idenfitifican desde dónde se hizo la compra.
        if (isExternalMenu) {
            this.brandFrom = AppConstants.BRAND_ALIAS_ONLINE.originNumber;
            this.brand = AppConstants.BRAND_ALIAS_ONLINE.name;
        } else {
            this.brandFrom = AppConstants.BRAND_ALIAS.originNumber;
            this.brand = AppConstants.BRAND_ALIAS.name;
        }

        this.tip = 0;

        this.allInformation = false;
        this.requestQuote = {
            order_number: '',
            provider: ''
        };
    }


}
