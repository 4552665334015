import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { DynamicComponentLoader } from 'app/core/dynamic-loader/dynamic-component-loader.service';

@Component({
    selector: 'app-dialog-cancel-order-component',
    template: '<div #cancelOrder></div>'}
)
export class DialogCancelOrderComponent {
    @ViewChild('cancelOrder', { read: ViewContainerRef, static: true }) cancelOrder:
        | ViewContainerRef
        | undefined;

    constructor(private readonly loader: DynamicComponentLoader) {
        this.loader.getComponentFactory('cancel-order').subscribe({
            next: factory => {
                if (!this.cancelOrder) {
                    return;
                }
                this.cancelOrder.createComponent(factory).changeDetectorRef.detectChanges();
            }
        });
    }
}
