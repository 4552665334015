<!--// Main Wrapper \\-->
<div class="wm-main-wrapper">

    <header id="wm-header" class="wm-header-one wm-withbg">
        <mainHeader class="cien"></mainHeader>
    </header>

    <!--// Main Content \\-->
    <article class="wm-main-content">
        <!--// Main Section \\-->
        <div class="wm-main-section">
            <router-outlet></router-outlet>
        </div>
        <!--// Main Section \\-->
    </article>
    <!--// Main Content \\-->
    <footer id="wm-footer" class="footer-one" [ngClass]="{'col-md-8': showMap, 'col-md-12': !showMap}" appInTheMap>
        <mainFooter></mainFooter>
    </footer>

    <!-- Carrito flotante -->
    <a class="float-button animate__bounceIn" *ngIf="orderServices?.getTotalItems()" (click)="openCart()">
        <span class="badge-pill badge-danger">{{orderServices?.getTotalItems()}}</span>
        <i class="fa fa-shopping-cart float-shopping-cart"></i>
    </a>
    <!-- Carrito flotante -->

    <!-- Modal formulario de inicio de sesión -->
    <!-- <app-login-modal></app-login-modal> -->

    <!-- Modal de registro de usuario -->
    <!-- <registerModal></registerModal> -->

    <!-- Modal formulario de agregar y editar direcciones -->
    <!-- <app-address-modal></app-address-modal> -->

    <!-- Modal del detalle de las notificaciones -->
    <!-- <app-notification-detail></app-notification-detail> -->

    <!-- Modal del detalle de las notificaciones de los productos-->
    <!-- <app-notification-products></app-notification-products> -->

    <!-- Modal detalle de un cupón -->
    <!-- <app-detail-coupon-modal></app-detail-coupon-modal> -->

    <!-- Modal de lenguaje -->
    <app-language></app-language>

    <!-- modal de direcciones  -->
    <app-address-delivery-modal></app-address-delivery-modal>

    <!--  modal para abrir las opciones de un producto antes de agregarlo al carrito -->
    <!-- <article class="modal fade" id="modifier" tabindex="-1" role="dialog">
        <div class="modal-dialog " role="document">
            <div class="modal-content">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true" value="Close" aria-label="close">×</button>
                <app-cart-item-details></app-cart-item-details>
            </div>
        </div>
    </article> -->


    <!-- Modal que muestra los detalles del carrito de compras  -->
    <!-- <article class="modal fade" id="shoopingCartModal" tabindex="-1" role="dialog">
        <div class="modal-dialog shoppingWid" role="document">
            <div class="modal-content shopping">
                <button type="button" class="close" value="close" data-dismiss="modal" aria-hidden="true" aria-label="close">×</button>
                <app-shopping-cart></app-shopping-cart>
            </div>
        </div>
    </article> -->

    <!-- modal que muestra el checkout para que el cliente finalize el proceso de compra  -->
    <!-- <article class="modal fade" id="checkoutModal" tabindex="-1" role="dialog">
        <div class="modal-dialog shoppingWid" role="document">
            <div class="modal-content">
                <button type="button" class="close" value="close" data-dismiss="modal" aria-hidden="true" aria-label="close">×</button>
                <app-checkout></app-checkout>
            </div>
        </div>
    </article> -->

    <!-- modal que muestra el horario del restaurante  -->
    <!-- <article class="modal fade" id="cheduleRestModal" tabindex="-1" role="dialog">
        <div class="modal-dialog shoppingWid" role="document">
            <div class="modal-content">
                <button type="button" class="close" data-dismiss="modal" aria-hidden="true" value="close" aria-label="close">×</button>
                <app-service-time [showTitle]="true" [showTitleNoInfo]="true" ></app-service-time>
            </div>
        </div>
    </article> -->


</div>
<!--// Main Wrapper \\-->