import { Component, ViewChild, ViewContainerRef } from '@angular/core';

import { DynamicComponentLoader } from 'app/core/dynamic-loader/dynamic-component-loader.service';
import { GlobalEventsService } from 'app/core/services/globalEvents.service';
import { MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-dialog-checkout-component',
    template: '<div #checkout></div>'})
export class DialogCheckoutComponent {
    /**
     *  Variable para almacenar la suscripción al evento de cierre de la modal
     */
    private _closeDialogCheckout: Subscription;
    
    @ViewChild('checkout', { read: ViewContainerRef, static: true }) checkout:
        | ViewContainerRef
        | undefined;

    constructor(
        private readonly loader: DynamicComponentLoader,
        private readonly globalEvents: GlobalEventsService,
        private readonly dialogRef: MatDialogRef<any>,) {
            
        this.loader.getComponentFactory('checkout').subscribe({
            next: factory => {
                if (!this.checkout) {
                    return;
                }
                this.checkout.createComponent(factory).changeDetectorRef.detectChanges();
            }
        });
    }

    ngOnInit(): void{
        // Si se está usando un evento para cerrar el dialogo del checkout nos suscribimos para cerrarlo
        this._closeDialogCheckout = this.globalEvents.closeDialogCheckout.subscribe(() => {
            this.dialogRef.close();
        });
    }

    ngOnDestroy(): void{
        // Despues que se haya cerrado el dialogo del checkout por medio de un evento, cancelamos la subcripción
        this._closeDialogCheckout.unsubscribe();
    }
}
