import { GeoPosition } from 'app/core/entities/GeoPosition';
import Swal from 'sweetalert2';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UtilsComponent } from './utils.component';
import { Router } from '@angular/router';
import { MapsAPILoader } from '@agm/core';
import { UserLocationData } from 'app/core/models/user-location-data.interface';
import { ResultAutoComplete } from 'app/core/entities/ResultAutoComplete';
import { AppConstants } from 'app/app-constants';
import { ReplaySubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SearchIpStructureResponse, UserServices } from 'app/core/interfaces/user.interfaces';
import { ResponsesStructure } from 'app/core/interfaces/transversal.interfaces';
import { OrderServices } from 'app/core/interfaces/orders.interfaces';

declare var google: any;

/*
  Generated class for the UserLocationProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class UserLocationProvider {

    // esta es la posicion por defecto para toda la aplicación,
    // esto para cuando el usuario no da los permisso para usar el gps
    private defaultPosition: GeoPosition;

    // es el pais por defecto indicado para la posicion por defecto
    private defaultCountry: string;

    // permite almacenar el nombre del pais en el que el usuario está
    private currentCountry: string;

    // permite almacenar la ubicacion del usuario generada la ultima vez
    public currentLocation: GeoPosition;

    private zipCode: string;

    /**
     * almacena los datos de localizacion del usuario
     */
    private userLocationData: UserLocationData;

    // private geoCoder: any;


    // manejador de eventos para cuando se detecte un resultado en obtencion
    // de cordenadas del GPS
    public onChange = new ReplaySubject<any>(1);

    /**
     * Indica el numero de veces que hara el GPS para ser mas preciso la ubicación
     */
    private LOCATION_REQUEST_ATTEMPTS: number;

    /**
     * Umbral de precision, indica por cuanto en metros debe ser la precion del GPS,
     * el objetivo de esto es que si la precicion esta por encima de un valor el sistema vuelva y solicite
     * la pocicion del usuario hasta que la precion este por debajo del umbral
     */
    private ACCURACY_THRESHOLD: number;

    /**
     * indica el tiempo maximo de espera para que el sistema solicite la posicion del usuario
     */
    private TIME_OUT_ATTEMPTS: number;

    /**
     * indica el tiempo que la posicion debe estar en cache, para cuando vuelva a ser buscada
     */
    private MAXIMUM_AGE: number;

    /**
     * Inidica cuando se esta cargando la posicion del usuario, la finalidad es qeu el proceso no se haga 2 veces en paralelo
     */
    private isLoadingPosition: boolean;
    clientId: any;

    constructor(
        public http: HttpClient,
        public utils: UtilsComponent,
        private router: Router,
        @Inject('UserServices') private readonly userServices: UserServices,
        private mapsAPILoader: MapsAPILoader,
        @Inject('OrderServices') private readonly orderServices: OrderServices,
        private translate: TranslateService
    ) {

        // creamos una instancia del GEOPOSITION del user por defecto con coordenadas en colombia
        this.defaultPosition = new GeoPosition();
        this.defaultPosition.lat = 40.7589689;
        this.defaultPosition.lng = -73.9849219;

        this.MAXIMUM_AGE = 60000;

        // iniciamos los intentos de precesion en 5, para que solicite como un maximo de 5 veces la posicion del usuario
        this.LOCATION_REQUEST_ATTEMPTS = 3;

        // iniciamos el umbral de preción en 100 metros a la redonda
        this.ACCURACY_THRESHOLD = 150;

        // indicamos el tiempo máximo de espera en 20 segundos
        this.TIME_OUT_ATTEMPTS = 20000;


        // indicamos que el pais por defecto es colombia
        this.defaultCountry = 'USA';

        // inicializamos el evento
        // this.onChange = new EventEmitter<void>();

        // inicializamos la posicion
        this.currentLocation = null;

        // inicializamos el pais
        this.currentCountry = sessionStorage.getItem(AppConstants.SESSIONS_STORAGE_DATA.USER_COUNTRY);

        this.currentLocation = JSON.parse(sessionStorage.getItem(AppConstants.SESSIONS_STORAGE_DATA.USER_POSITION));

        // esto para que es?
        setTimeout(() => {
            if (this.router.url.indexOf('lng') !== -1) {
                // console.log('ngAfterViewInit -  ', this.router.url);
            } else {
                // cargamos datos
            }
        }, 700);
        // this.load();
    }

    /**
     * Permite cargar los datos relacionados con el servicio
     */
    /* private load(): void {
        // primero verificamos si ya se habia hecho una segunda carga antes
        if (this.isLoadingPosition) {
            return;
        }

        // indicamos el estado de carga
        this.isLoadingPosition = true;

        // obtenemos los datos guardados en la sesion
        const userPosition = sessionStorage.getItem(AppConstants.SESSIONS_STORAGE_DATA.USER_POSITION);


    } */

    public handlerLocation() {

        return new Promise((resolve) => {
            console.log("handlerLocation")
            if (this.currentLocation) {
                this.getUserLocationDataFromGoogleMaps();
            }
    
            // buscamos inicialmente la posicion y luego el pais
            this.getCurrentPositionFromBrowser().then(async (resultLocation: GeoPosition) => {
                console.log('getCurrentPositionFromBrowser', resultLocation)
                resolve(resultLocation);
                // console.info("RESULTADO BUSQUEDA POSICION: ", resultLocation);
                // si no encontro la posicion
                if (!resultLocation) {
                    /* this.findLocationByNetwork((response) => {
                        console.log('callllllll', response);
                        // indicamos el nuevo estado de carga
                        this.isLoadingPosition = false;
    
                        if (response['data']) {
                            // asignamos el nombre del pais para tenerlo en memoria
                            this.currentCountry = response['data']['country'];
                            this.clientId = response['data']['clientId'];
                            const newLocation = new GeoPosition();
                            newLocation.lat = Number(response['data']['latitude']);
                            newLocation.lng = Number(response['data']['longitude']);
                            // verifica la posicion nueva para disparar el evento relacionado
                            console.log('call checkLocationEmit2', this.currentLocation);
                            this.checkLocationEmit(newLocation);
                            // guardamos el valor en session
                            sessionStorage.setItem(AppConstants.SESSIONS_STORAGE_DATA.USER_COUNTRY, this.currentCountry);
                        } else {
                            // asignamos los valores por defecto
                            this.currentCountry = this.defaultCountry;
                            this.currentLocation = this.defaultPosition;
                            this.getUserLocationDataFromGoogleMaps();
                            console.log('emit1');
                            this.onChange.emit();
                        }
                    }); */
    
                    const {isSmall} = this.utils.isSmallDevice();
    
                    if (!isSmall) {
                        
                        this.translate.get(['GPS_DISABLED', 'OMT_USE_THE_GPS']).subscribe((translation: any) => {
                            Swal.fire(
                                translation.GPS_DISABLED,
                                translation.OMT_USE_THE_GPS,
                            );
                        });
                    }
    
    
    
    
    
                    this.findLocationByNetwork().then((response: ResponsesStructure) => {
                        console.log('responseFINDLOCSTION', response)
                        // console.log('callllllll', response);
                        // indicamos el nuevo estado de carga
                        this.isLoadingPosition = false;
                        const dataResponse: SearchIpStructureResponse = response.data;
                        if (dataResponse) {
                            // asignamos el nombre del pais para tenerlo en memoria
                            this.currentCountry = dataResponse.country;
                            this.clientId = dataResponse.clientId;
                            const newLocation = new GeoPosition();
                            newLocation.lat = Number(dataResponse.latitude);
                            newLocation.lng = Number(dataResponse.longitude);
                            // verifica la posicion nueva para disparar el evento relacionado
                            this.checkLocationEmit(newLocation);
                            // guardamos el valor en session
                            sessionStorage.setItem('Usa', this.currentCountry);
                        } else {
                            // asignamos los valores por defecto
                            this.currentCountry = this.defaultCountry;
                            this.currentLocation = this.defaultPosition;
                            this.getUserLocationDataFromGoogleMaps();
                            //console.log('emit1');
                            this.onChange.next();
                        }
                    });
    
                    // como no se habilitó la posición por parte del usuario entonces
                    // indicamos la posición por defecto al igual que el pais de esa posicion
                    // sin embargo en el metodo de buscar pais hay una ultima opcion
                    // en la cual busca esta informacion un poco menos precisa
                    // pero busca estos datos a traves de la IP
    
                    // that.currentLocation = that.defaultPosition;
                    // that.currentCountry = that.defaultCountry;
                    // that.onChange.emit();
    
                    //this.findLocationByNetwork(() => {
                    // indicamos el nuevo estado de carga
                    // this.isLoadingPosition = false;
                    // });
    
                } else {
                    // verificamos si la ubicacion encontrada es nueva posicion
                    // para disparar el evento relacionado
                    // console.log('call checkLocationEmit1', resultLocation);
                    await this.checkLocationEmit(resultLocation);
    
                    // indicamos el nuevo estado de carga
                    this.isLoadingPosition = false;
    
                    // creamos un visualizador de posiciones
                    // this.watchPosition();
    
                }
            });
        });

        
    }

    /**
     * Permite buscar el pais del usuario que visita la pagina
     */
    public searchCountry() {

        return new Promise((resolve) => {

            sessionStorage.setItem(AppConstants.SESSIONS_STORAGE_DATA.USER_POSITION, JSON.stringify(this.currentLocation));

            // en caso de que haya la ubicacion del usuario
            if (this.currentLocation !== null) {

                // obtenemos el pais de la cordenas del usuario
                this.utils.getCountryFromLatLon(String(this.currentLocation.lat), String(this.currentLocation.lng), (countryUser) => {

                    // Validamos que hubo respuesta correcta
                    if (countryUser !== null) {
                        // asignamos el nombre del pais para tenerlo en memoria
                        this.currentCountry = countryUser.name;
                    }

                    // guardamos el valor en session
                    sessionStorage.setItem(AppConstants.SESSIONS_STORAGE_DATA.USER_COUNTRY, this.currentCountry);

                    

                    
                   

                    resolve(this.currentCountry);
                });
            } else {
                console.log("cayo en el else de search country")
                resolve(null);
            }
        });

    }

    /**
     * permite obtener el pais y la ubicacion lo mas cernao posible a traves de su conexion a internet
     */
    public findLocationByNetwork() {
        // console.log('findLocationByNetwork');
        return new Promise(resolve => {
            this.userServices.getCountryFromIp().subscribe((response: ResponsesStructure) => {
                resolve(response);
            });
        });
    }

    /**
     * Permite obtener la ubicacion del usuario, usando el metodo normal,
     * es decir el plugin; https://cordova.apache.org/docs/en/latest/reference/cordova-plugin-geolocation/
     *
     * @param onSuccessCallback
     */
    private getCurrentPositionFromBrowser(callback: Function = null, tmpAttempt: number = 0) {
        //TODO: Revisar parametro callback por que es necesario, en el momento nunca se envia.
        return new Promise((resolve) => {
            const getPosition = (callback: Function = null, tmpAttempt: number = 0) => {
                const onErrorCallback = (error) => {
                    console.error('ERROR OBTENIENDO POSICIÓN: ', error);
                    switch (error.code) {
                        case error.PERMISSION_DENIED:
                            console.log('ERROR: User denied access to track physical position!');
                            break;

                        case error.POSITION_UNAVAILABLE:
                            console.log('ERROR: There is a problem getting the position of the device!');
                            break;

                        case error.TIMEOUT:
                            console.log('ERROR: The application timed out trying to get the position of the device!');
                            break;

                        default:
                            console.log('ERROR: Unknown problem!');
                            break;
                    }

                    // llamos recursivamente por segunda ves pero esta vez cambiando la alta precicion a baja
                    if (tmpAttempt < this.LOCATION_REQUEST_ATTEMPTS) {
                        getPosition(callback, tmpAttempt + 1);
                    } else {
                        resolve(null);
                    }
                };

                const onSuccessCallback = (position) => {
                    // en caso de que no exista actualmente una posicion
                    // se solicita nuevamente con el callback
                    if (position === null || position === undefined || position === '' || position.coords.accuracy > this.ACCURACY_THRESHOLD) {
                        getPosition(callback, tmpAttempt + 1);
                        return;
                    }

                    const tmpCurrentLocation = new GeoPosition();
                    tmpCurrentLocation.lat = position.coords.latitude;
                    tmpCurrentLocation.lng = position.coords.longitude;
                    
                    console.log('resolveseje')
                    resolve(tmpCurrentLocation);

                };

                const optionsGeo = {
                    enableHighAccuracy: true,
                    maximumAge: this.MAXIMUM_AGE,
                    timeout: this.TIME_OUT_ATTEMPTS
                };

                // verificamos si existe el gps en el navegador
                if (navigator.geolocation) {
                    // consultamos las cordenas del usuario
                    navigator.geolocation.getCurrentPosition(onSuccessCallback, onErrorCallback, optionsGeo);
                } else {
                    resolve(null);
                }

            }

            getPosition(callback, tmpAttempt);

        });
    }

    /**
     * Permite verificar si una ubicacion pasada esta cerca o no a la anterior,
     * esto para disparar el evento de que se ha cambiado la ubicacion del usuario
     *
     * @param newLocation. la nueva posicion
     * @param saveInSessionStorage. Indica si se debe guardar la posicion en el sessionStorage
     */
    public checkLocationEmit(newLocation: GeoPosition, saveInSessionStorage = false, stringAddress: string = null): Promise<boolean> {

        return new Promise(resolve => {
            // asignamos la nueva posicion
            this.currentLocation = newLocation;
            this.getUserLocationDataFromGoogleMaps();


            // guardamos la posicion siempre y cuando no provenga del formulario de inicio
            if (!saveInSessionStorage) {
                sessionStorage.setItem(AppConstants.SESSIONS_STORAGE_DATA.USER_POSITION, JSON.stringify(this.currentLocation));
            }

            


                // como encontro la posicion
                // buscamos el nombre del pais en el que está el usuario
                // tener en cuenta que este metodo internamente verifica si
                // si ya se encontro el pais, de lo contraario busca por la direccion ip
                this.searchCountry().then(() => {
                    // console.log("emitt");
                    /* console.log('EMMIT POSITION: ', this.currentLocation); */
                    // emitimos evento de que el usuario tiene una nueva posicion
                    this.getUserLocationData();
                    // console.log('emit2', this.currentLocation);
                    this.onChange.next(stringAddress);

                // resolve(true);
            });
            // }

        });
    }

    /**
     * Permite verificar si una posicion es igual a la posicion actual del usuario
     */
    private isEqualsLocation(newLocation: GeoPosition): boolean {
        let isSamePosition = true;

        // en caso de que la posicion actual sea null asignamos inmediatamente la posicion recibida
        if (this.currentLocation === null) {
            isSamePosition = false;
        } else {
            // comparamos la posicion nueva con la posicion ya guardada anteriormente
            isSamePosition = this.isSameLocation(newLocation, this.currentLocation);
        }

        return isSamePosition;
    }

    /**
      * Permite verificar si la posicion que tiene actual el usuario es de por defecto del sistema
      */
    private currentLocationIsDefault(): boolean {
        // verificamos si la posicion actual y la posicion por defecto es la misma
        return this.isSameLocation(this.currentLocation, this.defaultPosition);
    }

    /**
      * Permite comparar si dos ubicacion son la misma
      */
    public isSameLocation(locationA: GeoPosition, locationB: GeoPosition): boolean {

        const distance = this.getDistanceFromTwoPositions(locationA, locationB);

        return distance < this.ACCURACY_THRESHOLD;
    }

    /**
     * Permite obtener la unidad de medida de distancia segun la ubicacion del usuario
     * NOTA: No deberia hacerse en base a es Colombia o Usa
     *
     * @author Jhonier Gaviria M. - Ago. 22-2018
     * @version 1.0.0
     */
    public getMetricUnit(): string {
        if (this.isColombia()) {
            return 'km';
        } else {
            return 'mi';
        }
    }

    /**
    * Permite obtener la distancia entre 2 posiciones,
    * el resultado lo devuelve en metros
    */
    public getDistanceFromTwoPositions(locationA: GeoPosition, locationB: GeoPosition) {

        const getDistanceFromLatLonInKm = (lat1, lon1, lat2, lon2) => {
            const R = 6371; // Radius of the earth in km
            const dLat = deg2rad(lat2 - lat1);  // deg2rad below
            const dLon = deg2rad(lon2 - lon1);
            const a =
                Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
                Math.sin(dLon / 2) * Math.sin(dLon / 2)
                ;
            const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            const d = R * c; // Distance in km
            return d;
        };

        const deg2rad = (deg) => {
            return deg * (Math.PI / 180);
        };

        // calculamos y lo pasamos a metros y el resultado es el que devolvemos
        return getDistanceFromLatLonInKm(locationA.lat, locationA.lng, locationB.lat, locationB.lng) * 1000;
    }

    /**
     * Permite verificar si el pais es estados unidos
     */
    public isUSA(country: string = null): boolean {

        // en caso de que no hayamos recibido el argumento
        if (country === null) {
            // en caso de que no hayamos cargado el pais
            if (this.currentCountry === null) {
                return true; // por defecto tomamos como si el usuario estuviera en USA
            }

            // clonamos la varible para que no vaya a ser afectada
            country = this.utils.clone(this.currentCountry);
        }

        if (country.trim() === 'United States' ||
            country.trim() === 'Estados Unidos' ||
            country.trim() === 'United States of America' ||
            country.trim() === 'EE. UU.' ||
            country.trim() === 'USA' ||
            country.trim() === 'UnitedStates') {
            return true;
        }

        return false;
    }
    /**
     * indica si se debe usar el formato de modena de colombia
     */
    public useColombianCurrency(): boolean {
        let country = sessionStorage.getItem(AppConstants.SESSIONS_STORAGE_DATA.USER_COUNTRY);
        const restaurant = this.orderServices.getRestaurant();
        if (restaurant && restaurant['address'] && restaurant['address']['countryName'] && restaurant['address']['countryName'] !== '') {
            country = restaurant['address']['countryName'];
        }
        return this.isColombia(country);
    }

    /**
     * Permite verificar si el usuario esta ubicado en colombia
     */
    public isColombia(country: string = null): boolean {
        // en caso de que no hayamos recibido el argumento
        if (country === null) {
            this.currentCountry = sessionStorage.getItem(AppConstants.SESSIONS_STORAGE_DATA.USER_COUNTRY);
            // en caso de que no hayamos cargado el pais
            if (this.currentCountry === null) {
                return false;
            }

            return this.currentCountry.trim().toLowerCase() === 'colombia';
        }

        return country.trim().toLowerCase() === 'colombia';
    }

    /**
     * Permite obtener el ultimo nombre del ultimos pais encontrado
     */
    public getCurrentCountry(): string {
        return this.currentCountry;
    }

    /**
     * Permite obtener el ultimo nombre del ultimos pais encontrado
     */
    public getClientId(): string {
        return this.clientId;
    }
    /**
     * Permite obtener la ultima posición generada
     */
    public getCurrentLocation(): GeoPosition {
        return this.currentLocation;
    }

    /**
     * @author Anderson Barreto B. - Feb. 24-2020
     * @description obtiene los datos de la localizacion del usuario desde googlemaps
     * @version 1.0.0
     */
    private getUserLocationDataFromGoogleMaps() {

        this.mapsAPILoader.load().then(() => {
            const geoCoder = new google.maps.Geocoder;
            const latlng = { lat: this.currentLocation.lat, lng: this.currentLocation.lng };
            geoCoder.geocode({ 'location': latlng, 'region': 'es' }, (results, status) => {

                if (status === 'OK') {
                    const address = this.buildAddressFromGoogleGeoCode(results[0]);
                    this.userLocationData = {
                        location: {
                            lat: this.currentLocation.lat,
                            lng: this.currentLocation.lng,
                        },
                        zipCode: address.zip_code,
                        country: address.country_name,
                        state: address.state_name,
                        city: address.city_name
                    };
                }
            });
        });


    }

    /**
     * @author William Alarcón. - Marz. 11-2020
     * @version 1.0.0
     * Apartir del resultado de la funcion geocoder.geocode se construye la informacion
     * de una direccion 
     *      id: null,
            street: '',
            suit_number: '',
            zip_code: '',
            city_name: '',
            state_name: '',
            country_name: '',
            latitude: null,
            longitude: null,
            formatted: 

            @params {Object} resultGeoCode Arreglo 
                            address_components: 0: {long_name: "12336", short_name: "12336", types: ...  }
                            formatted_address 12336 Kirby Smith Rd, Orlando, FL 32832, EE. UU.
                            geometry {}
                            place_id String
                            plus_code {}
                            types []
     */
    public buildAddressFromGoogleGeoCode(resultGeoCode): any {
        const addressComponentsGoogle = resultGeoCode.address_components;
        const formattedAddress = resultGeoCode.formatted_address;
        const address = {
            id: null,
            street: '',
            suit_number: '',
            zip_code: '',
            city_name: '',
            state_name: '',
            country_name: '',
            latitude: null,
            longitude: null,
            formatted: ''
        };
        let street = '';
        let route = '';
        for (const comp of addressComponentsGoogle) {
            if (comp.types[0]) {
                // verificamos si es codigo postal
                if (comp.types[0] === 'postal_code') {
                    address.zip_code = comp.long_name;
                }
                // verificamos si es pais
                if (comp.types[0] === 'country') {
                    address.country_name = comp.long_name;
                }
                // verificamos si es departamento
                if (comp.types[0] === 'administrative_area_level_1') {
                    address.state_name = comp.long_name;
                }
                // verificamos si es municipio
                if (comp.types[0] === 'locality') {
                    address.city_name = comp.long_name;
                }

                // verificamos si es municipio
                if (comp.types[0] === 'administrative_area_level_2' && address.city_name.length == 0) {
                    address.city_name = comp.long_name;
                }
                // verificamos si es carrera
                if (comp.types[0] === 'route') {
                    route = comp.long_name;
                }
                // verificamos si es calle
                if (comp.types[0] === 'street_number') {
                    street = comp.long_name;
                }
            }
        }

        // asignamos este valor despues de iterar el arreglo
        address.street = (street + ' ' + route).trim();

        // aki en caso de no haber detectado el address street miramos si lo podemos obtener
        if (address.street === '' && formattedAddress !== null) {
            const addressSplit = formattedAddress.split(',');
            if (addressSplit.length > 3 && addressSplit[0]) {
                address.street = addressSplit[0];
            }
        }

        return address;
    }
    /**
     * @author William Alarcon. - Abri. 28-2020
     * @description Obtiene la dirección actual y la retorna
     * @version 1.0.0
     */
    public getCurrentAddres(): Promise<ResultAutoComplete> {
        return new Promise(resolve => {
            console.log("con la promesa de getCurrentAdress", resolve);
            const that = this;
            const geocoder = new google.maps.Geocoder();
            console.log("geocoder", geocoder)
            // const userPosition = this.userLocation.getCurrentLocation();
            const tmpLocation = sessionStorage.getItem(AppConstants.SESSIONS_STORAGE_DATA.USER_POSITION);
            const tmpLocat = JSON.parse(tmpLocation);
            const userPosition = new GeoPosition();
            userPosition.lat = tmpLocat.lat;
            userPosition.lng = tmpLocat.lng;
            if (userPosition !== null) {
                const result = new ResultAutoComplete();
                result.coords = userPosition;
                geocoder.geocode({ 'location': userPosition }, function (results, status) {

                    // console.info("DIRECCION POR GPS: ", results);

                    if (status === 'OK') {

                        // organizamos los datos para devolverlos
                        const address = that.buildAddressFromGoogleGeoCode(results[0]);

                        // como es la ubicacion del usuario, entonces es el mismo pais
                        result.isSameCountry = true;

                        // asignamos los datos faltantes
                        address.formatted = results[0].formatted_address;
                        address.latitude = userPosition.lat;
                        address.longitude = userPosition.lng;

                        // asignamos la direccion encontrada como tal
                        result.address = address;
                        result.place = results[0];
                        resolve(result);
                    } else {
                        resolve(null);
                    }
                });
            } else {
                resolve(null);
            }
        });
    }

    /**
     * @author Anderson Barreto B. - Feb. 24-2020
     * @description accede a los datos de localizacion del usuario ya almacenados
     * @version 1.0.0
     */
    public getUserLocationData() {
        return this.userLocationData;
    }
    /**
     * Se consulta el pais por la ip y con este se obtiene la misma por si la ubicacion no fallo
     */
    public getCountryIp() {
        this.userServices.getCountryFromIp().subscribe((response: ResponsesStructure) => {
            const dataResponse: SearchIpStructureResponse = response.data;
            this.clientId = dataResponse.clientId;
            if (!this.currentCountry) {
                sessionStorage.setItem('Usa', dataResponse.country);
            }
        });
    }

    /**
     * Funcion que valida que determinada ubicacion este en el rango a la redonda del restaurante
     * @param radius El radio configurado del restaurante
     * @param center El centro de donde empieza a medir, es la ubicacion del restaurante
     * @param latLgn Es la ubicacion del usuario
     */
    public restaurantContainsUserUbication(radius, center, latLgn) {
        return new Promise(resolve => {
            const cityCircle = new google.maps.Circle({
                center: center,
                radius: radius
            });

            const isInsideRange = cityCircle.getBounds().contains(latLgn)
            // && google.maps.geometry.spherical.computeDistanceBetween(cityCircle.getCenter(), latLgn);
            resolve(isInsideRange)
        });
    }

    /**
    * Funcion que calcula la distancia entre dos distancias
    * @param positionRestaurant ubicacion del restaurante
    * @param userPosition ubicacion del usuario
    */
    public getDistanceMatrixService(positionRestaurant, userPosition) {
        return new Promise(resolve => {
            const service = new google.maps.DistanceMatrixService();

            service.getDistanceMatrix(
                {
                    origins: [positionRestaurant],
                    destinations: [userPosition],
                    travelMode: 'DRIVING',
                    unitSystem: google.maps.UnitSystem.METRIC
                },
                callback
            );
            function callback(response, status) {
                const data = {
                    response: response, status: status
                }
                resolve(data);
            }
        });
    }
}
