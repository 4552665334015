import { AuthInterceptor, DEFAULT_TIMEOUT } from './core/services/auth-interceptor';
import {
    AuthServiceConfig,
    FacebookLoginProvider,
    GoogleLoginProvider,
    SocialLoginModule
}
    from 'angularx-social-login';
import { BrowserModule, Title } from '@angular/platform-browser';
import { CommonModule, DecimalPipe, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { IConfig, NgxMaskModule } from 'ngx-mask';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { RECAPTCHA_V3_SITE_KEY, ReCaptchaV3Service, RecaptchaModule } from 'ng-recaptcha';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { AddressDeliveryModalComponent } from './shared/components/address-delivery-modal/address-delivery-modal.component';
import { AppComponent } from './app.component';
import { AppConstants } from './app-constants';
import { AppRoutingModule } from './app-routing.module';
import { Auth } from './core/services/auth-service';
import { AutosizeDirective } from 'angular-autosize';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { CalendarModule } from 'primeng/primeng';
import { ClientsAccompanimentService } from './core/services/clients-accompaniment.service';
import { ConfigComponent } from './core/config/config';
import { ContactUsService } from './core/services/contact-us.service';
import { CouponsService } from './core/services/coupons.service';
import { CreditCardService } from './core/services/credit-card.service';
import { Currency } from 'app/shared/pipes/currency.pipe';
import { DLoginComponent } from './shared/dynamic-modules/login/DLogin.component';
import { DLoginModule } from './shared/dynamic-modules/login/DLogin.module';
import { DeferredOrderComponent } from 'app/feature/views/detail-restaurant/components/deferred-order/deferred-order.component';
import { DeliveryService } from './core/services/delivery.service';
import { DialogAddTipModalComponent } from './shared/dynamic-modules/add-tip-modal/dialog-add-tip.modal.component';
import { DialogAddTipModule } from './shared/dynamic-modules/add-tip-modal/dialog-add-tip.modal.module';
import { DialogAddressComponent } from './shared/dynamic-modules/address-modal/dialog-address-modal.component';
import { DialogAddressModule } from './shared/dynamic-modules/address-modal/dialog-address-modal.module';
import { DialogBranchRestaurantsModalComponent } from './shared/dynamic-modules/branch-restaurants-modal/dialog-branch-restaurants-modal.component';
import { DialogBranchRestaurantsModalModule } from './shared/dynamic-modules/branch-restaurants-modal/dialog-branch-restaurants-modal.module';
import { DialogCancelOrderComponent } from './shared/dynamic-modules/cancel-order/dialog-cancel-order.component';
import { DialogCancelOrderModule } from './shared/dynamic-modules/cancel-order/dialog-cancel-order.module';
import { DialogCartComponent } from './shared/dynamic-modules/cart/dialog-cart.component';
import { DialogCartModule } from './shared/dynamic-modules/cart/dialog-cart.module';
import { DialogCheckoutComponent } from './shared/dynamic-modules/checkout/dialog-checkout.component';
import { DialogCheckoutModule } from './shared/dynamic-modules/checkout/dialog-checkout.module';
import { DialogCouponCodeComponent } from './shared/dynamic-modules/coupon-code/dialog-coupon-code.component';
import { DialogCouponCodeModule } from './shared/dynamic-modules/coupon-code/dialog-coupon-code.module';
import { DialogDetailCouponModalComponent } from './shared/dynamic-modules/detail-coupon-modal/dialog-detail-coupon-modal.component';
import { DialogDetailCouponModalModule } from './shared/dynamic-modules/detail-coupon-modal/dialog-detail-coupon-modal.module';
import { DialogDirectionsComponent } from './shared/dynamic-modules/directions-modal/dialog-directions-modal.component';
import { DialogDirectionsModalModule } from './shared/dynamic-modules/directions-modal/dialog-directions-modal.module';
import { DialogItemDetailsComponent } from './shared/dynamic-modules/item-details/dialog-item-details.component';
import { DialogItemDetailsModule } from './shared/dynamic-modules/item-details/dialog-item-details.module';
import { DialogNotificationDetailComponent } from './shared/dynamic-modules/notification-detail/dialog-notification-detail.component';
import { DialogNotificationDetailModule } from './shared/dynamic-modules/notification-detail/dialog-notification-detail.module';
import { DialogNotificationProductsComponent } from './shared/dynamic-modules/notification-product/dialog-notification-products.component';
import { DialogNotificationProductsModule } from './shared/dynamic-modules/notification-product/dialog-notification-products.module';
import { DialogPrivacyPolicyComponent } from './shared/dynamic-modules/privacy-policy/dialog-privacy-policy.component';
import { DialogPrivacyPolicyModule } from './shared/dynamic-modules/privacy-policy/dialog-privacy-policy.module';
import { DialogRegisterComponent } from './shared/dynamic-modules/register/dialog-register.component';
import { DialogRegisterModule } from './shared/dynamic-modules/register/dialog-register.module';
import { DialogReviewsModalComponent } from './shared/dynamic-modules/reviews-modal/dialog-reviews-modal.component';
import { DialogReviewsModalModule } from './shared/dynamic-modules/reviews-modal/dialog-reviews-modal.module';
import { DialogServiceTimeRestComponent } from './shared/dynamic-modules/service-time-rest/dialog-service-time-rest.component';
import { DialogServiceTimeRestModule } from './shared/dynamic-modules/service-time-rest/dialog-service-time-rest.module';
import { DialogTermsAndContiditionsComponent } from './shared/dynamic-modules/terms-and-conditions/dialog-terms-and-conditions.component';
import { DialogTermsAndContiditionsModule } from './shared/dynamic-modules/terms-and-conditions/dialog-terms-and-conditions.module';
import { DynamicComponentLoaderModule } from './core/dynamic-loader/dynamic-component-loader.module';
import { DynamicComponentManifest } from './core/dynamic-loader/dynamic-component-manifest';
import { DynamicModalsService } from './core/services/dynamic-modals.service';
import { EmailService } from './core/services/email.service';
import { ErrorService } from './core/services/error.service';
import { ExternalMenuService } from './core/services/external-menu.service';
import { FaviconService } from './core/services/favicon.service';
import { FilterSearchComponent } from './shared/components/filterSearch/filterSearch.component';
import { FooterComponent } from './feature/principal-layouts/footer/footer.component';
import { GatewayService } from './core/services/gateway.service';
import { GlobalErrorHandler } from 'app/core/services/global-error-handler';
import { GlobalEventsService } from './core/services/globalEvents.service';
import { GoogleMapsAPIWrapper } from '@agm/core';
import { HeaderComponent } from './feature/principal-layouts/header/header.component';
import { HttpClient } from '@angular/common/http';
import { ImageCropperModule } from 'ngx-image-cropper';
import { ImagesUtilsComponent } from './utils/imagesUtils.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ItemDetailsEventsService } from './utils/itemDetailsEvents.service';
import {
    LanguageComponent
}
    from './feature/views/profile/modals/language/language.component';
import { LoggingService } from './core/services/logging.service';
import { MenuesService } from './core/services/menues.service';
import { MessagesService } from './core/services/messages.service';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ModifiersProductsService } from './core/services/modifiers-products.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NotificationService } from './core/services/notification-service';
import { OrderProvider } from './core/services/order.service';
import { PaymentGatewayComponent } from './feature/views/payment-gateway/payment-gateway/payment-gateway.component';
import { PaymentGatewayModule } from './feature/views/payment-gateway/payment-gateway.module';
import { PhoneOrWebService } from './core/services/phone-or-web.service'
import { ProfileService } from './core/services/profile.service';
import { RequestCacheService } from './core/services/request-cache.service';
import { RestaurantService } from './core/services/restaurants.service';
import { ReviewsService } from './core/services/reviews.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SharedModule } from './shared/shared.module';
import { SplashService } from './core/services/splash.service';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { SweetAlertService } from './utils/sweetAlert.service';
import { TaxesService } from './core/services/taxes.service';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { ToastrModule } from 'ngx-toastr';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslatorModule } from './translator/translator.module';
import { UserLocationProvider } from './utils/userLocationService';
import { UserServiceProvider } from './core/services/user-service';
import { UtilsComponent } from './utils/utils.component';
import { UtilsSocialNetwork } from './utils/utilsSocialNetwork';
import { VisibleButtonDeferredService } from "app/core/services/visible-button-deferred.service";
import { autoCompleteMenuComponent } from './shared/components/autoComplete/autoCompleteMenu.component';
import { environment } from 'environments/environment';

export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient, './assets/locales/', '.json');
}

const manifests: DynamicComponentManifest[] = [
    {
        componentId: 'login',
        path: 'login',
        loadChildren: () => import('./shared/dynamic-modules/login/DLogin.module').then(m => m.DLoginModule)
    },
    // {
    //     componentId: 'language',
    //     path: 'language',
    //     loadChildren: './dynamic-modules/language/DLanguage.module#DLanguageModule'
    // },
    {
        componentId: 'register',
        path: 'register',
        loadChildren: () => import('./shared/dynamic-modules/register/dialog-register.module').then(m => m.DialogRegisterModule)
    },
    {
        componentId: 'items-cart',
        path: 'items-cart',
        loadChildren: () => import('./shared/dynamic-modules/item-details/dialog-item-details.module').then(m => m.DialogItemDetailsModule)
    },
    // {
    //     componentId: 'cart',
    //     path: 'cart',
    //     loadChildren: './dynamic-modules/cart/dialog-cart.module#DialogCartModule'
    // },
    {
        componentId: 'checkout',
        path: 'checkout',
        loadChildren: () => import('./shared/dynamic-modules/checkout/dialog-checkout.module').then(m => m.DialogCheckoutModule)
    },
    {
        componentId: 'checkout',
        path: 'checkout',
        loadChildren: () => import('./shared/dynamic-modules/checkout/dialog-checkout.module').then(m => m.DialogCheckoutModule)
    },
    {
        componentId: 'add-tip',
        path: 'add-tip',
        loadChildren: () => import('./shared/dynamic-modules/add-tip-modal/dialog-add-tip.modal.module').then(m => m.DialogAddTipModule)
    },
    {
        componentId: 'directions-modal',
        path: 'directions-modal',
        loadChildren: () => import('./shared/dynamic-modules/directions-modal/dialog-directions-modal.module').then(m => m.DialogDirectionsModalModule)
    },
    {
        componentId: 'reviews-modal',
        path: 'reviews-modal',
        loadChildren: () => import('./shared/dynamic-modules/reviews-modal/dialog-reviews-modal.module').then(m => m.DialogReviewsModalModule)
    },
    {
        componentId: 'time-rest',
        path: 'time-rest',
        loadChildren: () => import('./shared/dynamic-modules/service-time-rest/dialog-service-time-rest.module').then(m => m.DialogServiceTimeRestModule)
    },
    {
        componentId: 'notification-product',
        path: 'notification-product',
        loadChildren: () => import('./shared/dynamic-modules/notification-product/dialog-notification-products.module').then(m => m.DialogNotificationProductsModule)
    },
    {
        componentId: 'privacy-policy',
        path: 'privacy-policy',
        loadChildren: () => import('./shared/dynamic-modules/privacy-policy/dialog-privacy-policy.module').then(m => m.DialogPrivacyPolicyModule)
    },
    {
        componentId: 'terms-and-conditions',
        path: 'terms-and-conditions',
        loadChildren: () => import('./shared/dynamic-modules/terms-and-conditions/dialog-terms-and-conditions.module').then(m => m.DialogTermsAndContiditionsModule)
    },
    {
        componentId: 'home-map',
        path: 'home-map', // some globally-unique identifier, used internally by the router
        loadChildren: () => import('./shared/dynamic-modules/home-map/home-map.module').then(m => m.HomeMapModule)
    },
    {
        componentId: 'notification-detail',
        path: 'notification-detail',
        loadChildren: () => import('./shared/dynamic-modules/notification-detail/dialog-notification-detail.module').then(m => m.DialogNotificationDetailModule)
    },
    {
        componentId: 'branch-restaurants-modal',
        path: 'branch-restaurants-modal',
        loadChildren: () => import('./shared/dynamic-modules/branch-restaurants-modal/dialog-branch-restaurants-modal.module').then(m => m.DialogBranchRestaurantsModalModule)
    },
    {
        componentId: 'detail-coupon-modal',
        path: 'detail-coupon-modal',
        loadChildren: () => import('./shared/dynamic-modules/detail-coupon-modal/dialog-detail-coupon-modal.module').then(m => m.DialogDetailCouponModalModule)
    },
    {
        componentId: 'coupon-code',
        path: 'coupon-code',
        loadChildren: './shared/dynamic-modules/coupon-code/dialog-coupon-code.module#DialogCouponCodeModule'
    },
    {
        componentId: 'cancel-order',
        path: 'cancel-order',
        loadChildren: () => import('./shared/dynamic-modules/cancel-order/dialog-cancel-order.module').then(m => m.DialogCancelOrderModule)
    },
    {
        componentId: 'address-modal',
        path: 'address-modal',
        loadChildren: () => import('./shared/dynamic-modules/address-modal/dialog-address-modal.module').then(m => m.DialogAddressModule)
    },
    {
        componentId: 'cart',
        path: 'cart',
        loadChildren: () => import('./shared/dynamic-modules/cart/dialog-cart.module').then(m => m.DialogCartModule)
    }
];
export function SocialNetworkFactory(): AuthServiceConfig {
    return new AuthServiceConfig([
        {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider('859283234719-stm87d5oohqv2uov2641kao63unied2n.apps.googleusercontent.com')
        },
        {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.facebookID)
        }
    ]);
}
export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;
@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        // FormDeliveryColombiaComponent,
        FooterComponent,
        FilterSearchComponent,
        AutosizeDirective,
        LanguageComponent,
        AddressDeliveryModalComponent
    ],
    imports: [
        CommonModule,
        DLoginModule,
        // DLanguageModule,
        MatDialogModule,
        DynamicComponentLoaderModule.forRoot(manifests),
        BrowserModule,
        ImageCropperModule,
        CalendarModule,
        SharedModule,
        InfiniteScrollModule,
        BsDatepickerModule.forRoot(),
        TimepickerModule.forRoot(),
        ToastrModule.forRoot(),
        ModalModule.forRoot(),
        RecaptchaModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        SocialLoginModule,
        TranslatorModule,
        AppRoutingModule,
        DLoginModule,
        DialogRegisterModule,
        DialogItemDetailsModule,
        DialogCartModule,
        DialogCheckoutModule,
        DialogAddressModule,
        DialogServiceTimeRestModule,
        DialogNotificationProductsModule,
        DialogPrivacyPolicyModule,
        DialogTermsAndContiditionsModule,
        DialogDirectionsModalModule,
        DialogReviewsModalModule,
        DialogNotificationDetailModule,
        DialogDetailCouponModalModule,
        DialogAddTipModule,
        DialogBranchRestaurantsModalModule,
        SweetAlert2Module.forRoot(),
        DialogCouponCodeModule,
        DialogCancelOrderModule,
        ServiceWorkerModule.register('/ngsw-worker.js', { enabled: environment.production }),
        NgxMaskModule.forRoot(),
        NgbModule,
        NgxSpinnerModule,
        PaymentGatewayModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
        }),
       // MatSnackBarModule
    ],
    providers: [
        Title,
        UtilsSocialNetwork,
        DecimalPipe,
        HeaderComponent,
        // FormDeliveryColombiaComponent,
        RestaurantService,
        ConfigComponent,
        OrderProvider,
        SweetAlertService,
        AppComponent,
        UtilsComponent,
        ImagesUtilsComponent,
        MenuesService,
        GoogleMapsAPIWrapper,
        CreditCardService,
        Auth,
        ProfileService,
        ReviewsService,
        ModifiersProductsService,
        TaxesService,
        GatewayService,
        SplashService,
        GlobalEventsService,
        ItemDetailsEventsService,
        AppConstants,
        UserServiceProvider,
        UserLocationProvider,
        MessagesService,
        NotificationService,
        EmailService,
        Currency,
        VisibleButtonDeferredService,
        PhoneOrWebService,
        {
            // http.get(..., { headers: new HttpHeaders({ timeout: `${20000}` }) });
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        { 
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        
        },
        {
            provide: RECAPTCHA_V3_SITE_KEY,
            useValue: '6LctwgAVAAAAAFhh1W8s7JD8RVPdi9Vr23cL7ZYP' },
        {
            provide: DEFAULT_TIMEOUT,
            useValue: 30000
        },
        // {
        //     provide: HTTP_INTERCEPTORS,
        //     useClass: CachingInterceptorService,
        //     multi: true
        // },
        {
            provide: LocationStrategy, useClass: PathLocationStrategy
        },
        {
            provide: AuthServiceConfig,
            useFactory: SocialNetworkFactory
        },
        {
            provide: MatDialogRef,
            useValue: {}
        },
        // RecaptchaService,
        { provide: ReCaptchaV3Service },
        Title,
        GlobalErrorHandler,
        {provide: 'AuthServices', useClass: Auth},
        {provide: 'ClientsAccompanimentServices', useClass: ClientsAccompanimentService},
        {provide: 'ContactUsServices', useClass: ContactUsService},
        {provide: 'CouponsServices', useClass: CouponsService},
        {provide: 'DeliveryServices', useClass: DeliveryService},
        {provide: 'DynamicModalServices', useClass: DynamicModalsService},
        {provide: 'ErrorServices', useClass: ErrorService},
        {provide: 'EmailServices', useClass: EmailService},
        {provide: 'ExternalMenuServices', useClass: ExternalMenuService},
        {provide: 'FaviconServices', useClass: FaviconService},
        {provide: 'CreditCardsServices', useClass: CreditCardService},
        {provide: 'LoggingServices', useClass: LoggingService},
        {provide: 'MenuesServices', useClass: MenuesService},
        {provide: 'MessagesServices', useClass: MessagesService},
        {provide: 'ModifiersServices', useClass: ModifiersProductsService},
        {provide: 'NotificationServices', useClass: NotificationService},
        {provide: 'OrderServices', useClass: OrderProvider},
        {provide: 'ProfileServices', useClass: ProfileService},
        {provide: 'RequestCacheServices', useClass: RequestCacheService},
        {provide: 'RestaurantsServices', useClass: RestaurantService},
        {provide: 'ReviewsServices', useClass: ReviewsService},
        {provide: 'TaxesServices', useClass: TaxesService},
        {provide: 'UserServices', useClass: UserServiceProvider},
        {provide: 'VisibleButtonDeferredService', useClass: VisibleButtonDeferredService},
        {provide: 'PhoneOrWebService', useClass: PhoneOrWebService},
    ],
    bootstrap: [AppComponent],
    exports: [DialogTermsAndContiditionsModule],
    entryComponents: [
        autoCompleteMenuComponent,
        DLoginComponent,
        // DLanguageComponent,
        DialogRegisterComponent,
        DialogItemDetailsComponent,
        DialogCartComponent,
        DialogCheckoutComponent,
        DialogAddressComponent,
        DialogServiceTimeRestComponent,
        DeferredOrderComponent,
        DialogCouponCodeComponent,
        DialogNotificationProductsComponent,
        DialogTermsAndContiditionsComponent,
        DialogPrivacyPolicyComponent,
        DialogDirectionsComponent,
        DialogReviewsModalComponent,
        DialogNotificationDetailComponent,
        DialogDetailCouponModalComponent,
        DialogBranchRestaurantsModalComponent,
        DialogCancelOrderComponent,
        DialogAddTipModalComponent,
        PaymentGatewayComponent
    ]

})

export class AppModule {
}
