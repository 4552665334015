import { GeoPosition } from 'app/core/entities/GeoPosition';
import { ResultAutoComplete } from 'app/core/entities/ResultAutoComplete';
import { GlobalEventsService } from 'app/core/services/globalEvents.service';
import { UserLocationProvider } from 'app/utils/userLocationService';
import { UtilsComponent } from 'app/utils/utils.component';

import { MapsAPILoader } from '@agm/core';
import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, AfterContentChecked, Inject }
        from '@angular/core';
import { AppConstants } from 'app/app-constants';
import { AuthServices } from 'app/core/interfaces/auth.interfaces';
import { ResponsesStructure } from 'app/core/interfaces/transversal.interfaces';
import { ProfileAddressStructureResponse, ProfileServices } from 'app/core/interfaces/profile.interfaces';

// Variable para manejo autocompletar location
declare var google: any;

@Component({
    selector: 'auto-complete-menu',
    templateUrl: './autoCompleteMenu.component.html',
    styleUrls: ['./autoCompleteMenu.component.css']
})
export class autoCompleteMenuComponent implements OnDestroy, AfterContentChecked {

    public isFocus: boolean;
    public predictions: Array<any>;
    public addresses: Array<any>;
    public showAddress: boolean;
    public onSelect: EventEmitter<ResultAutoComplete>;

    subsChangeAddressEvent: any;
    subsLoginEvent: any;
    subsLogoutEvent: any;

    private serviceAutoComp: any;

    /**
    * Variable que contiene la latitud y la longitud del usuario
    */
    private defaultBounds: string;

    /**
     * let us know if mapsApi is loaded
     */
    private isApiLoaded: boolean;

    constructor(
        private readonly _loader: MapsAPILoader,
        private readonly chRef: ChangeDetectorRef,
        private readonly userLocation: UserLocationProvider,
        @Inject('ProfileServices') private readonly profileServices: ProfileServices,
        private readonly globalService: GlobalEventsService,
        @Inject('AuthServices') private readonly authService: AuthServices,
        public utils: UtilsComponent)
    {
        this.isFocus = false;
        this.showAddress = false;
        this.predictions = [];
        this.addresses = [];
        this.onSelect = new EventEmitter<ResultAutoComplete>();
        this.isApiLoaded = false;
        this.subsChangeAddressEvent = null;
        this.subsLoginEvent = null;
        this.subsLogoutEvent = null;

        this.globalService.selectCurrentLocation.subscribe(() => {
            this.selectCurrentLocation();
        });
    }

    /**
     * @see {@link https://angular.io/guide/lifecycle-hooks#lifecycle-hooks Lifecycle Hooks Docs}
     */
    ngAfterContentChecked(): void {
        //Called after every check of the component's or directive's content.
        //Add 'implements AfterContentChecked' to the class.
        if (!this.isApiLoaded) {
            this._loader.load().then(() => {
                this.isApiLoaded = true;
                this.serviceAutoComp = new google.maps.places.AutocompleteService();
            });
        }
    }

    /**
     * Se ejecuta cuando el componente se remueve
     */
    ngOnDestroy() {

        // en caso de que la variable este escuchando el evento
        // desanclamos el evento y lo destruimos
        if (this.subsChangeAddressEvent !== null) {
            this.subsChangeAddressEvent.unsubscribe();
            this.subsChangeAddressEvent = null;
        }

        // en caso de que la variable este escuchando el evento
        // desanclamos el evento y lo destruimos
        if (this.subsLoginEvent !== null) {
            this.subsLoginEvent.unsubscribe();
            this.subsLoginEvent = null;
        }

        // en caso de que la variable este escuchando el evento
        // desanclamos el evento y lo destruimos
        if (this.subsLogoutEvent !== null) {
            this.subsLogoutEvent.unsubscribe();
            this.subsLogoutEvent = null;
        }
        this.isFocus = null;
        this.predictions = null;
        this.addresses = null;
        this.showAddress = null;
    }

    /**
     * Permite cargar las direcciones de un usuario logueado
     */
    private loadAddress() {
        if (this.authService.getToken() !== null) {
            this.profileServices.load()
                .subscribe((response: ResponsesStructure) => {
                    const dataResponse: ProfileAddressStructureResponse[] = response.data;
                    if (response.errors || dataResponse.length === 0) {
                        this.showAddress = false;
                    } else {
                        this.addresses = dataResponse;
                        // recorremos las direcciones, para eliminarles el pais en caso de que estas esten fuera del pais al del usuario
                        // tslint:disable-next-line:forin
                        for (const i in this.addresses) {
                            if (this.isSameCountry(this.addresses[i].countryName)) {
                                this.addresses[i].naturalAddress = this.removeCountry(this.addresses[i].naturalAddress);
                            }
                        }
                    }
                });
        }
    }

    /**
     *
     * @param status
     */
    public enableShowAddress(status: boolean) {
        this.showAddress = status;
        if (status) {
            this.loadAddress();
            this.subsChangeAddressEvent = this.globalService.changeAddress.subscribe(() => {
                this.loadAddress();
            });
            this.subsLoginEvent = this.globalService.login.subscribe(() => {
                this.loadAddress();
            });
            this.subsLogoutEvent = this.globalService.logout.subscribe(() => {
                this.addresses = [];
            });
        }
    }

    /**
     * Permite actualizar el valor del focu
     * @param status
     */
    public setFocus(status: boolean): void {
        this.isFocus = status;
    }


    /**
     * Permite recorrer el arreglo devuelto de google del listado de predicciones del usuario sobre una busqueda
     */
    private filterPredictions(predictions: Array<any>) {
        for (const i in predictions) {
            if (predictions.hasOwnProperty(i)) {
                predictions[i].description = this.removeCountry(predictions[i].description, true);
            }
        }
        return predictions;
    }

    /**
     * Permite hacer una busqueda de una ubicación a partir de un texto ingresado
     * @param inputSearch
     */
    public loadPredictions(inputSearch: string): boolean {

        this.predictions = [];

        // validamos que haya texto ingresado
        if (inputSearch.trim() === '') {
            return false;
        }

        // this._loader.load().then(() => {
        if (this.serviceAutoComp) {
            this.serviceAutoComp = new google.maps.places.AutocompleteService();
            const that = this;
            const userPosition = sessionStorage.getItem(AppConstants.SESSIONS_STORAGE_DATA.USER_POSITION);

            if (userPosition !== null) {
                const tmpLocat = JSON.parse(userPosition);
                const tmpLocation = new GeoPosition();
                tmpLocation.lat = tmpLocat.lat;
                tmpLocation.lng = tmpLocat.lng;
                // Seteamos la longitud y latitud del usuario para que los resultados sean mas precisos
                // respecto a la ubicación del usuario
                this.defaultBounds = new google.maps.LatLngBounds(new google.maps.LatLng(tmpLocation.lat, tmpLocation.lng));
            }
            if (this.userLocation.isColombia(sessionStorage.getItem(AppConstants.SESSIONS_STORAGE_DATA.USER_COUNTRY))) {
                this.serviceAutoComp.getPlacePredictions({
                    input: inputSearch, bounds: this.defaultBounds, strictBounds: true,
                    types: ['geocode'], componentRestrictions: { country: ['co', 'us'] }
                }, function (resultPredictions, status) {
                    that.handlerAutoCompleteService(resultPredictions, status);
                });
            } else {
                this.serviceAutoComp.getQueryPredictions({ input: inputSearch }, function (resultPredictions, status) {
                    that.handlerAutoCompleteService(resultPredictions, status);
                });
            }
        }
        // });
    }
    /**
     *  Se encarga de las predicciones al momento de estar escribiendo una dirección
     * @param resultPredictions
     * @param status
     */
    private handlerAutoCompleteService(resultPredictions, status) {
        const that = this;
        if (status !== google.maps.places.PlacesServiceStatus.OK) {
            return;
        }
        // asignamos a la variable de la clase para mostrar en la vista
        that.predictions = that.filterPredictions(that.utils.clone(resultPredictions));
        // actualizamos la vista
        if (!that.chRef['destroyed']) {
            that.chRef.detectChanges();
        }
    }
    /**
     * Este metodo se ejecuta cuando se selecciona una prediccion del menu
     */
    public selectPrediction(prediction: any) {
        const that = this;
        const service = new google.maps.places.PlacesService(document.createElement('div'));
        const result = new ResultAutoComplete();
        service.getDetails({
            placeId: prediction.place_id
        }, function callback(place, status) {
            if (status === google.maps.places.PlacesServiceStatus.OK) {
                // organizamos los datos para devolverlos
                const address = that.userLocation.buildAddressFromGoogleGeoCode(place);
                // verificamos si es del mismo pais la busqueda
                result.isSameCountry = that.isSameCountry(address.country_name);
                // asignamos los datos faltantes
                address.formatted = place.formatted_address;
                address.latitude = place.geometry.location.lat();
                address.longitude = place.geometry.location.lng();
                // para este caso como estamos buscando una direccion
                // asignamos esta para este campo
                // asignamos la direccion encontrada como tal
                result.address = address;
                const coords = new GeoPosition();
                coords.lat = place.geometry.location.lat();
                coords.lng = place.geometry.location.lng();
                result.coords = coords;
                result.place = place;
            }
            that.onSelect.emit(result);
        });
    }

    /**
     * * Este metodo se ejecuta cuando se selecciona la opcion de ubicacion actual
     */
    public selectCurrentLocation() {
        this.userLocation.getCurrentAddres().then(responseAddress => {
            if (responseAddress) {
                this.onSelect.emit(responseAddress);
            }
        });
    }

    /**
     * Permite verificar si el resultado de la busqueda es del mismo pais del usuario
     */
    public isSameCountry(countrySearched: string): boolean {
        const countryUser = this.userLocation.getCurrentCountry();
        if (countryUser == null) {
            return false;
        }
        try {
            // verificamos si el pais es el mismo o no
            if (countryUser.toLowerCase().trim() === countrySearched.toLowerCase().trim()) {
                return true;
            }
        } catch (error) {
            console.error(error);
        }
        // pasamos el paremtro de pais encontrado
        const countrySearchedIsUSA = this.userLocation.isUSA(countrySearched);
        // como no estamos pasando parametro va a tomar internamente el del usuario
        const countryUserIsUSA = this.userLocation.isUSA();
        // comparamos si el usuario y la busqueda estan en USA
        if (countrySearchedIsUSA && countryUserIsUSA) {
            return true;
        }
        return false;
    }

    /**
     * Permite eliminar el pais del formato de dirección
     */
    public removeCountry(addressFormated: string, checkCountry = false) {
        // separamos en arreglo las direcciones
        const addressSplit = addressFormated.split(',');
        if (checkCountry) {
            if (this.isSameCountry(addressSplit[addressSplit.length - 1])) {
                // eliminamos el ultimo campo que es el de pais
                addressSplit.splice(addressSplit.length - 1, 1);
            }
        } else {
            // eliminamos el ultimo campo que es el de pais
            addressSplit.splice(addressSplit.length - 1, 1);
        }
        return addressSplit.join(',');
    }
    /**
     * Permite establecer una direccion para la busqueda
     * @param address
     */
    public selectAddress(address) {
        const result = new ResultAutoComplete();
        const coords = new GeoPosition();
        coords.lat = Number(address.latitude);
        coords.lng = Number(address.longitude);
        result.coords = coords;
        result.address = {
            id: address.id,
            street: address.street,
            suit_number: address.suitNumber,
            zip_code: address.zipCode,
            city_name: address.cityName,
            state_name: address.stateName,
            country_name: address.countryName,
            latitude: Number(address.latitude),
            longitude: Number(address.longitude),
            formatted: (address.name.charAt(0).toUpperCase().concat(address.name.substr(1))).concat(' :: ').concat(address.naturalAddress)
        };
        // verificamos si es del mismo pais la busqueda
        result.isSameCountry = this.isSameCountry(address.countryName);
        this.onSelect.emit(result);
    }
}
