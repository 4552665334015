import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppConstants } from 'app/app-constants';
import { ConfigComponent } from 'app/core/config/config';
import { ResponsesStructure } from 'app/core/interfaces/transversal.interfaces';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DataError, LoggingServices } from '../interfaces/logging.interfaces';

@Injectable({
    providedIn: 'root'}
)
export class LoggingService implements LoggingServices {

    constructor(private readonly httpClient: HttpClient, private readonly config: ConfigComponent) { }

    logError(message: string, stack: string, errorType): Observable<ResponsesStructure> {
        const data: DataError = {
            message: message,
            subject: 'Error',
            typeProject: AppConstants.PROJECT_TYPE.PROJECT_OMT_WEB,
            errorType: errorType
        };
        const url = `${this.config.getUrlOmt()}sendEmail/reportError`;
        return this.httpClient.post<ResponsesStructure>(url, data).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),catchError(error => {
                return throwError(error);
            })
        );
    }
}