import { AverageWaitTimePerServiceStructureRequest, DataUser, OrderServices, OrderStructureRequest } from 'app/core/interfaces/orders.interfaces';
import { Injectable, Injector } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AppConstants } from 'app/app-constants';
import { Auth } from 'app/core/services/auth-service';
import { ConfigComponent } from 'app/core/config/config';
import { Delivery } from 'app/core/entities/Delivery';
import { GlobalEventsService } from 'app/core/services/globalEvents.service';
import { HttpClient } from '@angular/common/http';
import { IOrderExternalDeliveryProvider } from '../interfaces/order-external-delivery-provider.interfaces';
import { IPaymentGateway } from '../interfaces/payment-gateway-information.interface';
import { OrderTotals } from 'app/core/entities/OrderTotals';
import { OrderType } from 'app/core/enums/OrderType';
import { Payment } from 'app/core/entities/Payment';
import { PhoneOrderType } from 'app/core/enums/PhoneOrderType';
import { ProductSale } from 'app/core/entities/ProductSale';
import { ResponsesStructure } from 'app/core/interfaces/transversal.interfaces';
import { RestaurantOrder } from 'app/core/entities/RestaurantOrder';
import { Sale } from 'app/core/entities/Sale';
import { UtilsComponent } from 'app/utils/utils.component';

/*
 Generated class for the OrderProvider provider.

 See https://angular.io/guide/dependency-injection for more info on providers and Angular DI.
 */
// Variable para manejo de funciones jquery
declare var jQuery: any;
@Injectable()
export class OrderProvider implements OrderServices {
    /**
     * Guarda los productos seleccionados por el usuario
     */
    private items: Array<any>;
    restaurant: RestaurantOrder;
    public phoneOrderType: PhoneOrderType;
    /**
     * Almacena el tipo de servicio selecionado por el usuiario al abrir
     * el detalle de un restaurante
     */
    public serviceTypeSelected: any;
    // Variable que almacena la url de los webservices
    private urlOmt = this.config.getUrlOmt();
    public noteGeneralOrder: any = '';
    public maxCharacters: any = 200;

    // variable que contiene los detalles de totales y taxes de la orden
    public orderDetail: OrderTotals;

    public dataUser: DataUser = {
        name: '',
        phone: '',
        email: '',
        externalMenu: false,
        externalMenuColor: ''
    };

    public tip: number;

    public payment: Payment;

    // Variable para almacenar los datos de domicilio
    public deliveryDetail: Delivery;
    // Variable para almacenar el estado de contraentrega
    public uponDelivery: any;
    public upon: boolean = true;
        
    /**
     * Propiedad que almacena el turno o numero de mesa en DineIn
     */
    public turn: string;

    /**
     * Variable para almacenar los cupones aplicados a la orden
     */
    coupons: any[];

    /**
     * Clave para el almacenamiento de items en el localStorage (lc)
     */
    private lsCartitems: string;
    /**
    * Clave para el almacenamiento del tipo de orden en el localStorage (lc)
    */
    private lsCartTypeOrder: string;
    /**
    * Clave para el almacenamiento del restaurante en el localStorage (lc)
    */
    private lsRestaurant: string;
    /**
    * Clave para el almacenamiento de la información de delivery en el localStorage (lc)
    */
    private lsDeliveryDetail: string;
    /**
    * Clave para el almacenamiento del tipo de servicio en el localStorage (lc)
    */
    private lsServiceTypeSelected: String;

    /**
     * Propiedad que contiene el piso seleccionado cuando llegan por parametros por medio de codigo QR
     */
     public selectedFloor: string;
     /**
      * Propiedad que contiene la mesa seleccionada cuando llegan por parametros por medio de codigo QR
      */
     public selectedTable: string;

    /* Almacena lo que ingresa el usuario en la modal de mesa o lo que lleue por parametro de la url
     *   nombre de paremetro table=x 
     */
    public selectedTableOrTurn: string;

    /**
     * Variable para definir los campos del calendario y la hora del componente orden diferida y para colocar la fehca y hora en modales para información
     */
    public dataDeferred: any = {
        dataDeferredInfo: null,
        timeDeferredInfo: null,
        dateDeferred: null,
        timeDeferred: null,
    };

    public currentTime: any = {};
    /**
     *  variable global para agragar todos los datos de los productos que se enviar al ws de order
     */
    public productSale: any;

    /**
     * variable para guardar el token que se genera al usar la pasarela de pago con dejavoo
     */
    public paymentTokenId: string;

    constructor(public http: HttpClient,
        public utils: UtilsComponent,
        private config: ConfigComponent,
        private globalEvents: GlobalEventsService,
        private injector: Injector
    ) {
        this.selectedFloor = '';
        this.selectedTable = '';
        this.selectedTableOrTurn = '';
        this.defaultVars();
        // cargamos datos del localstorage
        // this.loadData();
        this.tip = 0;
    }

    private defaultVars() {
        this.items = [];
        this.phoneOrderType = null;
        this.deliveryDetail = null;
        this.orderDetail = new OrderTotals();
        this.coupons = [];
        this.restaurant = new RestaurantOrder();
        this.serviceTypeSelected = { id: null, orderType: null };
    }
    /**
     * Define el nomnre para las variables de local store 
     * si se esta accediendo a un menu online se le concatena el id del restaurante al
     * los valores por defecto
     */
    private setVarsLocalStore(): void {
        let idRestaurantMenuOnline = '';
        if (this.utils.externalMenu && sessionStorage.getItem('idRestaurantExt')) {
            idRestaurantMenuOnline = '_' + sessionStorage.getItem('idRestaurantExt');
        }
        this.lsCartitems = '__cart__items' + idRestaurantMenuOnline;
        this.lsCartTypeOrder = '__cart__typeOrder' + idRestaurantMenuOnline;
        this.lsRestaurant = '__restaurant' + idRestaurantMenuOnline;
        this.lsDeliveryDetail = AppConstants.LOCAL_STORAGE_DATA.CART_DELIVERY_DETAIL + idRestaurantMenuOnline;
        this.lsServiceTypeSelected = '__cart_service_type_selelected' + idRestaurantMenuOnline;
    }

    /**
     * Permite cargar los datos del localstorage
     */
    public loadData(): void {
        this.defaultVars();
        this.setVarsLocalStore();
        const tmpItems = JSON.parse(localStorage.getItem(this.config.envName + this.lsCartitems));
        if (tmpItems !== null) {
            // Recorremos los items para eliminar las propiedades de cupones y descuentos
            for (const tmpItem of tmpItems) {
                if (tmpItem.hasOwnProperty('discount')) {
                    delete tmpItem['discount'];
                }

                if (tmpItem.hasOwnProperty('coupons')) {
                    delete tmpItem['coupons'];
                }
                this.items.push(this.utils.clone(tmpItem));
            }
        }
        const tmpDeliveryDetail = localStorage.getItem(this.lsDeliveryDetail);
        if (tmpDeliveryDetail !== null) {
            this.deliveryDetail = this.utils.clone(JSON.parse(tmpDeliveryDetail));
        }
        const tmpRestaurant = localStorage.getItem(this.config.envName + this.lsRestaurant);
        if (tmpRestaurant !== null) {
            this.setRestaurant(JSON.parse(tmpRestaurant));
        }
        const tmpDelivery = localStorage.getItem(this.lsDeliveryDetail);
        if (tmpDelivery !== null) {
            this.deliveryDetail = JSON.parse(localStorage.getItem(this.lsDeliveryDetail));
        }
        if (localStorage.getItem(this.config.envName + this.lsServiceTypeSelected)) {
            this.serviceTypeSelected = JSON.parse(localStorage.getItem(this.config.envName + this.lsServiceTypeSelected));
        }
        const tmpPhoneOrderType = localStorage.getItem(this.lsCartTypeOrder);
        if (tmpPhoneOrderType !== null && tmpPhoneOrderType !== 'null') {
            switch (tmpPhoneOrderType) {
                case String(PhoneOrderType.delivery):
                    this.phoneOrderType = PhoneOrderType.delivery;
                    break;
                case String(PhoneOrderType.pickup):
                    this.phoneOrderType = PhoneOrderType.pickup;
                    break;
                case String(PhoneOrderType.table):
                    this.phoneOrderType = PhoneOrderType.table;
                    break;
                default:
                    break;
            }
        }
        if (this.items.length > 0) {
            jQuery('body').addClass('cart-products');
        }
    }

    /**
     * Permite guardar los datos en el localstorage
     */
    public saveData(): void {
        if (this.items.length > 0) {
            localStorage.setItem(this.config.envName + this.lsCartitems, JSON.stringify(this.items));
        }else {
            localStorage.removeItem('requestQuote');
            localStorage.removeItem('dataDelivery');
        }
        
        if (this.phoneOrderType !== null) {
            localStorage.setItem(this.lsCartTypeOrder, String(this.phoneOrderType));
        }

        if (this.restaurant !== null && this.items.length) {
            // tener en cuenta que este campo tambien se carga en taxes service para consulta un campo de la configuracion
            localStorage.setItem(this.config.envName + this.lsRestaurant, JSON.stringify(this.restaurant));
        }

        if (this.deliveryDetail !== null) {
            localStorage.setItem(this.lsDeliveryDetail, JSON.stringify(this.deliveryDetail));
        }

        if (this.serviceTypeSelected !== null) {
            localStorage.setItem(this.config.envName + this.lsServiceTypeSelected, JSON.stringify(this.serviceTypeSelected));
        }

        this.globalEvents.verifiedTypeOrder.emit();
    }

    /**
     * guarda los detalles de la orden para ser accedidos desde el carro de ventas al ws sales
     * @param orderDetail
     */
    public setOrderDetail(orderDetail: OrderTotals): void {
        this.orderDetail = orderDetail;
    }

    /**
     * Método que permite guardar la información del domicilio
     */
    public setDeliveryDetail(tmpDelivery: Delivery): void {
        this.deliveryDetail = tmpDelivery;
    }

    /**
     * Metodo que permite guardar el comentario de la orden
     */
    public setNoteGeneralOrder(noteGeneralOrder: string): void {
        this.noteGeneralOrder = noteGeneralOrder;
    }
    /**
     * Permite obtener la informacion del delivery
     */
    getDeliveryDetail(): Delivery {
        return this.deliveryDetail;
    }

    /**
     * retorna el arreglo del order Detail para ser mostrado
     * @returns {any}
     */
    public getOrderDetail(): OrderTotals {
        return this.orderDetail;
    }

    /**
     * Devuelve el restaurante de la orden, sin embargo recibe por argumento un tipo de variable booleano
     * que permite consultar en el localstorage y asiganrlo
     */
    public getRestaurant(): RestaurantOrder {
        return this.restaurant;
    }

    /**
     * almacena el id del restaurante que pertenecen los productos a comprar
     * @param restaurantId
     */
    public setRestaurant(restaurant: RestaurantOrder) {
        this.restaurant = restaurant;
    }

    public resetRestaurant (): void {
        this.restaurant = new RestaurantOrder();
    }

    /**
     * Permite obtener el tipo de orden
     */
    public getTypeOrder(): PhoneOrderType {
        return this.phoneOrderType;
    }

    /**
     * Permite establecer el tipo de orden
     * @param tmpPhoneOrderType
     */
    public setTypeOrder(tmpPhoneOrderType: PhoneOrderType): void {
        this.phoneOrderType = tmpPhoneOrderType;
    }

    /**
     * Permite obtener el tipo de orden
     */
    public getServiceTypeSelected(): any {
        return this.serviceTypeSelected;
    }

    /**
     * Permite establecer el tipo de orden
     * @param tmpPhoneOrderType
     */
    public setServiceTypeSelected(serviceType: any): void {
        // console.log('setServiceTypeSelected', serviceType);
        this.serviceTypeSelected = serviceType;
    }

    /**
     * Permite establecer el tipo de orden en null
     */
    public resetOrderType(): void {
        this.phoneOrderType = null;
    }

    /**
     * permite obtener los items del carrito de compras
     */
    public getItems(): any {
        return this.items;
    }

    /**
     * Permite asignar un nuevo listado de productos,
     * este metodo se creo para ser llamado desde el componente del carrito de compras.
     */
    public setItems(products: Array<any>): void {
        // console.log('setItems', products);
        this.items = products;
        this.saveData();
    }

    /**
     * Permite obtener el total de productos en el carrito de compras
     */
    public getTotalItems(): number {
        let totals = 0;
        // console.log('this.items', this.items);
        for (const product of this.items) {
            if (product.size.quantity) {
                totals += product.size.quantity;
            }
        }
        return totals;
    }

    /**
     * Permite obtener el total de descuento por cupones a productos
     *
     * @author Jhonier Gaviria M. - Jul. 11-2018
     * @version 1.0.0
     */
    public getTotalItemsDiscountCoupons(): number {
        let total = 0;
        for (const product of this.items) {
            if (product.discount) {
                total += product.discount;
            }
        }
        return total;
    }

    /**
     * @author William Alarcon. - Nov. 18-2020
     * @version 1.0.0
     * Se encarga de recorrer los arreglos que contienen los submodificadores guardados y los submodificadores seleccionados
     * del producto a añadir.
     * @param modifiers
     * @param productToAdd
     */
    private _sameSubmodiers(modifiers, productToAdd): boolean {
        let sameSubmodifers = true;
        if (modifiers && modifiers.length > 0 && productToAdd.modifiers) {
            for (let j = 0; j < modifiers.length; j++) {
                for (let p = 0; p < modifiers[j].subModifiers.length; p++) {
                    let foundSameModifiers = false;
                    let quantytiModifiers = 0;
                    for (let k = 0; k < productToAdd.modifiers[j].subModifiers.length; k++) {
                        foundSameModifiers = productToAdd.modifiers[j].subModifiers.find(function (element) {
                            return element.id === modifiers[j].subModifiers[p].id;
                        });
                        if (foundSameModifiers) {
                            quantytiModifiers++;
                        }
                    }
                    if (modifiers[j].subModifiers.length !== quantytiModifiers) {
                        sameSubmodifers = false;
                        break;
                    }
                }
            }
        }
        return sameSubmodifers;
    }

    /**
     * permite agregar un item al carrito de compras
     */
    public addItem(product): void {
        // valida si existe el producto y se puede sumar por cantidad
        let existProduct = false;
        for (const i in this.items) {
            if (this.items[i].id === product.id && product.size.quantity > 0
                && this.items[i].size.id === product.size.id) {
                const quantytiModifiers = this.quantityMoifiersByProduct(this.items[i].modifiers, product);

                if (!product.modifiers || (quantytiModifiers === product.modifiers.length)) {
                    if (this._sameSubmodiers(this.items[i].modifiers, product)) {
                        existProduct = true;
                    }
                }
                if (existProduct) {
                    this.items[i].size.quantity = this.items[i].size.quantity + product.size.quantity;
                    if (product.modifiers && product.modifiers.length) {
                        for (const indexModifier in product.modifiers) {
                            this.items[i].modifiers[indexModifier]['qty'] += product.modifiers[indexModifier].qty;
                            this.items[i].setTotalModifiers += product.setTotalModifiers;
                            if (product.modifiers[indexModifier]['subModifiers']) {
                                for (const groupSubMod in product.modifiers[indexModifier]['subModifiers']) {
                                    // for (let k = 0; k < product.modifiers[indexModifier]['subModifiers'][groupSubMod]['subModifiers'].length; k++) {
                                    this.items[i].modifiers[indexModifier]['subModifiers'][groupSubMod]['qty']
                                        += product.modifiers[indexModifier]['subModifiers'][groupSubMod]['qty'];
                                    // }
                                }
                            }
                        }
                    }
                }
            }
        }
        // si no existe se inserta al carrito
        if (!existProduct) {
            this.items.push(this.utils.clone(product));
        }
        // Se agrega una clase de CSS al body para identificar cuando se tenga 
        // Productos en el carrito
        if (this.items.length > 0) {
            jQuery('body').addClass('cart-products');
        }
    }

    /**
     * Se encarga de recorrer cada uno de los modificadores
     * @param modifiers Array de modificadores existentes en los productos que tenga en el carrito
     * @param productToAdd Es el producto que se está intentando agregar al carrito
     *
     * @returns number: Número de modificadores iguales encontrados
     */
    private quantityMoifiersByProduct(modifiers, productToAdd): number {
        let quantytiModifiers = 0;
        if (modifiers && modifiers.length > 0) {
            for (let j = 0; j < modifiers.length; j++) {
                if (productToAdd.modifiers) {
                    const foundSameModifiers = productToAdd.modifiers.find(function (element) {
                        return element.id === modifiers[j].id;
                    });
                    if (foundSameModifiers) {
                        quantytiModifiers++;
                    }
                }
            }
        }
        return quantytiModifiers;
    }

    /**
     * Permite limpiar el carrito de compras
     */
    public clearCart(onlyItems: boolean = true, loadEvent: boolean = false): void {
        this.tip = 0;
        this.orderDetail.tip = 0;
        // resteo por defecto el tipo de servicio como pickup
        this.serviceTypeSelected.orderType = this.phoneOrderType;
        // this.setTypeOrder(this.phoneOrderType);

        jQuery('body').removeClass('cart-products');
        this.items = [];
        this.resetCoupons();
        this.resetRestaurant();
        localStorage.removeItem(this.config.envName + this.lsCartitems);
        localStorage.removeItem(this.config.envName + this.lsServiceTypeSelected);
        localStorage.removeItem(`${this.config.envName}${this.lsRestaurant}`);
        const data = {
            loadEvent: loadEvent
        };
        // if (!loadEvent) {
        // }
        if (!onlyItems) {
            // TODO: revisar por que se colocal this.phoneOrderType en null
            //this.phoneOrderType = null;
            localStorage.removeItem(this.lsCartTypeOrder);
            this.globalEvents.verifiedTypeOrder.emit();
            this.noteGeneralOrder = '';
        }
        this.globalEvents.clearCart.emit(data);
    }


    /**
     * Permite reiniciar la información relacionada a los cupones en el carrito
     */
    private resetCoupons(): void {
        this.coupons = [];
        this.globalEvents.resetStorageCoupons.emit();
    }

    /**
     * Permite reiniciar la información del domicilio
     */
    private restartDeliveryDetail() {
        this.deliveryDetail.del_address = '';
        this.deliveryDetail.del_charge = 0;
        this.deliveryDetail.del_comment = '';
        this.deliveryDetail.del_distance = 0;
        this.deliveryDetail.z_zipcode = '';
    }

    /**
     * Permite verificar si ya existe el producto en el carrito de compras,
     * para este caso en caso de que exista devuelve la posicion
     * de lo contrario devuelve null
     *
     * @param product
     */
    private getKeyItem(product): string {
        for (const i in this.items) {
            if (product.id === this.items[i].id) {
                return i;
            }
        }
        return null;
    }

    /**
     * permite sumar las cantidades de un producto entrante en un producto
     * que ya existe en el carrito de compras
     *
     * @param product
     * @param key
     */
    private addProductQuantities(product: object, key) {
        // tslint:disable-next-line:forin
        for (const i in product['sizes']) {
            const id = product['sizes'][i].id;
            let sizeExist = false;
            // tslint:disable-next-line:forin
            for (const j in this.items[key].sizes) {
                const idTmp = this.items[key].sizes[j].id;
                if (id === idTmp) {
                    sizeExist = true;
                    this.items[key].sizes[j]['quantity'] += product['sizes'][i]['quantity'];
                    break;
                }
            }
            if (!sizeExist) {
                this.items[key].sizes.push(product['sizes'][i]);
            }
        }
    }

    /**
     * permite verificar si un producto que se va a agregar,
     * es del mismo restaurante que el primer producto agregado
     * @param product
     */
    public isSameRestaurant(product: any): boolean {
        // vertificamos que tenga productos agregados
        if (this.items.length > 0) {

            // verificamos que restNickname no sea null
            if (product.restNickname !== null) {

                // verificamos si el restNickName es igual que el primero
                if (this.items[0].restNickname !== product.restNickname) {
                    return false;
                }
            }
        }
        return true;
    }

    /**
     * Permite obtener la información de la venta conglomeradamente para el servicio web que procesa la compra
     */
    public getSale(): Sale {
        const that = this;
        if (this.phoneOrderType && this.restaurant && this.items.length > 0) {
            const auth = this.injector.get(Auth);
            const currentDate = this.utils.getCurrentDateFormat('YYYY-MM-DD HH:mm:ss');
            let tmpDelivery: Delivery = null;
            if (this.deliveryDetail === null) {
                tmpDelivery = new Delivery();
            } else {
                tmpDelivery = this.deliveryDetail;
            }
            const sale = new Sale(currentDate, tmpDelivery, this.utils.externalMenu);
            sale.restaurant_id = this.restaurant.id;
            sale.phone_type = String(this.phoneOrderType);
            sale.is_anonimous = auth.getToken() === null;
            sale.total_order = String(this.orderDetail.total);
            sale.subtotal_order = String(this.orderDetail.subTotal);
            sale.arrayTaxes = this.orderDetail.taxs;
            sale.taxsIncluide = this.utils.roundDecimals(this.orderDetail.taxsIncluide, AppConstants.DECIMALS_NUMBER.THREE);
            sale.tip = this.orderDetail.tip;
            sale.ord_auto_tip_value = this.orderDetail.tip;
            sale.totalTax = this.orderDetail.totalTax;
            // por ahora no se esta usando el pago externo para dejavoo por lo que se 
            // asigna la variable siempre en false 
            // sale.externalPay = this.restaurant?.configuration?.[0]?.externalPay;
            sale.externalPay = false;
            for (const item of this.items) {
                if (item.size.quantity > 0) {
                    this.productSale = new ProductSale();
                    this.productSale.product_id = item.id;
                    this.productSale.name = item.name;
                    this.productSale.product_size_id = item.size.id;
                    this.productSale.qty = item.size.quantity;
                    this.productSale.sold_value = item.size.price;
                    this.productSale.setTotalModifiers = item.setTotalModifiers; // total sumado de los modificadores sin impuestos
                    this.productSale.total = item.total; // total de venta con impuestos
                    this.productSale.salePrice = item.size.price; // valor original del producto
                    this.productSale.ode_sub_total = item.subTotal; // valor original *cantidad de productos
                    this.productSale.productDescription = item.description;
                    this.productSale.itt_id = item.itemType;
                    this.productSale.itParent = item.itParent;
                    this.productSale.tax_total = this.utils.roundDecimals(item.totalTax, AppConstants.DECIMALS_NUMBER.THREE);
                    this.productSale.ode_status = 0; // estado 0 significa pendiente para poder mostrarse en cocina.
                    this.productSale.tax_include = item.taxInclude ? 1 : 0;
                    this.productSale.ode_discount = 0;
                    this.productSale.ode_discount_data = '';
                    this.productSale.taxes = item.tax;
                    item.note = item.note ? item.note : '';
                    // Deción para setear los datos this.productSale.ode_deferred_date y this.productSale.ode_status por si el usuario
                    // hizo una orden diferida
                    if(this.dataDeferred?.dateDeferred && this.dataDeferred?.timeDeferred){
                        this.productSale.ode_deferred_date = this.dataDeferred.dateDeferred  + ' ' + this.dataDeferred.timeDeferred;
                        this.productSale.ode_status = 2;
                    }
                    if (item.modifiers) {
                        const note = {
                            text: item.note,
                            products: item.modifiers
                        };
                        this.productSale.note = JSON.stringify(note);
                    } else {
                        this.productSale.note = '{"text":"' + item.note + '","products":[]}';
                    }
                    sale.products.push(this.productSale);
                }
            }
            if (this.uponDelivery === true && !this.orderDetail.payPoints) {
                sale.payment = undefined;
                sale.uponDelivery = true;
                sale.uponDeliveryData = this.orderDetail.uponDeliveryData;
            }else if(this.orderDetail.payPoints){
                sale.useLoyaltyPoints = this.orderDetail.useLoyaltyPoints;
                sale.uponDelivery = this.orderDetail.uponDelivery;
            } else {
                sale.payment = this.payment;
                sale.uponDelivery = false;
                if(this.paymentTokenId && this.paymentTokenId != ''){
                    sale.payment.paymentTokenId = this.paymentTokenId;
                }
            }
            if (this.orderDetail.tipPercent) {
                sale.ord_auto_tip_percent = this.orderDetail.tipPercent;
            } else {
                sale.ord_auto_tip_percent = 0;
            }
            // Validamos que sea de tipo mesa o turno seleccionado para enviarlo en la petición
            if (this.phoneOrderType === PhoneOrderType.turnAutomatic || this.phoneOrderType === PhoneOrderType.table || this.phoneOrderType === PhoneOrderType.turn) {
                if (this.phoneOrderType != PhoneOrderType.turnAutomatic) {
                    sale.orderDineInTurn = this.selectedTableOrTurn;
                    sale.order_type = OrderType.dineIn;

                } else {
                    sale.order_type = OrderType.counter;
                }
            } else {
                sale.order_type = OrderType.phoneOrder;
            }
            sale.allInformation = true;
            sale.name = this.dataUser.name;
            sale.phone = this.dataUser.phone;
            sale.email = this.dataUser.email;
            sale.externalMenu = this.dataUser.externalMenu;
            sale.externalMenuColor = this.dataUser.externalMenuColor;
            sale.ord_comment = that.noteGeneralOrder;
            
            // Validaciones para determinar el estado de la orden
            if(this.dataDeferred?.dateDeferred && this.dataDeferred?.timeDeferred){
                // Si existe fecha y hora de la orden, el sistema la toma como diferida
                sale.ord_status = AppConstants.ORDER_STATUS.deferredOrder;
            }else if (this.restaurant?.configuration?.[0]?.externalPay){
                // Si está activo el pago con la pasarela, el sistema la toma como billed así lo 
                // pidio el backend
                sale.ord_status = AppConstants.ORDER_STATUS.billed;
            }else if (this.uponDelivery){
                // Si no hay ninguna configuración, y se paga con contra entrega, el sistema
                // lo toma como pendiente
                sale.ord_status = AppConstants.ORDER_STATUS.pending;
            }else if(!this.uponDelivery){
                // Si no hay ninguna configuración, y se paga con tarjeta, el sistema lo toma
                // como pago
                sale.ord_status = AppConstants.ORDER_STATUS.billed;
            }
            // Obtenemos el objeto de requestQuote
            const QuoteDelivery = localStorage.getItem('requestQuote');
            // Si existe este objeto se envia estos datos al ws de order
            if (QuoteDelivery) {
                const QuoteObj = JSON.parse(QuoteDelivery);
                sale.requestQuote = {
                    order_number: QuoteObj.order_number,
                    provider: QuoteObj.provider
                };
            }
            return sale;
        }
        return null;
    }

    /**
     * Metodo encargado del manejo de errores que se pueden presentar
     * en las consultas a los webservices
     */
    private handleError(error: any) {
        return Promise.reject(error.message || error);
    }

    /**
     * Permite procesar la compra
     */
    /**
     * Se realiza la creación de las interfaces referentes al tipado de las estructuras del request y el
     * response de la petición del servicio trabajado
     */
    public sale(creditCard: boolean): Observable<ResponsesStructure> {
        this.uponDelivery = !creditCard;
        const auth = this.injector.get(Auth);
        const isLogged = auth.getToken() !== null;
        const sale: OrderStructureRequest = this.getSale();
        if (sale !== null) {
            // Agregamos los cupones utilizados en la orden
            sale.coupons = this.coupons;
            // Validamos si esta logueado
            if (isLogged) {
                return this.http.post<ResponsesStructure>(this.urlOmt + 'cli/order', JSON.stringify(sale)).pipe(
                    map((response: ResponsesStructure) =>{
                        return response;
                    }),
                    catchError(e => {
                      return throwError(e);
                    })
                );
            } else {
                return this.http.post<ResponsesStructure>(this.urlOmt + 'bg/order', JSON.stringify(sale)).pipe(
                    map((response: ResponsesStructure) =>{
                        return response;
                    }),
                    catchError(e => {
                      return throwError(e);
                    })
                );
            }
        }
    }

    /**
     * Se realiza la creación de las interfaces para el tipado del request y response de la petición del
     * servicio trabajado 
     */
    public averageWaitTimePerService(data: any): Observable<ResponsesStructure> {
        const params: AverageWaitTimePerServiceStructureRequest | any = {
            servicesType: data.servicesType,
            restaurant_id: data.restaurantId,
            distance: data.distance
        }
        const url = `${this.urlOmt}restaurants/averageWaitTimePerService`;
        return this.http.get<ResponsesStructure>(url, {params: params}).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),
            catchError(e => {
              return throwError(e);
            })
        );
    }
    
    public cancelOrder(order: any): Observable<ResponsesStructure> {
        const url = this.urlOmt + 'cli/cancelAnOrder';
        return this.http.post<ResponsesStructure>(url, JSON.stringify(order)).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),
            catchError(e => {
              return throwError(e);
            })
        );
    }

    /**
     * Método para enviarle los datos sobre el pago de la pasarela de pago al servidor
     * @author Oscar Alarcon - Marz. 26-2024
     * @param data datos que se envían
     */
    public paymentGateway(data: IPaymentGateway):Observable<IPaymentGateway>{
        const url = this.urlOmt + 'bg/externalPayConfirm';
        return this.http.post<IPaymentGateway>(url, JSON.stringify(data)).pipe(
            map((response: IPaymentGateway) => {
                return response;
            }),
            catchError(e => {
                return throwError(e);
            })
        );
    }

    /**
     * Se consume ws para el recargo del delivery
     * @author Oscar Alarcon - May. 29-2024
     * @param idRestaurant id del restaurante
     * @param data datos necesario que se envian al ws
     */
    externalDelivery(idRestaurant: string, data: IOrderExternalDeliveryProvider) {
        const auth = this.injector.get(Auth);
        const isLogged = auth.getToken() !== null;
        const url = isLogged 
            ? `${this.config.getUrlOmt()}cli/order/quote/${idRestaurant}`
            : `${this.config.getUrlOmt()}bg/order/quote/${idRestaurant}`;
        
        return this.http.post<ResponsesStructure>(url, data).pipe(
            map((response: ResponsesStructure) => {
                if(!response.data.error){
                    localStorage.setItem('dataDelivery', JSON.stringify(response));
    
                    // Se extrae el requestQuote y los quotes
                    const requestQuote = response.data.requestQuote;
                    const minQuote = response.data.quotes.minQuote;
                    const maxQuote = response.data.quotes.maxQuote;
        
                    // Se agrega el provider a requestQuote, usando minQuote si está disponible, sino maxQuote
                    requestQuote.provider = minQuote ? minQuote.provider : maxQuote.provider;
        
                    // Se guarda el requestQuote modificado por separado en localStorage
                    localStorage.setItem('requestQuote', JSON.stringify(requestQuote));
        
                    return response;
                } else {
                    console.error('Error en el recargo de la entrega');
                }
            }),
            catchError(e => {
                return throwError(e);
            })
        );
    }

    /**
     * Se consume ws para obtener los tips sugeridos
     * @author Oscar Alarcon - Oct.17-2024
     * @param idRestaurant 
     * @returns 
     */
    public suggestedTip(idRestaurant: string): Observable<ResponsesStructure>{
        const url = `${this.urlOmt}suggestedTips/${idRestaurant}`;
        return this.http.get<ResponsesStructure>(url).pipe(
            map((response) => {
                return response
            }),
            catchError(e => {
              return throwError(e);
            })
        );
    }

}
