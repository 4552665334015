import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

/*
 Generated class for the SplashProvider provider.

 See https://angular.io/guide/dependency-injection for more info on providers
 and Angular DI.
 */
@Injectable()
export class SplashService {
  // url de pagos de pruebas de splash
  urlPayment = 'https://test-api.splashpayments.com/txns';
  // api key generada desde la interfas web de splash
  apyKey = '3c615f59183f6a3ade2db4180191ecc0';
  // cabecera para las transacciones de pagos
  headers: HttpHeaders;

  transactionsType = {
    // Sale Transaction. This is the most common type of Transaction, it processes a sale and charges the customer
    sale: '1',
    // Auth Transaction. Authorizes and holds the requested total on the credit card
    auth: '2',
    // Capture Transaction. Finalizes a prior Auth Transaction and charges the customer
    capture: '3',
    // Reverse Authorization. Reverses a prior a prior Auth or Sale Transaction and releases the credit hold
    reverse: '4',
    // Refund Transaction. Refunds a prior Capture or Sale Transaction (total may be specified for a partial refund).
    refund: '5'
  };

  constructor(public http: HttpClient) {
    this.headers = new HttpHeaders()
      .set('APIKEY', this.apyKey);
  }

  /**
   * recibe los datos de pago y el id del comerciante para enviar la peticion de pago a splash
   * @param creditCardData
   * @param merchantId
   * @returns {Observable<Object>}
   */
  startPayment(creditCardData, merchantId) {
    const expiration = String(creditCardData.exp_month) + String(creditCardData.exp_year);
    const params = new HttpParams()
      .set('merchant', merchantId)
      .set('type', this.transactionsType.auth)
      .set('origin', '2')

      .set('payment[method]', '2')
      .set('payment[number]', creditCardData.number)
      .set('payment[cvv]', creditCardData.cvv)

      .set('expiration', expiration)
      .set('total', creditCardData.amount);
    return this.http.post(this.urlPayment, params, {headers: this.headers});
  }

  startReverse(txn) {
    const params = new HttpParams()
      .set('type', this.transactionsType.reverse)
      .set('fortxn', txn);
    return this.http.post(this.urlPayment, params, {headers: this.headers});
  }

  startRefund(txn, amount) {
    const params = new HttpParams()
      .set('type', this.transactionsType.refund)
      .set('fortxn', txn)
      .set('total', amount);
    return this.http.post(this.urlPayment, params, {headers: this.headers});
  }
  startAdjust(txn, amount) {
    const params = new HttpParams()
      .set('id', this.transactionsType.refund)
      .set('id', txn)
      .set('amount', amount);
    return this.http.post(this.urlPayment, params, {headers: this.headers});
  }

}
