import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';

import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { UtilsComponent } from 'app/utils/utils.component';

/**
 * Permite realizar la carga de los archivos  de idiomasss
 *
 * @author Jhonier Gaviria M. Jul. 27-2018
 * @version 1.0.0
 *
 * @param httpClient Servicio para realizar peticiones
 */
export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient, './assets/locales/', '.json');
}

@NgModule({
    imports: [
        CommonModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
        isolate: true
        }),
    ],
    exports: [
        TranslateModule
    ],
    declarations: []
})
export class TranslatorModule {

    /**
     * Constructor de la clase
     *
     * @author Jhonier Gaviria M. Jul. 27-2018
     * @version 1.0.0
     *
     * @param translate Servicio de traduccion
     */
    constructor(private translate: TranslateService,
                private utils: UtilsComponent) {
        this.setDefaultLang();
    }

    /**
     * Permite verificar el idioma para establecerlo dependiendo de si el usuario ya lo selecciono o no
     */
    private async setDefaultLang() {

        if (window.location.hash.trim() === '') {
            const language = await this.utils.getDefaultLang();
            this.translate.setDefaultLang(language);
            this.translate.use(language);
             // Si tenemos ya un lenguaje
            if (language) {
                // Esta key LOCALIZE_DEFAULT_LANGUAGE la agrega la libreria de translate, entonces la igualamos para que sea igual
                // a la que el usuario selecciono
                let languageLocalStorage = localStorage.getItem('LOCALIZE_DEFAULT_LANGUAGE');
                if (languageLocalStorage) {
                    localStorage.setItem('LOCALIZE_DEFAULT_LANGUAGE', language); // Actualiza el valor en localStorage
                }
            }
        }
    }
}
