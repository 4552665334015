import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PhoneOrWebService {


 /**
  * Variable para guardar si el usuario ingresa desde un celular o desde un computador
  */
  public sizeDisplay: string = 'phone' || 'web';


  constructor(
    public breakpointObserver: BreakpointObserver,
  ) 
  {
    this.mediaQuery();
   }

    /**
     *  Metodo para saber si el usuairo está ingresando desde un celular o desde un computador
     *  @author Oscar Alarcon - agos. 17-2023
     *  @version 1.0.0
     */
      public mediaQuery(){
        this.breakpointObserver
        .observe(Breakpoints.Small)
        .subscribe((state: BreakpointState) => {
            if(state.matches){
                this.sizeDisplay = 'phone';
            }
        });

        this.breakpointObserver
        .observe(Breakpoints.Web)
        .subscribe((state: BreakpointState) => {
            if(state.matches){
                this.sizeDisplay = 'web';
            }
        });

        this.breakpointObserver
        .observe(Breakpoints.Tablet)
        .subscribe((state: BreakpointState) => {
            if(state.matches){
                this.sizeDisplay = 'tablet';
            }
        });
    }

}
