import { Injectable } from '@angular/core';
import { FaviconServices } from '../interfaces/favicon.interfaces';

@Injectable({
    providedIn: 'root'}
)
export class FaviconService implements FaviconServices{

    constructor() {
        //
    }

    /**
     * Metodo para eliminar el favicon de la pagina
     * @param href url o ubicacion en el proyecto del favicon a usar en formato png
     * @author Alvaro Felipe Garcia Mendez - Sept. 3 - 2019
     * @version 1.0.0
     */
    public removeFavicon(): void {
        // buscamos los tags de favicon en el index
        const toRemove = document.head.querySelector('#favicon');
        // los removemos si existen
        if (toRemove) {
            document.head.removeChild(toRemove);
        }
    }

    /**
     * Metodo para realizar el cambio de link que contiene el favicon de la pagina
     * y se altere segun sea OMT o External menu
     * @param href url o ubicacion en el proyecto del favicon a usar en formato png
     * @author Alvaro Felipe Garcia Mendez - Sept. 3 - 2019
     * @version 1.0.0
     */
    public setFavicon(href: string): void {
        this.removeFavicon();
        // creamos un nuevo link igual al anterior
        const linkElement = document.createElement('link');
        linkElement.setAttribute('id', 'favicon');
        linkElement.setAttribute('rel', 'icon');
        linkElement.setAttribute('type', 'image/jpeg');
        linkElement.setAttribute('href', href);
        // incluimos el nodo en el head
        document.head.appendChild(linkElement);
    }
}
