import { ConfigNotificationsStructureRequest, NotificationServices, NotificationsStructureRequest, StatusNotificationsStructureRequest } from 'app/core/interfaces/notifications.interfaces';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ConfigComponent } from 'app/core/config/config';
import { GeoPosition } from 'app/core/entities/GeoPosition';
import { Injectable } from '@angular/core';
import { ResponsesStructure } from 'app/core/interfaces/transversal.interfaces';

@Injectable()
export class NotificationService implements NotificationServices{

    /**
     * Dispatch events
     */

    $dispatchDeleteNotification: Subject<void> = new Subject<void>();

    /**
     * Constructor de la clase
     *
     * @param config
     * @param http
     */
    constructor(
        private readonly config: ConfigComponent,
        public http: HttpClient)
    { }

    /**
     * Metodo para obtener las notificaciones del usuario
     */
    /**
     * Se crea la interface con la estructura del objeto del response del servicio y se agrega la
     * inyección del servicio por medio de la interface de servicios
     */
    getUserNotifications(status_read, idRestaurant = null, page: number): Observable<ResponsesStructure> {
        let params = new HttpParams();
        if (status_read) {
            params = params.append('status_read', String(status_read));
        }
        if (idRestaurant) {
            params = params.append('idRestaurant', String(idRestaurant));
        }
        params = params.append('page', String(page));
        const url = `${this.config.getUrlOmt()}cli/notifications`;
        return this.http.get<ResponsesStructure>(url, { params: params }).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),
            catchError(e => {
              return throwError(e);
            })
        );
    }

    /**
     * permite guardar el estado de si fue leido o no la notificacion en el WS
     */
    setStatusNotification(data: StatusNotificationsStructureRequest): Observable<ResponsesStructure> {
        const url = `${this.config.getUrlOmt()}cli/notifications/${data.id}`;
        return this.http.put<ResponsesStructure>(url, data).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),
            catchError(e => {
              return throwError(e);
            })
        );
    }

    /**
     * envia el la petiecion al WS para borrar las notificaciones
     * @param data
     */

    /**
     * Se realiza correctamente el tipado del request de la petición de la eliminación de notificaciones
     */
    deleteNotifications(data): Observable<ResponsesStructure> {
        const url = `${this.config.getUrlOmt()}cli/notifications/delete`;
        return this.http.post<ResponsesStructure>(url, data).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),
            catchError(e => {
              return throwError(e);
            })
        );
    }

    /**
     * It calls a method to subscribe in the father´s component
     */
   public deleteListenNotification(): Observable<void>{
     return this.$dispatchDeleteNotification.asObservable();
    }

    /**
     * It sends information to delete
     */
   public sendDeleteNotification(){
     this.$dispatchDeleteNotification.next();
    }



    /**
     * Permite obtener el listado de contextos para mostrarse en la configuración de notificaciones
     *
     * @author Jhonier Gaviria M. - Ago. 06-2018
     * @version 1.0.0
     */
    /**
     * Se realiza correctamente el tipado de la respuesta de la petición al servicio que retorna la
     * información de los contextos de las notificaciones
     */
    getContexts(): Observable<ResponsesStructure> {
        const url = `${this.config.getUrlOmt()}contexts`;
        return this.http.get<ResponsesStructure>(url).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),
            catchError(e => {
              return throwError(e);
            })
        );
    }

    /**
     * Envia la peticion al WS para guardar la configuracion de la notificaciones por restaurante
     * @param notifications
     */
    /**
     * Se realiza la creación de las interfaces para el tipado del request y response de la petición del
     * servicio trabajado
     */
    saveConfigurationNotification(notifications: ConfigNotificationsStructureRequest): Observable<ResponsesStructure> {
        const url = `${this.config.getUrlOmt()}cli/notifications/configuration`;
        return this.http.post<ResponsesStructure>(url, notifications).pipe(
            map((response: ResponsesStructure) =>{
                return response;
            }),
            catchError(e => {
              return throwError(e);
            })
        );
    }

    /**
     * Envia la peticion al WS para guardar la configuracion de la notificaciones de productos por restaurante
     * @param notificationsProducts
     */
    saveConfigurationNotificationProducts(notificationsProducts: any): Observable<ResponsesStructure> {
        const url = `${this.config.getUrlOmt()}cli/notifications/configuration/products`;
        return this.http.post<ResponsesStructure>(url, notificationsProducts).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),
            catchError(e => {
              return throwError(e);
            })
        );
    }

    /**
     * Trae los restaurantes segun el criterio de busqueda
     *
     * @author Jhonier Gaviria M. - Ago. 30-2018
     * @version 1.2.0
     *
     * @param itemsPerPage Cantidad de items por pagina a consultar
     * @param allCuisines Arreglo con las cocinas seleccionadas para filtrar
     * @param page Numero de pagina a consultar
     * @param coordinates Coordenadas de ubicacion del usuario
     * @param freeText Texto libre que envia el usuario como parte del nombre o descripcion del restaurante
     * @param range Rango en km en el que busca restaurantes de acuerdo a las coordenadas
     */
    /**
     * Se realiza la creación de las interfaces para el tipado del request y response del servicio
     * consumido y se agrega el método a la inyección de servicios en la interface correspondiente
     */
    getRestaurantsForNotifications(itemsPerPage: number, allCuisines: Array<number>,
        page: number, coordinates: GeoPosition = null, freeText: any, range: number): Observable<ResponsesStructure> {
        let params = new HttpParams();
        if (coordinates !== null) {
            params = params.append('latitude', String(coordinates.lat));
            params = params.append('longitude', String(coordinates.lng));
        }
        params = params.append('page', String(page));
        params = params.append('items_per_page', String(itemsPerPage));
        params = params.append('cuisines', String(allCuisines));
        params = params.append('order_by', 'distance');
        params = params.append('sort', '0');
        params = params.append('range', String(range));
        if (freeText) {
            params = params.append('free_text', String(freeText));
        }
        const url = `${this.config.getUrlOmt()}cli/notifications/restaurants?`;
        return this.http.get<ResponsesStructure>(url, { params: params }).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),
            catchError(e => {
              return throwError(e);
            })
        );
    }

    /**
     * Trae los productos por restaurante
     *
     * @author Jhonier Gaviria M. - Ago. 08-2018
     * @version 1.1.0
     *
     * @param itemsPerPage Cantidad de items por pagina a consultar
     * @param page Numero de pagina a consultar
     * @param idRestaurant Identificador del restaurante
     */
    /**
     * Se realiza correctamente el tipado del objeto de la respuesta de la petición que retorna los 
     * productos por restaurante
     */
    getProductByRestaurant(itemsPerPage: number, page: number, idRestaurant: string): Observable<ResponsesStructure> {
        let params = new HttpParams();
        params = params.append('page', String(page));
        params = params.append('items_per_page', String(itemsPerPage));
        const url = `${this.config.getUrlOmt()}cli/notifications/restaurant/` + idRestaurant + '/products';
        return this.http.get<ResponsesStructure>(url, { params: params }).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),
            catchError(e => {
              return throwError(e);
            })
        );
    }

    getUserNotificationsConfig(): Observable<ResponsesStructure> {
        const url = `${this.config.getUrlOmt()}cli/checkNotificationSettings`;
        return this.http.get<ResponsesStructure>(url).pipe(
            map((response: ResponsesStructure) =>{
                return response;
            }),
            catchError(e => {
              return throwError(e);
            })
        );
    }
}
