import * as moment from 'moment';

import { Router, RouterEvent } from '@angular/router';

import { AppConstants } from 'app/app-constants';
import { Injectable } from '@angular/core';
import { createAt } from './../interfaces/profile.interfaces';
import { filter } from 'rxjs/operators';

/**
 * Servicio para agregar funcionalidades compartidas en toda la aplicacion
 *
 * @author Jhonier Gaviria M. - Jul 12-2018
 * @version 1.0.0
 */
@Injectable()
export class SharedService {
    isCookie: string;

    /**
     * Constructor de la clase
     *
     * @author Jhonier Gaviria M. - Jul 12-2018
     * @version 1.0.0
     */
    constructor(private router: Router) {
        this.isCookie = document.cookie;
    }

    /**
     * Permite clonar un objeto apartir de otro con las propiedades indicadas
     *
     * @author Jhonier Gaviria M. - Jul 12-2018
     * @version 1.0.0
     *
     * @param element El elemento a clonar
     * @param properties Las propiedades a clonar
     */
    cloneObjectByProperties(element: any, properties: any) {
        const newElement = {};
        // Recorremos el arreglo de propiedades que requerimos
        for (const property in properties) {
            // Si tiene la propiedad que se busca
            if (element.hasOwnProperty(properties[property])) {
                const key = Object.keys(element)[property];
                newElement[key] = element[key];
            }
        }
        return newElement;
    }

    /**
     * Permite clonar un objeto apartir de otro omitiendo las propiedades indicadas
     *
     * @author Jhonier Gaviria M. - Jul 12-2018
     * @version 1.0.0
     *
     * @param element El elemento a clonar
     * @param properties Las propiedades que no se deben clonar
     */
    /*cloneObjectExcludingProperties(element: any, properties: any) {
      const newElement = {};
      const elementProperties = Object.keys(element);
      // Recorremos el arreglo de propiedades que requerimos
      for (const property in elementProperties) {
        // Si tiene la propiedad que se busca
        if (element.hasOwnProperty(properties[property])) {
          const key = Object.keys(element)[property];
          newElement[key] = element[key];
        }
      }
      return newElement;
    }*/
    /**
      * Establece la variable para saber si es necesario mostrar el formulario
      * @param event evento de cambio de url
      * @param stringPage nombre de la url
      * @param emptyRoute indica si la ruta puede estar vacia
      *
      * @author Alvaro Felipe Garcia Mendez - July 22 - 2019
      * @author Valeria Medina Ramírez - August 06 - 2019
      * @version 1.0.1
      */
    public setIsLandingPage(event: any, stringPage: string, emptyRoute: boolean = false): boolean {
        if (event['url'].indexOf(stringPage) !== -1 || (event['url'] === '/' && emptyRoute)
            || event['url'].indexOf('profile') !== -1 && this.isCookie.indexOf('wasVisited=true') !== -1) {
            return true;
        }
        return false;
    }

    /**
     * Indica los cambios de ruta en la pagina
     * @author Alvaro Felipe Garcia Mendez - July 22 - 2019
     * @author Valeria Medina Ramírez - August 06 - 2019
     * @version 1.0.1
     */
    public listenerForRoute(): any {
        return this.router.events.pipe(
            filter(e => e instanceof RouterEvent)
        );
    }
    /**
      * Método para transformar la fecha de creación de la cuenta y que pueda ser traducida
      * @version 1.0.1
      */
    public memberSinceMethod() {
        if (localStorage.getItem(AppConstants.LOCAL_STORAGE_DATA.DATA_USER)) {
            moment.locale(localStorage.getItem('langUser'));
            const data = JSON.parse(localStorage.getItem(AppConstants.LOCAL_STORAGE_DATA.DATA_USER));
            let vartemp;
            if (data.createdAt) {
                vartemp = data.createdAt.split(' ', 1);
            }else if (data.createdat) {
                vartemp = data.createdat.split(' ', 1);
            }

            const date = new Date(vartemp);
            return moment(date, 'YYYY-MM-DD', true).format('MM/DD/YYYY');
        }
    }
}
