import { GlobalEventsService } from 'app/core/services/globalEvents.service';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AppConstants } from 'app/app-constants';
import { ActivatedRoute } from '@angular/router';
import { UserLocationProvider } from 'app/utils/userLocationService';
import { ResponsesStructure } from 'app/core/interfaces/transversal.interfaces';
import { AuthServices } from 'app/core/interfaces/auth.interfaces';
import { ProfileServices } from 'app/core/interfaces/profile.interfaces';

/**
 * Modal de seleccion de lenguage en la configuracion de usuario
 *
 * @author Jhonier Gaviria Muñoz - Jul. 08-2018
 * @version 1.0.0
 */
@Component({
    selector: 'app-language',
    templateUrl: './language.component.html',
    styleUrls: ['./language.component.css']}
)
export class LanguageComponent implements OnInit {

    /**
     * Listado de lenguajes de la aplicacion
     */
    languages: any[];

    /**
     * Es el lenguaje seleccionado
     */
    languageSelected: string;

    /**
     * Es el modal de lenguaje
     */
    @ViewChild('languageModal', { static: true }) public languageModal: ModalDirective;

    /**
     * Constructor de la clase
     *
     * @author Jhonier Gaviria M. - Jul. 26-2018
     * @version 1.0.0
     *
     * @param localize Servicio de traduccion
     * @param globalEvents Servicio de emision de eventos globales de la aplicacion
     */
    constructor(
        private readonly globalEvents: GlobalEventsService,
        @Inject('ProfileServices') private readonly profileServices: ProfileServices,
        private readonly activatedRoute: ActivatedRoute,
        private readonly userLocationService: UserLocationProvider,
        @Inject('AuthServices') private readonly authService: AuthServices)
    {
        this.languages = [
            {
                value: 'es',
                label: 'SPANISH'},
            {
                value: 'en',
                label: 'ENGLISH'}
        ];

        // Escucha para abrir el modal
        this.globalEvents.openLanguageModal.subscribe(() => {
            this.languageModal.show();
        });
    }

    /**
     * @see {@link https://angular.io/guide/lifecycle-hooks#lifecycle-hooks Lifecycle Hooks Docs}
     */
    ngOnInit(): void {
        this.languageModal.onShow.subscribe(() => {
            if (localStorage.getItem(AppConstants.LOCAL_STORAGE_DATA.LANGUAGE_USER) !== null) {
                this.languageSelected = localStorage.getItem(AppConstants.LOCAL_STORAGE_DATA.LANGUAGE_USER);
            } else {
                this.languageSelected = 'en';
            }
        });

        
            // Si no hay ningun idioma establedicio dependiendo de la ubicación del usuario, se configura su idioma
            if (localStorage.getItem(AppConstants.LOCAL_STORAGE_DATA.LANGUAGE_USER) === null) {
                if (this.userLocationService.useColombianCurrency()) {
                    this.languageSelected = 'es';
                    this.chooseLanguage();
                } else {
                    this.languageSelected = 'en';
                    this.chooseLanguage();
                }
            }

    }

    /**
     * Permite realizar el cambio del lenguaje seleccionado
     *
     * @author Jhonier Gaviria M. - Jul. 26-2018
     * @version 1.0.0
     *
     * @param lang El nuevo valor del lenguaje
     */
    changeLanguageSelected(lang): void {
        this.languageSelected = lang;
    }

    /**
     * Seleccionar el idioma de la app
     *
     * @author Jhonier Gaviria M. - Jul. 26-2018
     * @version 1.0.0
     */
    chooseLanguage(): void {
        // guardamos en el servidor el idioma establecido por el usuario
        if (this.authService.isAuthenticated()) {
            this.profileServices.saveLang(this.languageSelected).subscribe((response: ResponsesStructure) =>{
                console.log(response);
            }, error =>{
                console.log(error);
            });
        }
        localStorage.setItem(AppConstants.LOCAL_STORAGE_DATA.LANGUAGE_USER, this.languageSelected);
        this.globalEvents.langChange.emit(this.languageSelected);
        this.close();
    }

    /**
     * Permite cerrar el modal
     *
     * @author Jhonier Gaviria M. - Jul. 26-2018
     * @version 1.0.0
     */
    close(): void {
        this.languageModal.hide();
    }
}
