import { NgModule } from '@angular/core';
import { DynamicComponentLoaderModule } from 'app/core/dynamic-loader/dynamic-component-loader.module';
import { DialogReviewsModalComponent } from './dialog-reviews-modal.component';

@NgModule({
    imports: [
        DynamicComponentLoaderModule.forModule({
            componentId: 'reviews-modal',
            path: 'reviews-modal',
            loadChildren: () => import('./content/reviews-modal.module').then(m => m.ReviewsModalModule)
        })
    ],
    declarations: [DialogReviewsModalComponent],
    exports: [DialogReviewsModalComponent]
})
export class DialogReviewsModalModule { }
