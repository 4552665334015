import {
    AuthServices,
    CheckEmailStructureRequest,
    CreateNewCodeStructureRequest,
    SignInStructureRequest,
    SignUpSocialNetworkStructureRequest,
    SignUpStructureRequest,
    ValidateCodeStructureRequest,
    ValidateEmailStructureRequest,
    ValidateUsernameStructureRequest,
    validateSingUpLoyaltyUserByCodeStructureRequest
} from 'app/core/interfaces/auth.interfaces';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AppConstants } from 'app/app-constants';
import { ConfigComponent } from 'app/core/config/config';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ResponsesStructure } from 'app/core/interfaces/transversal.interfaces';

@Injectable()
export class Auth implements AuthServices{

    constructor(
        public http: HttpClient,
        private readonly config: ConfigComponent){/** */}

    /**
     * consume el servicio de registro de usuario
     * @param data
     */
     /**
     * Tipado del request y el response del servicio implementado correctamente
     */
    singUp(data: SignUpStructureRequest): Observable<ResponsesStructure> {
        const url = this.config.getUrlOmt() + 'cli/signup';
        return this.http.post<ResponsesStructure>(url, data).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),catchError(error => {
                return throwError(error);
            })
        );
    }

    /**
     * consume el servicio de registro de usuario de una red social
     * @param data
     */
    /**
     * Tipado del request y el response del servicio implementado correctamente
     */
    singUpSocialNet(data: any): Observable<ResponsesStructure> {
        const arrayData: SignUpSocialNetworkStructureRequest = {
            name: data.name,
            lastname: data.lastname,
            email: data.email,
            social_network: data.provider,
            social_network_token: data.authToken,
            social_network_user_id: data.id,
            device_platform: 'Web'
        };
        const url = this.config.getUrlOmt() + 'cli/signupSocialNetwork';
        return this.http.post<ResponsesStructure>(url, arrayData).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),catchError(error => {
                return throwError(error);
            })
        );
    }

    /**
     * consume el servicio de logueo de un usuario
     * @param data
     */
    /**
     * Tipado del request y el response del servicio implementado correctamente
     */
    login(data: SignInStructureRequest): Observable<ResponsesStructure> {
        const url = this.config.getUrlOmt() + 'cli/login';
        console.log("url login", url);
        
        return this.http.post<ResponsesStructure>(url, data).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),catchError(error => {
                return throwError(error);
            })
        );
    }

    /**
     * Consume el servicio de cierre de session
     * @param data
     */
    /**
     * Tipado del request y el response del servicio implementado correctamente
     */
    logout(data: any): Observable<ResponsesStructure> {
        const url = this.config.getUrlOmt() + 'cli/logout';
        return this.http.post<ResponsesStructure>(url, data).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),catchError(error => {
                return throwError(error);
            })
        );
    }

    /**
     * Permite obtener el token generado en el login
     */
    getToken(): string {
        return localStorage.getItem(AppConstants.LOCAL_STORAGE_DATA.AUTH_TOKEN);
    }

    /**
     * Permite validar si esta logueado un usuario
     */
    isAuthenticated(): boolean {
        // Llamamos la función de traer el token
        const token = this.getToken();
        // Validamos si existe el token
        if (token !== null) {
            return true;
        }
        return false;
    }

    /**
     * Permite setear el valor del token generado
     *
     * @param token Es el nuevo token de la sesión del usuario
     */
    setToken(token: string) {
        localStorage.setItem(AppConstants.LOCAL_STORAGE_DATA.AUTH_TOKEN, token);
        const dataSession = JSON.parse(localStorage.getItem(AppConstants.LOCAL_STORAGE_DATA.DATA_SESSION));
        dataSession.apiKey = token;
        localStorage.setItem(AppConstants.LOCAL_STORAGE_DATA.DATA_SESSION, JSON.stringify(dataSession));
    }

    /**
     * Permite validar si existe un usuario registrado por medio del correo
     *
     * @param email Es el correo del usuario a validar si esta registrado
     */
    /**
     * Tipado del request y el response del servicio implementado correctamente
     */
    existUser(email: string): Observable<ResponsesStructure> {
        const checkEmail: CheckEmailStructureRequest = {
            email: email,
            validate_exists: true,
            return_username: true
        };
        const url = this.config.getUrlOmt() + 'check-email';
        return this.http.post<ResponsesStructure>(url, checkEmail).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),catchError(error => {
                return throwError(error);
            })
        );
    }

    /**
     * funcion para ubicar el usuario a traves de wifi
     */
    /**
     * Tipado del request y el response del servicio implementado correctamente
     */
    validateNickname(nickname): Observable<ResponsesStructure> {
        const valUsername: ValidateUsernameStructureRequest = {
            username: nickname
        };
        const url = this.config.getUrlOmt() + 'validatenickname';
        return this.http.post<ResponsesStructure>(url, valUsername).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),catchError(error => {
                return throwError(error);
            })
        );
    }

    /**
     * funcion para ubicar el usuario a traves de wifi
     */
    /**
     * Tipado del request y el response del servicio implementado correctamente
     */
    validateEmail(email): Observable<ResponsesStructure> {
        const valEmail: ValidateEmailStructureRequest = {
            email: email
        };
        const url = this.config.getUrlOmt() + 'validateemail';
        return this.http.post<ResponsesStructure>(url, valEmail).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),catchError(error => {
                return throwError(error);
            })
        );
    }

    censorEmail(email: string): string {
        const emailArray = email.split('@');
        const asterisks = '*'.repeat(4);
        const censorUserNameEmail = emailArray[0].slice(0, 3);
        const emailDomainArray = emailArray[1].split('.');
        const censorEmailOrganization = emailDomainArray[0].charAt(0);
        const typeDomain = emailDomainArray[1];
        return `${censorUserNameEmail}${asterisks}@${censorEmailOrganization}${asterisks}.${typeDomain}`;
    }

    /**
     * Tipado del request y el response del servicio implementado correctamente
     */
    requestNewVerificationCode(data: CreateNewCodeStructureRequest): Observable<ResponsesStructure> {
        const url = `${this.config.getUrlOmt()}sendActivationCode`;
        return this.http.post<ResponsesStructure>(url, data).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),catchError(error => {
                return throwError(error);
            })
        );
    }

    /**
     * Tipado del request y el response del servicio implementado correctamente
     */
    confirmationCodeVerification(data: ValidateCodeStructureRequest): Observable<ResponsesStructure> {
        const url = `${this.config.getUrlOmt()}activateAcountByCode`;
        return this.http.post<ResponsesStructure>(url, data).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),catchError(error => {
                return throwError(error);
            })
        );

    }


    /**
     *  Se consume ws validateSingUpLoyaltyUserByCode que funciona para el pre registro
     */
    validateSingUpPreRegistration(data: validateSingUpLoyaltyUserByCodeStructureRequest){
        const url = `${this.config.getUrlOmt()}validateSingUpLoyaltyUserByCode`;
        return this.http.post<ResponsesStructure>(url, data).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),catchError(error => {
                return throwError(error);
            })
        );
    }
}

