<!--// Footer \\-->
<!--// Footer Widget's \\-->
<div class="container byeExt">
	<div class="row flx3 rltv">
		<!--// Widegt Text \\-->
		<div class="flx6">
			<a *ngIf="mobileAndroid" href="{{urlPlaystore}}" class="appDownload" target="blank" rel="noopener">
				<img class="store" src="./assets/extra-images/appGoogle.png" alt="Open My Tab">
			</a>
			<a *ngIf="mobileIos" href="{{urlAppstore}}" class="appDownload" target="blank" rel="noopener">
				<img class="store" src="./assets/extra-images/appStore.png" alt="Open My Tab">
			</a>
		</div>
		<!--// Widegt Text \\-->
		<!--// Widegt Text \\-->
		<div class="wm-copyright flx2">
			<!-- <a href="#terms" data-toggle="modal" data-target="#terms">{{ 'TERMSANDCONDITIONS' | translate }}</a>  |  <a  href="#policy" data-toggle="modal" data-target="#policy">{{ 'PRIVACYPOLICY' | translate }}</a> -->
			<a (click)="openModalTermsAndConditions()">{{ 'TERMSANDCONDITIONS' | translate }}
			</a> |
			<span class="privacy-policy" (click)="openModalPrivacyPolicy()">
				{{ 'PRIVACYPOLICY' | translate }}
			</span>

			{{ 'MadeWith' | translate }} <em class="fa fa-heart"></em>
			{{ 'By' | translate }}
			<a class="omt-footer-link" href="{{currentUrl}}" target="_blank" rel="noopener">Open My Tab</a>

		</div>
		<!--// Widegt Text \\-->
	</div>
</div>
<!--// Footer Widget's \\-->

<!--// Footer \\-->

<!--// Terms Of Use \\-->
<!-- <article class="modal fade" id="terms" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
	<div class="modal-dialog" role="document">
		<div class="modal-content shopping">
			<div class="modal-header">
				<span class="h4"><b>{{ 'TERMSANDCONDITIONS' | translate }}</b></span>
				<button type="button" data-dismiss="modal" class="close" aria-hidden="true" aria-label="close">×</button>
			</div>

			<div class="container">
				
				
				<p>{{ 'TERMSOFSERVICE_P' | translate }} </p>
				
				<span class="h4">
					<b>{{ 'OVERVIEW' | translate }} </b>
				</span>
				
				<p class="text-justify">{{ 'OVERVIEWONE' | translate }} </p>
				<p class="text-justify">{{ 'OVERVIEWTWO' | translate }} </p>
				
				<span class="h2 text-uppercase">
					<b>{{ 'SECCION' | translate }} 1</b>
				</span>
				<span class="h4">
					<b>{{ 'ACCURACY' | translate }} </b>
				</span>
				<p class="text-justify">{{ 'ACCURACYONE' | translate }} </p>
				<p class="text-justify">{{ 'ACCURACYTWO' | translate }} </p>
				
				<span class="h2 text-uppercase">
					<b>{{ 'SECCION' | translate }} 2</b>
				</span>
				<span class="h4">
					<b>{{ 'THIRD_PARTY_LINKS' | translate }} </b>
				</span>
				<p class="text-justify">{{ 'THIRD_PARTY_LINKSONE' | translate }} </p>
				<p class="text-justify">{{ 'THIRD_PARTY_LINKSTWO' | translate }} </p>
				<p class="text-justify">{{ 'THIRD_PARTY_LINKSTHREE' | translate }} </p>
				
				
				<span class="h2 text-uppercase">
					<b>{{ 'SECCION' | translate }} 3</b>
				</span>
				<span class="h4">
					<b>{{ 'USER_COMMENTS' | translate }} </b>
				</span>
				<p class="text-justify">{{ 'USER_COMMENTSONE' | translate }} </p>
				<p class="text-justify">{{ 'USER_COMMENTSTWO' | translate }} </p>
				<p class="text-justify">{{ 'USER_COMMENTSTHREE' | translate }} </p>
				
				<span class="h2 text-uppercase">
					<b>{{ 'SECCION' | translate }} 4</b>
				</span>
				<span class="h4">
					<b>{{ 'INACCURACIES' | translate }} </b>
				</span>
				<p class="text-justify">{{ 'INACCURACIESONE' | translate }} </p>
				<p class="text-justify">{{ 'INACCURACIESTWO' | translate }} </p>
				
				<span class="h2 text-uppercase">
					<b>{{ 'SECCION' | translate }} 5</b>
				</span>
				<span class="h4">
					<b>{{ 'PROHIBITED_USES' | translate }} </b>
				</span>
				<p class="text-justify">{{ 'PROHIBITED_USESP' | translate }} </p>
				
				<ol class="text-justify">
					<li>{{ 'PROHIBITED_USESONE' | translate }}</li>
					<li>{{ 'PROHIBITED_USESTWO' | translate }}</li>
					<li>{{ 'PROHIBITED_USESPTHREE' | translate }}</li>
					<li>{{ 'PROHIBITED_USESFOUR' | translate }}</li>
					<li>{{ 'PROHIBITED_USESFIVE' | translate }}</li>
					<li>{{ 'PROHIBITED_USESSIX' | translate }}</li>
					<li>{{ 'PROHIBITED_USESSEVEN' | translate }}</li>
					<li>{{ 'PROHIBITED_USESEIGHT' | translate }}</li>
					<li>{{ 'PROHIBITED_USESNINE' | translate }}</li>
					<li>{{ 'PROHIBITED_USESTEN' | translate }}</li>
					<li>{{ 'PROHIBITED_USESELEVEN' | translate }}</li>
				</ol>
				<p class="text-justify">{{ 'PROHIBITED_USESP2' | translate }} </p>
				
				
				<span class="h2 text-uppercase">
					<b>{{ 'SECCION' | translate }} 6</b>
				</span>
				<span class="h4">
					<b>{{ 'LIABILITY' | translate }} </b>
				</span>
				<p class="text-justify">{{ 'LIABILITYONE' | translate }} </p>
				<p class="text-justify">{{ 'LIABILITYTWO' | translate }} </p>
				<p class="text-justify">{{ 'LIABILITYTHREE' | translate }} </p>
				
				
				<span class="h2 text-uppercase">
					<b>{{ 'SECCION' | translate }} 7</b>
				</span>
				<span class="h4">
					<b>{{ 'INDEMNIFICATION' | translate }} </b>
				</span>
				<p class="text-justify">{{ 'INDEMNIFICATIONONE' | translate }} </p>
				
				<span class="h2 text-uppercase">
					<b>{{ 'SECCION' | translate }} 8</b>
				</span>
				<span class="h4">
					<b>{{ 'TERMINATION' | translate }} </b>
				</span>
				<p class="text-justify">{{ 'TERMINATIONONE' | translate }} </p>
				<p class="text-justify">{{ 'TERMINATIONTWO' | translate }} </p>
				<p class="text-justify">{{ 'TERMINATIONTHREE' | translate }} </p>
				
				<span class="h2 text-uppercase">
					<b>{{ 'SECCION' | translate }} 9</b>
				</span>
				<span class="h4">
					<b>{{ 'ENTIRE_AGREEMENT' | translate }} </b>
				</span>
				<p class="text-justify">{{ 'ENTIRE_AGREEMENTONE' | translate }} </p>
				
				<span class="h2 text-uppercase">
					<b>{{ 'SECCION' | translate }} 10</b>
				</span>
				<span class="h4">
					<b>{{ 'GOVERNING_LAW' | translate }} </b>
				</span>
				<p class="text-justify">{{ 'GOVERNING_LAWONE' | translate }} </p>
				
				<span class="h2 text-uppercase">
					<b>{{ 'SECCION' | translate }} 11</b>
				</span>
				<span class="h4">
					<b>{{ 'CHANGES_TERMS' | translate }} </b>
				</span>
				<p class="text-justify">{{ 'CHANGES_TERMSONE' | translate }} </p>
				
				
				<span class="h2 text-uppercase">
					<b>{{ 'SECCION' | translate }} 20</b>
				</span>
				<span class="h4">
					<b>{{ 'CONTACT_INFORMATION' | translate }} </b>
				</span>
				<p class="text-justify">{{ 'CONTACT_INFORMATIONONE' | translate }} </p>

			</div>
		</div>
	</div>
</article> -->

<!--// Privacy Policy \\-->
<!-- <article class="modal fade" id="policy" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
	<div class="modal-dialog" role="document">
		<div class="modal-content shopping">
			<div class="modal-header">
				<span class="h4"><b>{{ 'PRIVACYPOLICY' | translate }}</b></span>
				<button type="button" data-dismiss="modal" class="close" aria-hidden="true" aria-label="close">×</button>
			</div>

			<div class="container">

				<span class="h2 text-uppercase" ><b>{{ 'SECCION' | translate }} 1</b></span>
				<span class="h4"><b>{{ 'COLLECTEDINFORMATION' | translate }}</b></span>

				<p>{{ 'WECOLLECTINFORMATION' | translate }} </p>

				
				<ol class="text-justify">
					<li>{{ 'COLLECTEDINFORMATIONSECCIONONE' | translate }}</li>
					<li>{{ 'COLLECTEDINFORMATIONSECCIONTWO' | translate }}</li>
					<li>{{ 'COLLECTEDINFORMATIONSECCIONTHREE' | translate }}</li>
					<li>{{ 'COLLECTEDINFORMATIONSECCIONFOUR' | translate }}</li>
					<li>{{ 'COLLECTEDINFORMATIONSECCIONFIVE' | translate }}</li>
				</ol>
				
				<span class="h2 text-uppercase" ><b>{{ 'SECCION' | translate }} 2</b></span>
				<span class="h4"><b>{{ 'MANAGEMENTINFORMATION' | translate }} </b></span>

				<p class="text-justify">{{ 'WEMANAGEMENTINFORMATION' | translate }}  </p>

				<ol class="text-justify">
					<li> {{ 'MANAGEMENTINFORMATIONSECCIONONE' | translate }} </li>
					<li> {{ 'MANAGEMENTINFORMATIONSECCIONTWO' | translate }} </li>
					<li> {{ 'MANAGEMENTINFORMATIONSECCIONTHREE' | translate }} </li>
					<li> {{ 'MANAGEMENTINFORMATIONSECCIONFOUR' | translate }} </li>
					<li> {{ 'MANAGEMENTINFORMATIONSECCIONFIVE' | translate }} </li>
					<li> {{ 'MANAGEMENTINFORMATIONSECCIONSIX' | translate }} </li>
				</ol>

				<span class="h2 text-uppercase" ><b>{{ 'SECCION' | translate }}  3</b></span>
				<span class="h4"><b>{{ 'CONSENT' | translate }} </b></span>

				<span class="h3">{{ 'HOWDOCONSENT' | translate }}</span>
				<p class="text-justify">{{ 'HOWDOCONSENTONE' | translate }}</p>
				<p class="text-justify">{{ 'HOWDOCONSENTTWO' | translate }}</p>

				<span class="h3">{{ 'HOWDOWITHDRAW' | translate }}</span>
				<p class="text-justify">{{ 'HOWDOWITHDRAWONE' | translate }}</p>

				<span class="h2 text-uppercase"><b>{{ 'SECCION' | translate }} 4</b></span>
				<span class="h4"><b>{{ 'DISCLOSURE' | translate }}</b></span>

				<p class="text-justify">{{ 'DISCLOSUREONE' | translate }}</p>

				<span class="h2 text-uppercase"><b>{{ 'SECCION' | translate }}  5</b></span>
				<span class="h4"><b>{{ 'THIRDPARTY' | translate }}</b></span>

				<p class="text-justify">{{ 'THIRDPARTYONE' | translate }}</p>
				<p class="text-justify">{{ 'THIRDPARTYTWO' | translate }}</p>

				<span class="h2 text-uppercase"><b>{{ 'SECCION' | translate }}  6</b></span>
				<span class="h4"><b>{{ 'CHANGESPRIVACITY' | translate }}</b></span>

				<p class="text-justify">{{ 'CHANGESPRIVACITYONE' | translate }}</p>

				<span class="h2 text-uppercase"><b>{{ 'SECCION' | translate }} 7</b></span>
				<span class="h4"><b>{{ 'QUESTIONSANDCONTACT' | translate }}</b></span>

				<p class="text-justify">{{ 'QUESTIONSANDCONTACTONE' | translate }}</p>

			</div>
		</div>
	</div>
</article> -->