import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogCancelOrderComponent } from './dialog-cancel-order.component';
import { DynamicComponentLoaderModule } from 'app/core/dynamic-loader/dynamic-component-loader.module';

@NgModule({
  declarations: [DialogCancelOrderComponent],
  exports: [DialogCancelOrderComponent],
  imports: [
    CommonModule,
    DynamicComponentLoaderModule.forModule({
      componentId: 'cancel-order',
      path: 'cancel-order',
      loadChildren: () => import('./content/cancel-order.module').then(m => m.CancelOrderModule)
    }),
  ]
})
export class DialogCancelOrderModule { }
