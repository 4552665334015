import { NgModule } from '@angular/core';
import { DynamicComponentLoaderModule } from 'app/core/dynamic-loader/dynamic-component-loader.module';
import { DialogAddressComponent } from './dialog-address-modal.component';

@NgModule({
  declarations: [DialogAddressComponent],
  exports: [DialogAddressComponent],
  imports: [
    DynamicComponentLoaderModule.forModule({
      componentId: 'address-modal',
      path: 'address-modal',
      loadChildren: () => import('./content/address-modal.module').then(m => m.AddressModalModule)
    })
  ]
})
export class DialogAddressModule {}
