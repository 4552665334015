import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { DynamicComponentLoader } from 'app/core/dynamic-loader/dynamic-component-loader.service';

@Component({
    selector: 'app-dialog-register-component',
    template: '<div #termsAndConditions></div>' })
export class DialogTermsAndContiditionsComponent {
    @ViewChild('termsAndConditions', { read: ViewContainerRef, static: true }) termsAndConditions:
        | ViewContainerRef
        | undefined;

    constructor(private readonly loader: DynamicComponentLoader) {
        this.loader.getComponentFactory('terms-and-conditions').subscribe({
            next: factory => {
                if (!this.termsAndConditions) {
                    return;
                }
                this.termsAndConditions.createComponent(factory).changeDetectorRef.detectChanges();
            }
        });
    }
}
