import { Component, ViewChild, ViewContainerRef, OnInit } from '@angular/core';
import { DynamicComponentLoader } from 'app/core/dynamic-loader/dynamic-component-loader.service';

@Component({
  selector: 'app-dialog-branch-restaurants-modal',
  template: '<div #branchRestaurantsModal></div>'})
export class DialogBranchRestaurantsModalComponent implements OnInit {
  @ViewChild('branchRestaurantsModal', { read: ViewContainerRef, static: true }) branchRestaurantsModal:
    | ViewContainerRef
    | undefined;

  constructor(private readonly loader: DynamicComponentLoader) {
    this.loader.getComponentFactory('branch-restaurants-modal').subscribe({
      next: factory => {
        if (!this.branchRestaurantsModal) {
          return;
        }
        this.branchRestaurantsModal.createComponent(factory).changeDetectorRef.detectChanges();
      }
    });
  }

  ngOnInit() {/** */}

}
