import { NgModule } from '@angular/core';
import { DynamicComponentLoaderModule } from 'app/core/dynamic-loader/dynamic-component-loader.module';
import { DialogTermsAndContiditionsComponent } from './dialog-terms-and-conditions.component';

@NgModule({
    declarations: [DialogTermsAndContiditionsComponent],
    exports: [DialogTermsAndContiditionsComponent],
    imports: [
        DynamicComponentLoaderModule.forModule({
            componentId: 'terms-and-conditions',
            path: 'terms-and-conditions',
            loadChildren: () => import('./content/terms-and-conditions.module').then(m => m.TermsAndConditionsModule)
        }),
    ]
})
export class DialogTermsAndContiditionsModule { }
