import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { DynamicComponentLoader } from 'app/core/dynamic-loader/dynamic-component-loader.service';

@Component({
    selector: 'app-dialog-item-details-component',
    template: '<div #cartDetailsModal></div>'})
export class DialogItemDetailsComponent {
    @ViewChild('cartDetailsModal', { read: ViewContainerRef, static: true }) cartDetailsModal:
        | ViewContainerRef
        | undefined;

    constructor(private readonly loader: DynamicComponentLoader){
        this.loader.getComponentFactory('items-cart').subscribe({
            next: factory => {
                if (!this.cartDetailsModal) {
                    return;
                }
                this.cartDetailsModal.createComponent(factory).changeDetectorRef.detectChanges();
            }
        });
    }
}
