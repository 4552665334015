import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class ConfigComponent {
    /**
     * Variable url para utilizar en el envio de peticiones al webserivee
     */

    // SERVIDOR PRODUCCIÓN
    // urlOmtSd = 'https://omtws.openmytab.com/sd/restaurant';
    // urlOmt = 'https://omtws.openmytab.com/';

    // SERVIDOR DE STAGE
    // urlOmtSd: string = 'https://omtws.kijho.com/sd/restaurant';
    // urlOmt: string =  'https://omtws.kijho.com/';

    // SERVIDOR DE PRUEBAS
    // urlOmtSd = 'http://devomtws.kijho.co/sd/restaurant';
    // urlOmt = 'http://devomtws.kijho.co/';

    // SERVIDOR LOCAL
    // public urlOmtSd: string = 'http://omt-api.loc/app_dev.php/sd/restaurant';
    // public urlOmt: string =  'http://omt-api.loc/app_dev.php/';

    // SERVIDOR LOCAL
    // public urlOmtSd: string = 'http://omt.loc/app_dev.php/sd/restaurant';
    // public urlOmt: string =  'http://omt.loc/app_dev.php/';

    // SERVIDOR LOCAL
    // public urlOmtSd: string = 'http://192.168.0.160/OmtWsSymfony/web/app_dev.php/sd/restaurant';
    // public urlOmt: string =  'http://192.168.0.160/OmtWsSymfony/web/app_dev.php/';

    // SERVIDOR LOCAL Angel
    //  public urlOmtSd: string = 'http://127.0.0.1/WebServiceSymfony/web/app_dev.php/sd/restaurant';
    //  public urlOmt: string =  'http://127.0.0.1/WebServiceSymfony/web/app_dev.php/';

    // SERVIDOR LOCAL LUIS FER
    // public urlOmtSd: string = 'http://192.168.0.127/sd/restaurant';
    // public urlOmt: string =  'http://192.168.0.127/';

    // SERVIDOR PRUEBAS VIEJO (NODEJS)
    // urlOmtSd: string = 'http://openws.kijho.com/sd/restaurant';
    // urlOmt: string = 'http://openws.kijho.com/';

    // SERVIDOR LOCAL DOCKER
    // public urlOmtSd = 'http://omt.localhost/sd/restaurant';
    // public urlOmt =  'http://omt.localhost/';
    
    constructor(private http: HttpClient) { }

    public urlOmtSd: string = environment.urlOmtSd 
    public urlOmt: string = environment.urlOmt 
    public envName: string = environment.envName
    public urlLicensor: string = environment.urlLicensor

    /**
     * contiene la llave(apikey) para hacer uso de los servicios de google maps
     *
     * @NOTE: TENER EN CUENTA QUE ESTA API KEY TAMBIEN ESTÀ EN EL ARCHIVO APP.MODULE.TS
     * @type {string}
     */
    public apiKeyGMap = 'AIzaSyDy4Rq7RHrtAoSq8jpN2e_e7Cwjn8LqV1k';


    /**
     * Metod encargado de devolver la Ip de los webservice
     * Nota: Sin sd en la URL
     */
    getUrlOmt() {
        return this.urlOmt;
    }

    /**
     * Metod encargado de devolver la Ip de los webservice de licensor
     * Nota: Va con /ws en la URL
     */
    getUrlLicensor() {
        return this.urlLicensor;
    }

    /**
    * permite obtener el host de la url del servidor api
    * @returns {string}
    */
    public getUrlHostApi() {
        const url = new URL(this.getUrlOmt());
        return url.protocol.concat('//').concat(url.host).concat('/');
    }
}
