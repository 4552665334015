import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { RestaurantsInformationStructureResponse, RestaurantsServices } from 'app/core/interfaces/restaurants.interfaces';
import { catchError, map } from 'rxjs/operators';

import { AppConstants } from 'app/app-constants';
import { ConfigComponent } from 'app/core/config/config';
import { GeoPosition } from 'app/core/entities/GeoPosition';
import { Injectable } from '@angular/core';
import { Paginator } from 'app/core/entities/Paginator';
import { ResponsesStructure } from 'app/core/interfaces/transversal.interfaces';
import { formSearchRestaurants } from 'app/core/entities/formSearchRestaurants';

@Injectable()
export class RestaurantService implements RestaurantsServices{

    /**
     * Contiene la respuesta del ws del detalle del restaurante
     */
    public detailRestaurant: RestaurantsInformationStructureResponse;
    // Variable que almacena la url de los webservices
    private urlOmt: string;

    // Variable con unidad de conversion metros a millas
    milla = 1609;

    // Variable que almacena los parametros de consulta
    public paramsSearch: formSearchRestaurants;

    // Variables para manejo de filtro
    private itemsPerPage = 20;
    private distance = 25000;
    private cuisines = '';

    // Variables para control de ordenamiento
    order_by = 'distance';
    sort = 0;
    defaultPosition: any;

    constructor(private httpC: HttpClient,
        private config: ConfigComponent) {
        this.urlOmt = this.config.getUrlOmt();
        this.paramsSearch = null;
        this.loadParamsSearch();
    }

    /**
     * Permite cargar de la cache los parametros de busqueda
     */
    public loadParamsSearch(): void {
        const tmpParamsSearch = sessionStorage.getItem(AppConstants.SESSIONS_STORAGE_DATA.PARAMS_SEARCH_RESTAURANT);
        if (tmpParamsSearch !== null) {
            this.paramsSearch = JSON.parse(tmpParamsSearch);
        } else {
            this.paramsSearch = new formSearchRestaurants;
        }
    }

    /**
     * Permite obtener los parametros de busqueda alacenados en cache
     */
    public getParamsSearch(): formSearchRestaurants {
        return this.paramsSearch;
    }

    /**
     * permite establece los parametros de busqueda, para ser almacenados en cache
     * @param paramsSearch
     */
    public setParamsSearch(paramsSearch: formSearchRestaurants): void {
        this.paramsSearch = paramsSearch;
        // guardamos en cache
        sessionStorage.setItem(AppConstants.SESSIONS_STORAGE_DATA.PARAMS_SEARCH_RESTAURANT, JSON.stringify(paramsSearch));
    }

    /**
     * Metodo encargado de capturar todas las comidas registradas
     * en el webservice OMT
     */
    public getAllMeals(position: GeoPosition, range: number): Observable<ResponsesStructure>  {
        return this.getFilters('meals', position, range);
    }

    /**
     * Metodo encargado de capturar todas las cocinas registradas
     * en el webservice OMT
     */
    public getAllCuisines(position: GeoPosition, range: number): Observable<ResponsesStructure> {
        return this.getFilters('cuisines', position, range);
    }

    /**
     * Metodo encargado de capturar todas las caracteristicas registradas
     * en el webservice OMT
     */
    public getAllFeatures(position: GeoPosition, range: number): Observable<ResponsesStructure> {
        return this.getFilters('features', position, range);
    }

    /**
     * obtiene filtros para la busqueda avanza según el criterio
     * @param filter Meals,Features o Cuisines
     * @param userLang lenguaje
     * @param position coordenadas
     * @param range rango
     */
    /**
     * Se realiza el respectivo tipado del response de la petición mediante una interface con la
     * estructura del objeto
     */
    private getFilters(filter: string, position: GeoPosition, range: number): Observable<ResponsesStructure> {
        const params = this.buildParamsForFilters(position, range);
        const url = this.urlOmt + filter;
        return this.httpC.get<ResponsesStructure>(url, { params: params }).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),
            catchError(e => {
              return throwError(e);
            })
        );
    }

    /**
     * Construye parametros para obtener los filtros de Meals, Features y Cuisines
     * @param position coordenadas
     * @param range rango
     * @author Alvaro Felipe Garcia Mendez - Nov. 9-2018
     * @version 1.0.0
     */
    private buildParamsForFilters(position: GeoPosition, range: number): HttpParams {
        let params = new HttpParams();
        let objectForParams;
        if (position !== null) {
            objectForParams = {
                lat: String(position.lat),
                lng: String(position.lng),
                range: String(range)
            };
        }
        if (objectForParams) {
            params = params.append('latitude', objectForParams.lat);
            params = params.append('longitude', objectForParams.lng);
            params = params.append('range', objectForParams.range);
        }
        return params;
    }

    /**
     * Metodo encargado de solicitar a los web services la informacion detallada de un restaurante
     * @param idRestaurant: number -> Identificador del restaurante
     * @param userLang: string -> Idioma que maneja la aplicacion
     */
    /**
     * Se realiza correctamente el tipado del response de la petición con una interfaces que contiene
     * la estructura adecuada
     */
    public getDetailRestaurant(idRestaurant: string, paramsQuery = ''): Observable<ResponsesStructure> {
        const date = (new Date()).toUTCString();
        const headers = new HttpHeaders().set('Date', date);
        const urlDetail = this.urlOmt.concat('restaurant/').concat(idRestaurant).concat('?public=true') + paramsQuery;
        console.log("urlDetail", urlDetail);
        
        return  this.httpC.get<ResponsesStructure>(urlDetail, { headers }).pipe(
            map((response:ResponsesStructure) => {
              return response;
            }),
            catchError(e => {
            console.log("cae en error");
              return throwError(e);
              
            })
        );
    }

    /**
     * Metodo encargado de capturar las configuraciones generales para la carga inicial de la pagina
     */
    /**
     * Se realiza el tipado del response del servicio mediante la respectiva interface aunque no se
     * encontro ningúna implementación de este método
     */
    public getGeneralSettings(): Observable<ResponsesStructure> {
        const url = `${this.urlOmt}globalSettings`;
        return this.httpC.get<ResponsesStructure>(url).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),
            catchError(e => {
              return throwError(e);
            })
        );
    }

    /**
     * Se realiza el tipado de la interface para el request ya que falta el response
     * @param data 
     * @param restaurant_id 
     * @returns Observable<ResponsesStructure>
     */
    public postDineIn(data, restaurant_id): Observable<ResponsesStructure> {
        const url = this.urlOmt + 'dinein';
        const array = JSON.stringify({
            phone: data.phone + '',
            full_name: data.name,
            date: data.date,
            restaurant_id: restaurant_id,
            comments: data.comments,
            guests: data.guest + '',
            hour: data.hour
        });
        return this.httpC.post<ResponsesStructure>(url, array).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),
            catchError(e => {
              return throwError(e);
            })
        );
    }

    /**
    * Permite realizar la busqueda de restaurantes de acuerdo al formulario y permite obtener todos los restaurantes,
    * en caso de que se envie una posicion
    * @author Valeria Medina Ramírez - Marz. 27-2019
    * @version 1.0.0
    * @version 2
    * @param dataSearch
    */
   /**
    * Se realiza el tipado del response del servicio con su respectiva interface y se modifican las 
    * implementaciones del mismo
    */
    public getAllRestaurants(dataSearch, paginator: Paginator, range: number): Observable<ResponsesStructure> {
        let params = new HttpParams();
        const tmpUrlApi = this.urlOmt + 'restaurants';
        // primero tenemos en cuenta de si se esta haciendo una consulta avanzada
        if (dataSearch.isSearchAvanced) {
            // verificamos si esta solicitando algun servicio destacado en especial
            if (dataSearch.cuisines.length > 0) {
                // extraigo un arreglo con el id de cada cocina elegida
                const cuisines = dataSearch.cuisines.map(a => a.id);
                params = params.append('cuisines', cuisines.toString());
            }
            // verificamos si esta solicitando tipos meals en especial
            if (dataSearch.meals.length > 0) {
                const meals = dataSearch.meals.map(a => a.id);
                params = params.append('meals', meals.toString());
            }
            // verificamos si esta solicitando algun servicio destacado en especial
            if (dataSearch.features.length > 0) {
                const features = dataSearch.features.map(a => a.id);
                params = params.append('features', features.toString());
            }
        }
        // verificamos si se esta usando el filtro
        if (dataSearch.textFilter !== null) {
            if (dataSearch.textFilter.trim() !== '') {
                params = params.append('free_text', dataSearch.textFilter.trim());
            }
        }
        // verificamos si se esta enviando un paginador
        if (paginator == null) {
            // creamos una instancia ya que el trae unos valores por defecto
            paginator = new Paginator();
            paginator.toDefaultValues();
        }
        // verificamos si se esta buscando cerca a alguna ubicacion
        if (dataSearch.nearLocation) {
            params = params.append('latitude', String(dataSearch.nearLocation.lat));
            params = params.append('longitude', String(dataSearch.nearLocation.lng));
        }
        const userPosition = JSON.parse(sessionStorage.getItem(AppConstants.SESSIONS_STORAGE_DATA.USER_POSITION));
        if (!dataSearch.nearLocation && userPosition) {
            params = params.append('latitude', String(userPosition.lat));
            params = params.append('longitude', String(userPosition.lng));
        }
        // En caso de querer ordenar por cupones
        if (paginator.orderBy === 'coupon') {
            paginator.orderBy = 'distance';
            params = params.append('coupons', String('coupons'));
        }
        params = params.append('page', String(paginator.page));
        params = params.append('items_per_page', String(paginator.itemsPerPage));
        params = params.append('order_by', String(paginator.orderBy));
        params = params.append('sort', String(paginator.sort));
        params = params.append('range', String(range));
        return this.httpC.get<ResponsesStructure>(tmpUrlApi, {
            'params': params
        }).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),
            catchError(e => {
              return throwError(e);
            })
        );
    }

    /**
    * Se realiza el tipado del response del servicio con su respectiva interface y se modifican las 
    * implementaciones del mismo
    */
    getConfiguration(idRestaurant: string): Observable<ResponsesStructure> {
        const tmpUrlApi = this.urlOmt.concat('restaurant/').concat(idRestaurant).concat('/config-view');
        return this.httpC.get<ResponsesStructure>(`${tmpUrlApi}`).pipe(
            map((response: ResponsesStructure) => {
              return response;
            }),
            catchError(e => {
              return throwError(e);
            })
        );
    }

    /**
     * Método para obtener el estado de Upon delivery (contra entrega)
     * @param status
     */
    /**
     * Se realiza el tipado del response del servicio mediante la respectiva interface y se realiza 
     * la inyección del método dentro de la interface de servicio pero no se encontró ningúna 
     * implementación del mismo
     */
    getUponDelivery(idRestaurant: string): Observable<ResponsesStructure> {
        const url = `${this.urlOmt}sd/restaurant/${idRestaurant}/configuration`;
        return this.httpC.get<ResponsesStructure>(url).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),
            catchError(e => {
              return throwError(e);
            })
        );
    }

    /**
     * @author William Alarcon. - Marz. 20-2020
     * @version 1.0.0
     * @description Consulta el ws si el restaurante tiene mas servicios aparte de cupones
     * @param idRestaurant id del restaurante
     * @returns {} devuelve true en caso de tener mas servicios aparte de cupones
     */
     /**
     * Se realiza la creación de la interface respectiva para el response del servicio y se realiza
     * su debida inyección en la interface de servicios
     */
    public checkRestaurantServices(idRestaurant: string): Observable<ResponsesStructure> {
        // mirar si implementar en constantes globales
        const params = new HttpParams()
            .set('id_restaurant', idRestaurant)
            .set('item_ignore', AppConstants.SERVICES_RESTAURANT.SERVICE_COUPON.toString()
            + ',' + AppConstants.SERVICES_RESTAURANT.SERVICE_DINE_IN.toString());
        const url = `${this.urlOmt}checkRestaurantServices`;
        return this.httpC.get<ResponsesStructure>(url, { 'params': params }).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),
            catchError(e => {
              return throwError(e);
            })
        );
    }

    /**
     * Metodo encargado de pedir al web service el listado completo de restaurantes
     * Este enviara la respuesta completa que recibe del web service para su posterior manejo
     * en el listado
     * @param: items: number -> Numero de items que retornara por petición
     * @param: cleanSearch : boolean -> Variable para saber si se debe limpiar los parametros de busqueda
     */
    // tslint:disable-next-line:max-line-length
    /**
     * Se realiza la creación de la interface respectiva para el response del servicio y se realiza
     * su debida inyección en la interface de servicios
     */
    getListRestaurantsAsOption(itemsPerPage: number, allCuisines: Array<number>, pages: number, ranges: number, coordinates: GeoPosition = null): Observable<ResponsesStructure> {
        let url = this.urlOmt +
            'restaurants?items_per_page=' + itemsPerPage +
            '&page=' + pages +
            '&order_by=distance&sort=0&range=' + ranges;
        if (coordinates !== null) {
            url += '&latitude=' + coordinates.lat + '&longitude=' + coordinates.lng;
        }
        this.itemsPerPage = itemsPerPage;
        this.distance = 1 * this.milla;
        this.order_by = 'distance';
        this.sort = 0;
        return this.httpC.get<ResponsesStructure>(url).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),
            catchError(e => {
              return throwError(e);
            })
        );
    }

    /**
     * trae los restaurantes segun el criterio de busqueda
     * @param freeText
     */
    /**
     * Se realiza la creación de la interface con la estructura del objeto del response para su debido
     * tipado
     */
    getRestaurantsForNotifications(itemsPerPage: number, allCuisines: Array<number>,
        pages: number, coordinates: GeoPosition = null, freeText: any, rangeSearchRestaurants: any, restaurant?: string): Observable<ResponsesStructure> {
        let url = this.urlOmt + 'cli/notifications/restaurants?';
        this.itemsPerPage = itemsPerPage;
        this.order_by = 'distance';
        this.sort = 0;
        this.cuisines = allCuisines.toString();
        url += 'page=' + pages + '&items_per_page=' + this.itemsPerPage + '&cuisines='
            + this.cuisines + '&order_by=' + this.order_by + '&sort=' + this.sort;
        if (coordinates !== null) {
            url += '&latitude=' + coordinates.lat + '&longitude=' + coordinates.lng;
        }
        if (freeText) {
            url += '&free_text=' + freeText;
        }

        if(restaurant){
          url += '&restaurant=' + restaurant ;
        }
        console.log({restaurant})
        console.log("url", url)
        return this.httpC.get<ResponsesStructure>(url).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),
            catchError(e => {
              return throwError(e);
            })
        );
    }

    clearParamsSearch(): void {
        this.paramsSearch.textFilter = '';
        this.paramsSearch.cuisines = [];
        this.paramsSearch.features = [];
        this.paramsSearch.meals = [];
        this.paramsSearch.order = '';
        this.paramsSearch.nearLocation = null;
    }
}
