import { ChangeDetectorRef, Inject } from '@angular/core';
import { Component, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OrderServices } from "app/core/interfaces/orders.interfaces";
import { SweetAlertService } from 'app/utils/sweetAlert.service';
import * as moment from "moment";
import { UtilsComponent } from 'app/utils/utils.component';

@Component({
  selector: 'app-deferred-order',
  templateUrl: './deferred-order.component.html',
  styleUrls: ['./deferred-order.component.css']
})
export class DeferredOrderComponent implements OnInit {

  /**
   *  Variable para igualar el metodo calendar y así poderlo usar en el html de ordenes diferidas
   */
  public arrayDate = [];

  /**
   *  Variable de tipo arreglo para guardar el arreglo las horas para las ordes diferidas
   */
  public arrayHour = [];

  /**
   * Variables que guarda la fecha y la hora que el usuario selecciono
   */
  public dateMain: any;
  public timeMain: any;

  
  
  
  /**
   * Varibale donde se guarda el horarios y los días de la semana que abre el restaurante
   */
  private _openingTime: any;


  /**
   *  Variable para verificar si el restaurante está cerrado o abierto
   */
  public verifyRestaurantClose: boolean;
  
  constructor(

    private readonly _chRef: ChangeDetectorRef,
    private readonly _dialogRef: MatDialogRef<any>,
    private readonly sweetAlertService: SweetAlertService,
    @Inject("OrderServices") public orderServices: OrderServices,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public utils: UtilsComponent
    )
  {
    this.verifyRestaurantClose = false;
  }

  ngOnInit(): void {
    if(this.data){
      this._openingTime = this.data.openingTime;
      this.arrayDate = this.calendar();
      this.dateMain = this.arrayDate[0].dateValue;
      this.timeMain = (this.arrayHour.length > 0) ? this.arrayHour[0].timeValue : null;
      if(this.data.verifyRestaurantClose){
        this.verifyRestaurantClose = true;
      }else{
        this.verifyRestaurantClose = false;
      }
      if (!this._chRef['destroyed']) {
        // Refresca los cambios
        this._chRef.detectChanges();
      }
    }
  }

  /**
     *  Metodo para sustraer los numero de los dias que funciona el restaurante
     *  @param array // Array del horario del restaturante
     *  @param propiedad // La proipidad con la que hara la comparacion
     *  @author Mauricio Hernandez - Junio. 16-2023
     *  @version 1.0.0
     */

  obtenerValoresUnicos(array: any[], propiedad: keyof any): any[] {
    const valoresUnicos = array.reduce((valores, objeto) => {
      const valor = objeto[propiedad];
      if (!valores.has(valor)) {
        valores.add(valor);
      }
      return valores;
    }, new Set<any>());
  
    return Array.from(valoresUnicos);
  }

  
    /**
     *  Metodo para crear un calendario y recorrerlo para que muestre solo un rango
     *  de fechas para las ordenes diferidas
     *  @author Oscar Alarcon - marz. 10-2023
     *  @version 1.0.0
     */
    public calendar(){
       const hoursRestaurant = this.obtenerValoresUnicos(this._openingTime.hours_data,'weekDay');
      const starDateC: Date = new Date ();
      const starDate: Date = new Date ();
      const endDate: Date  = new Date(starDateC.setMonth(starDateC.getMonth()+1));
      const daysBetweendates = [];
      let today = moment(starDate).format('YYYY-MM-DD');
      let tomorrow = moment(starDate).add(1, 'day').format('YYYY-MM-DD');
      this.arrayHour = this.hours(moment(starDate).day());
      while (starDate <= endDate){

        let todayTomorrow = moment(starDate).format('MMM, dd DD');

        if(today == moment(starDate).format('YYYY-MM-DD')){
          todayTomorrow = moment(starDate).format('MMM, DD') + ' Today';
        } else if(tomorrow == moment(starDate).format('YYYY-MM-DD')){
          todayTomorrow = moment(starDate).format('MMM, DD') + ' Tomorrow';
        }
        if(hoursRestaurant.includes(String(moment(starDate).day()))){
            const d = {
                dateDeferred: todayTomorrow,
                dateValue: moment(starDate).format('YYYY-MM-DD'),
                weekDay: moment(starDate).day()
            };
            daysBetweendates.push(d);

         }
         starDate.setDate(starDate.getDate() + 1);
      }
      // validación para que cuando el restaurante cierre y en el día actual no haya ya horario para seleccionar, ese día se elimine y me muestre el día siguiente
      if(this.arrayHour.length == 0){
        daysBetweendates.shift();
        this.getDate(daysBetweendates[0].dateValue);
      }
  return daysBetweendates;
  }


  /**
   *  Metodo para crear un arreglo de horario para que quede por ejemplo
   *  12:00 -12-30 12-30 - 01:00 para las ordenes diferidas
   *  @author Oscar Alarcon - marz. 17-2023
   *  @version 1.0.0
   */
  public hours(day = moment().day()){
    const hoursRestaurant = this.obtenerValoresUnicos(this._openingTime.hours_data,'weekDay');
    const hourT: any = this._openingTime;
    const numberDay = day;
    const currentDay = moment().day();
    const typeService = this.orderServices.getTypeOrder();
    if(!hoursRestaurant.includes(String(numberDay))){ 
      return [];
    }
     hourT.hours.forEach(element => {
        // se evalua el servicio que se selecciona
        if(typeService == element.attentionType && Number(element.weekDay) == numberDay){
            this.orderServices.currentTime = element;
        }
    });
    let startTime: any = moment(this.orderServices.currentTime.hourFrom, 'HHmm A').add(30, 'minutes');
    let endTime = moment(this.orderServices.currentTime.hourFrom, 'HHmm A').add(60, 'minutes');
    let actualTime: any = moment().add(30, 'minutes').format('HH:mm:ss');
    let minutesBetweenHours = [];
    for (let index = 0; index < 47; index++) {
      // Desición para que la hora del arreglo inicie 30 min despues de la apertura del restaurante ejemplo si el restaurante abre a las 9:00 AM
      // la primera hora que aparece es 9:30 AM - 10:00 AM
        if(startTime.format('HH:mm:ss') >= this.orderServices.currentTime.hourFrom && endTime.format('HH:mm:ss') <= this.orderServices.currentTime.hourTo){
          // Decisión para no aparezca la hora cuando la hora ya haya pasado por ejemplo el restaurante abre a las 9:00 AM y la hora real es 11:00 AM
          // entonces antes de las 11 no aparecen horas para seleccionar
          if(`${startTime.format('HH:mm:ss')} - ${endTime.format('HH:mm:ss')}` <= actualTime && currentDay == numberDay){
            minutesBetweenHours.shift();
          };
          // Se crean las horas en el arreglo para hacer la orden diferida
            minutesBetweenHours.push({
                timeDeferred: `${startTime.format('hh:mm A')} - ${endTime.format('hh:mm A')}`,
                timeValue: `${startTime.format('HH:mm:ss')}`,
                
                value: {
                    startTime: startTime.format('HHmmss'),
                    endTime: endTime.format('hh:mm A'),
                },
                disabled: true
            });
                startTime = startTime.add(30, 'minutes');
                endTime.add(30, 'minutes');
        } 
    }
    // Se elimina la ultima posición del arreglo para que el usuario no seleccione la hora en la que el restaurante cierra si no que 30 min 0 60 antes
    minutesBetweenHours.pop();
    return minutesBetweenHours
}


    /**
     *  Metodo para obtener el valor de la fecha o el tiempo de ordenes diferidas y guardarlo y enviarlo
     *  al WS de orders
     *  @author Oscar Alarcon - marz. 14-2023
     *  @version 1.0.0
     */
    public ObterDataDeferred(){
      this.orderServices.dataDeferred.dateDeferred = this.dateMain;
      this.orderServices.dataDeferred.timeDeferred = this.timeMain;
      // Se agregan datadeferredInfo y timeDeferredInfo para colocarlo de información en las modale del detalle de la orden
      this.orderServices.dataDeferred.dataDeferredInfo = moment(this.dateMain).format('MM-DD-YYYY');
      this.orderServices.dataDeferred.timeDeferredInfo = moment(this.timeMain, 'HH:mm').format('hh:mm A');
      // Se valida para mostrarle al usuario que debe de seleccionar la fecha y la hora para poder seguir con el proceso de hacer una
      // orden diferida
      if(!this.timeMain || !this.dateMain){
        this.sweetAlertService.SelectDateAndHour();
        return false;
      }else{
        this._dialogRef.close();
      }
  }



    /**
     *  Metodo para obtener el valor seleccionado de la lista de fechas de ordenes diferidas
     *  y darselo a dateMain que la variable que guarda lo que se selecciona del arreglo del 
     *  metodo ObterDataDeferred()
     *  @author Oscar Alarcon - marz. 19-2023
     *  @version 1.0.0
     */    
    public getDate(dateValue){
      this._defaultValues();
      const numberDay = moment(dateValue).day(); 
      this.arrayHour = this.hours(numberDay);
      this.dateMain = dateValue;
    }

    /**
     *  Metodo para obtener el valor seleccionado de la lista de horas de las ordenes diferidas
     *  y darselo a timeMain que la variable que guarda lo que se selecciona del arreglo del 
     *  metodo hours()
     *  @author Oscar Alarcon - marz. 19-2023
     *  @version 1.0.0
     */
    public getTime(timeValue){
      this.timeMain = timeValue;
      if(!this.verifyRestaurantClose){
        this.ObterDataDeferred();
      }
    }


    /**
     *  Metodo para cerrar la modal de ordenes diferidas y setear los campos de la modal de order diferred
     *  @author Oscar Alarcon - marz. 14-2023
     *  @version 1.0.0
     */
      public cancelDeferred(){
        this._defaultValues();
        this._dialogRef.close();
      }
  
    /**
     *  Metodo para borrar el valor de los campos cuando se presione el botón de cancelar o deliver now de la modal de order deferred
     *  @author Oscar Alarcon - marz. 14-2023
     *  @version 1.0.0
     */
   private _defaultValues(){
        this.orderServices.dataDeferred.dateDeferred = null;
        this.orderServices.dataDeferred.timeDeferred = null;
        this.dateMain = null;
        this.timeMain = null;
   }

   public closeModalDeferred(){
    this._dialogRef.close();
   }
}
