import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ConfigComponent } from 'app/core/config/config';
import { CreditCardsServices } from 'app/core/interfaces/credit-cards.interfaces';
import { ResponsesStructure } from 'app/core/interfaces/transversal.interfaces';

@Injectable()
export class CreditCardService implements CreditCardsServices{

    public onChange: EventEmitter<void>;

    constructor(
        private readonly http: HttpClient,
        private readonly config: ConfigComponent)
    {
        this.onChange = new EventEmitter<any>();
    }

    /**
     * Permite verificar si el numero de tarjeta de credito ya esta registrado
     * @param dataCard
     */
    /**
     * Pendiente finalizar el tipado del request y el response del servicio, se detecto un problema en la parte del back-end y esta bajo revisión
     */
    public isAlreadyRegistered(dataCard): Observable<ResponsesStructure> {
        const url = this.config.getUrlOmt().concat('cli/credit-cards/verify-number');
        return this.http.post<ResponsesStructure>(url, dataCard, { headers: new HttpHeaders({ timeout: `${10000}` })}).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),catchError(error => {
                return throwError(error);
            })
        );
    }

    /**
     * Permite obtener la informacion de una tarjeta de credito
     * @param dataCard
     */
    /** Se realiza correctamente el tipado del response del servicio consumido
    *   Es una url que se utizo para simular un pago exitoso antes de que se implementara la pasarela de pagos
    *   La respuesta tiene todos los datos del pago encriptados en un token
    */
    public getCardArmor(dataCard): Observable<ResponsesStructure> {
        const url = this.config.getUrlOmt() + 'cli/credit-cards/' + dataCard + '/pay';
        return this.http.get<ResponsesStructure>(url, { headers: new HttpHeaders({ timeout: `${10000}` }) }).pipe(
            map((response: ResponsesStructure) =>{
                return response;
            }),catchError(error => {
                return throwError(error);
            })
        );
    }

    /**
     * Permite obtener la informacion de una tarjeta de credito
     * @param dataCard
     */
    /**
     * Se realiza correctamente el tipado del response del servicio consumido
     */
    public getListDates(): Observable<ResponsesStructure> {
        const url = this.config.getUrlOmt() + 'cli/credit-cards/list-dates';
        return this.http.get<ResponsesStructure>(url, { headers: new HttpHeaders({ timeout: `${10000}` }) }).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),catchError(error => {
                return throwError(error);
            })
        )
    }

    /**
     * Permite obtener la tarjeta de credito principal de un cliente
     */
    /**
     * Se realiza correctamente el tipado del response del servicio consumido
     */
    public getDefaultCard(): Observable<ResponsesStructure> {
        const url = this.config.getUrlOmt() + 'cli/credit-cards/main';
        return this.http.get<ResponsesStructure>(url).pipe(
            map((response: ResponsesStructure) =>{
                return response;
            }),catchError(error => {
                return throwError(error);
            })
        );
    }

    /**
     * Permite obtener todas las tarjetas de credito de un cliente
     */
    /**
     * Se realiza correctamente el tipado del response del servicio consumido
     */
    public getListCards(): Observable<ResponsesStructure> {
        const url = this.config.getUrlOmt() + 'cli/credit-cards';
        return this.http.get<ResponsesStructure>(url).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),catchError(error => {
                return throwError(error);
            })
        );
    }

    /**
     * Permite establecer una tarjeta de credito como la principal
     * @param cardId
     */
    /**
     * Se realiza correctamente el tipado del response del servicio consumido
     */
    public setMainCard(cardId): Observable<ResponsesStructure> {
        const url = this.config.getUrlOmt() + 'cli/credit-cards/' + cardId + '/as-main';
        return this.http.post<ResponsesStructure>(url, {}).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),catchError(error => {
                return throwError(error);
            })
        );
    }

    /**
     * Permite registrar una tarjeta de credito
     * @param card
     */
    /**
     * Se realiza correctamente el tipado del response del servicio consumido, se envía y se recibe 
     * la información de la tarjeta escriptada en un token
     */
    public saveNewCard(card: string): Observable<ResponsesStructure>  {
        const url = this.config.getUrlOmt() + 'cli/credit-cards';
        const dataCard = {
            'token': card,
        };
        return this.http.post<ResponsesStructure>(url, dataCard).pipe(
            map((response: ResponsesStructure) =>{
                return response;
            }),catchError(error => {
                return throwError(error);
            })
        );
    }

    /**
     * Permite guardar los cambios de una tarjeta de credito
     *
     * @param card
     * @param card_id
     */
    /**
     * Se realiza correctamente el tipado del response del servicio consumido, se envía y se recibe 
     * la información de la tarjeta escriptada en un token
     */
    public saveChangeCard(card: string, card_id: string): Observable<ResponsesStructure> {
        const url = this.config.getUrlOmt() + 'cli/credit-cards/' + card_id;
        const dataCard = {
            'token': card,
        };
        return this.http.put<ResponsesStructure>(url, dataCard).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),catchError(error => {
                return throwError(error);
            })
        );
    }

    /**
     * Permite eliminar una tarjeta de credito de un cliente
     * @param cardId
     */
    /**
     * Se realiza correctamente el tipado del response del servicio consumido, se envía y se recibe 
     * la información de la tarjeta escriptada en un token
     */
    public deleteCard(cardId): Observable<ResponsesStructure> {
        const url = this.config.getUrlOmt() + 'cli/credit-cards/' + cardId;
        return this.http.delete<ResponsesStructure>(url).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),catchError(error => {
                return throwError(error);
            })
        );
    }

    /**
     * Permite identificar la franquisia de la tarjeta del cliente
     * @param numberCard
     */
    public getFranchiseFromNumber(numberCard: string): string {
        // visa
        // let re = new RegExp('^4');
        if (this.isFranchiseNumber(numberCard, 'Visa')) {
            return 'VISA';
        }
        // Mastercard
        // Updated for Mastercard 2017 BINs expansion
        // if (/^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(numberCard)) {
        if (this.isFranchiseNumber(numberCard, 'MasterCard')) {
            return 'MASTER_CARD';
        }
        // MESTRO
        if (this.isFranchiseNumber(numberCard, 'Maestro')) {
            return 'MAESTRO';
        }
        // Discover
        // re = new RegExp('^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)');
        // if (numberCard.match(re) != null) {
        if (this.isFranchiseNumber(numberCard, 'Discover')) {
            return 'DISCOVER';
        }
        // Diners
        if (this.isFranchiseNumber(numberCard, 'DinersClub')) {
            return 'DINERS';
        }
        // Diners - Carte Blanche
        // re = new RegExp('^30[0-5]');
        if (this.isFranchiseNumber(numberCard, 'CarteBlanche')) {
            return 'DINERS_-_CARTE_BLANCHE';
        }
        // AMEX
        // re = new RegExp('^3[47]');
        // if (numberCard.match(re) != null) {
        if (this.isFranchiseNumber(numberCard, 'AmEx')) {
            return 'AMERICAN_EXPRESS';
        }
        // JCB
        // re = new RegExp('^35(2[89]|[3-8][0-9])');
        // if (numberCard.match(re) != null) {
        if (this.isFranchiseNumber(numberCard, 'JCB')) {
            return 'JCB';
        }
        // Visa Electron
        // re = new RegExp('^(4026|417500|4508|4844|491(3|7))');
        // if (numberCard.match(re) != null) {
        if (this.isFranchiseNumber(numberCard, 'VisaElectron')) {
            return 'VISA_ELECTRON';
        }
        return null;
    }

    /**
     * obtiene el operador de las tarjetas de credito
     * @param creditCardNumber
     * @returns {string}
     */
    public getCodeFranchise(franchiseCreditCard): string {
        // obtenemos el identificador de la tarjeta para saber quien es el operador
        let cardType = '';
        if (franchiseCreditCard === 'AMERICAN_EXPRESS') {
            cardType = 'AmericanExpress';
        }
        if (franchiseCreditCard === 'VISA') {
            cardType = 'Visa';
        }
        if (franchiseCreditCard === 'MASTER_CARD') {
            cardType = 'MasterCard';
        }
        if (franchiseCreditCard === 'DISCOVER') {
            cardType = 'Discover';
        }
        if (franchiseCreditCard === 'DINERS') {
            cardType = 'Diners';
        }
        if (franchiseCreditCard === 'JCB') {
            cardType = 'JCB';
        }
        if (franchiseCreditCard === 'MAESTRO') {
            cardType = 'Maestro';
        }
        if (franchiseCreditCard === 'DINERS_-_CARTE_BLANCHE') {
            cardType = 'Diners-CarteBlanche';
        }
        if (franchiseCreditCard === 'VISA_ELECTRON') {
            cardType = 'VisaElectron';
        }
        return cardType;
    }

    /**
     * Permite validar si la tarjeta de credito es válida
     * @param creditCardNumber
     */
    public isValidNumberCard(creditCardNumber: string): boolean {
        // accept only digits, dashes or spaces
        // tslint:disable-next-line:curly
        if (/[^0-9-\s]+/.test(creditCardNumber))
            return false;
        // The Luhn Algorithm. It's so pretty.
        // en este caso aplicamos el algoritmo de Luhn
        let nCheck = 0,
            nDigit = 0,
            bEven = false;
        const tmpNumber = creditCardNumber.replace(/\D/g, '');
        for (let n = tmpNumber.length - 1; n >= 0; n--) {
            const cDigit = tmpNumber.charAt(n);
            nDigit = parseInt(cDigit, 10);
            if (bEven) {
                if ((nDigit *= 2) > 9) {
                    nDigit -= 9;
                }
            }
            nCheck += nDigit;
            bEven = !bEven;
        }
        return (nCheck % 10) === 0;
    }

    /**
     * Permite validar un numero con una franquicia
     *
     * @param numberCard
     * @param franchise
     */
    private isFranchiseNumber(numberCard, franchise): boolean {
        let ccErrorNo = 0;
        const ccErrors = new Array();
        ccErrors[0] = 'Unknown card type';
        ccErrors[1] = 'No card number provided';
        ccErrors[2] = 'Credit card number is in invalid format';
        ccErrors[3] = 'Credit card number is invalid';
        ccErrors[4] = 'Credit card number has an inappropriate number of digits';
        ccErrors[5] = 'Warning! This credit card number is associated with a scam attempt';
        function checkCreditCard(cardnumber, cardname) {
            // Array to hold the permitted card characteristics
            let cards = new Array();
            // Define the cards we support. You may add addtional card types as follows.

            //  Name:         As in the selection box of the form - must be same as user's
            //  Length:       List of possible valid lengths of the card number for the card
            //  prefixes:     List of possible prefixes for the card
            //  checkdigit:   Boolean to say whether there is a check digit
            cards[0] = {
                name: 'Visa',
                length: '13,16',
                prefixes: '4',
                checkdigit: true
            };
            cards[1] = {
                name: 'MasterCard',
                length: '16',
                prefixes: '51,52,53,54,55',
                checkdigit: true
            };
            cards[2] = {
                name: 'DinersClub',
                length: '14,16',
                prefixes: '36,38,54,55',
                checkdigit: true
            };
            cards[3] = {
                name: 'CarteBlanche',
                length: '14',
                prefixes: '300,301,302,303,304,305',
                checkdigit: true
            };
            cards[4] = {
                name: 'AmEx',
                length: '15',
                prefixes: '34,37',
                checkdigit: true
            };
            cards[5] = {
                name: 'Discover',
                length: '16',
                prefixes: '6011,622,64,65',
                checkdigit: true
            };
            cards[6] = {
                name: 'JCB',
                length: '16',
                prefixes: '35',
                checkdigit: true
            };
            cards[7] = {
                name: 'enRoute',
                length: '15',
                prefixes: '2014,2149',
                checkdigit: true
            };
            cards[8] = {
                name: 'Solo',
                length: '16,18,19',
                prefixes: '6334,6767',
                checkdigit: true
            };
            cards[9] = {
                name: 'Switch',
                length: '16,18,19',
                prefixes: '4903,4905,4911,4936,564182,633110,6333,6759',
                checkdigit: true
            };
            cards[10] = {
                name: 'Maestro',
                length: '12,13,14,15,16,18,19',
                prefixes: '5018,5020,5038,6304,6759,6761,6762,6763',
                checkdigit: true
            };
            cards[11] = {
                name: 'VisaElectron',
                length: '16',
                prefixes: '4026,417500,4508,4844,4913,4917',
                checkdigit: true
            };
            cards[12] = {
                name: 'LaserCard',
                length: '16,17,18,19',
                prefixes: '6304,6706,6771,6709',
                checkdigit: true
            };
            // Establish card type
            let cardType = -1;
            for (let i = 0; i < cards.length; i++) {

                // See if it is this card (ignoring the case of the string)
                if (cardname.toLowerCase() === cards[i].name.toLowerCase()) {
                    cardType = i;
                    break;
                }
            }
            // If card type not found, report an error
            if (cardType === -1) {
                ccErrorNo = 0;
                return false;
            }
            // Ensure that the user has provided a credit card number
            if (cardnumber.length === 0) {
                ccErrorNo = 1;
                return false;
            }
            // Now remove any spaces from the credit card number
            cardnumber = cardnumber.replace(/\s/g, '');
            // Check that the number is numeric
            const cardNo = cardnumber;
            const cardexp = /^[0-9]{13,19}$/;
            if (!cardexp.exec(cardNo)) {
                ccErrorNo = 2;
                return false;
            }
            // Now check the modulus 10 check digit - if required
            if (cards[cardType].checkdigit) {
                let checksum = 0;                                  // running checksum total
                let j = 1;                                         // takes value of 1 or 2
                // Process each digit one by one starting at the right
                let calc;
                for (let i = cardNo.length - 1; i >= 0; i--) {
                    // Extract the next digit and multiply by 1 or 2 on alternative digits.
                    calc = Number(cardNo.charAt(i)) * j;
                    // If the result is in two digits add 1 to the checksum total
                    if (calc > 9) {
                        checksum = checksum + 1;
                        calc = calc - 10;
                    }
                    // Add the units element to the checksum total
                    checksum = checksum + calc;
                    // Switch the value of j
                    if (j === 1) { j = 2 } else { j = 1 }
                }
                // All done - if checksum is divisible by 10, it is a valid modulus 10.
                // If not, report an error.
                if (checksum % 10 !== 0) {
                    ccErrorNo = 3;
                    return false;
                }
            }
            // Check it's not a spam number
            if (cardNo === '5490997771092064') {
                ccErrorNo = 5;
                return false;
            }
            // The following are the card-specific checks we undertake.
            let LengthValid = false;
            let PrefixValid = false;
            // We use these for holding the valid lengths and prefixes of a card type
            let prefix = new Array();
            let lengths = new Array();
            // Load an array with the valid prefixes for this card
            prefix = cards[cardType].prefixes.split(',');
            // Now see if any of them match what we have in the card number
            for (let i = 0; i < prefix.length; i++) {
                let exp = new RegExp('^' + prefix[i]);
                if (exp.test(cardNo)) PrefixValid = true;
            }
            // If it isn't a valid prefix there's no point at looking at the length
            if (!PrefixValid) {
                ccErrorNo = 3;
                return false;
            }
            // See if the length is valid for this card
            lengths = cards[cardType].length.split(',');
            for (let j = 0; j < lengths.length; j++) {
                if (cardNo.length === Number(lengths[j])) LengthValid = true;
            }
            // See if all is OK by seeing if the length was valid. We only check the length if all else was 
            // hunky dory.
            if (!LengthValid) {
                ccErrorNo = 4;
                return false;
            }
            // The credit card is in the required format.
            return true;
        }
        // ejecutamos la funcion
        return checkCreditCard(numberCard, franchise);
    }

    /**
     * Permite eliminar caracteres no validos de una cadena que pertenece la informacion de una tareta de credito
     * para este caso solo permitira letras exepctuando la ñ y digitos
     *
     * @param characters
     * @param allowDigits
     * @param allowLetters
     */
    public removeInvalidCharacters(
            characters: string,
            allowDigits: boolean = true,
            allowLetters: boolean = true,
            allowSpaces: boolean = true): string {
        let allowCharacters = [];
        const numbers = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
        const letters = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm',
            'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', '\''];
        // verificamos si vamos a permitir numeros en la cadena
        if (allowDigits) {
            allowCharacters = allowCharacters.concat(numbers);
        }
        // verificamos si vamos a permitir letras en la cadena
        if (allowLetters) {
            allowCharacters = allowCharacters.concat(letters);
        }
        // verificamos si vamos a permitir espacios en la cadena
        if (allowSpaces) {
            allowCharacters.push(' ');
        }
        const __removeChars = (chars: string): string => {
            let tmpChairs = '';
            // en caso de no haber caracteres paramos la recursividad
            if (chars.length === 0) {
                return tmpChairs;
            }
            // tomamos el primer caracter lo pasamos a minuscula y verificamos si es permitido
            if (allowCharacters.includes(String(chars.charAt(0)).toLowerCase())) {
                tmpChairs = chars.charAt(0);
            }
            // en caso de solamnete haber 1 paramos la recursividad
            if (chars.length === 1) {
                return tmpChairs;
            }
            // como hay mas caracteres validamos con el resto de caracteres
            return tmpChairs + __removeChars(chars.substring(1, chars.length));
        };
        // aplicamos el filtro
        return __removeChars(characters);
    }
}
