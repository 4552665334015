import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { DynamicComponentLoader } from 'app/core/dynamic-loader/dynamic-component-loader.service';

@Component({
    selector: 'app-dialog-notification-detail',
    template: '<div #notificationDetail></div>'})
export class DialogNotificationDetailComponent {

    @ViewChild('notificationDetail', { read: ViewContainerRef, static: true }) notificationDetail:
        | ViewContainerRef
        | undefined;

    constructor(private readonly loader: DynamicComponentLoader) {
        this.loader.getComponentFactory('notification-detail').subscribe({
            next: factory => {
                if (!this.notificationDetail) {
                    return;
                }
                this.notificationDetail.createComponent(factory).changeDetectorRef.detectChanges();
            }
        });
    }
}
