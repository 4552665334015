import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

/*
 * Pasar metros a kilometros(km) o millas(mi)
 * Toma el valor enviado y devuelve la distancia transformada.
 * Example:
 *   {{ 1000 | distance:'km' }}
 *   distance to: 1
*/
@Pipe({ name: 'distance' })
export class DistancePipe implements PipeTransform {


    constructor(private datePipe: DecimalPipe) { }

    /**
     * Permite obtener la transformacion de un numero a [km, mi, ...] con o sin la abreviacion
     *
     * @author Jhonier Gaviria M. - Ago. 30-2018
     * @version 1.1.0
     *
     * @param number Numero a transformar
     * @param decPlaces Cantidad de decimales
     * @param withAbbreviation Indica si lleva la abreviacion
     */
    abbrNum(number, decPlaces, withAbbreviation: boolean) {
        // 2 decimal places => 100, 3 => 1000, etc
        decPlaces = Math.pow(10, decPlaces);

        // Enumerate number abbreviations
        const abbrev = ['k', 'm', 'b', 't'];

        // Go through the array backwards, so we do the largest first
        for (let i = abbrev.length - 1; i >= 0; i--) {

            // Convert array index to "1000", "1000000", etc
            const size = Math.pow(10, (i + 1) * 3);

            // If the number is bigger or equal do the abbreviation
            if (size <= number) {
                // Here, we multiply by decPlaces, round, and then divide by decPlaces.
                // This gives us nice rounding to a particular decimal place.
                number = Math.round(number * decPlaces / size) / decPlaces;

                // Handle special case where we round up to the next abbreviation
                if ((number === 1000) && (i < abbrev.length - 1)) {
                    number = 1;
                    i++;
                }

                // Add the letter for the abbreviation
                if (withAbbreviation) {
                    number += abbrev[i];
                }

                // We are done... stop
                break;
            }
        }
        return number;
    }

    ceilDecimal(value: number) {
        const format = +this.datePipe.transform(value, '1.0-1');
        const normal = value;
        if (format < 0.1) {
            return +this.datePipe.transform(normal, '1.0-2');
        }
        return format;
    }

    /**
     * @see {PipeTransform#transform}
     */
    transform(value: number, ret: string, withAbbreviation: boolean = true): string {

        let result = value;

        if (ret === 'mi') {
            result = ((result / 1000) / 1.609);
        } else if (ret === 'km') {
            result = (result / 1000);
        }

        if (result >= 1000) {
            result = this.abbrNum(result, 1, withAbbreviation);
        } else {
            result = this.ceilDecimal(result);
        }
        return String(result);
    }
}
