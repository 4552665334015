import { NgModule } from '@angular/core';
import { DynamicComponentLoaderModule } from 'app/core/dynamic-loader/dynamic-component-loader.module';
import { DialogNotificationDetailComponent } from './dialog-notification-detail.component';

@NgModule({
    declarations: [DialogNotificationDetailComponent],
    exports: [DialogNotificationDetailComponent],
    imports: [
        DynamicComponentLoaderModule.forModule({
            componentId: 'notification-detail',
            path: 'notification-detail',
            loadChildren: () => import('./content/notification-detail.module').then(m => m.NotificationDetailModule)
        })
    ]
})
export class DialogNotificationDetailModule { }
