import { ConfigComponent } from 'app/core/config/config';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RestaurantReviewsStructureRequest, ReviewsServices } from 'app/core/interfaces/reviews.interfaces';
import { Observable, throwError } from 'rxjs';
import { ResponsesStructure } from 'app/core/interfaces/transversal.interfaces';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class ReviewsService implements ReviewsServices{

    // Variable que almacena la url de los webservices
    private urlOmt = this.config.getUrlOmt();

    url: string;

    /**
     * Contructor de la clase
     * @param httpClient
     * @param config
     */
    constructor(public httpClient: HttpClient,
        private config: ConfigComponent) { }

    /**
     * Permite traer las reviews de un cliente
     *
     * @author Jhonier Gaviria M. - Ago. 29-2018
     * @version 1.1.0
     *
     * @param page Es la pagina a traer la informacion
     */
    /**
     * Se realiza la creación de la debida interface para el tipado del response del servicio consumido
     * adicionalmente se agrega el método a la inyección de servicios
     */
    getReviewsList(page: number = 1, idRestaurant = null): Observable<ResponsesStructure> {
        let params = new HttpParams();
        params = params.append('page', String(page));
        if (idRestaurant) {
            params = params.append('idRestaurant', String(idRestaurant));
        }
        const url = `${this.urlOmt}cli/my-reviews`;
        return this.httpClient.get<ResponsesStructure>(url, { params: params }).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),
            catchError(e => {
              return throwError(e);
            })
        );
    }

    /**
     * permite listar los reviews por restaurante
     */
    /**
     * Se realiza la creación de la debida interface para el tipado del response del servicio consumido
     * adicionalmente se agrega el método a la inyección de servicios
     */
    getAllReviews(idRestaurant: any, queryParams: string): Observable<ResponsesStructure> {
        const url = this.urlOmt + 'reviews-restaurant/' + idRestaurant + '?' + queryParams;
        return this.httpClient.get<ResponsesStructure>(url).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),
            catchError(e => {
              return throwError(e);
            })
        );
    }

    /**
     * permite agregar review por restaurante
     */
    /**
     * Se realiza la creación de la debida interface para el tipado del request y response del servicio
     * consumido adicionalmente se agrega el método a la inyección de servicios
     */
    addReviewsRestaurant(data: RestaurantReviewsStructureRequest): Observable<ResponsesStructure> {
        const url = `${this.urlOmt}cli/review-restaurant`;
        return this.httpClient.post<ResponsesStructure>(url, data).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),
            catchError(e => {
              return throwError(e);
            })
        );
    }

    /**
     * permite editar review por restaurante
     */
     /**
     * Se realiza la creación de la debida interface para el tipado del response del servicio
     * consumido adicionalmente se agrega el método a la inyección de servicios
     */
    updateReviewsRestaurant(data: any): Observable<ResponsesStructure> {
        const tmpData: RestaurantReviewsStructureRequest = {
            comment: data.comment,
            review_id: data.id,
            rating: data.rating,
        };
        const url = `${this.urlOmt}cli/review-restaurant`;
        return this.httpClient.put<ResponsesStructure>(url, tmpData).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),
            catchError(e => {
              return throwError(e);
            })
        );
    }

    /**
     * permite eliminar review por restaurante
     */
    /**
     * Se realiza la creación de la debida interface para el tipado del response del servicio
     * consumido adicionalmente se agrega el método a la inyección de servicios
     */
    deleteReviewRestaurant(reviewId: any): Observable<ResponsesStructure> {
        const url = `${this.urlOmt}cli/review-restaurant/${reviewId}`;
        return this.httpClient.delete<ResponsesStructure>(url).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),
            catchError(e => {
              return throwError(e);
            })
        );
    }

    /**
     * Se encarga de cargar la lista de restaurantes a los que se le ha hecho un review
     *
     * @author Angel Andres Diaz - Jul. 08-2018
     * @version 1.0.0
     *
     * @param
     */
    /**
     * Se realiza la creación de la debida interface para el tipado del response del servicio
     * consumido adicionalmente se agrega el método a la inyección de servicios
     */
    myReviewsRestaurants(): Observable<ResponsesStructure> {
        this.url = this.config.getUrlOmt() + 'cli/reviews-restaurants';
        return this.httpClient.get<ResponsesStructure>(this.url).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),
            catchError(e => {
              return throwError(e);
            })
        );
    }
    
    /**
    * se encarga de enviar la peticion al WS con el criterio de busqueda
    * @param params
    */
   /**
     * Se realiza la creación de la debida interface para el tipado del response del servicio
     * consumido adicionalmente se agrega el método a la inyección de servicios
     */
    filterReviews(params): Observable<ResponsesStructure> {
        const url = this.config.getUrlOmt() + 'cli/my-reviews' + params;
        return this.httpClient.get<ResponsesStructure>(url).pipe(
            map((response: ResponsesStructure) => {
                return response;
            }),
            catchError(e => {
              return throwError(e);
            })
        );
    }

}
