import { UtilsComponent } from 'app/utils/utils.component';

import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ExternalMenuServices } from 'app/core/interfaces/external-menu.interfaces';
import { DialogPrivacyPolicyComponent } from 'app/shared/dynamic-modules/privacy-policy/dialog-privacy-policy.component';
import { DialogTermsAndContiditionsComponent } from 'app/shared/dynamic-modules/terms-and-conditions/dialog-terms-and-conditions.component';

@Component({
  selector: 'mainFooter',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']}
)
export class FooterComponent implements OnInit {

  refDialog: any = null;
  /**
   * Esta pendiente de accion del boton retroceso
   * del navegador, valida si el formulario esta activado
   * para evitar la usabilidad del boton en las otras funcionalidades
   */
  @HostListener('window:popstate', ['$event'])
  onPopState(_) {
    if(!!this.refDialog){
      window.history.forward();
      this.dialog.closeAll();
      this.refDialog = null;
    }
  }

  public urlPlaystore: string;

  public urlAppstore: string;

  public currentUrl: string;

  constructor(
    private readonly utils: UtilsComponent,
    public dialog: MatDialog,
    @Inject('ExternalMenuServices') private readonly externalMenuService: ExternalMenuServices)
  {
    this.urlAppstore = 'https://apps.apple.com/us/app/open-my-tab/id1486653030';
    this.urlPlaystore = 'https://play.google.com/store/apps/details?id=com.openmytab.app';
    this.currentUrl = '/en/app/restaurant-list';
  }


  mobileAndroid = true;
  mobileIos = true;

  ngOnInit() {
    this.externalMenuService.whiteLabelActive.subscribe(isActive => {
      if (isActive) {
        if (this.utils.urlAppStore && this.utils.urlAppStore.length) {
          this.urlAppstore = this.utils.urlAppStore;
        }
        if (this.utils.urlPlayStore && this.utils.urlPlayStore.length) {
          this.urlPlaystore = this.utils.urlPlayStore;
        }
      }
    });

    if (navigator.userAgent.match(/Android/i)) {
      this.mobileIos = false;
    } else if (navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)) {
      this.mobileAndroid = false;
    }
  }

  backTop() {
    this.utils.backTop();
  }

  openModalPrivacyPolicy() {
    this.refDialog = this.dialog.open(DialogPrivacyPolicyComponent, {
      panelClass: 'dialog-right'
    });
  }
  
  openModalTermsAndConditions() {
    this.refDialog = this.dialog.open(DialogTermsAndContiditionsComponent, {
      panelClass: 'dialog-right'
    });
  }
}
