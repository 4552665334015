import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { DynamicComponentLoader } from 'app/core/dynamic-loader/dynamic-component-loader.service';

@Component({
    selector: 'app-dialog-add-tip-component',
    template: '<div #addTip></div>'})
export class DialogAddTipModalComponent {
    @ViewChild('addTip', { read: ViewContainerRef, static: true }) addTip:
        | ViewContainerRef
        | undefined;

    constructor(private readonly loader: DynamicComponentLoader) {
        this.loader.getComponentFactory('add-tip').subscribe({
            next: factory => {
                if (!this.addTip) {
                    return;
                }
                this.addTip.createComponent(factory).changeDetectorRef.detectChanges();
            }});
    }
}