import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { DynamicComponentLoader } from 'app/core/dynamic-loader/dynamic-component-loader.service';

@Component({
    selector: 'app-dialog-service-time-rest-component',
    template: '<div #serviceTimeRest></div>'})
export class DialogServiceTimeRestComponent {
    @ViewChild('serviceTimeRest', { read: ViewContainerRef, static: true }) serviceTimeRest:
        | ViewContainerRef
        | undefined;

    constructor(private readonly loader: DynamicComponentLoader)
    {
        this.loader.getComponentFactory('time-rest').subscribe({
            next: factory => {
                if (!this.serviceTimeRest) {
                    return;
                }
                this.serviceTimeRest.createComponent(factory).changeDetectorRef.detectChanges();
            }
        });
    }
}
