import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogAddTipModalComponent } from './dialog-add-tip.modal.component';
import { DynamicComponentLoaderModule } from 'app/core/dynamic-loader/dynamic-component-loader.module';


@NgModule({
    declarations: [DialogAddTipModalComponent],
    exports: [DialogAddTipModalComponent],
    imports: [
        CommonModule,
        DynamicComponentLoaderModule.forModule({
            componentId: 'add-tip',
            path: 'add-tip',
            loadChildren: () => import('./content/add-tip-modal.module').then(m => m.AddTipModalModule)}),
    ]})
export class DialogAddTipModule {}
