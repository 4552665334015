import { SharedModule } from 'app/shared/shared.module';
import { NgModule } from '@angular/core';
import { DialogCheckoutComponent } from './dialog-checkout.component';
import { DynamicComponentLoaderModule } from 'app/core/dynamic-loader/dynamic-component-loader.module';

@NgModule({
  declarations: [DialogCheckoutComponent],
  exports: [DialogCheckoutComponent],
  imports: [
    DynamicComponentLoaderModule.forModule({
      componentId: 'checkout',
      path: 'checkout',
      loadChildren: () => import('./content/checkout.module').then(m => m.CheckoutModule)
    }),
    SharedModule
  ]
})
export class DialogCheckoutModule {}
