import { transition } from '@angular/animations';
import Swal, { SweetAlertResult, SweetAlertOptions, SweetAlertIcon } from 'sweetalert2';
import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { fromEvent } from 'rxjs';
import { AuthServices } from 'app/core/interfaces/auth.interfaces';
import { DynamicModalServices } from 'app/core/interfaces/dynamic-modals.interfaces';
import { DialogRegisterComponent } from 'app/shared/dynamic-modules/register/dialog-register.component';
import { DLoginComponent } from 'app/shared/dynamic-modules/login/DLogin.component';
import { promise } from 'protractor';
import { resolve } from 'path';
import { rejects } from 'assert';
import { title } from 'process';

@Injectable()
export class SweetAlertService {

    /**
     *
     * @param translate Servicio de traducción de la aplicación
     */
    constructor(private translate: TranslateService, 
        @Inject('DynamicModalServices') private readonly modalService: DynamicModalServices,
        @Inject('AuthServices') private readonly authService: AuthServices) {

    }
 
    /**
     * Permite mostrar una alerta en la cual se va a elegir una nueva dirección preferida para profile.component
     * @param title
     * @param text
     * @param buttons
     */
    alertMainAddress(
        title: string,
        text: string = null,
        buttons,
        typeAlert: SweetAlertIcon = null,
        buttonText: string = null
    ): Promise<SweetAlertResult> {
        // return new Promise((resolve, reject) => {
        const opts: any = {
            title: title,
            text: text,
            buttons: buttons,
            icon: typeAlert,
            button: buttonText,
            dangerMode: true,
            content: {
                element: 'input',
                attributes: {
                    type: 'radio',
                    id: 'radio',
                    style: '-webkit-appearance: radio;',
                    value: 'text'
                }
            },
            className: 'omt-sweet-confirm'
        };

        if (buttonText === null) {
            delete opts.button;
        }

        if (typeAlert === null) {
            delete opts.icon;
        }

        if (text === null) {
            delete opts.title;
            opts.text = title;
        }
        //     Swal(opts)
        //         .then(res => {
        //             resolve(res === true);
        //         });
        // });
        return Swal.fire(opts);
    }

    /**
     * Permite mostrar un alert personalizado
     *
     * @param title
     * @param text
     * @param typeAlert
     * @param buttonText
     * @param html este parametro sirve para incluir etiquetas html en el string
     */
    public alert(title: string, text: string = null, typeAlert: SweetAlertIcon = null, buttonText: string = null,
        closeOnClickOutside: boolean = true, html: string = null, confirmAction: (() => void) = null): Promise<SweetAlertResult> {
        const opts: SweetAlertOptions = {
            title: title,
            text: text,
            html: html,
            icon: typeAlert,
            confirmButtonText: buttonText ? buttonText : 'Ok',
            allowOutsideClick: closeOnClickOutside,
            customClass: 'omt-sweet-alert'
        };
        if (buttonText === null) {
            delete opts.confirmButtonText;
        }

        if (typeAlert === null) {
            delete opts.icon;
        }

        if (text === null) {
            delete opts.title;
            opts.text = title;
        }

        if (confirmAction) {
            opts.didClose = () => {
                confirmAction();
            };
        }

        return Swal.fire(opts);
    }

    /**
     * Permite mostrar un dialogo de confirmacion personalizado
     *
     * @param title
     * @param text
     * @param typeAlert
     * : Promise<boolean>
     */
    public confirm(title: any, text: string = null, typeAlert: string = null, isContentHtml: boolean = false): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.translate.get(['CANCEL', 'CONFIRM']).subscribe((res: string) => {

                const opts: SweetAlertOptions = {
                    title: title,
                    customClass: {
                        icon: typeAlert,
                    },
                    // buttons: [res['CANCEL'], res['CONFIRM']],
                    showConfirmButton: true,
                    confirmButtonText: res['CONFIRM'],
                    showCancelButton: true,
                    cancelButtonText: res['CANCEL'],
                    reverseButtons: true
                    // type: 'warning',
                    // className: 'omt-sweet-confirm'
                };
                if(isContentHtml){
                    opts.html  = text
                } else {
                    opts.text  = text
                }

                if (typeAlert === null) {
                    delete opts.customClass['icon'];
                }

                if (text === null) {
                    delete opts.title;
                    opts.text = title;
                }

                Swal.fire(opts)
                    .then(resp => {
                        resolve(!!resp.value);
                    });
            });
        });
    }

    /**
     * Servicio de alertas custom con dialogos y botones programables
     * @param text texto presente en la alerta
     * @param buttons arreglo de botones que debe tener la alerta
     * @param title titulo de la alerta (por ahora no se usan en omt)
     * @param icon ruta de un icono (tampoco se usa en omt)
     * @param closeOnClickOutside comportamiento de dar click fuera de la alerta y se cierre o no
     * @param closeOnEsc comportamiento de dar esc durante la alerta y se cierre o no
     * @author Alvaro Felipe Garcia Mendez - Oct. 31-2018 (ultima modificacion)
     * @version 1.0.1
     */
    public custom(
        text: string,
        buttons,
        title: string = null,
        icon: string = null,
        closeOnClickOutside: boolean = true,
        closeOnEsc: boolean = true,
        cancelButton: boolean = true,
        html: string = null) {
        return new Promise((resolve, reject) => {
            const options = {
                text: text,
                showCancelButton: cancelButton,
                confirmButtonText: buttons.confirmButtonText,
                cancelButtonText: buttons.cancelButtonText,
                reverseButtons: true,
                html: text
                // buttons: buttons,
                // showConfirmButton: buttons.showConfirmButton,
                // confirmButtonText: buttons.confirmButtonText,
                // showCancelButton: buttons.showCancelButton,
                // cancelButtonText: buttons.cancelButtonText,
                // allowOutsideClick: closeOnClickOutside,
                // allowEscapeKey: closeOnEsc
            };
            if (title !== null) {
                options['title'] = title;
            }

            if (icon !== null) {
                options['icon'] = icon;
            }
            // if (continueButton) {
            //     opts.buttons = [res['CONTINUE'], res['CONFIRM']];
            // }
            // if (loginButton) {
            //     opts.buttons = [res['CONTINUE'], res['LOGIN']];
            // }
            // if (registerButton) {
            //     opts.buttons = [res['CONTINUE'], res['REGISTER']];
            // }

            // return Swal.fire(options); 
            Swal.fire(options)
                .then(resp => {
                    let response = this._clone(resp);
                    let responseAlert = null;
                    if (response.dismiss && response.dismiss == "cancel") {
                        responseAlert = false;
                    } else if (response.value) {
                        responseAlert = true;
                    }
                    resolve(responseAlert);
                });
        });
    }


    // public alertToSelectTable(dineInConfigOption: number): Promise<string> {

    //     const dineInTypes = { 3: 'TABLE_NUMBER', 4: 'TURN_NUMBER' };

    //     const titleAlertDineIn = dineInTypes[dineInConfigOption];
        
    //     return new Promise((resolve) => {
    //         this.translate.get([titleAlertDineIn, 'OK', 'CANCEL', 'ENTER_A_NUMBER']).subscribe(async (translation: any) => {


    //             const opts: SweetAlertOptions = {
    //                 title: translation[titleAlertDineIn],
    //                 showConfirmButton: true,
    //                 showCancelButton: true,
    //                 confirmButtonText: translation.OK,
    //                 cancelButtonText: translation.CANCEL,
    //                 reverseButtons: true,
    //                 allowOutsideClick: false,
    //                 input: 'tel',
    //                 inputAttributes: {id: 'select-turn-alert-input'},
    //                 customClass: 'select-turn-alert',
    //                 inputValidator: (result) => {
    //                     const regex = /^[0-9]*$/;
    //                     const onlyNumbers = regex.test(result);
    //                     console.log('onlyNumbers', onlyNumbers);
    //                     if (!onlyNumbers || !result) {
    //                         return translation.ENTER_A_NUMBER;
    //                     }

    //                 }
    //             };
    //            const {value} = await Swal.fire(opts);
    //            resolve(value);
    //         });

    //         setTimeout(() => {
    //             const input: HTMLInputElement = document.querySelector('#select-turn-alert-input');
    //             input.addEventListener('keyup', event => {
    //                 input.value = input.value.replace(/[^0-9]*/g, '')
    //             });
                
    //         }, 500);
    //     });








    // }
    /**
     * Permite clonar variables
     * @param value
     */
    private _clone(value: any): any {
        if (value instanceof Array) {
            // return this.cloneArray(value);
        } else if (value instanceof Object) {
            return this._cloneObject(value);
        } else {
            return value;
        }
    }

    /**
     * Permite clonar objetos
     * @param anyObject
     */
    private _cloneObject(anyObject: Object): any {
        const cloneObj = {};
        // tslint:disable-next-line:forin
        for (const attribut in anyObject) {
            cloneObj[attribut] = this._clone(anyObject[attribut]);
        }
        return cloneObj;
    }

    public showcaseStores(msg: string, showOmtLogo: boolean, kindDevice: 'android' | 'ios', allowOutsideClick: boolean = true) {
        
    
        this.translate.get(msg).subscribe(traduction => {

            let title = traduction
            let showCloseButton = true;
            let customClass = '';
            let width = 'auto';
            let html = '';
            if (showOmtLogo) {
                title = '<img src="./assets/images/openmytab.svg" width="200px" alt="Open My Tab">';
                html += traduction;
                html += '<br><br>';
                width = '100%';
                showCloseButton = false;
                customClass = 'swal-custom-styles';
            }
            if (kindDevice === 'android') {
                html += '<a href="https://play.google.com/store/apps/details?id=com.openmytab.app" target="_blank">';
                html += '<img class="swal-img-margin" src="./assets/extra-images/appGoogle.png" alt="Smiley face"> </a>';
            }
            if (kindDevice === 'ios') {
                html += '<a href="https://apps.apple.com/us/app/open-my-tab/id1486653030" target="_blank">';
                html += '<img src="./assets/extra-images/appStore.png" alt="Smiley face"> </a>';
            }

            Swal.fire({
                title: title,
                padding: '3em',
                width: width,
                customClass: customClass,
                html: html,
                showConfirmButton: false,
                showCloseButton: showCloseButton,
                // Se usa el allowOutsideClick para que el pop up no se cierre cuando el usuario presione por fuera del pop up
                allowOutsideClick
            });
        });
    }

    /**
     * Alerta con hipervinculos
     * @param message Mensaje de la alerta
     */
    public alertWithHyperLink(message) {
        let html;
        html = message.YOU_MAY + ' <strong> <a id="login" #login> ' + message.LOGIN + ' </a> </strong>' +
            message.REGISTER_TWO + '<strong> <a id="register" #register>' + message.REGISTER + ' </a> </strong>' +
            message.WITH_US;

        const opts: SweetAlertOptions = {
            html: html,
            showConfirmButton: true,
            confirmButtonText: message.OK,
            customClass: 'omt-sweet-alert',
        };
        Swal.fire(opts).then(resp => {
        });
        const login = document.getElementById('login');
        const register = document.getElementById('register');
        if (login) {

            fromEvent(login, 'click').subscribe(() => {
                Swal.close();
                this.modalService.openDialog(DLoginComponent, {
                    panelClass: 'dialog-right'
                });
            });
        }

        if (register) {
            fromEvent(register, 'click').subscribe(() => {
                Swal.close();
                this.modalService.openDialog(DialogRegisterComponent, {
                    panelClass: 'dialog-right'
                });
            });
        }

    }

    public confirmationCodeAlert(email: string) {


        return new Promise<string>((resolve) => {

            this.translate.get(
                ['PLEASE_CHECK_EMAIL', 'CHECK_CODE', 'COMPLETE_CODE', 'CANCEL'], { email: this.authService.censorEmail(email) }).subscribe(translation => {

                    const opts: SweetAlertOptions = {
                        title: translation.PLEASE_CHECK_EMAIL,
                        showConfirmButton: true,
                        showCancelButton: true,
                        confirmButtonText: translation.CHECK_CODE,
                        cancelButtonText: translation.CANCEL,
                        allowOutsideClick: false,
                        input: 'tel',
                        inputAttributes: { maxlength: '6' },
                        customClass: 'alert-confirm-email',
                        inputValidator: (result) => {
                            if (result.length < 6) {
                                return translation.COMPLETE_CODE;
                            }

                        }
                    };

                    Swal.fire(opts).then((result: SweetAlertResult) => {
                        resolve(result.value);
                    });


                });
        });
    }

    public successfullyVerifiedAccountAlert() {
        return new Promise<string>((resolve) => {

            this.translate.get(['SUCCESSFULLY_VERIFIED', 'LOGIN']).subscribe(translation => {
                const opts: SweetAlertOptions = {
                    title: translation.SUCCESSFULLY_VERIFIED,
                    showConfirmButton: true,
                    confirmButtonText: translation.LOGIN,
                    allowOutsideClick: false
                };

                Swal.fire(opts).then((result: SweetAlertResult) => {
                    resolve(null);
                });
            });
        });
    }


    /**
     * Alerta para mostar un confirm al redimir un cupón sin haber iniciado sesión
     */

    /**
     * Permite mostrar un dialogo de confirmacion personalizado
     *
     * @param title
     * @param text
     * @param info
     * 
     */

    public alertLogin(title: any, text: string = null, info: string = null): Promise<boolean>{
        return new Promise((resolve, reject) => {
            this.translate.get(['USER_LOGIN', 'REGISTER_NOW']).subscribe((res: string) => {

                const opts: SweetAlertOptions = {
                    title: title,
                    text: text,
                    customClass: {
                        icon: info,
                    },
                    showCancelButton: true,
                    focusConfirm: false,
                    confirmButtonText:res['USER_LOGIN'],
                    confirmButtonAriaLabel: 'Thumbs up, great!',
                    cancelButtonText: res['REGISTER_NOW'],
                    cancelButtonColor: '#024661',
                    cancelButtonAriaLabel: 'Thumbs down',
                    allowOutsideClick: false
                };

                Swal.fire(opts)
                    .then(resp => {
                        resolve(!!resp.value);
                    });
            });
        });
    }

/**
     *  Alert para informarle al usaurio que no hay tipos de cocina para filtar la busqueda
     *  @author Oscar Alarcon - ener. 25-2023
     *  @version 1.0.0
     */
    public alertNoCusineType(info: string = null){ 
        return new Promise((resolve, rejects)=>{
            this.translate.get(['NO_TYPES_OF_CUISINE','OK']).subscribe((res: string) => {
                const opts: SweetAlertOptions = {
                    text: res['NO_TYPES_OF_CUISINE'],
                    customClass: {
                        icon: info,
                    },
                    showConfirmButton: true,
                    confirmButtonText: res['OK']
                };
                Swal.fire(opts)
                    .then(resp => {
                        resolve(!!resp.value);
                    });
            });
        });
    }


    /**
     *  Alert para informale al usaurio que no hay caracteristicas para filtrar la busqueda
     *  @author Oscar Alarcon - ener. 25-2023
     *  @version 1.0.0
     */
    public alertNoFeatures(info: string = null){ 
        return new Promise((resolve, rejects)=>{
            this.translate.get(['NO_FEATURES','OK']).subscribe((res: string) => {
                const opts: SweetAlertOptions = {
                    text: res['NO_FEATURES'],
                    customClass: {
                        icon: info,
                    },
                    showConfirmButton: true,
                    confirmButtonText: res['OK']
                };
                Swal.fire(opts)
                    .then(resp => {
                        resolve(!!resp.value);
                    });
            });
        });
    }

    /**
     *  Alert para informarle al usaurio que no hay comidas servidad para filtar la busqueda
     *  @author Oscar Alarcon - ener. 25-2023
     *  @version 1.0.0
     */
    public alertNoMeals(info: string = null){ 
        return new Promise((resolve, rejects)=>{
            this.translate.get(['NO_MEALS','OK']).subscribe((res: string) => {
                const opts: SweetAlertOptions = {
                    text: res['NO_MEALS'],
                    customClass: {
                        icon: info,
                    },
                    showConfirmButton: true,
                    confirmButtonText: res['OK']
                };
                Swal.fire(opts)
                    .then(resp => {
                        resolve(!!resp.value);
                    });
            });
        });
    }

        /**
     *  Alert para informarle al usaurio sobre la confirmación de su registro
     *  @author Oscar Alarcon - feb. 17-2023
     *  @version 1.0.0
     */
        public informationEmailConfirmation(email: string){ 
            return new Promise((resolve, rejects)=>{
                this.translate.get(
                    ['PLEASE_CHECK_EMAIL', 'OK'], { email: this.authService.censorEmail(email)}
                ).subscribe(transition => {
                    const opts: SweetAlertOptions = {
                        title: transition.PLEASE_CHECK_EMAIL,
                        showConfirmButton: true,
                        confirmButtonText: transition.OK
                    };
                    Swal.fire(opts).then((result: SweetAlertResult) => {
                        resolve(result.value);
                    });
                });
            });
        }


    /**
     *  Alert para informarle al usaurio que al momento de pagar su orden hubo fallas en su internet pero la orden si se hizo
     *  cuando el usuario está logueado
     *  @author Oscar Alarcon - may. 01-2023
     *  @version 1.0.0
     */
    public alertConfirmOrderNetworkOffline(info: string = null){ 
        return new Promise((resolve, rejects)=>{
            this.translate.get(['CONFIRM_ORDER_NETWORK_OFFLINE','OK']).subscribe((res: string) => {
                const opts: SweetAlertOptions = {
                    text: res['CONFIRM_ORDER_NETWORK_OFFLINE'],
                    customClass: {
                        icon: info,
                    },
                    showConfirmButton: true,
                    confirmButtonText: res['OK']
                };
                Swal.fire(opts)
                    .then(resp => {
                        resolve(!!resp.value);
                    });
            });
        });
    }
    


    /**
     *  Alerta para ingresar el codigo de verificación por medio del sms en el registro
     *  @author Oscar Alarcon - may. 22-2023
     *  @version 1.0.0
     */
    public alertConfirmationRegisterPreOrder(){
        return new Promise<string>((resolve)=>{
            this.translate.get(
                ['PLEASE_CHECK_MESANGE_TEXT', 'PLEASE_VERIFY_ACCOUNT_TEXT', 'CHECK_CODE', 'COMPLETE_CODE', 'CANCEL']).subscribe(translation => {

                    Swal.fire({                       
                        html: '<div class="verification-input"><input type="tel" inputmode="numeric" pattern="[0-9]*" max="6" id="verification-code" class="verification-code-input" maxlength="6" placeholder="- - - - - -" /></div>',
                        title: translation.PLEASE_VERIFY_ACCOUNT_TEXT,
                        showConfirmButton: true,
                        showCancelButton: true,
                        confirmButtonText: translation.CHECK_CODE,
                        cancelButtonText: translation.CANCEL,
                        allowOutsideClick: false,
                        customClass: 'alert-confirm-email',
                        didOpen: () => {

                            document.getElementById('verification-code').addEventListener('input', () => {                               

                                const inputElement = document.getElementById('verification-code') as HTMLInputElement;
                                const inputValue = inputElement.value;
                                const digitsOnly = inputValue.replace(/\D/g, ''); // Elimina todos los caracteres no numéricos
                                // Limita el valor a 6 dígitos
                                const maskedValue = digitsOnly.slice(0, 6);
                                // Asigna el valor enmascarado al input
                                inputElement.value = maskedValue;
                              });
                            
                        }
                      }).then((result: SweetAlertResult) => {
                        if (result.isConfirmed) {
                            const inputValue =(document.getElementById('verification-code') as HTMLInputElement).value;
                            resolve(inputValue);
                        }else{
                            resolve(result.value);
                        }

                      });


                });
        });
       
    }

    /**
     *  Alerta para informarle al usuario que la cuenta no ha sido verificada por sms en la parte del login
     *  @author Oscar Alarcon - may. 22-2023
     *  @version 1.0.0
     */
    public alertConfirmationRegisterPreOrderLogin(){
        return new Promise<string>((resolve)=>{
            this.translate.get(
                ['PLEASE_CHECK_MESANGE_TEXT_LOGIN', 'CHECK_CODE', 'COMPLETE_CODE', 'CANCEL']).subscribe(translation => {

                    Swal.fire({                       
                        html: '<div class="verification-input"><input type="tel" inputmode="numeric" pattern="[0-9]*" max="6" id="verification-code" class="verification-code-input" maxlength="6" placeholder="- - - - - -" /></div>',
                        title: translation.PLEASE_CHECK_MESANGE_TEXT_LOGIN,
                        showConfirmButton: true,
                        showCancelButton: true,
                        confirmButtonText: translation.CHECK_CODE,
                        cancelButtonText: translation.CANCEL,
                        allowOutsideClick: false,
                        customClass: 'alert-confirm-email',
                        didOpen: () => {

                            document.getElementById('verification-code').addEventListener('input', () => {                               

                                const inputElement = document.getElementById('verification-code') as HTMLInputElement;
                                const inputValue = inputElement.value;
                                const digitsOnly = inputValue.replace(/\D/g, ''); // Elimina todos los caracteres no numéricos
                                // Limita el valor a 6 dígitos
                                const maskedValue = digitsOnly.slice(0, 6);
                                // Asigna el valor enmascarado al input
                                inputElement.value = maskedValue;
                              });
                            
                        }
                      }).then((result: SweetAlertResult) => {
                        if (result.isConfirmed) {
                            const inputValue =(document.getElementById('verification-code') as HTMLInputElement).value;
                            resolve(inputValue);
                        }else{
                            resolve(result.value);
                        }

                      });


                });
        });
    }

    /**
     *  Alert para informarle al usaurio que no se puede pagar con contra entrega cuando es una orden diferida
     *  @author Oscar Alarcon - may. 29-2023
     *  @version 1.0.0
     */
    /*public paymentAllowedWhenUsingDeferred(info: string = null){ 
        return new Promise((resolve, rejects)=>{
            this.translate.get(['CHAS_IS_NOT_ALLOWEB_DEFERRED','OK']).subscribe((res: string) => {
                const opts: SweetAlertOptions = {
                    text: res['CHAS_IS_NOT_ALLOWEB_DEFERRED'],
                    customClass: {
                        icon: info,
                    },
                    showConfirmButton: true,
                    confirmButtonText: res['OK']
                };
                Swal.fire(opts)
                    .then(resp => {
                        resolve(!!resp.value);
                    });
            });
        });
    }*/

    /**
     *  Alert para informarle al usaurio que debe de seleccionar la fecha y la hora para hacer la orden diferida
     *  @author Oscar Alarcon - may. 29-2023
     *  @version 1.0.0
     */
    public SelectDateAndHour(info: string = null){ 
        return new Promise((resolve, rejects)=>{
            this.translate.get(['REQUIRED_TIME_HOUR','OK']).subscribe((res: string) => {
                const opts: SweetAlertOptions = {
                    text: res['REQUIRED_TIME_HOUR'],
                    customClass: {
                        icon: info,
                    },
                    showConfirmButton: true,
                    confirmButtonText: res['OK']
                };
                Swal.fire(opts)
                    .then(resp => {
                        resolve(!!resp.value);
                    });
            });
        });
    }

    /**
     *  Alert para informarle al usaurio que debe de seleccionar la fecha y la hora para hacer la orden diferida
     *  @author Oscar Alarcon - may. 29-2023
     *  @version 1.0.0
     */
    public CloseOrderDeferred(info: string = null){ 
        return new Promise((resolve, rejects)=>{
            this.translate.get(['TEMPORALLY_CLOSED_DEFERRED','OK']).subscribe((res: string) => {
                const opts: SweetAlertOptions = {
                    text: res['TEMPORALLY_CLOSED_DEFERRED'],
                    customClass: {
                        icon: info,
                    },
                    showConfirmButton: true,
                    confirmButtonText: res['OK']
                };
                Swal.fire(opts)
                    .then(resp => {
                        resolve(!!resp.value);
                    });
            });
        });
    }

    /**
     *  Alert para informarle al usaurio que debe de seleccionar la fecha y la hora para hacer la orden diferida
     *  @author Oscar Alarcon - may. 29-2023
     *  @version 1.0.0
     */
    public restaurantClose(info: string = null){ 
        return new Promise((resolve, rejects)=>{
            this.translate.get(['TEMPORALLY_CLOSED','OK']).subscribe((res: string) => {
                const opts: SweetAlertOptions = {
                    text: res['TEMPORALLY_CLOSED'],
                    customClass: {
                        icon: info,
                    },
                    showConfirmButton: true,
                    confirmButtonText: res['OK']
                };
                Swal.fire(opts)
                    .then(resp => {
                        resolve(!!resp.value);
                    });
            });
        });
    }

    /**
     *  Alert para informarle al usaurio que hubo un error
     *  @author Oscar Alarcon - may. 29-2023
     *  @version 1.0.0
     */
    public ErrorSystem(info: string = null){ 
        return new Promise((resolve, rejects)=>{
            this.translate.get(['ERROR','OK']).subscribe((res: string) => {
                const opts: SweetAlertOptions = {
                    text: res['ERROR'],
                    customClass: {
                        icon: info,
                    },
                    showConfirmButton: true,
                    confirmButtonText: res['OK']
                };
                Swal.fire(opts)
                    .then(resp => {
                        resolve(!!resp.value);
                    });
            });
        });
    }

    /**
     *  Alert para informarle al usaurio que el servicio de dine in no está activado
     *  @author Oscar Alarcon - febr. 12-2024
     *  @version 1.0.0
     */
    public showNoDineIn(msg: string, textButton: string,  allowOutsideClick: boolean = true) {
        
    
        this.translate.get(msg).subscribe(traduction => {
            this.translate.get(textButton).subscribe(buttonTraduction => {
                let title = traduction
                let showCloseButton = true;
                let customClass = '';
                let html = '';

                    title = '<img src="./assets/extra-images/dineInNoAvailable.svg" width="200px" alt="Open My Tab">';
                    html += traduction;
                    html += '<br><br>';
                    showCloseButton = false;
                    customClass = 'swal-container-noDineIn';

                Swal.fire({
                    title: title,
                    padding: '3em',
                    customClass: customClass,
                    html: html,
                    showConfirmButton: true,
                    confirmButtonText: buttonTraduction,
                    showCloseButton: showCloseButton,
                    willOpen: (modalElement) => {
                        modalElement.style.maxWidth = '350px';
                        modalElement.style.height = '400px'
                    },
                    // Se usa el allowOutsideClick para que el pop up no se cierre cuando el usuario presione por fuera del pop up
                allowOutsideClick
            });
            });
        });
    }


}
