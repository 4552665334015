<ul class="dropdown-menu" aria-labelledby="dropdownMenu2" [ngClass]="{'focus':isFocus}">
   <li class="currentLoc">
        <em class="fa fa-crosshairs"></em>
        <a (click)="selectCurrentLocation()">
            {{ 'USE_CURRENT_LOCATION' | translate }}
        </a>
    </li>

    <ng-container *ngIf="predictions.length > 0">
        <li role="separator" class="divider"></li>
        <li class="dropdown-header">
            {{ 'RESULT_SEARCH' | translate }}
        </li>
        <li *ngFor="let prediction of predictions ">
            <a (click)="selectPrediction(prediction)">
                {{ prediction.description }}
            </a>
        </li>
    </ng-container>

    <ng-container *ngIf="showAddress && addresses.length > 0">
        <li role="separator" class="divider"></li>
        <li class="dropdown-header">
            {{ 'YOUR_DIRECTIONS' | translate }}
        </li>
        <li *ngFor="let address of addresses ">
            <a (click)="selectAddress(address)">
                {{ address.name }} :: {{ address.naturalAddress }}
            </a>
        </li>
    </ng-container>

</ul>