import { Component, Inject, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UtilsComponent } from 'app/utils/utils.component';
import { PhoneOrderType } from 'app/core/enums/PhoneOrderType';
import { OrderServices } from 'app/core/interfaces/orders.interfaces';
import { PaymentGatewayServiceService } from 'app/core/services/payment-gateway-service.service';
import { AppConstants } from 'app/app-constants';
import { RestaurantOrder } from 'app/core/entities/RestaurantOrder';
import { SweetAlertService } from 'app/utils/sweetAlert.service';

@Component({
  selector: 'app-payment-gateway',
  templateUrl: './payment-gateway.component.html',
  styleUrls: ['./payment-gateway.component.css']
})
export class PaymentGatewayComponent implements OnInit {

  public showViewPanel: string;
  public paymentGatewayData: any; 
  public dataUser: any;
  public estimatedTime: string; 
  public orderTypeSelected: any; 
  public creditCardPaymentStatus: boolean;
  public typeServices = AppConstants.SERVICES_RESTAURANT;
   /**
     * Status of the pawyay(credit card or upondelivery)
     */
   public isCrediCarPanel: boolean;
   public uponDeliveryStatus: boolean;
    /**
   * Variable para almacenar el nombre del restaurante
   */
    public restaurant: RestaurantOrder;

  constructor(
    private  _utils: UtilsComponent,
    public  translate: TranslateService,
    @Inject('OrderServices') public readonly orderServices: OrderServices,
    private _paymentGatewayServiceService: PaymentGatewayServiceService,
    private readonly sweet: SweetAlertService,
    private  utils: UtilsComponent,
  ) { 
    this.showViewPanel = 'order-completed';
    this.estimatedTime = '30 minutos'; // Cambiado a un valor de ejemplo
    this.isCrediCarPanel = true;
    this.creditCardPaymentStatus = false;
    this.restaurant = null;
  }

  ngOnInit(): void {
    let language = localStorage.getItem(AppConstants.LOCAL_STORAGE_DATA.LANGUAGE_USER);
    this.restaurant = this._utils.clone(this.orderServices.getRestaurant());
    this.creditCardPaymentStatus = true;
    this.uponDeliveryStatus = false;
    if (!this.creditCardPaymentStatus) {
      this.isCrediCarPanel = false;
  }
  // Si en los valores por defecto no settearon como principal el
        // pago con tarjeta de credito se habilita el pago con tarjeta
        if (!this.uponDeliveryStatus && !this.creditCardPaymentStatus) {
          this.creditCardPaymentStatus = true;
          this.isCrediCarPanel = true;
      }
    this.paymentGatewayData = this._paymentGatewayServiceService.extractPaymentGatewayData();
    if(this.paymentGatewayData.responseCode === 200){
      this.orderServices.paymentGateway(this.paymentGatewayData).subscribe();
    }else {
      // si la respuesta no es un 200, se muestra un alert informandole al usuario que hubo un error en el pago
      this.translate.get('PAYMENT_ERROR').subscribe(resp => {
        this.sweet.alert(resp, null, null, null, true, null, () => {
            // Se envia el usuario al menu del restaurante
            this.utils.navigateRestaurant(language, this.utils.idRestaurantExt);
        });
    });
    }

    const that = this;
    this._utils.initializeLandingPages(true);
    this.orderTypeSelected = that.orderServices.getTypeOrder();
    if (JSON.parse(localStorage.getItem(AppConstants.LOCAL_STORAGE_DATA.DATA_USER))) {
      that.dataUser = JSON.parse(localStorage.getItem(AppConstants.LOCAL_STORAGE_DATA.DATA_USER));
    }
    that.orderTypeSelected = that.orderServices.getTypeOrder();
  }
}
