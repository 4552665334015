<!-- <form id="filterSearchForm" class="wm-listing-search fileSearch" [formGroup]="searchForm" novalidate (ngSubmit)="doSearch(searchForm.value, searchForm.valid, searchCity.value)">
-->
<form id="filterSearchForm" name="formSearch" class="wm-listing-search fileSearch" novalidate (ngSubmit)="toSearch()">
    <article class="wm-search main-fields">
        <input placeholder="{{ 'RESTAURANT_NAME' | translate }}" type="text" [(ngModel)]="formSearch.textFilter" name="search" aria-label="searchRestaurant">
        <div class="wm-search-inputLoc ">
            <em class="fa fa-map-marker marker" aria-hidden="true"></em>
            <span>{{ 'NEAR_LOCATION' | translate }}</span>
            <input id="cityState" [appAutoCompleteDirective] [enableAddress]="true" (onChange)="resultSearchLocation($event)"
                (keyup)="changeFieldNearLocation($event)" #nearLocation name="nearLocation" (keydown.enter)="$event.preventDefault()"
                autocorrect="off" autocapitalize="off" spellcheck="off" type="text" placeholder="{{ 'LocationAddressCityStateZipCode' | translate }}"
                [(ngModel)]="inputNearAddress" [isListeningValueInput]="true" aria-label="city state" />
        </div>
        <button type="submit" class="search-simple fa fa-search" aria-hidden="true" value="Search" aria-label="Search"></button>
    </article>

    <aside class="searchCont">

        <div id="searchToggle" @easeInOut class="filtersearch" [ngClass]="{'filterShow': isOpenAvancedOptions, 'filterHide': !isOpenAvancedOptions}">
            <div>

                <div class="container">
                    <div class="row">
                        <div *ngIf="isError" class="alert alert-danger alert-dismissable nlblk">
                            <button type="button" class="close" (click)="closeDivError()" aria-hidden="true" aria-label="close">&times;</button>{{messageError}}
                        </div>
                        <div class="w64">
                            <ul class="flex">
                                <!-- ordenar por -->
                                <li>
                                    <!-- <select id="orderFilterSearch" class="input cien" (change)="changeOrderList($event.target.value)">
                                            <option selected="" value="0">{{ 'OrderListBy' | translate }}</option>
                                            <option value="1">{{ 'Nearest' | translate }}</option>
                                            <option value="6">{{ 'RESTAURANTS_WITH_COUPONS' | translate }}</option>
                                            <option value="7">{{ 'BEST_RATED' | translate }}</option>
                                    </select> -->
                                    <span class="multiselect-native-select">
                                        <div class="btn-group">
                                            <button type="button" class="multiselect dropdown-toggle btn btn-default" data-toggle="dropdown" title="{{ 'OrderListBy' | translate }}" aria-expanded="true" aria-label="sort by">
                                                <!-- <span class="multiselect-selected-text">{{ 'OrderListBy' | translate }}</span> -->
                                                <span class="multiselect-selected-text" *ngIf="formSearch.order == '1' || formSearch.order == ''">{{ 'Nearest' | translate }}</span>
                                                <span class="multiselect-selected-text" *ngIf="formSearch.order == '6'">{{ 'RESTAURANTS_WITH_COUPONS' | translate }}</span>
                                                <span class="multiselect-selected-text" *ngIf="formSearch.order == '7'">{{ 'BEST_RATED' | translate }}</span>
                                                <span class="multiselect-selected-text" *ngIf="formSearch.order == '8'">{{ 'OpenNow' | translate }}</span>
                                                <strong class="caret"></strong>
                                            </button>
                                            <ul class="multiselect-container select dropdown-menu" (change)="changeOrderList($event.target.value)">
                                                <li>
                                                    <label>
                                                        <input type="radio" name="order" [value]="1" checked="true" aria-checked="true">
                                                        {{ 'Nearest' | translate }}
                                                    </label>
                                                    <label>
                                                        <input type="radio" name="order" [value]="6">
                                                        {{ 'RESTAURANTS_WITH_COUPONS' | translate }}
                                                    </label>
                                                    <label>
                                                        <input type="radio" name="order" [value]="7">
                                                        {{ 'BEST_RATED' | translate }}
                                                    </label>
                                                    <label>
                                                        <input type="radio" name="order" [value]="8">
                                                        {{ 'OpenNow' | translate }}
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </span>

                                </li>
                                <li>
                                    <!-- (change)="changeRange($event.target.value)" -->
                                    <!-- <select id="rangeFilterSearch" class="input cien" (change)="changeRange($event.target.value)">

                                            <option value="1">{{ RANGE_SEARCH_RESTAURANTS.FIVE_MILLES | distance:
                                                metricUnit : false | decimals:'1.0-0' }}
                                                <span *ngIf="metricUnit === 'mi'">{{ 'MILES' | translate }}</span>
                                                <span *ngIf="metricUnit === 'km'">{{ 'KILOMETERS' | translate }}</span>
                                            </option>
                                            <option value="2">{{ RANGE_SEARCH_RESTAURANTS.TEN_MILLES | distance: metricUnit
                                                : false | decimals:'1.0-0' }}
                                                <span *ngIf="metricUnit === 'mi'">{{ 'MILES' | translate }}</span>
                                                <span *ngIf="metricUnit === 'km'">{{ 'KILOMETERS' | translate }}</span>
                                            </option>
                                            <option value="3">{{ RANGE_SEARCH_RESTAURANTS.FIFTEEN_MILLES | distance:
                                                metricUnit : false | decimals:'1.0-0' }}
                                                <span *ngIf="metricUnit === 'mi'">{{ 'MILES' | translate }}</span>
                                                <span *ngIf="metricUnit === 'km'">{{ 'KILOMETERS' | translate }}</span>
                                            </option>
                                            <option value="4">{{ RANGE_SEARCH_RESTAURANTS.TWENTY_MILLES | distance:
                                                metricUnit : false | decimals:'1.0-0' }}
                                                <span *ngIf="metricUnit === 'mi'">{{ 'MILES' | translate }}</span>
                                                <span *ngIf="metricUnit === 'km'">{{ 'KILOMETERS' | translate }}</span>
                                            </option>
                                        </select> -->

                                    <span class="multiselect-native-select" id="rangeFilterSearch">
                                        <div class="btn-group" style="background-color: red;">
                                            <button type="button" class="multiselect dropdown-toggle btn btn-default" data-toggle="dropdown" title="" aria-expanded="true" aria-label="sort by range">
                                                <!-- <span class="multiselect-selected-text">{{ 'Distance' | translate }}</span> -->
                                                <span class="multiselect-selected-text" *ngIf="formSearch.range == '1' || formSearch.range == ''">{{ RANGE_SEARCH_RESTAURANTS.FIVE_MILLES | distance: metricUnit : false | decimals:'1.0-0' }}</span>
                                                <span class="multiselect-selected-text" *ngIf="formSearch.range == '2'">{{ RANGE_SEARCH_RESTAURANTS.TEN_MILLES | distance: metricUnit : false | decimals:'1.0-0' }}</span>
                                                <span class="multiselect-selected-text" *ngIf="formSearch.range == '3'">{{ RANGE_SEARCH_RESTAURANTS.FIFTEEN_MILLES | distance: metricUnit : false | decimals:'1.0-0' }}</span>
                                                <span class="multiselect-selected-text" *ngIf="formSearch.range == '4'">{{ RANGE_SEARCH_RESTAURANTS.TWENTY_MILLES | distance: metricUnit : false | decimals:'1.0-0' }}</span>
                                                <span *ngIf="metricUnit === 'mi'">&nbsp;{{ 'MILES' | translate }}</span>
                                                <span *ngIf="metricUnit === 'km'">&nbsp;{{ 'KILOMETERS' | translate }}</span>
                                                <strong class="caret"></strong>
                                            </button>
                                            <ul class="multiselect-container select dropdown-menu" (change)="changeRange($event.target.value)">
                                                <li>
                                                    <label value="1">
                                                        <input type="radio" name="range" [value]="1" checked aria-checked="true"/>
                                                        {{ RANGE_SEARCH_RESTAURANTS.FIVE_MILLES | distance: metricUnit : false | decimals:'1.0-0' }}
                                                        <span *ngIf="metricUnit === 'mi'">&nbsp;{{ 'MILES' | translate }}</span>
                                                        <span *ngIf="metricUnit === 'km'">&nbsp;{{ 'KILOMETERS' | translate }}</span>
                                                        
                                                    </label>
                                                    <label value="2">
                                                        <input type="radio" name="range" [value]="2"/>
                                                        {{ RANGE_SEARCH_RESTAURANTS.TEN_MILLES | distance: metricUnit : false | decimals:'1.0-0' }}
                                                        <span *ngIf="metricUnit === 'mi'">&nbsp;{{ 'MILES' | translate }}</span>
                                                        <span *ngIf="metricUnit === 'km'">&nbsp;{{ 'KILOMETERS' | translate }}</span>
                                                        
                                                    </label>
                                                    <label value="3">
                                                        <input type="radio" name="range" [value]="3"/>
                                                        {{ RANGE_SEARCH_RESTAURANTS.FIFTEEN_MILLES | distance: metricUnit : false | decimals:'1.0-0' }}
                                                        <span *ngIf="metricUnit === 'mi'">&nbsp;{{ 'MILES' | translate }}</span>
                                                        <span *ngIf="metricUnit === 'km'">&nbsp;{{ 'KILOMETERS' | translate }}</span>
                                                        
                                                    </label>
                                                    <label value="4">
                                                        <input type="radio" name="range" [value]="4"/>
                                                        {{ RANGE_SEARCH_RESTAURANTS.TWENTY_MILLES | distance: metricUnit : false | decimals:'1.0-0' }}
                                                        <span *ngIf="metricUnit === 'mi'">&nbsp;{{ 'MILES' | translate }}</span>
                                                        <span *ngIf="metricUnit === 'km'">&nbsp;{{ 'KILOMETERS' | translate }}</span>
                                                        
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </span>
                                    
                                </li>
                                <!-- SE BORRA EL CODIGO QUE PROCESA LA LOGICA DE LA DISTANCIA -->
                                <!--
                                    <li>
                                        <div class="input">
                                            <div class="pull-left">{{ 'Distance' | translate }} </div>
                                            <div class="range">
                                            <input [(ngModel)]="formSearch.distance" id="distance" #range type="range" name="range" min="0" max="1000" (change)="updatedistance(formSearch.distance)"/> // comentado
                                                <input [(ngModel)]="formSearch.distance" id="range-distance" #range type="range" name="range" min="0" max="1000"/>
                                                <output id="distance">
                                                    {{range.value}} {{ 'Miles' | translate }} - {{ rangeKm }} {{ 'Km' | translate}} // comentado
                                                    10 {{ 'Miles' | translate }} - 20 {{ 'Miles' | translate}} 
                                                </output>
                                            </div>
                                        </div>
                                    </li>
                                -->
                               <!--  <li>
                                    <div class="input">
                                            {{ 'OPEN' | translate }}
                                            <label class="btn-switch">
                                                <input type="checkbox" checked [(ngModel)]="formSearch.onlyOpen" name="only_open">
                                                <div class="btn-slider"></div>
                                            </label>
                                    </div> -->
                                  <!--   <span class="multiselect-native-select">
                                        <div class="btn-group">
                                            <button type="button" class="multiselect dropdown-toggle btn btn-default" data-toggle="dropdown" title="" aria-expanded="true" aria-label="sort by status">
                                                 <span class="multiselect-selected-text">Status</span> 
                                                <span class="multiselect-selected-text" *ngIf="formSearch.onlyOpen == true || formSearch.onlyOpen">{{ 'OPEN' | translate }}</span>
                                                <span class="multiselect-selected-text" *ngIf="formSearch.onlyOpen == false">{{ 'CLOSED' | translate }}</span>
                                                <b class="caret"></b>
                                            </button> 
                                            <ul class="multiselect-container select dropdown-menu" (change)="changeStatus($event.target.value)">
                                                <li>
                                                    <label value="1">
                                                        <input type="radio" [value]="true" name="onlyOpen" checked>
                                                        {{ 'OPEN' | translate }}
                                                    </label>
                                                    <label value="2">
                                                        <input type="radio" [value]="false" name="onlyOpen">
                                                        {{ 'CLOSED' | translate }}
                                                    </label>
                                                     <label value="3">
                                                        <input type="radio" name="onlyOpen">
                                                        No Available
                                                    </label> 
                                                </li>
                                            </ul>
                                        </div>
                                    </span>
                                    
                                </li>
                                -->
                                <li>
                                    <span class="multiselect-native-select">
                                        <div class="btn-group">
                                            <button type="button" class="multiselect dropdown-toggle btn btn-default" (click)="noTypeCuisines()"
                                                data-toggle="dropdown" title="Type Cuisines" aria-expanded="true" [ngClass]="{'load-selects': !responseCuisines}" aria-label="sort by cuisine type">
                                                <span class="multiselect-selected-text flx">{{ 'CUISINE_TYPE' | translate
                                                    }}<span class="number-filter"> {{ cuisinesLength }}</span>
                                                    <app-loading-image [hidden]="responseCuisines" [classCSS]="'section-loading loadFil'"></app-loading-image>
                                                </span>
                                                <strong class="caret"></strong>
                                            </button>
                                            <ul *ngIf="cuisines && cuisines.length > 0" class="multiselect-container dropdown-menu"
                                                (click)="$event.stopPropagation();" (change)="changeFilters('cuisines')">
                                                <li *ngFor="let item of cuisines">
                                                    <a tabindex="0">
                                                        <label class="checkbox labelSelect">
                                                            <input [(ngModel)]="item.checked" type="checkbox"
                                                                [ngModelOptions]="{standalone: true}" aria-checked="true"> {{item.name}}
                                                        </label>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </span>
                                </li>
                                <li>
                                    <span class="multiselect-native-select">
                                        <div class="btn-group">
                                            <button type="button" class="multiselect dropdown-toggle btn btn-default" (click)="noFeatures()"
                                                data-toggle="dropdown" title="Especial Features" aria-expanded="true" [ngClass]="{'load-selects': !responseFeatures}" aria-label="sort by features">
                                                <span class="multiselect-selected-text flx">{{ 'EspecialFeatures' |
                                                    translate }}<span class="number-filter"> {{ featuresLength }}</span>
                                                    <app-loading-image  [hidden]="responseFeatures" [classCSS]="'section-loading loadFil'"></app-loading-image>
                                                </span>
                                                <strong class="caret"></strong>
                                            </button>
                                            <ul class="multiselect-container dropdown-menu" (click)="$event.stopPropagation();"
                                            (change)="changeFilters('features')">
                                                <li *ngFor="let item of features">
                                                    <a tabindex="0">
                                                        <label class="checkbox labelSelect">
                                                            <input [(ngModel)]="item.checked" type="checkbox"
                                                                [ngModelOptions]="{standalone: true}" aria-checked="true"> {{item.name}}
                                                        </label>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </span>
                                </li>
                                <li>
                                    <span class="multiselect-native-select">
                                        <div class="btn-group">
                                            <button type="button" class="multiselect dropdown-toggle btn btn-default" (click)="noMeals()"
                                                data-toggle="dropdown" title="Offer Food" aria-expanded="true" [ngClass]="{'load-selects': !responseMeals}" aria-label="sort by offer food">
                                                <span class="multiselect-selected-text flx">{{ 'MEALS' | translate }}
                                                        <span class="number-filter"> {{ mealsLength }}</span>
                                                        <app-loading-image [hidden]="responseMeals" [classCSS]="'section-loading loadFil'"></app-loading-image>
                                                </span>
                                                <strong class="caret"></strong>
                                            </button>
                                            <ul class="multiselect-container dropdown-menu" (click)="$event.stopPropagation();"
                                            (change)="changeFilters('meals')">
                                                <li *ngFor="let item of meals">
                                                    <a tabindex="0">
                                                        <label class="checkbox labelSelect">
                                                            <input [(ngModel)]="item.checked" type="checkbox"
                                                                [ngModelOptions]="{standalone: true}" aria-checked="true"> {{item.name}}
                                                        </label>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </span>
                                </li>
                                <li class="byephone">
                                    <input id="searchButton" class="bigsearch" type="button" value="{{ 'Search' | translate }}"
                                        (click)="toSearch(true)">
                                </li>
                                <li class="hiphone">
                                    <input class="bigsearch" type="button" value="{{ 'Search' | translate }}" (click)="toSearch(true)">
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </aside>
</form>