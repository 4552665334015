import { Component, ViewChild, ViewContainerRef, Input } from '@angular/core';
import { DynamicComponentLoader } from 'app/core/dynamic-loader/dynamic-component-loader.service';

@Component({
    selector: 'app-dialog-login-component',
    template: '<div #outlet></div>'})
export class DLoginComponent {
    @ViewChild('outlet', { read: ViewContainerRef, static: true }) _outlet:
        | ViewContainerRef
        | undefined;
    @Input() public fundRaiser: boolean;

    constructor(private readonly loader: DynamicComponentLoader) {
        this.loader.getComponentFactory('login').subscribe({
            next: factory => {
                if (!this._outlet) {
                    return;
                }
                const ref = this._outlet.createComponent(factory);
                // Pasarle un parametro al componente que vamos a crear
                ref.instance['fundRaiser'] = this.fundRaiser;
                ref.changeDetectorRef.detectChanges();
            }
        });
    }
}
