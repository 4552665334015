import { NgModule } from '@angular/core';
import { DynamicComponentLoaderModule } from 'app/core/dynamic-loader/dynamic-component-loader.module';
import { DialogNotificationProductsComponent } from './dialog-notification-products.component';

@NgModule({
    declarations: [DialogNotificationProductsComponent],
    exports: [DialogNotificationProductsComponent],
    imports: [
        DynamicComponentLoaderModule.forModule({
            componentId: 'notification-product',
            path: 'notification-product',
            loadChildren: () => import('./content/notification-products.module').then(m => m.NotificationProductsModule)
        }),
    ]
})
export class DialogNotificationProductsModule { }
