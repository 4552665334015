import { RouterModule, Routes } from '@angular/router';

import { CommonModule } from '@angular/common';
import { LocalizeRouterModule } from 'localize-router';
import { NgModule } from '@angular/core';
import { PaymentGatewayComponent } from './payment-gateway/payment-gateway.component';

const routes: Routes = [
  {
    path: 'dejavoo',
    component: PaymentGatewayComponent
  }
]

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    LocalizeRouterModule.forChild(routes)
  ],
  exports: [
    RouterModule,
    LocalizeRouterModule
  ]
})
export class PaymentGatewayRoutingModule { }
