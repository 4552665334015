export class OrderTotals {
    taxs: Array<any>;
    taxes: number;
    preSubTotal: number;
    subTotal: number;
    charges: number;
    discounts: {
        total: number
    };
    discountOrderCouponData: {
        discount: number
    };
    totalTax: number;
    taxsIncluide: number;
    total: number;
    exemptTaxComment: string;
    uponDeliveryData: Object;
    tip: number;
    tipPercent: number;
    useLoyaltyPoints?: number;
    uponDelivery?: boolean;
    payPoints?: boolean;

    constructor() {
        this.taxs = [];
        this.taxes = 0;
        this.preSubTotal = 0;
        this.subTotal = 0;
        this.total = 0;
        this.charges = 0;
        this.discounts = {
            total: 0
        };
        this.discountOrderCouponData = {
            discount: 0
        };
        this.totalTax = 0;
        this.taxsIncluide = 0;
        this.exemptTaxComment = null;
        this.tip = 0;
    }

}
