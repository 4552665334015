import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DynamicModalServices } from 'app/core/interfaces/dynamic-modals.interfaces';

declare var jQuery: any;
@Injectable({
    providedIn: 'root'
})
export class DynamicModalsService implements DynamicModalServices{

    constructor(public dialog: MatDialog) {}
    
    /**
     *
     * @param component
     * @param data {MatDialogConfig}  {
     *                panelClass: dialog-right,
     *                data : {}
     *              }
     *  https://material.angular.io/components/dialog/api
     */
    openDialog(component: any, data: any): void {
        const dialogRef = this.dialog.open(component, data);
        dialogRef.afterOpened().subscribe(result => {
            jQuery('body').addClass('dialog-open');
        });
        dialogRef.beforeClosed().subscribe(result => {
            jQuery('body').removeClass('dialog-open');
        });
    }
}
