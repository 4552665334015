import { ToastrService } from 'ngx-toastr';
import { Injectable } from '@angular/core';
import { MessagesServices } from 'app/core/interfaces/messages.interfaces';

/**
 * Servicio encargardo de mostar los mensajes de completado o error en las transacciones
 *
 * @author Jhonier Gaviria
 * @version 1.0
 */
@Injectable()
export class MessagesService implements MessagesServices{

    /**
     * Constructor de la clase
     * @param toastrService Es el servicio que permite mostrar un mensaje informativo para el usuario en la parte superior de la pantalla
     */
    constructor(private toastrService: ToastrService) { }

    /**
     * Método encargado de mostrar un mensaje exitoso a partir del que recibe como parámetro
     * @param message Es el mensaje a mostrar al usuario
     */
    showMessageSuccess(message: string): void {
        this.toastrService.success(message);
    }

    /**
     * Método encargado de mostrar un mensaje de error a partir del que recibe como parámetro
     * @param message Es el mensaje a mostrar al usuario
     */
    showMessageWarning(message: string): void {
        this.toastrService.warning(message);
    }
}
